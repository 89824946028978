import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table';
import { BaseTable } from './base';
import {
  getCheckboxColumn,
  getRowSelectionFromSelectedItems,
  onRowSelectionChange,
  SelectableTableProps,
  TablePageSizeOptions,
  UNKNOWN_TOTAL_VALUE,
  UnselectableTableProps
} from './helpers';

interface CommonTableProps<TableData> {
  id: string;
  data: TableData[];
  totalRows?: number;
  columns: any[];
  pageSizeOptions?: TablePageSizeOptions;
  pagination: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  borderless?: boolean;
  loading?: boolean;
  isHighlightedRow?: (row: TableData) => boolean;
  noDataMessage?: JSX.Element | string;
  'aria-label'?: React.AriaAttributes['aria-label'];
  'aria-invalid'?: React.AriaAttributes['aria-invalid'];
  'aria-describedby'?: React.AriaAttributes['aria-describedby'];
}

type ServerSideUnselectableTableProps<TableData> = CommonTableProps<TableData> &
  UnselectableTableProps;

type ServerSideSelectableTableProps<TableData> = CommonTableProps<TableData> &
  SelectableTableProps<TableData>;

type ServerSideTableProps<TableData> =
  | ServerSideUnselectableTableProps<TableData>
  | ServerSideSelectableTableProps<TableData>;

function ServerSideTable<TableData>({
  id,
  data,
  totalRows,
  columns,
  enableSelectedItems,
  selectedItems,
  selectedItemsKey,
  onSelectedItemsChange,
  pageSizeOptions = [10, 25, 50, 100],
  pagination,
  onPaginationChange,
  sorting,
  onSortingChange,
  borderless,
  loading = false,
  isHighlightedRow,
  noDataMessage,
  'aria-label': ariaLabel,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy
}: ServerSideTableProps<TableData>) {
  const effectiveColumns = columns.map((col) => {
    col.sortDescFirst = false;

    // Sorting is enabled by default, which we don't want for server-side tables
    col.enableSorting = col.enableSorting || false;

    return col;
  });

  const hasKnownTotal = totalRows !== undefined;

  const tableOptions: TableOptions<TableData> &
    Required<Pick<TableOptions<TableData>, 'state'>> = {
    data,
    manualPagination: true,
    onPaginationChange,
    pageCount: hasKnownTotal
      ? Math.ceil(totalRows / pagination.pageSize)
      : UNKNOWN_TOTAL_VALUE,
    manualSorting: true,
    enableSorting: true,
    enableSortingRemoval: false,
    onSortingChange,
    enableColumnFilters: false,
    columns: effectiveColumns,
    meta: {
      baseId: id,
      loading,
      server: {
        totalRows: hasKnownTotal ? totalRows : UNKNOWN_TOTAL_VALUE
      }
    },
    filterFns: {
      // Server-side tables don't use this, but we have to define it because of TypeScript
      /* istanbul ignore next -- @preserve */
      multiFilterFn() {
        return true;
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      pagination,
      sorting
    }
  };

  if (enableSelectedItems) {
    effectiveColumns.unshift(getCheckboxColumn({ id }));

    tableOptions.state.rowSelection = getRowSelectionFromSelectedItems({
      selectedItems,
      selectedItemsKey,
      data
    });

    tableOptions.onRowSelectionChange = onRowSelectionChange({
      onSelectedItemsChange,
      rowSelection: tableOptions.state.rowSelection,
      data
    });
  }

  const table = useReactTable(tableOptions);

  return (
    <BaseTable
      table={table}
      data={data}
      columns={effectiveColumns}
      pageSizeOptions={pageSizeOptions}
      borderless={borderless}
      loading={loading}
      isHighlightedRow={isHighlightedRow}
      noDataMessage={noDataMessage}
      aria-label={ariaLabel}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
    />
  );
}

export { ServerSideTable };
export type { ServerSideTableProps };
