import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { classnames } from '@library/utils';
import { InfoTooltipSize } from '.';
import { Tooltip } from './tooltip';
import { TooltipPlacement } from '../tooltip';

interface InfoTooltipProps {
  content: React.ReactNode;
  placement?: TooltipPlacement;
  size?: InfoTooltipSize;
  maxWidth?: string;
}

const sizeClasses = {
  'size.sm': 'h-3 w-3',
  'size.md': 'h-4 w-4',
  'size.lg': 'h-6 w-6'
} satisfies Record<InfoTooltipSize, string>;

function InfoTooltip({
  content,
  placement,
  size = 'size.md',
  maxWidth
}: InfoTooltipProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={content}
      placement={placement}
      maxWidth={maxWidth}
      className='inline-flex'
    >
      <QuestionMarkCircleIcon
        role='img'
        className={classnames('inline', sizeClasses[size])}
        title={`${t('library.questionMark')}`}
        aria-label={`${t('library.questionMark')}`}
      />
    </Tooltip>
  );
}

export type { InfoTooltipProps };
export { InfoTooltip };
