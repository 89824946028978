import React, { MouseEvent } from 'react';
import {
  NavLink as RRDNavLink,
  NavLinkProps as RRDNavLinkProps
} from 'react-router-dom';

interface NavLinkProps extends RRDNavLinkProps {
  disabled?: boolean;
}

// It is not possible to disable the onClick behavior of a react-router-dom <NavLink /> via props,
// so we use this component, which exposes the same API surface, with the addition of a disabled prop.

// As we are going to support the entire API of NavLink, I do not particularly want to duplicate it
// with explicit types and would instead rather rely on ...delegated and extended props. But we do
// not do this everywhere, so should decide how we want to handle stuff like this (see the headlessui components
// for examples where we explicitly reproduce the supported API surface we want, even if it is the same as the underlying
// implementation)
const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  function NavLinkWithRef(
    { className, disabled, onClick, ...delegated }: NavLinkProps,
    ref
  ) {
    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        e.preventDefault();
        return;
      }

      onClick && onClick(e);
    };

    return (
      <RRDNavLink
        aria-disabled={disabled}
        ref={ref}
        onClick={handleClick}
        className={className}
        {...delegated}
      />
    );
  }
);

export { NavLink };
export type { NavLinkProps };
