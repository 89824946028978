import { organizationAdapter } from '@/adapters/organization';
import { ServiceData, ServiceQuery } from '../service';

import { Organization } from '@models/organization';
import { FilterRequestParam } from '@models/table';

interface GetData extends ServiceData {
  organizations: Organization[];
}

interface GetParams {
  institutionId: string;
  filter?: FilterRequestParam[];
}

const get: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ institutionId, filter = [] }] = queryKey;

  const { data, meta } = await organizationAdapter.get(
    { institutionId, filter },
    { signal }
  );

  return { organizations: data, meta };
};

export type { GetData, GetParams };
export { get };
