import { classnames } from '@library/utils';
import { ImgHTMLAttributes } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  allowTransparency?: boolean;
}

function Image({
  allowTransparency = false,
  className,
  ...delegated
}: ImageProps) {
  const classes = classnames(
    className,
    allowTransparency
      ? null
      : 'bg-disabled bg-whiteLogo bg-no-repeat bg-center bg-[length:65%]'
  );

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img className={classes} {...delegated} />;
}

export { Image };
