import { useQueryClient } from '@tanstack/react-query';

import { useToasts } from '@library/components';

import { useApiMutation } from '@hooks/use-api-mutation';
import { offerService } from '@/services/offer';
import { t } from 'i18next';

const useDeleteOffer = (campaignId) => {
  const queryClient = useQueryClient();

  const deleteMutation = useApiMutation(offerService.delete());

  const { sendToast } = useToasts();

  const handleDeleteOffer = () => {
    deleteMutation.mutate(
      {
        campaignId: `${campaignId}`
      },
      {
        async onSuccess() {
          sendToast({
            variant: 'variant.success',
            content: 'The Offer was removed'
          });

          await queryClient.invalidateQueries({
            queryKey: offerService.invalidationKey
          });
        },
        onError() {
          sendToast({
            variant: 'variant.error',
            content: t('label.errorDeletingOffer')
          });
        }
      }
    );
  };

  return handleDeleteOffer;
};

interface DeleteOfferContentProps {
  logo?: string;
  merchantName?: string;
  campaignId?: number;
}

const DeleteOfferContent = ({
  logo,
  merchantName,
  campaignId
}: DeleteOfferContentProps) => (
  <div>
    <div className='grid grid-flow-row-dense grid-cols-3 gap-10 p-5'>
      <div className='mx-auto'>
        <img src={logo} alt={merchantName} />
      </div>
      <div className='col-span-2 my-auto'>
        <div>
          <p className='pr-1 font-bold'>{t('label.offerId')}:</p>
          {campaignId}
        </div>
        <div>
          <p className='mt-5 pr-1 font-bold'>{t('label.merchantName')}:</p>
          {merchantName}
        </div>
      </div>
    </div>
  </div>
);

export { useDeleteOffer };
export { DeleteOfferContent };
