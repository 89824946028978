import invariant from 'tiny-invariant';
import { offerAdapter } from '@adapters/offer';
import { ServiceData, ServiceQuery } from '../service';

import { GetCampaignByIdResponse } from '@models/campaign';

interface GetByIdData extends ServiceData {
  offer: GetCampaignByIdResponse;
}

interface GetByIdParams {
  campaignId: number;
}

const getById: ServiceQuery<GetByIdData, GetByIdParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ campaignId }] = queryKey;

  invariant(campaignId, 'campaignId is required');

  const { data } = await offerAdapter.getById({ campaignId }, { signal });

  return { offer: data };
};

export type { GetByIdData, GetByIdParams };
export { getById };
