import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { ZodSchema, z } from 'zod';
import { Message } from '../../../message';
import { FieldErrorContainer } from './field-error';
import { formErrorMap } from '../../form-error-map';

interface FieldWarning<TFormSchema extends FieldValues> {
  collapsible?: boolean;
  id: string;
  name: FieldPath<TFormSchema>;
  schema: ZodSchema;
}

function FieldWarning<TFormSchema extends FieldValues>({
  collapsible,
  id,
  name,
  schema
}: FieldWarning<TFormSchema>) {
  const values = useWatch<TFormSchema>();

  const result = schema.safeParse(values, { errorMap: formErrorMap });

  if (result.success) {
    return <FieldErrorContainer collapsible={collapsible} />;
  }

  const issues = result.error.issues;

  // exclude any non-warning issues and issues from other fields
  const warning = issues.find(
    (issue) =>
      issue.path.includes(name) &&
      issue.code === z.ZodIssueCode.custom &&
      issue.params &&
      issue.params.warning
  );

  if (!warning) {
    return <FieldErrorContainer collapsible={collapsible} />;
  }

  return (
    <FieldErrorContainer collapsible={collapsible}>
      <Message id={id} message={warning.message} variant='variant.warning' />
    </FieldErrorContainer>
  );
}

export { FieldWarning };
