import {
  DateDuration,
  endOfMonth,
  getWeeksInMonth
} from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import { CalendarCell } from './calendar-cell';

interface CalendarGridProps {
  calendarState: CalendarState | RangeCalendarState;
  offset?: DateDuration;
  onCellClick?: (isoDate: string) => void;
}

function CalendarGrid({
  calendarState,
  offset = {},
  onCellClick
}: CalendarGridProps) {
  const startDate = calendarState.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate
    },
    calendarState
  );

  /*
   * Get the number of weeks in the month so we can render the proper number of rows.
   * We use useLocale here (and in other calendar files) instead of i18n.language
   * for consistency, since react-aria/calendar reads from the I18nProvider, which,
   * like useLocale, is provided by react-aria/i18n.
   */
  const { locale } = useLocale();
  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <table {...gridProps} cellPadding='0' className='flex flex-col'>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={index} className='h-9 w-9 text-sm'>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array.from({ length: weeksInMonth }).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {calendarState
              .getDatesInWeek(weekIndex, startDate)
              .map((date, index) => {
                if (!date) {
                  // eslint-disable-next-line react/no-array-index-key
                  return <td key={index} />;
                }

                return (
                  <CalendarCell
                    key={date.toString()}
                    calendarState={calendarState}
                    currentMonth={startDate}
                    date={date}
                    onCellClick={onCellClick}
                  />
                );
              })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export { CalendarGrid };
