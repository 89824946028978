import { useRef } from 'react';
import { useSelectState } from '@react-stately/select';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useButton } from '@react-aria/button';
import { AriaSelectOptions, HiddenSelect, useSelect } from '@react-aria/select';

import { SelectorProps } from '../types';
import { classnames } from '../../../../../utils';
import { ListBoxPopover } from '../list-box';
import {
  disabledClasses,
  sizeClasses,
  useSelectedKey
} from '../../../../selectors/utils';

// Dropdown only allows single selection
type DropdownProps<TItemType extends object> = Omit<
  Extract<SelectorProps<TItemType>, { selectionMode: 'mode.single' }>,
  'selectionMode' | 'onSelectionChange'
> &
  Pick<
    AriaSelectOptions<TItemType>,
    | 'label'
    | 'name'
    | 'id'
    | 'items'
    | 'children'
    | 'onBlur'
    | 'aria-label'
    | 'aria-describedby'
  > & {
    placeholder?: string;
    onSelectionChange?: (item: TItemType) => void;
  };

function Dropdown<TItemType extends object>({
  itemKey,
  selectedItem,
  onSelectionChange,
  size = 'size.lg',
  className,
  placeholder,
  disabled,
  forceIsOpen,
  ...props
}: DropdownProps<TItemType>) {
  const ref = useRef<HTMLButtonElement>(null);

  const selectionProps = useSelectedKey({
    itemKey,
    selectedItem,
    selectionMode: 'mode.single'
  });

  // @ts-ignore
  const state = useSelectState({
    ...props,
    ...selectionProps,
    isOpen: forceIsOpen === true ? forceIsOpen : undefined,
    onSelectionChange(selectedKey) {
      const item = state.collection.getItem(selectedKey);
      if (item?.value && onSelectionChange) {
        onSelectionChange(item.value);
      }

      // keep focus on dropdown; I don't know where it automatically goes but it's not here
      ref.current?.focus();
    }
  });
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    { ...props, isDisabled: disabled },
    state,
    ref
  );

  const { buttonProps } = useButton(triggerProps, ref);

  return (
    <div className={classnames(className, 'w-full')}>
      {props.label ? (
        <div {...labelProps} className='mb-1'>
          {props.label}
        </div>
      ) : null}
      {/* A <HiddenSelect> is used to render a hidden native <select>, which
      enables browser form autofill support.
      https://react-spectrum.adobe.com/react-aria/useSelect.html#example */}
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <button
        {...buttonProps}
        ref={ref}
        className={classnames(
          sizeClasses[size],
          'input-field flex w-full items-center justify-between gap-4 focus:border-active',
          state.isOpen ? 'border-active' : null,
          disabled ? disabledClasses : null
        )}
      >
        <div
          {...valueProps}
          className={classnames(
            'flex-1 text-left',
            state.selectedItem ? null : 'text-placeholder'
          )}
        >
          {state.selectedItem ? state.selectedItem.rendered : placeholder}
        </div>
        <ChevronDownIcon className='h-5 w-5' />
      </button>
      {state.isOpen ? (
        <ListBoxPopover
          triggerRef={ref}
          listBoxProps={menuProps}
          state={state}
          placement='bottom start'
          width={ref.current?.offsetWidth}
        />
      ) : null}
    </div>
  );
}

export { Item as DropdownItem } from '@react-stately/collections';
export type { DropdownProps };
export { Dropdown };
