import React from 'react';
import { ButtonlikeSize } from '../types';
import { useLinkClasses } from '../use-link-classes';
import { BaseButton } from './base';

interface LinkButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  size?: ButtonlikeSize;
}

const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  function LinkButtonWithRef({ size = 'size.md', ...delegated }, ref) {
    return (
      <BaseButton
        {...delegated}
        className={useLinkClasses({ size })}
        ref={ref}
      />
    );
  }
);

export type { LinkButtonProps };
export { LinkButton };
