import { classnames } from '@library/utils';
import { useTranslation } from 'react-i18next';
import { UNKNOWN_TOTAL_VALUE } from './helpers';

const NUM_BUTTONS = 3;

const baseClasses =
  'rounded-full min-h-8 min-w-8 mx-0.5 active:bg-inverse/40 active-child-svg:fill-btn-pressed-alt';

const focusBaseClasses =
  'focus-visible:ring-4 focus-visible:ring-enabled/25 focus-visible:outline-0';

const currentClasses = 'bg-btn-neutral-alt text-inverse';

const notCurrentClasses =
  'bg-content text-btn-neutral-alt hover:text-btn-hover-alt active:text-btn-pressed-alt';

function getPageButtonIndices(pageIndex: number, totalPages: number) {
  // If we don't know the total pages, just show the current page
  if (totalPages === UNKNOWN_TOTAL_VALUE) {
    return [pageIndex];
  }

  // [0], [0, 1], or [0, 1, 2]
  if (totalPages <= NUM_BUTTONS) {
    return [...Array.from({ length: Math.max(1, totalPages) }).keys()];
  }

  // [0, 1, 2]
  if (pageIndex === 0) {
    return [...Array.from({ length: NUM_BUTTONS }).keys()];
  }

  // If totalPages is 10, returns [7, 8, 9]
  if (pageIndex === totalPages - 1) {
    return Array.from(
      { length: NUM_BUTTONS },
      (_element, index) => index + totalPages - NUM_BUTTONS
    );
  }

  // If pageIndex is 4, returns [3, 4, 5]
  return Array.from(
    { length: NUM_BUTTONS },
    (_element, index) => index + pageIndex - 1
  );
}

type TablePaginatorButtonProps = Omit<
  TablePaginatorButtonsProps,
  'totalPages'
> & { value: number };

function TablePaginatorButton({
  pageIndex,
  value,
  onButtonClick
}: TablePaginatorButtonProps) {
  const { t } = useTranslation('global');

  return (
    <button
      type='button'
      value={value}
      className={classnames(
        baseClasses,
        focusBaseClasses,
        value === pageIndex ? currentClasses : notCurrentClasses
      )}
      onClick={(e) => {
        onButtonClick(Number.parseInt(e.currentTarget.value, 10));
      }}
    >
      {t('library.numberDefault', { value: value + 1 })}
    </button>
  );
}

interface TablePaginatorButtonsProps {
  pageIndex: number;
  totalPages: number;
  onButtonClick: (newIndex: number) => void;
}

function TablePaginatorButtons({
  pageIndex,
  totalPages,
  onButtonClick
}: TablePaginatorButtonsProps) {
  return (
    <div className='mx-0.5 flex'>
      {getPageButtonIndices(pageIndex, totalPages).map((value) => (
        <TablePaginatorButton
          key={value}
          pageIndex={pageIndex}
          value={value}
          onButtonClick={onButtonClick}
        />
      ))}
    </div>
  );
}

export { TablePaginatorButtons };
