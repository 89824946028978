import { ServiceError } from '@services/service';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query';
import { useApiTransport } from './use-api-transport';

const useApiMutation = <
  TData = unknown,
  TError = ServiceError<TData>,
  TVariables = unknown
>(
  mutation: UseMutationOptions<TData, TError, TVariables>
): UseMutationResult<TData, TError, TVariables> => {
  useApiTransport();

  return useMutation(mutation);
};

export { useApiMutation };
