import React from 'react';
import { ButtonlikeSize, ButtonlikeVariant, ButtonlikeWidth } from '../types';
import { useButtonClasses } from '../use-button-classes';
import { BaseButton, BaseButtonProps } from './base';

interface TertiaryButtonProps
  extends Omit<BaseButtonProps, 'className' | 'size' | 'usage' | 'variant'> {
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  width?: ButtonlikeWidth;
}

const TertiaryButton = React.forwardRef<HTMLButtonElement, TertiaryButtonProps>(
  function TertiaryButtonWithRef(
    {
      size = 'size.md',
      variant = 'variant.base',
      width = 'width.unset',
      ...delegated
    },
    ref
  ) {
    return (
      <BaseButton
        {...delegated}
        className={useButtonClasses({
          size,
          usage: 'usage.tertiary',
          variant,
          width
        })}
        ref={ref}
      />
    );
  }
);

export type { TertiaryButtonProps };
export { TertiaryButton };
