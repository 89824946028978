/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '.';
import { classnames } from '../../utils';
import { IconButton, SecondaryButton } from '..';

interface FileDragAndDropProps {
  id?: string;
  accept: string;
  selectedFile?: File | null;
  onComplete: (file: File | null) => void;
  error?: React.ReactNode;
  'aria-label'?: React.AriaAttributes['aria-label'];
  'aria-invalid'?: React.AriaAttributes['aria-invalid'];
  'aria-describedby'?: React.AriaAttributes['aria-describedby'];
}

function FileDragAndDrop({
  id,
  accept,
  selectedFile,
  onComplete,
  error,
  'aria-label': ariaLabel,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy
}: FileDragAndDropProps) {
  const { t } = useTranslation();

  const fileInput = useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const openUploadDialog = () => {
    // unable to test lack of ref value, but we need the check for type guarding
    // istanbul ignore else -- @preserve
    if (fileInput.current) {
      fileInput.current.value = '';
      fileInput.current.click();
    }
  };

  useEffect(() => {
    function onFileDrop(e: DragEvent) {
      e.preventDefault();

      setIsDragging(false);

      const file = e.dataTransfer?.files[0];
      if (file) {
        onComplete(file);
      }
    }

    function onFileDrag(e: DragEvent) {
      e.preventDefault();

      setIsDragging(e.type === 'dragover');
    }

    document.body.addEventListener('drop', onFileDrop);
    document.body.addEventListener('dragover', onFileDrag);
    document.body.addEventListener('dragleave', onFileDrag);

    return () => {
      document.body.removeEventListener('drop', onFileDrop);
      document.body.removeEventListener('dragover', onFileDrag);
      document.body.removeEventListener('dragleave', onFileDrag);
    };
  }, [onComplete]);

  return (
    <div className='flex flex-col gap-1'>
      <div
        id={id}
        aria-label={ariaLabel}
        aria-invalid={ariaInvalid}
        aria-describedby={ariaDescribedBy}
        className={classnames(
          'relative rounded-md border border-dashed bg-contentNested p-8',
          isDragging
            ? 'border-active'
            : 'border-neutral aria-invalid:border-error',
          selectedFile ? null : 'cursor-pointer'
        )}
        onClick={() => {
          if (!selectedFile) {
            openUploadDialog();
          }
        }}
      >
        {selectedFile ? (
          <div className='absolute right-2 top-2'>
            <IconButton
              aria-label={t('library.remove') ?? ''}
              size='size.sm'
              onClick={() => {
                onComplete(null);
              }}
            >
              <XMarkIcon />
            </IconButton>
          </div>
        ) : null}
        <div className='flex flex-col items-center text-sm'>
          {selectedFile ? (
            <>
              <div className='break-all'>{selectedFile.name}</div>
              {error}
            </>
          ) : (
            <>
              <CloudArrowUpIcon className='m-auto h-8 w-8 fill-active' />
              <div className='mb-2'>{t('library.dragAndDropOr')}</div>
              <SecondaryButton
                size='size.sm'
                onClick={(e) => {
                  e.stopPropagation(); // don't trigger click on full drop box

                  openUploadDialog();
                }}
              >
                {t('library.browseFiles')}
              </SecondaryButton>
            </>
          )}
        </div>
        <FileUpload onComplete={onComplete} accept={accept} ref={fileInput} />
      </div>
      {selectedFile ? null : error}
    </div>
  );
}

export { FileDragAndDrop };
export type { FileDragAndDropProps };
