import { useMemo, useState } from 'react';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  LinkButton
} from '@library/components';

import { useAuth } from '@/context-providers/auth';

import { classnames } from '@library/utils';
import { OfferInstructionsModal } from '../Instructions/OfferInstructionsModal';
import { CustomerInstructionsModal } from '../Instructions/CustomerInstructionsModal';

const animationClasses =
  'data-[state=open]:animate-slideAndFadeInTop data-[state=closed]:animate-slideAndFadeOutTop';

const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { userProfile, logout } = useAuth();

  const logoutClicked = () => {
    setIsOpen(false);
    logout();
  };

  const userName = useMemo(
    () => `${userProfile.firstName} ${userProfile.lastName}`,
    [userProfile]
  );

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <PopoverTrigger>
        <button type='button' className='h-full px-2 hover:bg-hover'>
          <span>
            Welcome, <b className='font-bold text-blue-action'>{userName}</b>
          </span>
        </button>
      </PopoverTrigger>
      <PopoverContent
        alignment='alignment.end'
        className={classnames(
          'z-50 w-48 overflow-hidden text-sm',
          animationClasses
        )}
      >
        <div className='px-4 py-2'>
          <CustomerInstructionsModal />
        </div>
        <div className='px-4 py-2'>
          <OfferInstructionsModal />
        </div>
        <div className='px-4 py-2'>
          <LinkButton onClick={logoutClicked}>Logout</LinkButton>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export { HeaderMenu };
