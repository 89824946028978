import { institutionAdapter } from '@/adapters/institution';
import { ServiceData, ServiceQuery } from '../service';

import { Institution } from '@models/institution';

interface GetData extends ServiceData {
  institutions: Institution[];
}

const get: ServiceQuery<GetData> = async (context) => {
  const { signal } = context;

  const { data, meta } = await institutionAdapter.get({ signal });

  return { institutions: data, meta };
};

export type { GetData };
export { get };
