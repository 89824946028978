import { classnames } from '@library/utils';
import { ButtonlikeIconVariant, ButtonlikeSize } from './types';

const sizeClasses = {
  'size.sm': 'h-8 w-8 child-svg:h-4 child-svg:w-4',
  'size.md': 'h-10 w-10 child-svg:h-6 child-svg:w-6',
  'size.lg': 'h-12 w-12 child-svg:h-8 child-svg:w-8'
} satisfies Record<ButtonlikeSize, string>;

const variantClasses = {
  'variant.base':
    'child-svg:stroke-btn-neutral-alt hover-child-svg:stroke-btn-hover-alt text-btn-neutral-alt hover:text-btn-hover-alt',
  'variant.inverse':
    'child-svg:stroke-inverse hover-child-svg:stroke-inverse text-inverse hover:text-inverse',
  'variant.primary':
    'child-svg:stroke-btn-pressed-base  hover-child-svg:stroke-btn-hover-alt text-redstroke-btn-pressed-base hover:text-btn-hover-alt'
} satisfies Record<ButtonlikeIconVariant, string>;

const neutralClasses =
  'group inline-flex flex-shrink-0 items-center justify-center rounded-full bg-transparent';

// bg-inverse/20 instead of gray.rules to accommodate non-white backgrounds
const hoverClasses = 'hover:bg-inverse/20';

// bg-inverse/40 instead of gray.disabledText to accommodate non-white backgrounds
const focusClasses = [
  'active:bg-inverse/40',
  'focus-visible:ring-4',
  'focus-visible:ring-enabled/25',
  'focus-visible:outline-0',
  'active-child-svg:stroke-btn-pressed-alt'
];

const disabledClasses = [
  'disabled:cursor-default',
  'disabled:hover:bg-transparent',
  'disabled-child-svg:stroke-disabled',
  'disabled-hover-child-svg:stroke-disabled',
  'disabled-child-svg:text-disabled',
  'disabled-hover-child-svg:text-disabled'
];

const ariaDisabledClasses = [
  'aria-disabled:cursor-default',
  'aria-disabled:hover:bg-transparent',
  'aria-disabled-child-svg:stroke-disabled',
  'aria-disabled-hover-child-svg:stroke-disabled',
  'aria-disabled-child-svg:text-disabled',
  'aria-disabled-hover-child-svg:text-disabled'
];

interface IconButtonClassesProps {
  size: ButtonlikeSize;
  variant?: ButtonlikeIconVariant;
}

const useIconButtonClasses = ({
  size,
  variant = 'variant.base'
}: IconButtonClassesProps) =>
  classnames(
    sizeClasses[size],
    variantClasses[variant],
    neutralClasses,
    hoverClasses,
    focusClasses,
    disabledClasses,
    ariaDisabledClasses
  );

export { useIconButtonClasses };
