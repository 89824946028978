import { classnames } from '@library/utils';
import { useTranslation } from 'react-i18next';
import { InfoTooltip } from '../../../info-tooltip';

function OptionalLabel() {
  const { t } = useTranslation();

  return (
    <>
      {' '}
      <span className='font-normal lowercase text-muted'>
        ({t('library.OPTIONAL')})
      </span>
    </>
  );
}

interface FieldLabelProps {
  className?: string;
  inline?: boolean; // Inline labels (like for switches and checkboxes) are not bolded
  htmlFor: string;
  label: string;
  optional?: boolean;
  secondaryLabel?: string;
  tooltipContent?: React.ReactNode;
}

function FieldLabel({
  className,
  htmlFor,
  inline,
  label,
  optional,
  secondaryLabel,
  tooltipContent
}: FieldLabelProps) {
  return (
    <label
      className={classnames(
        inline ? 'cursor-pointer' : 'font-bold',
        className,
        'text-sm',
        'whitespace-nowrap'
      )}
      htmlFor={htmlFor}
    >
      <div className='flex items-center gap-x-1'>
        {label}
        {optional ? <OptionalLabel /> : null}
        {tooltipContent ? <InfoTooltip content={tooltipContent} /> : null}
      </div>
      {secondaryLabel ? (
        <div className={classnames('text-xs font-normal')}>
          {secondaryLabel}
        </div>
      ) : null}
    </label>
  );
}

export { FieldLabel };
