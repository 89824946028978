import { matchPath, useLocation } from 'react-router-dom';
import { classnames } from '@library/utils';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { NavItem, NavItemParent } from '.';
import { NavLink } from '../buttonlike/nav-link';
import { Menu, MenuItem, MenuItems, MenuTrigger } from '../menu';

const topLevelClasses = 'float-left h-full flex items-center';

const activeTopLevelClasses =
  'before:h-0.75 before:w-[calc(100%_-_30px)] before:bg-active before:absolute before:bottom-0 before:right-1/2 before:translate-x-1/2';

const disabledClasses = 'opacity-50 cursor-default';

const baseClasses =
  'relative focus-visible:outline-none focus-visible:bg-hover';

const hasChildren = (item: NavItem | NavItemParent): item is NavItemParent =>
  'items' in item;

const useHasActiveChild = (items: NavItem[]) => {
  const location = useLocation();
  const match = items.some((item) => matchPath(item.url, location.pathname));

  return !!match;
};

const ParentNavItem = ({ text, items, disabled }: NavItemParent) => (
  <Menu>
    <div
      className={classnames(
        topLevelClasses,
        baseClasses,
        disabled ? disabledClasses : 'hover:bg-hover',
        useHasActiveChild(items) ? activeTopLevelClasses : null
      )}
    >
      <MenuTrigger
        disabled={disabled}
        className='flex h-full items-center px-6 focus-within:bg-hover focus-within:outline-none data-[state=open]:bg-hover'
      >
        {text}
        <ChevronDownIcon className='ml-0.5 h-3 w-3 fill-currentColor stroke-currentColor stroke-1' />
      </MenuTrigger>
      <MenuItems align='start' className='bg-content shadow-md'>
        {items.map((item: NavItem) => (
          <MenuItem key={item.text} asChild={true} disabled={item.disabled}>
            <NavLink
              end={item.end}
              to={item.url}
              disabled={item.disabled}
              className='block focus-within:bg-hover focus-within:outline-none'
            >
              {({ isActive }) => (
                <div
                  className={classnames(
                    'whitespace-nowrap border-l-3 py-4 pl-5 pr-6 text-sm text-link-neutral',
                    item.disabled ? disabledClasses : null,
                    isActive ? 'border-active' : 'border-transparent'
                  )}
                >
                  {item.text}
                </div>
              )}
            </NavLink>
          </MenuItem>
        ))}
      </MenuItems>
    </div>
  </Menu>
);

interface NavItemsProps {
  items: (NavItem | NavItemParent)[];
}

const NavItems = ({ items }: NavItemsProps) => (
  <div className='h-full text-sm text-link-neutral'>
    {items.map((item) => {
      if (hasChildren(item)) {
        return (
          <ParentNavItem
            key={item.text}
            text={item.text}
            items={item.items}
            disabled={item.disabled}
          />
        );
      }

      return (
        <NavLink
          key={item.text}
          to={item.url}
          end={item.end}
          disabled={item.disabled}
          className={({ isActive }) =>
            classnames(
              topLevelClasses,
              baseClasses,
              item.disabled ? disabledClasses : 'hover:bg-hover',
              isActive ? activeTopLevelClasses : null,
              'px-6'
            )
          }
        >
          {item.text}
        </NavLink>
      );
    })}
  </div>
);

export { NavItems };
