import { classnames } from '@library/utils';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { TableBodyEmpty } from './body-empty';
import {
  bodyCellClasses,
  borderlessBodyCellClasses,
  commonCellClasses
} from './helpers';
import { TableHiddenAndSelectedMessageRow } from './hidden-and-selected-message-row';

const bodyRowClasses = 'group hover:bg-hover';

const highlightedRowClasses = 'bg-contentNested';

interface TableBodyProps<TableData> {
  table: Table<TableData>;
  rows: Row<TableData>[];
  borderless?: boolean;
  loading?: boolean;
  isHighlightedRow?: (row: TableData) => boolean;
  numVisibleColumns: number;
  noDataMessage?: React.ReactNode;
}

const emptyFn = () => false;

function TableBody<TableData>({
  table,
  rows,
  borderless,
  loading,
  isHighlightedRow = emptyFn,
  numVisibleColumns,
  noDataMessage
}: TableBodyProps<TableData>) {
  const numTotalSelected = table.getSelectedRowModel().rows.length;
  const numFilteredAndSelected =
    table.getFilteredSelectedRowModel().rows.length;
  const numHiddenAndSelected = numTotalSelected - numFilteredAndSelected;

  if (rows.length === 0) {
    return (
      <TableBodyEmpty
        borderless={borderless}
        loading={loading}
        numVisibleColumns={numVisibleColumns}
        noDataMessage={noDataMessage}
        numHiddenAndSelected={numHiddenAndSelected}
      />
    );
  }

  return (
    <tbody>
      <TableHiddenAndSelectedMessageRow
        borderless={borderless}
        numHiddenAndSelected={numHiddenAndSelected}
        numVisibleColumns={numVisibleColumns}
      />
      {rows.map((row) => (
        <tr
          key={row.id}
          className={classnames(
            bodyRowClasses,
            isHighlightedRow(row.original) ? highlightedRowClasses : null
          )}
        >
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              className={classnames(
                commonCellClasses,
                bodyCellClasses,
                borderless ? borderlessBodyCellClasses : null
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

export { TableBody };
