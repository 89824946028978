import { ServiceMutation } from '../service';
import { offerAdapter } from '@adapters/offer';

interface OfferDeleteParams {
  campaignId: string;
}

const del: ServiceMutation<void, OfferDeleteParams> = async function (params) {
  const { campaignId } = params;

  await offerAdapter.del({
    campaignId
  });
};

export { del };
export type { OfferDeleteParams };
