import { apiTransport } from '@services/rest-api';
import { AdapterDeleteFunction } from '../types';

interface DeleteParams {
  sourceCustomerId: string;
}

const del: AdapterDeleteFunction<DeleteParams> = async function ({
  sourceCustomerId
}) {
  const url = `/v1/customers/profiles`;

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: sourceCustomerId
  });

  await apiTransport.delete({
    url
  });

  apiTransport.setHeader({ key: 'X-Cdlx-Customer-Id', value: '' });
};

export { del };
export type { DeleteParams };
