import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  BodyContainer
} from '@library/components';

import { InstitutionList } from '@views/InstitutionList/InstitutionList';

const AdminTabView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation();
  const [key, setKey] = useState(0);

  const TabsData = [
    {
      path: 'publishers',
      label: t('label.publishers'),
      icon: <GlobeAltIcon className='mr-2 h-5 w-5' />,
      element: <InstitutionList />
    }
  ];

  const getDefaultTabIndex = () => {
    // TODO: Nasty workaround, find a way to automate this process using TabsData as reference, the idea is yo remove the magic numbers and maybe the if?
    const pathname = location.pathname;

    return pathname.includes('publishers') ? 0 : 1;
  };

  const defaultTabIndex = getDefaultTabIndex();
  const [selectedIndex, setSelectedIndex] = useState<number>(defaultTabIndex);

  const handleOnTabChange = (tabIndex: number) => {
    setSelectedIndex(tabIndex);
    const { path } = TabsData[tabIndex];
    navigate(path, { replace: true });
  };

  useEffect(() => {
    setTimeout(() => {
      setSelectedIndex(getDefaultTabIndex());
      // Force Re-render
      setKey((currentKey) => currentKey + 1);
    }, 10);
  }, [location.pathname]);

  return (
    <div key={key}>
      <BodyContainer>
        <TabGroup
          variant='variant.folder'
          defaultIndex={selectedIndex}
          onChange={handleOnTabChange}
        >
          <TabList>
            {TabsData.map((item) => (
              <Tab key={item.path} aria-label={item.label}>
                <div className='flex items-center'>
                  {item.icon}
                  {item.label}
                </div>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {TabsData.map((item) => (
              <TabPanel key={item.path}>
                <div className='p-10'>
                  <Outlet />
                </div>
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </BodyContainer>
    </div>
  );
};

export { AdminTabView };
