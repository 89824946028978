import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';

import { Portfolio } from '@models/portfolio';
import { FilterRequestParam } from '@models/table';

import { getFilterString } from '@utils/rest-api';

interface GetParams {
  institutionId: string;
  filter: FilterRequestParam[];
}

const get: AdapterFunction<Portfolio[], GetParams> = (
  { institutionId, filter },
  { signal }
) => {
  apiTransport.setBaseUrl(process.env.PM_API_URL);

  const parsedFilter =
    getFilterString(filter).length > 0 ? `?${getFilterString(filter)}` : '';

  const url = `/v2/institutions/${institutionId}/portfolios${parsedFilter}`;

  const response = apiTransport.get<Portfolio[]>({
    url,
    config: {
      signal
    }
  });

  apiTransport.setBaseUrl(process.env.API_URL);

  return response;
};

export type { GetParams };
export { get };
