/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { classnames } from '@library/utils';
import { CalendarDate, isSameDay, isSameMonth } from '@internationalized/date';
import { useCalendarCell } from '@react-aria/calendar';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import { useRef } from 'react';

function isRangeCalendarState(
  obj: CalendarState | RangeCalendarState
): obj is RangeCalendarState {
  return 'highlightedRange' in obj;
}

function getSelectionStatus({
  calendarState,
  date
}: Pick<CalendarCellProps, 'calendarState' | 'date'>) {
  let isSelectionStart;
  let isSelectionEnd;
  let isWithinRange = false;

  if (isRangeCalendarState(calendarState) && calendarState.highlightedRange) {
    isSelectionStart = isSameDay(date, calendarState.highlightedRange.start);
    isSelectionEnd = isSameDay(date, calendarState.highlightedRange.end);
    /*
     * useCalendarCell provides an isSelected field that we can theoretically use
     * for styling purposes. However, isSelected is always false if the calendar is
     * disabled, making displaying the selected values of a displayed calendar
     * impossible, so we do a compare on the highlighted range for the range picker
     * and look at calendarState.value for the single date picker.
     */
    isWithinRange =
      date.compare(calendarState.highlightedRange.end) <= 0 &&
      date.compare(calendarState.highlightedRange.start) >= 0;
  } else if (!isRangeCalendarState(calendarState) && calendarState.value) {
    isSelectionStart = isSameDay(date, calendarState.value);
    isSelectionEnd = isSelectionStart;
  }

  return { isSelectionStart, isSelectionEnd, isWithinRange };
}

const circleBaseClasses =
  'flex h-full w-full items-center justify-center rounded-full';

const circleFocusClasses =
  'group-focus-visible:ring-enabled/25 group-focus-visible:outline-0 group-focus-visible:ring-4';

interface CircleClassesProps {
  isSelectionStart?: boolean;
  isSelectionEnd?: boolean;
  isDisabled: boolean;
}

function getConditionalCircleClasses({
  isSelectionStart,
  isSelectionEnd,
  isDisabled
}: CircleClassesProps) {
  if (isSelectionStart || isSelectionEnd) {
    return isDisabled ? 'bg-disabled' : 'bg-enabled';
  }
  return isDisabled ? '' : 'hover:bg-hover';
}

interface CalendarCellProps {
  calendarState: CalendarState | RangeCalendarState;
  currentMonth: CalendarDate;
  date: CalendarDate;
  onCellClick?: (isoDate: string) => void;
}

function CalendarCell({
  calendarState,
  currentMonth,
  date,
  onCellClick
}: CalendarCellProps) {
  const ref = useRef(null);
  const { cellProps, buttonProps, isDisabled, formattedDate } = useCalendarCell(
    { date },
    calendarState,
    ref
  );

  const { isSelectionStart, isSelectionEnd, isWithinRange } =
    getSelectionStatus({ calendarState, date });

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <td {...cellProps} className='py-0.5'>
      <div
        hidden={isOutsideMonth}
        className={classnames(
          'group h-9 w-9 text-sm focus-visible:outline-0',
          isSelectionStart ? 'rounded-l-full' : '',
          isSelectionEnd ? 'rounded-r-full' : '',
          isDisabled ? 'cursor-default text-disabled' : '',
          isSelectionStart || isSelectionEnd ? 'text-inverse' : '',
          isWithinRange && !isDisabled ? 'bg-contentNested' : '',
          isWithinRange && isDisabled ? 'bg-disabled' : ''
        )}
      >
        <div
          {...buttonProps}
          onClick={(e) => {
            /*
             * Fire an event to let interested parties know a date was specifically
             * clicked on. For some reason, the aria label doesn't come through in
             * Firefox or the test environment, but it works with dataset, so we're
             * reading from that instead of the aria label.
             */
            const cellLabel = e.currentTarget.dataset.date;
            onCellClick?.(
              cellLabel ||
                /* istanbul ignore next -- @preserve */
                // This isn't ever unset, but TypeScript thinks it can be
                ''
            );

            // Then do the default passthrough on click behavior
            buttonProps.onClick?.(e);
          }}
          data-date={date.toString()}
          ref={ref}
          className={classnames(
            circleBaseClasses,
            /*
             * Focus classes go here so that we can have the focus ring be rounded
             * while still having the bg-contentNested in the background.
             */
            circleFocusClasses,
            getConditionalCircleClasses({
              isSelectionStart,
              isSelectionEnd,
              isDisabled
            })
          )}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}

export { CalendarCell };
