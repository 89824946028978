import PlaceholderLogo from '../icons/placeholder-logo';
import { Image } from '../image';

interface LogoProps {
  src?: string;
  alt?: string;
}

function Logo({ src, alt }: LogoProps) {
  return src ? (
    <Image
      allowTransparency={false}
      className='h-24 w-24'
      src={src}
      alt={alt}
    />
  ) : (
    <div className='h-24 w-24'>
      <PlaceholderLogo />
    </div>
  );
}

export { Logo };
