import { ContentSpacing } from './types';

const spacingClasses = {
  'spacing.sm': 'm-4',
  'spacing.md': 'm-6',
  'spacing.lg': 'm-8'
} satisfies Record<ContentSpacing, string>;

interface ContentSectionProps {
  spacing?: ContentSpacing;
  children: React.ReactNode;
}

const ContentSection = ({
  spacing = 'spacing.md',
  children
}: ContentSectionProps) => (
  <div className={spacingClasses[spacing]}>{children}</div>
);

export { ContentSection };
export type { ContentSectionProps };
