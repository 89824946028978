import { Dispatch, SetStateAction, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import {
  PrimaryButton,
  SecondaryButton,
  TextInput,
  useToasts
} from '@library/components';

interface NewPasswordLoginFlowProps {
  setLoginFlow: Dispatch<SetStateAction<'MFA' | 'NEW_PASSWORD' | 'MAIN'>>;
  credentials: { email: string; password: string };
}

const NewPasswordLoginFlow = ({
  setLoginFlow,
  credentials
}: NewPasswordLoginFlowProps) => {
  const [t] = useTranslation();
  const { sendToast } = useToasts();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const onCancelClick = () => {
    setLoginFlow('MAIN');
  };

  const onChangePasswordClick = async () => {
    setIsChangingPassword(true);
    if (newPassword === confirmPassword) {
      setErrorMessage('');

      const { email, password } = credentials;

      try {
        const loginResp = await Auth.signIn(email, password);
        const { challengeName } = loginResp;

        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(loginResp, newPassword, {});
        }
      } catch (error) {
        sendToast({
          variant: 'variant.info',
          content: `${error}`
        });
      }
    } else {
      setErrorMessage(`${t('label.passwordsDoesNotMatch')}`);
    }
    setIsChangingPassword(false);
  };

  return (
    <div>
      <p className='mb-2'>{t('label.createNewPassword')}</p>
      <div className='mb-2'>
        <TextInput
          className='flex-1'
          value={newPassword}
          onChange={setNewPassword}
          size='size.md'
          placeholder='New Password'
          type='password'
          clearable={false}
        />
      </div>
      <TextInput
        className='flex-1'
        value={confirmPassword}
        onChange={setConfirmPassword}
        size='size.md'
        placeholder='Confirm Password'
        type='password'
        clearable={false}
      />
      {errorMessage ? (
        <p className='m-0 mt-1 text-sm text-error'>{errorMessage}</p>
      ) : null}
      <div className=' mt-4 flex items-center justify-between'>
        <SecondaryButton onClick={onCancelClick} disabled={isChangingPassword}>
          {t('library.cancel')}
        </SecondaryButton>
        <PrimaryButton
          onClick={onChangePasswordClick}
          disabled={isChangingPassword}
        >
          {isChangingPassword
            ? t('label.updatingEllipsis')
            : t('label.updatePassword')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export { NewPasswordLoginFlow };
