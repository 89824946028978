import { z } from 'zod';

/*
 * This is rather confusing, but the /brands/segments?id={brandId} endpoint returns
 * segments (which we are referring to as merchants both for UI consistency reasons
 * and also to avoid confusion with audience segmentation types), but within "store
 * segment" objects (which are basically purchase channel objects), which are in
 * turn located within brand objects. In other words, this endpoint technically
 * returns an array of brand objects in which merchant objects live (a few layers
 * deep). There is no endpoint that we know of at this time that will return a flat
 * list of merchants.
 *
 * It is also important to note that the parent endpoint (aka just /brands) obviously
 * returns brand objects, but they aren't quite the same as the brand objects from
 * this endpoint. For this reason, all the schemas here will be prefixed with Merchant.
 */

const MERCHANT_SEGMENT_CREATION_ORIGINS = ['US', 'GB'] as const;
const merchantSegmentCreationOriginSchema = z.enum(
  MERCHANT_SEGMENT_CREATION_ORIGINS
);
type MerchantSegmentCreationOrigin =
  (typeof MERCHANT_SEGMENT_CREATION_ORIGINS)[number];

// https://github.com/cardlytics/cl-brand-metadata-service/blob/main/Cardlytics.BrandMetadataService/Cardlytics.BrandMetadataService.Shared/Utilities/Constants.cs
const MERCHANT_PRODUCT_CATEGORIES = [
  'Fuel',
  'Membership',
  'Subscription'
] as const;
const merchantProductCategorySchema = z.enum(MERCHANT_PRODUCT_CATEGORIES);
type MerchantProductCategory = (typeof MERCHANT_PRODUCT_CATEGORIES)[number];

// See above link for these too
const MERCHANT_PAYMENT_CHANNELS = [
  'ACH',
  'App',
  'BillPay',
  'GiftCard',
  'GiftCertificates',
  'Google',
  'Paypal',
  'WireTransfers'
] as const;
const merchantPaymentChannelSchema = z.enum(MERCHANT_PAYMENT_CHANNELS);
type MerchantPaymentChannel = (typeof MERCHANT_PAYMENT_CHANNELS)[number];

// See above link for these too
const MERCHANT_STORE_LOCATION_SUB_TYPES = [
  'Convenience',
  'Grocery',
  'PayAtPump',
  'Restaurant'
] as const;
const merchantStoreLocationSubTypeSchema = z.enum(
  MERCHANT_STORE_LOCATION_SUB_TYPES
);
type MerchantStoreLocationSubType =
  (typeof MERCHANT_STORE_LOCATION_SUB_TYPES)[number];

const apiMerchantSchema = z.object({
  name: z.string(),
  segmentCreationOrigin: merchantSegmentCreationOriginSchema,
  productCategories: z.array(merchantProductCategorySchema),
  paymentChannel: merchantPaymentChannelSchema.optional(),
  storeLocationSubType: merchantStoreLocationSubTypeSchema.optional()
});

type ApiMerchant = z.infer<typeof apiMerchantSchema>;

// https://github.com/cardlytics/cl-brand-metadata-service/blob/main/Cardlytics.BrandMetadataService/Cardlytics.BrandMetadataService.Kafka/Enums/StoreLocationType.cs
const MERCHANT_PURCHASE_CHANNELS = ['InStore', 'Online', 'Other'] as const;
const merchantPurchaseChannelSchema = z.enum(MERCHANT_PURCHASE_CHANNELS);
type MerchantPurchaseChannel = (typeof MERCHANT_PURCHASE_CHANNELS)[number];

const apiMerchantStoreSchema = z.object({
  purchaseChannel: merchantPurchaseChannelSchema,
  segments: z.record(z.string(), apiMerchantSchema)
});

type ApiMerchantStore = z.infer<typeof apiMerchantStoreSchema>;

const apiMerchantBrandSchema = z.object({
  brandId: z.number(),
  brandName: z.string(),
  storeSegments: z.array(apiMerchantStoreSchema)
});

type ApiMerchantBrand = z.infer<typeof apiMerchantBrandSchema>;

// We want to return a flat list of merchants, so we'll basically collapse all of the above
const adapterMerchantSchema = apiMerchantSchema.and(
  z.object({
    brandId: z.number(),
    brandName: z.string(),
    purchaseChannel: merchantPurchaseChannelSchema,
    id: z.number()
  })
);

type AdapterMerchant = z.infer<typeof adapterMerchantSchema>;

export * from './definitions';
export {
  MERCHANT_PAYMENT_CHANNELS,
  MERCHANT_PRODUCT_CATEGORIES,
  MERCHANT_PURCHASE_CHANNELS,
  MERCHANT_SEGMENT_CREATION_ORIGINS,
  MERCHANT_STORE_LOCATION_SUB_TYPES,
  adapterMerchantSchema,
  apiMerchantBrandSchema,
  apiMerchantSchema,
  apiMerchantStoreSchema,
  merchantPaymentChannelSchema,
  merchantProductCategorySchema,
  merchantPurchaseChannelSchema,
  merchantSegmentCreationOriginSchema,
  merchantStoreLocationSubTypeSchema
};
export type {
  AdapterMerchant,
  ApiMerchant,
  ApiMerchantBrand,
  ApiMerchantStore,
  MerchantPaymentChannel,
  MerchantProductCategory,
  MerchantPurchaseChannel,
  MerchantSegmentCreationOrigin,
  MerchantStoreLocationSubType
};
