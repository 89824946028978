import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { BasicLink } from '../buttonlike';
import { Error403Icon, Error404Icon, Error500Icon } from '../icons';

// an internal error string that should not need to be translated, is it is never shown
const DEFAULT_AUTH_ERROR_MESSAGE = 'Unauthorized';
class AuthError extends Error {
  constructor(message = 'Unauthorized') {
    super(message);

    // See the following for why this is necessary. May have to click "See changes for older releases".
    // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

interface DisplayProps {
  message: string;
  details: string | null;
  status?: 404 | 403;
}

const Icon = ({ status }: Pick<DisplayProps, 'status'>) => {
  switch (status) {
    case 404: {
      return <Error404Icon />;
    }
    case 403: {
      return <Error403Icon />;
    }
    default: {
      return <Error500Icon />;
    }
  }
};

const Display = ({ message, details, status }: DisplayProps) => {
  const { t } = useTranslation();

  return (
    <div className='flex items-center justify-center pt-24 text-body'>
      <div className='flex w-2/3 gap-8 child-svg:shrink-0'>
        <Icon status={status} />
        <div>
          <p className='mt-24 text-5xl font-bold'>{message}</p>
          <p className='my-8 text-lg font-bold'>{details}</p>
          <BasicLink to='/' size='size.lg'>
            {t('library.goToSafety')}
          </BasicLink>
        </div>
      </div>
    </div>
  );
};

interface ErrorDisplayProps {
  status?: number;
}

const ErrorDisplay = ({ status }: ErrorDisplayProps) => {
  const { t } = useTranslation();
  const error = useRouteError();

  let message = '';
  if (error instanceof Error) {
    message = error.message;
  }

  // The only route error response status that we expect to have is a 404, as other error codes
  // can only be thrown by react-router loaders, which we do not use.
  if (isRouteErrorResponse(error) || status === 404) {
    return (
      <Display
        message={t('library.errorMessage404')}
        details={t('library.errorCode404')}
        status={404}
      />
    );
  }

  // AuthError is our own custom sub-class of Error, because react-router-dom
  // doesn't export the ability to create RouteErrorResponse errors outside of loaders
  if (error instanceof AuthError) {
    return (
      <Display
        message={t('library.ERROR_MESSAGE_403')}
        details={
          error.message === DEFAULT_AUTH_ERROR_MESSAGE
            ? t('library.ERROR_CODE_403')
            : error.message
        }
        status={403}
      />
    );
  }

  return <Display message={t('library.UNEXPECTED_ERROR')} details={message} />;
};

export { ErrorDisplay, AuthError };
