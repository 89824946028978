// @ts-nocheck
import { classnames } from '@library/utils';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

type ClearButtonProps = {
  id?: string;
  disabled?: boolean;
  onClick: () => void;
};

function ClearButton({ id, disabled, onClick }: ClearButtonProps) {
  const { t } = useTranslation();

  return (
    <button
      type='button'
      aria-label={t('library.CLEAR_INPUT', { id })}
      className='absolute bottom-0 right-12 top-0 m-auto h-4 w-4'
      disabled={disabled}
      onClick={() => {
        onClick();
      }}
      onKeyDown={(e) => {
        /*
         * In theory this separate event handler shouldn't be needed, but something
         * about the React Aria calendar causes hitting enter/space on this element
         * to go to the year input instead by default, so we have to override that.
         */
        if (['Enter', 'NumpadEnter', 'Space'].includes(e.code)) {
          onClick();
        }
      }}
    >
      <XMarkIcon
        className={classnames(
          disabled
            ? 'cursor-default stroke-disabled text-disabled'
            : 'cursor-pointer stroke-btn-neutral-alt text-btn-neutral-alt'
        )}
      />
    </button>
  );
}

export { ClearButton };
