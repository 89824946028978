import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LoginPage } from '@library/components';
import { useAuth } from '@/context-providers/auth';
import { useToasts } from '@library/components';

import { MainLoginFlow } from './MainLoginFlow';
import { NewPasswordLoginFlow } from './NewPasswordLoginFlow';

const Login = () => {
  const [t] = useTranslation();
  const { sendToast } = useToasts();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [loginFlow, setLoginFlow] = useState<'MFA' | 'NEW_PASSWORD' | 'MAIN'>(
    'MAIN'
  );

  const { userProfile, logout } = useAuth();

  const isSigningIn = useMemo(
    () => Boolean(searchParams.get('loading')) || false,
    [searchParams]
  );

  const throwAccessError = () => {
    sendToast({
      variant: 'variant.info',
      content: t('common.noAccessToApp')
    });

    setTimeout(() => {
      logout();
    }, 2000);
  };

  useEffect(() => {
    if (
      Boolean(userProfile.firstName) &&
      userProfile.products &&
      userProfile.products.includes('selfserve_testing')
    ) {
      navigate('/customers'); //if user is logged and it has the app assigned
    }

    if (
      Boolean(userProfile.firstName) &&
      userProfile.products &&
      !userProfile.products.includes('selfserve_testing')
    ) {
      // the user is logged and it has not the app assigned
      throwAccessError();
    }

    if (Boolean(userProfile.firstName) && !userProfile.institutionId) {
      // the user is logged and has no custom:preprod_institution property
      throwAccessError();
    }
  }, [userProfile]);

  return (
    <div className='flex h-screen items-center'>
      <LoginPage
        appName='Self-Serve Testing Tool'
        buildNumber={process.env.PACKAGE_VERSION}
      >
        {loginFlow === 'MAIN' && (
          <MainLoginFlow
            isLoading={isSigningIn}
            setLoginFlow={setLoginFlow}
            setCredentials={setCredentials}
          />
        )}
        {loginFlow === 'NEW_PASSWORD' && (
          <NewPasswordLoginFlow
            setLoginFlow={setLoginFlow}
            credentials={credentials}
          />
        )}
      </LoginPage>
    </div>
  );
};

export { Login };
