import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  // useEffect,
  useMemo,
  useState
} from 'react';

import {
  COUNTRY_CURRENCIES,
  Institution,
  CurrencyCode,
  CurrencyMark,
  PublisherLocaleCode
} from '@models/institution';
import { apiTransport } from '../services/rest-api';
import { institutionService } from '../services/institution';

import { useApiQuery } from '@hooks/use-api-query';

interface InstitutionProviderProps {
  children: React.ReactNode;
}

interface Currency {
  code: CurrencyCode;
  mark: CurrencyMark;
  locale: PublisherLocaleCode;
}

type InstitutionContextType = {
  institutionInfo: Institution;
  currency: Currency;
  currentInstitutionId: string;
  setCurrentInstitutionId: Dispatch<SetStateAction<string>>;
  setInstitutionInfo: Dispatch<SetStateAction<Institution>>;
};

const InstitutionContext = createContext<InstitutionContextType | undefined>(
  undefined
);

const useInstitution = () => {
  const context = useContext(InstitutionContext);

  if (context === undefined) {
    throw new Error(
      'useInstitution can only be used within an InstitutionContext'
    );
  }
  return context;
};

const InstitutionProvider = ({ children }: InstitutionProviderProps) => {
  const [currentInstitutionId, setCurrentInstitutionId] = useState<string>('');
  const [institutionInfo, setInstitutionInfo] = useState<Institution>(
    {} as Institution
  );
  const [isError, setIsError] = useState(false);

  const { data } = useApiQuery(
    institutionService.getById({
      params: {
        institutionId: currentInstitutionId
      },
      enabled: currentInstitutionId.length > 0
    })
  );

  useEffect(() => {
    if (currentInstitutionId.length > 0) {
      apiTransport.setHeader({
        key: 'X-Cdlx-Institution-Id',
        value: currentInstitutionId
      });
    }
  }, [currentInstitutionId]);

  useEffect(() => {
    if (data?.institution) {
      setInstitutionInfo(data.institution);
    }
  }, [data]);

  const currency = useMemo(
    () => COUNTRY_CURRENCIES[institutionInfo.country] || COUNTRY_CURRENCIES.US,
    [institutionInfo]
  );

  const contextValue = useMemo(
    () => ({
      institutionInfo,
      isError,
      currency,
      currentInstitutionId,
      setCurrentInstitutionId,
      setInstitutionInfo,
      setIsError
    }),
    [institutionInfo]
  );

  return (
    <InstitutionContext.Provider value={contextValue}>
      {children}
    </InstitutionContext.Provider>
  );
};

export { InstitutionProvider, useInstitution };
