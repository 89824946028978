import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid';
import { NestedHeadlessCheckboxContextValue } from './nested-headless-group';

type CheckboxSelection = string | number;
type CheckboxGroupSelection = Record<CheckboxSelection, boolean>;

function CheckedIcon() {
  return <CheckIcon className='h-3 w-3 stroke-currentColor stroke-2' />;
}

function IndeterminateIcon() {
  return <MinusIcon className='h-3 w-3 stroke-currentColor stroke-2' />;
}

/**
 * Traverses the given nested array to find the item that has a value matching
 * the given value.
 * @param items The list of items, where each item may have child items,
 * which in turn may have child items as well.
 * @param value The value (aka ID) being searched for.
 * @param parentKey The name of the field to check for nested child items.
 * @param valueKey The name of the field to check against the given value.
 * @returns The matching item based on the given value.
 */
function findNestedItem({
  items,
  value,
  parentKey,
  valueKey
}: Pick<NestedHeadlessCheckboxContextValue, 'parentKey' | 'valueKey'> & {
  items: object[];
  value: CheckboxSelection;
}): object {
  for (const item of items) {
    const itemValue = item[valueKey as keyof typeof item];
    if (
      typeof itemValue === 'number' ? itemValue === +value : itemValue === value
    ) {
      return item;
    }

    const childItems: object[] = item[parentKey as keyof typeof item];
    if (childItems && childItems.length > 0) {
      const foundItem = findNestedItem({
        items: childItems,
        value,
        parentKey,
        valueKey
      });

      if (foundItem[valueKey as keyof typeof item] !== undefined) {
        return foundItem;
      }
    }
  }

  return {};
}

/**
 * Returns the checked and indeterminate state of the checkbox with the given value.
 * @param value The value of the checkbox whose state is being returned.
 * @param leafChildren The flattened list of leaf items.
 * @param valueKey The name of the field to check against the given value.
 * @param selections The object containing the values of all the selected items.
 * @returns An object with checked and indeterminate properties as booleans.
 */
function getCheckboxState({
  value,
  leafChildren,
  valueKey,
  selections
}: Pick<NestedHeadlessCheckboxContextValue, 'valueKey' | 'selections'> & {
  leafChildren: object[];
  value: CheckboxSelection;
}): {
  checked: boolean;
  indeterminate: boolean;
} {
  let checked = false;
  let indeterminate = false;

  if (leafChildren.length > 0) {
    // If there are leaf children, we have to see if they are all selected
    const numLeavesSelected = getNumLeavesSelected({
      items: leafChildren,
      valueKey,
      selections
    });
    // For a parent item, checked is if at least one child is selected
    checked = numLeavesSelected > 0;
    // Indeterminate is if at least one is selected, but not all
    indeterminate = checked && numLeavesSelected < leafChildren.length;
  } else {
    // If no children, check if the item itself is selected
    checked = selections[value] || false;
  }

  return { checked, indeterminate };
}

/**
 * Given a parent value with an unknown amount of nesting, returns all leaf
 * children of the value (items without children).
 * @param valueKey The name of the field to check against a given value.
 * @param parentValue The value of the parent whose leaves to find.
 * @param parentKey The name of the field to check for nested child items.
 * @param allItems The full list of items.
 * @returns A flattened list of leaf children in the given array.
 */
function getLeaves({
  valueKey,
  parentKey,
  parentValue,
  allItems
}: Pick<
  NestedHeadlessCheckboxContextValue,
  'valueKey' | 'parentKey' | 'allItems'
> & {
  parentValue: CheckboxSelection;
}) {
  const getLeaf = (item: object): object => {
    const itemChildren: object[] = item[parentKey as keyof typeof item];
    return itemChildren && itemChildren.length > 0
      ? itemChildren.flatMap((element) => getLeaf(element))
      : item;
  };

  const nestedItem = findNestedItem({
    items: allItems,
    value: parentValue,
    parentKey,
    valueKey
  });

  const items: object[] =
    nestedItem[parentKey as keyof typeof nestedItem] || [];

  return items.flatMap((element) => getLeaf(element));
}

/**
 * Counts the number of selected items there are in the given array.
 * @param items The flattened list of leaf items.
 * @param valueKey The name of the field to check against a given value.
 * @param selections The object containing the values of all the selected items.
 * @returns The number of selected items within the given array.
 */
function getNumLeavesSelected({
  items,
  valueKey,
  selections
}: Pick<NestedHeadlessCheckboxContextValue, 'valueKey' | 'selections'> & {
  items: object[];
}) {
  let numSelected = 0;
  for (const item of items) {
    if (selections[item[valueKey as keyof typeof item]]) {
      numSelected += 1;
    }
  }
  return numSelected;
}

/**
 * Based on what was just checked or unchecked, return the updated object of selected item values.
 * @param newValue The value of the item that was just toggled.
 * @param leafChildren The flattened list of leaf items.
 * @param selections The object containing the values of all the selected items.
 * @param indeterminate Whether or not the checkbox is indeterminate.
 * @param checked Whether or not the checkbox is checked.
 * @param valueKey The name of the field to check against the given value.
 * @returns An updated object of selections.
 */
function getUpdatedSelections({
  newValue,
  leafChildren,
  selections,
  indeterminate,
  checked,
  valueKey
}: Pick<NestedHeadlessCheckboxContextValue, 'selections' | 'valueKey'> & {
  newValue: string;
  leafChildren: object[];
  indeterminate: boolean;
  checked: boolean;
}) {
  const newSelections = { ...selections };

  if (leafChildren.length === 0) {
    // If there's no children, do a simple add or remove
    if (newSelections[newValue]) {
      delete newSelections[newValue];
    } else {
      newSelections[newValue] = true;
    }
  } else if (indeterminate || !checked) {
    /*
     * If item is indeterminate, it means some, not all, leaves are checked.
     * If item isn't checked, then no leaves are checked. In both cases, we
     * should check all leaves now.
     */
    for (const leaf of leafChildren) {
      newSelections[leaf[valueKey as keyof typeof leaf]] = true;
    }
  } else {
    // Not indeterminate but checked means all leaves were selected - deselect them
    for (const leaf of leafChildren) {
      delete newSelections[leaf[valueKey as keyof typeof leaf]];
    }
  }

  return newSelections;
}

export type { CheckboxSelection, CheckboxGroupSelection };
export {
  CheckedIcon,
  IndeterminateIcon,
  findNestedItem,
  getCheckboxState,
  getLeaves,
  getNumLeavesSelected,
  getUpdatedSelections
};
