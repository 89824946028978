import { OpportunityStageName } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface OpportunityStageNameDefinition {
  readonly id: OpportunityStageName;
  readonly isClosed: boolean;
  readonly supportsProjections: boolean;
  readonly label: string;
  /*
   * Stage names are associated with different "stages" of the order. Approved
   * stages are higher priority than pending stages, which are higher priority
   * than rejected stages.
   */
  readonly stagePriority: 1 | 2 | 3;
}

const {
  getById: opportunityStageNameDef,
  getAll: opportunityStageNameAllDefs
} = createDefinition<OpportunityStageName, OpportunityStageNameDefinition>({
  definitions: {
    ClosedLost: {
      isClosed: false,
      supportsProjections: true,
      label: t('opportunitymodel.CLOSED_LOST'),
      stagePriority: 3
    },
    ClosedWon: {
      isClosed: true,
      supportsProjections: true,
      label: t('opportunitymodel.CLOSED_WON'),
      stagePriority: 1
    },
    ContractNegotiation: {
      isClosed: false,
      label: t('opportunitymodel.CONTRACT_NEGOTIATION'),
      supportsProjections: true,
      stagePriority: 2
    },
    MeetingPresentation: {
      isClosed: false,
      label: t('opportunitymodel.MEETING_PRESENTATION'),
      supportsProjections: false,
      stagePriority: 2
    },
    Proposal: {
      isClosed: false,
      supportsProjections: true,
      label: t('opportunitymodel.PROPOSAL'),
      stagePriority: 2
    },
    Qualification: {
      isClosed: false,
      supportsProjections: false,
      label: t('opportunitymodel.QUALIFICATION'),
      stagePriority: 2
    },
    QualifiedOut: {
      isClosed: false,
      supportsProjections: false,
      label: t('opportunitymodel.QUALIFIED_OUT'),
      stagePriority: 3
    },
    SubmittedProposal: {
      isClosed: false,
      supportsProjections: true,
      label: t('opportunitymodel.SUBMITTED_PROPOSAL'),
      stagePriority: 2
    },
    SubmittoClose: {
      isClosed: false,
      supportsProjections: false,
      label: t('opportunitymodel.SUBMIT_TO_CLOSE'),
      stagePriority: 2
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as OpportunityStageName,
      isClosed: false,
      label: 'Unknown stage name',
      supportsProjections: false,
      stagePriority: 3
    };
  }
});

export { opportunityStageNameAllDefs, opportunityStageNameDef };
export type { OpportunityStageNameDefinition };
