import { z } from 'zod';
import { translate as t } from '@/i18n';

interface TextFieldSchemaOptions {
  max?: number;
  min?: number;
  optional?: boolean;
}

// This is necessary to allow Zod to optionally run validation methods (email, url, min, max)
// and pass when the input is an empty string, which is what an "empty" input is when
// there is no value. Without this, an "empty", optional email input would fail
// as an invalid email address
// https://github.com/colinhacks/zod/issues/310#issuecomment-794533682
function allowEmptyString(schema: z.ZodString) {
  return schema.or(z.literal(''));
}

function textFieldSchema({
  max = Number.POSITIVE_INFINITY,
  min = 0,
  optional = false
}: TextFieldSchemaOptions) {
  const schema = z
    .string()
    .min(optional ? 0 : 1, { message: t('library.fieldRequiredError') })
    .min(min, { message: t('library.fieldLengthMinError', { num: min }) })
    .max(max, {
      message: t('library.fieldLengthMaxError', { num: max })
    })
    .trim();

  if (optional) {
    return allowEmptyString(schema);
  }

  return schema;
}

type EmailFieldSchemaOptions = Pick<TextFieldSchemaOptions, 'optional'>;

function emailFieldSchema({ optional }: EmailFieldSchemaOptions) {
  const schema = z
    .string()
    .min(optional ? 0 : 1, { message: t('library.fieldRequiredError') })
    .email(t('library.fieldEmailError'))
    .trim();

  if (optional) {
    return allowEmptyString(schema);
  }

  return schema;
}

export { textFieldSchema, emailFieldSchema };
