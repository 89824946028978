import { useEffect, useState } from 'react';

function useCombinedIsOpen(isDatePickerStateOpen: boolean) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // We need to combine the popover behavior with the datepicker behavior
    setIsOpen(isDatePickerStateOpen);
  }, [isDatePickerStateOpen]);

  return [isOpen, setIsOpen] as const;
}

export { useCombinedIsOpen };
