// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgDriveSales(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      viewBox='0 0 121 114'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <circle
        cx={4.474}
        cy={37.737}
        r={3.624}
        stroke='#0078BF'
        strokeWidth={1.7}
      />
      <circle cx={15.211} cy={2.842} r={2.684} fill='#0078BF' />
      <circle cx={10.737} cy={73.526} r={1.789} fill='#0078BF' />
      <circle cx={51.447} cy={9.553} r={4.026} fill='#0078BF' />
      <rect
        x={21.836}
        y={22.889}
        width={81.591}
        height={75.328}
        rx={3.133}
        stroke='#0078BF'
        strokeWidth={2.514}
      />
      <path
        d='M108.733 42.21h6.688A3.579 3.579 0 0 1 119 45.79v62.631a3.579 3.579 0 0 1-3.579 3.579h-68a3.579 3.579 0 0 1-3.579-3.579v-6.216'
        stroke='#0CB9F8'
        strokeWidth={2.514}
      />
      <path
        stroke='#0078BF'
        strokeWidth={2.514}
        d='M32.573 64.941h13.591v21.644H32.573zM54.941 53.31h13.591v33.275H54.941z'
      />
      <path
        stroke='#0CB9F8'
        strokeWidth={2.514}
        d='M77.31 37.205h13.591v49.38H77.31z'
      />
      <path
        d='m76.947 44.447 11.185-7.158M77.395 48.921l13.868-8.947M76.947 53.842l13.869-8.947m-13.421 13.42 13.868-8.947M77.395 81.579l13.868-8.947M76.947 86.5l13.869-8.947M84.553 86.5l6.71-4.474M76.947 63.237l14.316-9.395M77.395 67.71l13.868-8.947M76.947 72.632l13.869-8.948M77.395 77.105l13.868-8.947'
        stroke='#0CB9F8'
        strokeWidth={1.789}
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgDriveSales);
export default ForwardRef;
