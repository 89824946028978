import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { Institution } from '@models/institution';

interface GetByIdParams {
  institutionId: string;
}

const getById: AdapterFunction<Institution, GetByIdParams> = (
  { institutionId },
  { signal }
) => {
  const url = `/v2/institutions/${institutionId}`;

  apiTransport.setBaseUrl(process.env.PM_API_URL);

  const response = apiTransport.get<Institution>({
    url,
    config: {
      signal
    }
  });

  apiTransport.setBaseUrl(process.env.API_URL);

  return response;
};

export type { GetByIdParams };
export { getById };
