import { offerAdapter } from '@adapters/offer';
import { ServiceData, ServiceQuery } from '../service';

import { Offer } from '@models/campaign';
import { FilterRequestParam } from '@models/table';
import { TablePaginationState } from '@library/components';

interface GetData extends ServiceData {
  offers: Offer[];
}

interface GetParams {
  filter?: FilterRequestParam[];
  pagination?: TablePaginationState;
}

const get: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;

  const [{ filter = [], pagination = {} as TablePaginationState }] = queryKey;

  const path = '';
  const { data, meta } = await offerAdapter.get(
    { path, filter, pagination },
    { signal }
  );

  return { offers: data, meta };
};

export type { GetData, GetParams };
export { get };
