import { CampaignStatus } from '..';
import { AdStatus } from '../../ad';
import { AdGroupStatus } from '../../ad-group';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

/*
 * Note that despite this being in the campaign folder, this definition
 * applies to all statuses that fall under the "campaign structure"
 * umbrella (campaigns, ad groups, ads).
 */
// These are currently the same, but this could change in the future
// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
type CampaignStructureStatus = CampaignStatus | AdGroupStatus | AdStatus;

interface CampaignStructureStatusDefinition {
  readonly id: CampaignStructureStatus;
  readonly label: string;
  // Ideally this imports BadgeVariant from @ads-ui/library but... cannot
  readonly badgeVariant: string;
}

const {
  getById: campaignStructureStatusDef,
  getAll: campaignStructureStatusAllDefs
} = createDefinition<
  CampaignStructureStatus,
  CampaignStructureStatusDefinition
>({
  definitions: {
    Active: {
      label: t('campaignmodel.STATUS_ACTIVE'),
      badgeVariant: 'variant.success'
    },
    Draft: {
      label: t('campaignmodel.STATUS_DRAFT'),
      badgeVariant: 'variant.neutral'
    },
    InReview: {
      label: t('campaignmodel.STATUS_IN_REVIEW'),
      badgeVariant: 'variant.outline'
    },
    Inactive: {
      label: t('campaignmodel.STATUS_INACTIVE'),
      badgeVariant: 'variant.strong'
    },
    Scheduled: {
      label: t('campaignmodel.STATUS_SCHEDULED'),
      badgeVariant: 'variant.callout'
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as CampaignStructureStatus,
      label: id,
      badgeVariant: 'variant.neutral'
    };
  }
});

export { campaignStructureStatusAllDefs, campaignStructureStatusDef };
export type { CampaignStructureStatus, CampaignStructureStatusDefinition };
