import { Key } from 'react';
import { InputSize } from '../inputs';
import { SelectorProps } from './types';

const sizeClasses = {
  'size.md': 'input-field-md',
  'size.lg': 'input-field-lg'
} satisfies Record<InputSize, string>;

const disabledClasses = 'bg-disabled text-disabled';

/**
 * Gets an item's key value in a form to be used with React Aria.
 * @param item Item whose key to get
 * @param key Path to item's key value
 * @returns key value (string | number)
 */
function getItemKey<TItemType, TKey extends keyof TItemType>(
  item: TItemType,
  key: TKey
) {
  // just have to trust that itemKey points to a Key
  const value = item[key] as Key;

  /*
   * if the key was not passed in via the "key" or "id" field, then React Aria
   * will convert it to a string. To account for this, we also have to convert
   * non-key fields to strings in order for equality comparisons to function
   * properly.
   */
  return key === 'key' || key === 'id' ? value : String(value);
}

/**
 * Converts items into keys to be used with React Aria selectors, e.g, Select,
 * ComboBox, List.
 */
function useSelectedKey<TItemType>({
  itemKey,
  selectionMode,
  selectedItem,
  selectedItems
}: SelectorProps<TItemType>) {
  if (selectionMode === 'mode.multiple') {
    const selectedKeys = [];
    for (const item of selectedItems) {
      /* @ts-ignore */
      selectedKeys.push(getItemKey(item, itemKey));
    }

    return { selectedKeys };
  }

  if (selectedItem && typeof selectedItem === 'object') {
    return { selectedKey: getItemKey(selectedItem, itemKey) };
  }

  return { selectedKey: null };
}

export { getItemKey, useSelectedKey, sizeClasses, disabledClasses };
