import { useToasts } from '@library/components';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMemo } from 'react';

declare module '@tanstack/react-query' {
  interface QueryMeta {
    suppressToast?: boolean;
  }
}

interface ApiServiceProviderProps {
  children: React.ReactNode;
}

const ApiServiceProvider = ({ children }: ApiServiceProviderProps) => {
  const { sendToast } = useToasts();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retryOnMount: false,
            retry: false,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 5
          }
        },
        queryCache: new QueryCache({
          // this will trigger only once for a given request, even if there
          // are multiple observers to that request
          // https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
          onError(error, query) {
            // TODO: Send Error to Datadog or whatever

            if (query.options.meta?.suppressToast) {
              return;
            }

            // error is typed as unknown, so we have to narrow it to Error,
            // but i have no idea how to trigger this without error being an Error.
            /* istanbul ignore else -- @preserve */
            if (error instanceof Error) {
              // toast when a fetch fails
              sendToast({
                content: <>{error.message}</>,
                variant: 'variant.error'
              });
            }
          }
        })
      }),
    [sendToast]
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export { ApiServiceProvider };
