import camelcaseKeys from 'camelcase-keys';
import { CamelCasedPropertiesDeep } from 'type-fest';
import { z, ZodEffects } from 'zod';

interface CreateDefinitionOptions<KeyType extends string | number, ValueType> {
  definitions: { [key in KeyType]: Omit<ValueType, 'id'> };
  getUnknownDefinition: (id: KeyType) => ValueType;
}

function getDefinitionById<KeyType extends string | number, ValueType>({
  definitions,
  getUnknownDefinition
}: CreateDefinitionOptions<KeyType, ValueType>) {
  return (id: KeyType) => {
    const def = definitions[id] || getUnknownDefinition(id);
    return { ...def, id };
  };
}

function createDefinition<KeyType extends string, ValueType>({
  definitions,
  getUnknownDefinition
}: CreateDefinitionOptions<KeyType, ValueType>) {
  const allDefinitions: ValueType[] = [];
  for (const [id, definition] of Object.entries(definitions) as [
    KeyType,
    ValueType
  ][]) {
    allDefinitions.push({ ...definition, id });
  }

  const getById = getDefinitionById({ definitions, getUnknownDefinition });

  const getAll = () => allDefinitions;

  return { getById, getAll };
}

function createNumberDefinition<KeyType extends number, ValueType>({
  definitions,
  getUnknownDefinition
}: CreateDefinitionOptions<KeyType, ValueType>) {
  const allDefinitions: ValueType[] = [];
  for (const [id, definition] of Object.entries(definitions) as [
    string,
    ValueType
  ][]) {
    allDefinitions.push({ ...definition, id: +id });
  }

  const getById = getDefinitionById({ definitions, getUnknownDefinition });

  const getAll = () => allDefinitions;

  return { getById, getAll };
}

// Converts an object schema which will transform an object with non-camelCase keys
// into the transformed object with camelCase keys
// https://github.com/colinhacks/zod/issues/486
// need CamelCasedPropertiesDeep because of
// https://github.com/sindresorhus/camelcase-keys/issues/77#issuecomment-1339844470
export const zodToCamelCase = <T extends z.ZodTypeAny>(
  zod: T
): ZodEffects<z.infer<T>, CamelCasedPropertiesDeep<T['_output']>> =>
  zod.transform(
    (val) => camelcaseKeys(val, { deep: true }) as CamelCasedPropertiesDeep<T>
  );

export { createDefinition, createNumberDefinition };
