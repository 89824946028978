import React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { classnames } from '@library/utils';
import { TooltipPlacement } from '../tooltip/types';

type RadixTooltipSides = 'bottom' | 'left' | 'right' | 'top';
const placements = {
  'placement.bottom': 'bottom',
  'placement.left': 'left',
  'placement.right': 'right',
  'placement.top': 'top'
} satisfies Record<TooltipPlacement, RadixTooltipSides>;

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  placement?: TooltipPlacement;
  maxWidth?: string;
  disabled?: boolean;
}

function Tooltip({
  children,
  content,
  className = 'inline-block',
  placement = 'placement.top',
  maxWidth = '350px',
  disabled = false
}: TooltipProps) {
  const animationClasses =
    'data-[state=delayed-open]:animate-fadeIn data-[state=closed]:animate-fadeOut';

  return (
    <RadixTooltip.Provider delayDuration={200}>
      <RadixTooltip.Root>
        <RadixTooltip.TooltipTrigger asChild={true}>
          <div className={className}>{children}</div>
        </RadixTooltip.TooltipTrigger>
        {disabled ? null : (
          <RadixTooltip.Portal>
            <RadixTooltip.TooltipContent
              className={classnames(
                'box-content rounded-md bg-inverse px-2 py-1 text-sm text-inverse',
                animationClasses
              )}
              side={placements[placement]}
              sideOffset={2}
              style={{ maxWidth }}
            >
              <RadixTooltip.TooltipArrow width={12} height={8} />
              {content}
            </RadixTooltip.TooltipContent>
          </RadixTooltip.Portal>
        )}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

export type { TooltipProps };
export { Tooltip };
