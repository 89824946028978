import { AdapterRewardType } from '..';
import { CreativeRewardType } from '../../creative';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

const ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP = {
  SingleRewardPercentAmountBack: 'PercentBack',
  SingleRewardFlatAmountBack: 'FlatAmountBack',
  SingleRewardSpendXGetY: 'SpendXGetY',
  MultipleRewardPercentAmountBack: 'PercentBack',
  MccSingleRewardPercentAmountBack: 'PercentBack',
  MccSingleRewardFlatAmountBack: 'FlatAmountBack',
  CustomRewardRedemption: 'FlatAmountBack'
} satisfies Record<AdapterRewardType, CreativeRewardType>;

interface RewardTypeDefinition {
  readonly id: AdapterRewardType;
  readonly label: string;
  readonly isMcc: boolean;
  readonly isMultiple: boolean;
  /**
   * True if this is ONLY available on RMS, false otherwise.
   */
  readonly isOnlyRms: boolean;
  /**
   * A reward's rewardTemplate field is equivalent to a creative's rewardType field.
   * We are committing to using CreativeRewardType instead of a RewardTemplate type
   * because there is an unrelated type already called RewardTemplate.
   */
  readonly rewardTemplate: CreativeRewardType;
}

const { getById: rewardTypeDef, getAll: rewardTypeAllDefs } = createDefinition<
  AdapterRewardType,
  RewardTypeDefinition
>({
  definitions: {
    SingleRewardPercentAmountBack: {
      label: t('rewardmodel.TYPE_SINGLE_REWARD_PERCENT_AMOUNT_BACK'),
      isMcc: false,
      isMultiple: false,
      isOnlyRms: false,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.SingleRewardPercentAmountBack
    },
    SingleRewardFlatAmountBack: {
      label: t('rewardmodel.TYPE_SINGLE_REWARD_FLAT_AMOUNT_BACK'),
      isMcc: false,
      isMultiple: false,
      isOnlyRms: false,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.SingleRewardFlatAmountBack
    },
    SingleRewardSpendXGetY: {
      label: t('rewardmodel.TYPE_SINGLE_REWARD_SPEND_X_GET_Y'),
      isMcc: false,
      isMultiple: false,
      isOnlyRms: false,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.SingleRewardSpendXGetY
    },
    MultipleRewardPercentAmountBack: {
      label: t('rewardmodel.TYPE_MULTIPLE_REWARD_PERCENT_AMOUNT_BACK'),
      isMcc: false,
      isMultiple: true,
      isOnlyRms: false,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.MultipleRewardPercentAmountBack
    },
    MccSingleRewardPercentAmountBack: {
      label: t('rewardmodel.TYPE_MCC_SINGLE_REWARD_PERCENT_AMOUNT_BACK'),
      isMcc: true,
      isMultiple: false,
      isOnlyRms: true,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.MccSingleRewardPercentAmountBack
    },
    MccSingleRewardFlatAmountBack: {
      label: t('rewardmodel.TYPE_MCC_SINGLE_REWARD_FLAT_AMOUNT_BACK'),
      isMcc: true,
      isMultiple: false,
      isOnlyRms: true,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.MccSingleRewardFlatAmountBack
    },
    CustomRewardRedemption: {
      label: t('rewardmodel.TYPE_CUSTOM_REWARD_REDEMPTION'),
      isMcc: false,
      isMultiple: false,
      isOnlyRms: true,
      rewardTemplate:
        ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP.SingleRewardPercentAmountBack
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as AdapterRewardType,
      label: id,
      isMcc: false,
      isMultiple: false,
      isOnlyRms: true,
      rewardTemplate: 'PercentBack'
    };
  }
});

export {
  ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP,
  rewardTypeAllDefs,
  rewardTypeDef
};
export type { RewardTypeDefinition };
