import React from 'react';
import { classnames } from '@library/utils';
import * as RadixPopover from '@radix-ui/react-popover';
import { useModalContainer } from '../modal';
import { PopoverAlignment } from './types';

interface PopoverBaseProps {
  children: React.ReactNode;
}

interface PopoverControlledProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

interface PopoverUncontrolledProps {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
}

type PopoverProps = PopoverBaseProps &
  (PopoverControlledProps | PopoverUncontrolledProps);

const Popover = ({ children, isOpen, onOpen, onClose }: PopoverProps) => (
  <RadixPopover.Root
    open={isOpen}
    onOpenChange={(newOpenState) => {
      newOpenState ? onOpen?.() : onClose?.();
    }}
  >
    {children}
  </RadixPopover.Root>
);

interface PopoverTriggerProps {
  children: React.ReactNode;
}

const PopoverTrigger = ({ children }: PopoverTriggerProps) => (
  <RadixPopover.Trigger asChild={true}>{children}</RadixPopover.Trigger>
);

type RadixPopoverAligns = 'start' | 'center' | 'end';
const alignments = {
  'alignment.start': 'start',
  'alignment.center': 'center',
  'alignment.end': 'end'
} satisfies Record<PopoverAlignment, RadixPopoverAligns>;

interface PopoverContentProps {
  children: React.ReactNode;
  className?: string;
  alignment?: PopoverAlignment;
  preventAutoFocus?: boolean;
}

const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent(
    {
      children,
      className,
      alignment = 'alignment.center',
      preventAutoFocus = false
    },
    ref
  ) {
    const container = useModalContainer();

    return (
      <RadixPopover.Portal container={container || undefined}>
        <RadixPopover.Content
          className={classnames('rounded-md bg-content shadow-lg', className)}
          align={alignments[alignment]}
          onOpenAutoFocus={(event) => {
            if (preventAutoFocus) {
              event.preventDefault();
            }
          }}
          sideOffset={1}
          ref={ref}
        >
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    );
  }
);

export { Popover, PopoverContent, PopoverTrigger };
