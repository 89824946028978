import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

import { FileDragAndDrop, LinkButton } from '@library/components';
import { PreviewCustomersModal } from '../PreviewCustomersModal/PreviewCustomersModal';

const areFilePropertiesValid = (target, propertyNamesArray) => {
  const objectPropertyNames = Object.keys(target);

  if (objectPropertyNames.length !== propertyNamesArray.length) {
    return false;
  }

  for (const element of propertyNamesArray) {
    if (!objectPropertyNames.includes(element)) {
      return false;
    }
  }

  for (const objectPropertyName of objectPropertyNames) {
    if (!propertyNamesArray.includes(objectPropertyName)) {
      return false;
    }
  }

  return true;
};

const downloadTemplateFile = () => {
  const url = import.meta.env.BASE_URL + 'customers-template.csv';
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'customers-template.csv';
  document.body.append(anchor);
  anchor.click();
  anchor.remove();
};

const AddMultipleCustomers = ({
  setIsModalOpen
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [t] = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedCustomers, setUploadedCustomers] = useState<any[]>([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [fileError, setFileError] = useState(false);

  const handleFileUpload = (file) => {
    if (file) {
      Papa.parse(file, {
        header: true,
        complete(results) {
          setUploadedFile(file);
          validateFile(results.data);
        }
      });
    } else {
      setUploadedFile(null);
    }
  };

  const validateFile = (file) => {
    const validProperties = [
      'sourceCustomerId',
      'sourceAccountId',
      'sourceOrganizationId',
      'sourcePortfolioId',
      'enrollmentOptInStatus',
      'lifetimeEarnedAmount'
    ];

    if (file.length > 0 && areFilePropertiesValid(file[0], validProperties)) {
      setUploadedCustomers(
        file.map((customer) => ({
          ...customer,
          lifetimeEarnedAmount: Number(customer.lifetimeEarnedAmount.trim())
        }))
      );
      setFileError(false);
      setIsPreviewModalOpen(true);
    } else {
      setUploadedFile(null);
      setFileError(true);
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <h4 className='mb-2 text-lg font-bold'>
          {t('label.addMultipleTestCustomers')}
          <span className='ml-2 text-sm font-normal text-gray-disabledText'>
            ({t('label.csvFile')})
          </span>
        </h4>
        <LinkButton onClick={downloadTemplateFile}>
          <DocumentArrowDownIcon className='h-5 w-6' />
          {t('label.downloadCsvTemplate')}
        </LinkButton>
      </div>
      <FileDragAndDrop
        accept='.csv'
        selectedFile={uploadedFile}
        onComplete={handleFileUpload}
        error={
          fileError && (
            <p className='text-red-alerts'>
              <span className='mr-2 font-bold uppercase'>
                {t('label.wrongFile')}
              </span>
              {t('label.useCsvTemplate')}
            </p>
          )
        }
      />
      <PreviewCustomersModal
        isModalOpen={isPreviewModalOpen}
        setIsModalOpen={setIsPreviewModalOpen}
        setIsAddCustomerModalOpen={setIsModalOpen}
        customers={uploadedCustomers}
      />
    </div>
  );
};

export { AddMultipleCustomers };
