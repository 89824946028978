import React from 'react';
import { ButtonlikeSize, ButtonlikeVariant, ButtonlikeWidth } from '../types';
import { useButtonClasses } from '../use-button-classes';
import { NavLink, NavLinkProps } from './nav-link';

interface TertiaryLinkProps
  extends Omit<NavLinkProps, 'className' | 'size' | 'usage' | 'variant'> {
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  width?: ButtonlikeWidth;
}

const TertiaryLink = React.forwardRef<HTMLAnchorElement, TertiaryLinkProps>(
  function TertiaryLinkWithRef(
    {
      size = 'size.md',
      variant = 'variant.base',
      width = 'width.unset',
      ...delegated
    },
    ref
  ) {
    return (
      <NavLink
        {...delegated}
        className={useButtonClasses({
          size,
          usage: 'usage.tertiary',
          variant,
          width
        })}
        ref={ref}
      />
    );
  }
);

export type { TertiaryLinkProps };
export { TertiaryLink };
