import { z } from 'zod';

const apiMerchantCategoryCodeSchema = z.object({
  mcc: z.string(),
  // eslint-disable-next-line camelcase
  edited_description: z.string(),
  // eslint-disable-next-line camelcase
  combined_description: z.string(),
  // eslint-disable-next-line camelcase
  usda_description: z.string(),
  // eslint-disable-next-line camelcase
  irs_description: z.string(),
  // eslint-disable-next-line camelcase
  irs_reportable: z.string(),
  id: z.number(),
  newEntry: z.boolean().optional()
});

type ApiMerchantCategoryCode = z.infer<typeof apiMerchantCategoryCodeSchema>;

const adapterMerchantCategoryCodeSchema = z.object({
  mcc: z.number(),
  name: z.string()
});

type AdapterMerchantCategoryCode = z.infer<
  typeof adapterMerchantCategoryCodeSchema
>;

export { adapterMerchantCategoryCodeSchema, apiMerchantCategoryCodeSchema };
export type { AdapterMerchantCategoryCode, ApiMerchantCategoryCode };
