import React from 'react';
import { ButtonType } from './types';

interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
}

const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  function BaseButton(
    { className, type = 'button', disabled, ...delegated },
    ref
  ) {
    return (
      <button
        {...delegated}
        disabled={disabled}
        type={type}
        className={className}
        ref={ref}
      />
    );
  }
);

export type { BaseButtonProps };
export { BaseButton };
