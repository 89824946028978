import {
  TransportError,
  TransportResponseMeta
} from '@services/rest-api.types';
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import { z } from 'zod';

interface ServiceData {
  meta?: TransportResponseMeta;
}

type ServiceError<TData> = TransportError<TData>;

interface KeyBase {
  name: string;
}

type ServiceQueryOptions<
  TParams,
  TQueryFnData,
  TError = ServiceError<TQueryFnData>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = ServiceQueryKey<TParams>
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn' | 'staleTime' | 'cacheTime'
>;

type ServiceQueryKey<TParams> = readonly [KeyBase & TParams];

type ServiceQuery<TData, TParams = void> = QueryFunction<
  TData,
  ServiceQueryKey<TParams>
>;

type ServiceQueryArguments<TParams, TData> = {
  params?: TParams;
} & ServiceQueryOptions<TParams, TData>;

const generateQueryKey = <TParams>(
  name: string,
  method: string,
  params: TParams
) => [{ name, method, ...params }] as const;

const serviceCacheTimeSchema = z.enum([
  'cache.none',
  'cache.short',
  'cache.long',
  'cache.veryLong'
]);
type ServiceCacheTime = z.infer<typeof serviceCacheTimeSchema>;

interface ServiceOptions {
  name: string;
  cacheTime: ServiceCacheTime;
}
interface QueryCacheConfig {
  staleTime: number;
}

const queryCacheConfigs = {
  'cache.none': { staleTime: 0 }, // data is immediately stale and will be refetched every time it is accessed
  'cache.short': { staleTime: 1000 * 60 * 2 }, // data will be cached locally for 2 min; persist in memory for 5 min
  'cache.long': { staleTime: 1000 * 60 * 5 }, // data will be cached locally for 5 min; persist in memory for 5 min
  'cache.veryLong': { staleTime: 1000 * 60 * 60 } // data will be cached locally for 60 min; persist in memory for 60 min
} satisfies Record<ServiceCacheTime, QueryCacheConfig>;

const wrapQueryFunction =
  <TData, TParams>(
    options: ServiceOptions,
    method: ServiceQuery<TData, TParams>
  ) =>
  ({ params, ...queryOptions }: ServiceQueryArguments<TParams, TData> = {}) =>
    // the return here must have a type assertion due to
    // https://github.com/microsoft/TypeScript/issues/42873
    ({
      queryKey: generateQueryKey(options.name, method.name, params),
      queryFn: method,
      ...queryOptions,
      ...queryCacheConfigs[options.cacheTime]
    } as UseQueryOptions<
      TData,
      ServiceError<TData>,
      TData,
      ServiceQueryKey<TParams>
    >);

type ServiceMutation<TData, TVariables> = MutationFunction<TData, TVariables>;

const wrapMutationFunction =
  <TData, TVariables>(
    options: ServiceOptions,
    method: ServiceMutation<TData, TVariables>
  ) =>
  (
    mutationOptions: Omit<
      UseMutationOptions<TData, ServiceError<TData>, TVariables>,
      'mutationFn' | 'mutationKey'
    > = {}
  ) =>
    ({
      // not a cache key; can be used to inherit defaults set with queryClient.setMutationDefaults
      // or to identify the mutation in the devtools.
      mutationKey: [`${options.name}:${method.name}`],
      mutationFn: method,
      ...mutationOptions
    } as UseMutationOptions<TData, ServiceError<TData>, TVariables>);

export type {
  ServiceData,
  ServiceError,
  ServiceMutation,
  ServiceOptions,
  ServiceQuery,
  ServiceQueryKey,
  ServiceQueryOptions
};
export { wrapQueryFunction, wrapMutationFunction };
