import { t } from 'i18next';
import { z } from 'zod';

// A custom zod error map that is applied to our forms. This is used to override
// some built in validation errors that would otherwise have to be defined on every
// instance of a field
const formErrorMap: z.ZodErrorMap = function (error, ctx) {
  switch (error.code) {
    case 'invalid_enum_value': {
      if (error.received === '') {
        return { message: t('library.fieldRequiredError') };
      }

      // default error message is
      // Invalid enum value. Expected '' | 'aaa', received 'bbb'
      return { message: t('library.fieldInvalidValueError') };
    }
    default: {
      return { message: ctx.defaultError };
    }
  }
};

export { formErrorMap };
