import { classnames } from '@library/utils';
import { TextLoader } from './text-loader';

const rowLengthClasses = [
  'w-full',
  'w-3/4',
  'w-3/5',
  'w-4/5',
  'w-3/5',
  'w-2/3',
  'w-4/5',
  'w-1/3',
  'w-2/5'
];

interface ParagraphLoaderProps {
  rows?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

function ParagraphLoader({ rows = 3 }: ParagraphLoaderProps) {
  return (
    <>
      {Array.from({ length: rows }, (_value, index) => (
        /* eslint-disable react/no-array-index-key */
        <div
          key={index}
          className={classnames(
            rowLengthClasses[index],
            index > 0 ? 'mt-2' : ''
          )}
        >
          <TextLoader />
        </div>
      ))}
    </>
  );
}

export type { ParagraphLoaderProps };
export { ParagraphLoader };
