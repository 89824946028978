import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { CustomersList } from '@components/Customers/CustomersList/CustomersList';
import { CustomersListHeader } from '@/components/Customers/CustomersList/CustomersListHeader/CustomersListHeader';

import { useApiQuery } from '@hooks/use-api-query';
import { customerService } from '@services/customer';

import { Customer } from '@models/customer';
import { useInstitution } from '@/context-providers/institution';
import { TablePaginationState } from '@library/components';
import { parseCustomerList } from '@utils/customer';

const CustomersListView = () => {
  const [t] = useTranslation();
  const { currentInstitutionId } = useInstitution();
  const queryClient = useQueryClient();

  const [filterText, setFilterText] = useState('');
  const [filterRequestText, setFilterRequestText] = useState<string>('');
  const [enableRequest, setEnableRequest] = useState<boolean>(false);

  const [pagination, setPagination] = useState<TablePaginationState>({
    pageIndex: 0,
    pageSize: 5
  });

  const clearCustomerCache = async () => {
    await queryClient.invalidateQueries({
      queryKey: customerService.invalidationKey
    });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (filterText.length === 0 || filterText.length > 1) {
        setFilterRequestText(filterText);
        setPagination({ ...pagination, pageIndex: 0 });
        setEnableRequest(true);
      }
    }, 400);

    return () => clearTimeout(getData);
  }, [filterText]);

  useEffect(() => {
    refetchPaginated();
  }, []);

  useEffect(() => {
    clearCustomerCache();
  }, [currentInstitutionId]);

  const {
    data: dataPaginated,
    isError: isErrorPaginated,
    refetch: refetchPaginated,
    isFetching: isFetchingPaginated
  } = useApiQuery(
    customerService.get({
      params: {
        filter: [
          { key: 'sourceCustomerId', value: filterRequestText, type: 'string' }
        ],
        pagination
      },
      enabled: currentInstitutionId.length > 0 && enableRequest
    })
  );

  const customers = useMemo(
    () =>
      dataPaginated?.customers
        ? parseCustomerList(dataPaginated.customers)
        : ([] as Customer[]),
    [dataPaginated, isFetchingPaginated]
  );

  const isLoadingCustomers = useMemo(
    () => isFetchingPaginated,
    [isFetchingPaginated]
  );

  const totalCustomers = useMemo(
    () =>
      isFetchingPaginated || !dataPaginated?.meta
        ? 0
        : dataPaginated?.meta.total,
    [dataPaginated]
  );

  return (
    <div>
      <h2 className='mb-10 text-2xl font-bold'>{t('label.testCustomers')}</h2>
      <CustomersListHeader
        filterText={filterText}
        filterCustomers={setFilterText}
        disableFilter={isLoadingCustomers}
      />

      {isErrorPaginated ? (
        <h2 className='text-lg font-bold'>
          {t('label.couldNotLoadCustomers')}
        </h2>
      ) : (
        <CustomersList
          customers={customers}
          isLoading={isLoadingCustomers}
          totalCustomers={totalCustomers}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      )}
    </div>
  );
};

export { CustomersListView };
