import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdPreview } from '@library/components';

import { useInstitution } from '@/context-providers/institution';

import { Ad, AdCopy, Image, Link } from '@models/campaign';
import { MerchantProductCategory } from '@library/models/merchant';
import { RedeemingMerchantAnnotationPurchaseChannelAnnotation } from '@library/models/redeeming-merchant-annotation';
import { ApiRewardAnnotation } from '@library/models/reward';

interface OfferPreviewProps {
  advertisement: Ad;
}

const getCreativeImage = (images: Image[], slot: string) => {
  const img = images.find((image) => image.slot === slot);
  return img?.externalUrl || undefined;
};

const getCreativeAdCopy = (adCopies: AdCopy[], slot: string) => {
  const copy = adCopies.find((adCopy) => adCopy.slot === slot);
  return copy?.text || undefined;
};

const getCreativeLink = (links: Link[], slot: string) => {
  const linkText = links.find((link) => link.slot === slot);
  return linkText?.text || undefined;
};

const parseAdvertisement = (advertisement: Ad) => {
  if (advertisement === null) {
    return;
  }

  const {
    creative,
    storeLocationTypes,
    productCategoryAnnotations: productAnnotations
  } = advertisement;
  const { merchantName, adCopy, images, links: creativeLinks } = creative;

  const brandImageSrc = getCreativeImage(images, 'HeroImage');
  const logoImageSrc = getCreativeImage(images, 'Logo');

  const headline = getCreativeAdCopy(adCopy, 'Headline');
  const rewardDetails = getCreativeAdCopy(adCopy, 'RewardDetails');
  const marketingCopy = getCreativeAdCopy(adCopy, 'MarketingCopy');
  const termsAndConditions = getCreativeAdCopy(adCopy, 'TermsAndConditions');

  const callToAction = getCreativeLink(creativeLinks, 'CallToAction');

  const rewardCapMessage = advertisement?.rewardCapMessage || '';
  const expiresMessage = advertisement.expiresMessage;

  const productCategoryAnnotations =
    productAnnotations as MerchantProductCategory[];

  const purchaseChannelAnnotations =
    storeLocationTypes as RedeemingMerchantAnnotationPurchaseChannelAnnotation[];

  const rewardAnnotation = 'FlashDeal' as ApiRewardAnnotation;

  return {
    brandImageSrc,
    logoImageSrc,
    merchantName,
    rewardCapMessage,
    expiresMessage,
    productCategoryAnnotations,
    purchaseChannelAnnotations,
    rewardAnnotation,
    headline,
    rewardDetails,
    termsAndConditions,
    marketingCopy,
    callToAction
  };
};

const OfferPreview = ({ advertisement }: OfferPreviewProps) => {
  const [t] = useTranslation();
  const { currency } = useInstitution();

  const adPreviewProps = useMemo(
    () => parseAdvertisement(advertisement),
    [advertisement]
  );

  const usLocale = {
    locale: currency.locale,
    currency: currency.code
  };

  return (
    <div className='flex flex-col justify-center'>
      {Boolean(advertisement) && (
        <AdPreview {...adPreviewProps} {...usLocale} />
      )}

      <p className='mt-4 text-xs'>
        <span className='font-bold'>{t('label.disclaimer')}</span>{' '}
        {t('label.closeApproximation')}
      </p>
    </div>
  );
};
export { OfferPreview };
