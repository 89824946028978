import { organizationAdapter } from '@/adapters/organization';
import { ServiceData, ServiceQuery } from '../service';

interface GetData extends ServiceData {
  validOrganizations: string[];
}

interface GetParams {
  institutionId: string;
  organizationIds: string[];
}

const getGroup: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ institutionId, organizationIds }] = queryKey;

  const parsedOrganizationIds = organizationIds.map((id) => [
    {
      key: 'source_organization_id',
      value: id,
      type: 'string'
    }
  ]);

  const promises = parsedOrganizationIds.map((orgIdFilter) =>
    organizationAdapter.get({ institutionId, filter: orgIdFilter }, { signal })
  );

  const responses = (await Promise.allSettled(promises)) as any;

  const validOrganizations = responses
    .filter(
      (organization) =>
        organization.status === 'fulfilled' &&
        organization.value.data.length > 0
    )
    .map((organization) => organization.value.data[0].sourceOrganizationId);

  return { validOrganizations };
};

export type { GetData, GetParams };
export { getGroup };
