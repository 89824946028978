import { ServiceData, ServiceMutation } from '../service';

import { UpdateCampaignRequest } from '@models/campaign';
import { customerAdapter } from '@adapters/customer';

type PortfolioTypeUpdateVariables = {
  payload: any;
  sourceCustomerId: string;
};

interface UpdateData extends ServiceData {
  offer: { data: Partial<UpdateCampaignRequest> };
}

const postClientEvent: ServiceMutation<
  any,
  PortfolioTypeUpdateVariables
> = async (params) => {
  const { payload, sourceCustomerId } = params;

  const response = await customerAdapter.postClientEvent({
    body: payload,
    sourceCustomerId
  });

  return {
    offer: response
  };
};

export type { UpdateData };
export { postClientEvent };
