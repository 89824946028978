import { FieldError, get, useFormState } from 'react-hook-form';

interface UseFieldErrorArgs {
  name: string;
}

// A hook to subscribe to the errors of a single field by name
// This used to use `getFieldState` from `useFormContext` paired with `useFormState` (an supposedly supported usage),
// but that resulted in Warning: The current testing environment is not configured to support act(...)
// warnings whenever this code was run in a test that used act().
// Now this replicates what the react-hook-form ErrorMessage component does, but still sometimes ends up with that
// damn warning in tests that run this code and use act().
function useFieldError({ name }: UseFieldErrorArgs) {
  const formState = useFormState();

  return { error: get(formState.errors, name) as FieldError | undefined };
}

export { useFieldError };
