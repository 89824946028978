import { ServiceError } from '@services/service';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useApiTransport } from './use-api-transport';

// this tweet thread may be useful when integrating auth0
// https://twitter.com/TkDodo/status/1491451513264574501
const useApiQuery = <
  TQueryFnData = unknown,
  TError = ServiceError<TQueryFnData>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  query: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> => {
  useApiTransport();

  return useQuery(query);
};

export { useApiQuery };
