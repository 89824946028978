import React from 'react';
import { useTranslation } from 'react-i18next';

interface FileUploadProps {
  onComplete: (file: File) => void | Promise<void>;
  accept: string;
}

const FileUpload = React.forwardRef<HTMLInputElement, FileUploadProps>(
  function FileUpload({ onComplete, accept }: FileUploadProps, ref) {
    const { t } = useTranslation();

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        void onComplete(e.target.files[0]);
      }
    };

    return (
      <input
        className='hidden'
        aria-label={t('library.fileUpload') ?? ''}
        type='file'
        accept={accept}
        onChange={onSelectFile}
        ref={ref}
      />
    );
  }
);

export { FileUpload };
export type { FileUploadProps };
