// @ts-nocheck
import { classnames } from '@library/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useButton } from '@react-aria/button';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import type { AriaButtonProps } from '@react-types/button';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../buttonlike';

interface CalendarHeaderProps {
  monthsToShow: number;
  calendarState: CalendarState | RangeCalendarState;
  prevButtonProps: AriaButtonProps<'button'>;
  nextButtonProps: AriaButtonProps<'button'>;
}

function CalendarHeader({
  monthsToShow,
  calendarState,
  prevButtonProps,
  nextButtonProps
}: CalendarHeaderProps) {
  const { t } = useTranslation();

  const { buttonProps: usedPrevButtonProps } = useButton(
    prevButtonProps,
    useRef(null)
  );

  const { buttonProps: usedNextButtonProps } = useButton(
    nextButtonProps,
    useRef(null)
  );

  return (
    <div className='flex items-center justify-between'>
      <div>
        <IconButton {...usedPrevButtonProps} title={t('library.PREVIOUS')}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <div
        className={classnames(
          'grid',
          monthsToShow > 1 ? 'grid-cols-2 gap-24' : 'grid-cols-1'
        )}
      >
        {Array.from({ length: monthsToShow }, (_val, index) => {
          const text = t('library.DATE_MONTH_YEAR', {
            value: calendarState.visibleRange.start
              .add({ months: index })
              .toString()
          });

          return (
            <h2 key={text} className='w-44 px-2 text-center'>
              {text}
            </h2>
          );
        })}
      </div>
      <div>
        <IconButton {...usedNextButtonProps} title={t('library.NEXT')}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
}

export { CalendarHeader };
