import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { classnames } from '@library/utils';
import { useButton } from '@react-aria/button';
import { useComboBox } from '@react-aria/combobox';
import { useRef } from 'react';
import { HorizontalLoader } from '../../horizontal-loader';
import { ListBoxPopover } from '../list-box';
import { disabledClasses, sizeClasses } from '../utils';
import { useComboBoxState } from './hooks';
import { ComboBoxProps } from './types';

function ComboBox<TItemType extends object>({
  disabled,
  loading,
  noDataMessage,
  size = 'size.lg',
  className,
  'aria-invalid': ariaInvalid,
  'aria-required': ariaRequired,
  forceIsOpen,
  ...props
}: ComboBoxProps<TItemType>) {
  const state = useComboBoxState(props);

  if (forceIsOpen && !state.isOpen) {
    state.open();
  }

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps
  } = useComboBox(
    // @ts-ignore
    {
      ...props,
      onSelectionChange: undefined,
      isDisabled: disabled,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef
    },
    state
  );

  const { buttonProps } = useButton(triggerProps, buttonRef);

  return (
    <div className={classnames(className, 'w-full')}>
      {props.label ? (
        <label className='mb-1 block' {...labelProps}>
          {props.label}
        </label>
      ) : null}
      <div className='relative'>
        <input
          {...inputProps}
          aria-invalid={ariaInvalid}
          aria-required={ariaRequired}
          ref={inputRef}
          className={classnames(
            sizeClasses[size],
            'input-field w-full pr-10',
            state.isFocused ? 'cursor-text' : null,
            disabled ? disabledClasses : 'cursor-pointer'
          )}
          onClick={() => {
            state.open('first', 'manual');
          }}
        />
        <button
          {...buttonProps}
          ref={buttonRef}
          className={classnames(
            'absolute bottom-0 right-px top-0 m-auto h-3/4 px-4',
            disabled ? disabledClasses : null
          )}
        >
          <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
        </button>
        <HorizontalLoader dataTestId='combo-box-loader' isActive={!!loading} />
      </div>
      {state.isOpen ? (
        <ListBoxPopover
          popoverRef={popoverRef}
          triggerRef={inputRef}
          listBoxRef={listBoxRef}
          listBoxProps={listBoxProps}
          state={state}
          placement='bottom start'
          loading={loading}
          noDataMessage={noDataMessage}
          width={inputRef.current?.offsetWidth}
        />
      ) : null}
    </div>
  );
}

export { Item as ComboBoxItem } from '@react-stately/collections';
export { ComboBox };
