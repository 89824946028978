import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import logoPlaceholder from '@assets/bank.svg';

import { Badge } from '@library/components';

import { useInstitution } from '@/context-providers/institution';

import { InstitutionLogo } from '@models/institution';

import { customerService } from '@services/customer';

import { getLogoUrl } from '@/utils';

interface InstitutionCardProps {
  id: number;
  institutionLogos: InstitutionLogo[];
  name: string;
  codeName: string;
  isActive: boolean;
}

const InstitutionCard = ({
  id,
  institutionLogos,
  codeName,
  name,
  isActive
}: InstitutionCardProps) => {
  const queryClient = useQueryClient();
  const { setCurrentInstitutionId } = useInstitution();
  const altText = `${name} Logo`;
  const navigate = useNavigate();

  const institutionClicked = async () => {
    setCurrentInstitutionId(`${id}`);
    sessionStorage.setItem('SST_FIID', `${id}`);

    await queryClient.invalidateQueries({
      queryKey: customerService.invalidationKey
    });

    navigate(`/customers`);
  };

  const logoSrc = useMemo(() => {
    const logoFull =
      institutionLogos &&
      institutionLogos.length > 0 &&
      getLogoUrl({
        logos: institutionLogos,
        type: 'LogoFull',
        institutionId: id
      });

    const logoMark =
      institutionLogos &&
      institutionLogos.length > 0 &&
      getLogoUrl({
        logos: institutionLogos,
        type: 'LogoMark',
        institutionId: id
      });

    return logoFull && logoFull.length > 0 ? logoFull : logoMark;
  }, [institutionLogos]);

  return (
    <button
      className='InstitutionCard my-2 rounded border border-gray-rules bg-white'
      onClick={institutionClicked}
    >
      <div className='flex h-32 justify-center p-4 text-center'>
        <img
          src={logoSrc && logoSrc.length > 0 ? logoSrc : logoPlaceholder}
          alt={altText}
          className={isActive ? 'object-contain' : 'object-contain opacity-30'}
        />
      </div>
      <div className='px-4 py-2 shadow-top'>
        <h2 className='mr-2 overflow-hidden text-ellipsis text-left text-xs font-bold uppercase'>
          {name}
        </h2>
        <div className='flex items-center justify-between'>
          <h2 className='mr-2 text-left text-xs font-bold uppercase text-blue-action'>
            {codeName}
          </h2>
          <Badge
            variant={isActive ? 'variant.primary' : 'variant.strong'}
            text={`${id}`}
          />
        </div>
      </div>
    </button>
  );
};

export { InstitutionCard };
