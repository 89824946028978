import { parseDate } from '@internationalized/date';
import { PickerSize } from './types';

function getMinMaxDates({
  minValue,
  maxValue
}: {
  minValue?: string;
  maxValue?: string;
}) {
  const minDate = minValue ? parseDate(minValue) : undefined;
  const maxDate = maxValue ? parseDate(maxValue) : undefined;

  if (minDate && maxDate && minDate.compare(maxDate) > 0) {
    throw new Error('The minimum date cannot be later than the maximum date');
  }

  return { minDate, maxDate };
}

const baseGroupClasses = 'group flex input-field items-center relative';

const disabledGroupClasses = 'text-disabled bg-disabled';

const baseInputClasses = 'flex';

const sizeClasses = {
  'size.md': 'input-field-md',
  'size.lg': 'input-field-lg'
} satisfies Record<PickerSize, string>;

export {
  baseGroupClasses,
  baseInputClasses,
  disabledGroupClasses,
  getMinMaxDates,
  sizeClasses
};
