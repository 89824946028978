import React from 'react';
import { ButtonlikeIconVariant, ButtonlikeSize } from '../types';
import { useIconButtonClasses } from '../use-icon-button-classes';
import { NavLink, NavLinkProps } from './nav-link';

interface IconLinkProps
  extends Omit<NavLinkProps, 'className' | 'size' | 'usage'> {
  variant?: ButtonlikeIconVariant;
  size?: ButtonlikeSize;
}

const IconLink = React.forwardRef<HTMLAnchorElement, IconLinkProps>(
  function IconLinkWithRef(
    { size = 'size.md', variant = 'variant.base', ...delegated },
    ref
  ) {
    return (
      <NavLink
        {...delegated}
        className={useIconButtonClasses({ size, variant })}
        ref={ref}
      />
    );
  }
);

export type { IconLinkProps };
export { IconLink };
