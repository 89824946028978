import { classnames } from '@library/utils';
import { ButtonlikeSize } from './types';

const sizeClasses = {
  // must override the padding present in btn-* sizeClasses
  'size.sm': 'btn-sm !px-0 !py-0',
  'size.md': 'btn-md !px-0 !py-0',
  'size.lg': 'btn-lg !px-0 !py-0'
} satisfies Record<ButtonlikeSize, string>;

interface LinkClassesProps {
  size: ButtonlikeSize;
}

const useLinkClasses = ({ size }: LinkClassesProps) =>
  classnames(
    sizeClasses[size],
    'btn text-link-neutral hover:text-link-hover active:text-link-pressed'
  );

export { useLinkClasses };
