import { AccessorFn, ColumnDef } from '@tanstack/react-table';
import { translate as t } from '@/i18n';

type UnprocessedColumnDef<TableData> = ColumnDef<TableData> & {
  accessorKey: string;
};

type ProcessedColumnDef<TableData> = ColumnDef<TableData> & {
  accessorFn: AccessorFn<TableData, unknown>;
};

function checkDef<TableData>(
  def: UnprocessedColumnDef<TableData> | ProcessedColumnDef<TableData>
) {
  if (def.sortingFn || def.cell) {
    throw new Error(
      'Custom sorting/cell formatting is not permitted for non-custom columns'
    );
  }

  if (def.filterFn && def.filterFn !== 'multiFilterFn') {
    throw new Error(
      'Only multiFilterFn is supported - add UX support for other filter functions if needed'
    );
  }
}

function EmptyCell() {
  return <>-</>;
}

/*
 * Used to define a simple text column that needs no processing, hence the
 * omission of custom sort/formatting functions. (Header/footer formatting
 * is still allowed, as it is unrelated to the display of the actual data.)
 */
function text<TableData>(def: UnprocessedColumnDef<TableData>) {
  checkDef(def);

  def.cell = ({ getValue }) => {
    const str = getValue() as string;

    if (!str) {
      return <EmptyCell />;
    }

    return <>{str}</>;
  };

  return def;
}

/*
 * Used for columns where we expect the user will only care about the formatted
 * value for all operations. For example, if we have an enum called "ACTIVE"
 * but display it in the UI as "Running", the user will expect results with
 * "ACTIVE" to be returned if they filter on "Running", and if they sort the
 * column, "Running" should appear where the Rs appear, not where the As appear.
 *
 * To use, an accessor function (accessorFn) must be passed in, along with the
 * transformation needed to convert the raw value to the formatted value. The
 * table will then treat the formatted value as the source of truth without
 * needing to pass in any other custom functions.
 */
function enumeration<TableData>(def: ProcessedColumnDef<TableData>) {
  checkDef(def);

  return def;
}

function date<TableData>(def: UnprocessedColumnDef<TableData>) {
  checkDef(def);

  def.cell = ({ getValue }) => {
    const dateStr = getValue() as string;

    if (!dateStr) {
      return <EmptyCell />;
    }

    return (
      <span className='whitespace-nowrap'>
        {t('library.dateMedium', { value: dateStr, ns: 'global' })}
      </span>
    );
  };

  return def;
}

function number<TableData>(def: UnprocessedColumnDef<TableData>) {
  checkDef(def);

  def.cell = ({ getValue }) => {
    const num = getValue() as number;

    if (!num && num !== 0) {
      return <EmptyCell />;
    }

    return <>{t('library.numberDefault', { value: num, ns: 'global' })}</>;
  };

  return def;
}

function currency<TableData>(
  def: UnprocessedColumnDef<TableData>,
  { currency: currencyCode }: { currency: string }
) {
  checkDef(def);

  def.cell = ({ getValue }) => {
    const num = getValue() as number;

    if (!num && num !== 0) {
      return <EmptyCell />;
    }

    return <>{currencyCode}</>;
  };

  return def;
}

// Use this if none of the other define functions above suit your purposes
function custom<TableData>(def: ColumnDef<TableData>) {
  return def;
}

const tableColumnDefs = {
  text,
  enumeration,
  date,
  number,
  currency,
  custom
};

export { tableColumnDefs };
