import { classnames } from '@library/utils';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { AriaButtonProps, useButton } from '@react-aria/button';
import React, { useRef } from 'react';
import { sizeClasses } from './helpers';
import { PickerSize } from './types';

const buttonFocusClasses =
  'group-focus-within/button:ring-enabled/25 group-focus-within/button:outline-0 group-focus-within/button:ring-4';

/*
 * Ideally button props are passed in via a spread, but our popover also
 * automatically passes in props via a spread to the trigger element. To
 * keep them distinct, we put the the props from React Aria into an object.
 */
interface DateButtonProps extends AriaButtonProps {
  isCalendarOpen: boolean;
  size: PickerSize;
  disabled: boolean;
  buttonProps: AriaButtonProps<'button'>;
}

const DateButton = React.forwardRef<HTMLButtonElement, DateButtonProps>(
  function DateButtonWithRef(
    { isCalendarOpen, size, disabled, buttonProps, ...delegated },
    ref
  ) {
    // useButton doesn't need the actual ref; it just needs any ref...
    const emptyRef = useRef(null);

    const { buttonProps: buttonAriaProps, isPressed } = useButton(
      buttonProps,
      emptyRef
    );

    /*
     * Not entirely sure what is going on here, but the onMouseDown function
     * that useButton provides appears to not play well with our popover and
     * will cause tests to fail even if it works in Ladle. Deleting this fixes
     * the tests and does not appear to remove any noticable functionality.
     */
    delete buttonAriaProps.onMouseDown;

    return (
      <button
        {...buttonAriaProps}
        {...delegated}
        disabled={disabled}
        ref={ref}
        className={classnames(
          'group/button flex flex-1 justify-end outline-none',
          sizeClasses[size]
        )}
      >
        <div className={classnames(buttonFocusClasses, 'rounded-full')}>
          <CalendarIcon
            className={classnames(
              'h-5 w-5 stroke-btn-neutral-alt stroke-2 group-focus-within:stroke-active group-disabled/button:stroke-disabled group-aria-invalid:stroke-error',
              isPressed || isCalendarOpen ? 'stroke-active' : ''
            )}
          />
        </div>
      </button>
    );
  }
);

export { DateButton };
