import { PublisherLocaleCode } from '@library/utils';
import {
  RedeemingMerchantAnnotationProductCategoryAnnotation,
  RedeemingMerchantAnnotationPurchaseChannelAnnotation
} from '..';
import { createDefinition } from '../../utils';

type RedeemingMerchantAnnotation =
  | RedeemingMerchantAnnotationProductCategoryAnnotation
  | RedeemingMerchantAnnotationPurchaseChannelAnnotation;

interface RedeemingMerchantAnnotationDefinition {
  readonly id: RedeemingMerchantAnnotation;
  readonly label: Record<PublisherLocaleCode, string>;
}

const {
  getById: redeemingMerchantAnnotationDef,
  getAll: redeemingMerchantAnnotationAllDefs
} = createDefinition<
  RedeemingMerchantAnnotation,
  RedeemingMerchantAnnotationDefinition
>({
  definitions: {
    Membership: {
      label: {
        'en-US': 'Membership',
        'en-GB': 'Membership'
      }
    },
    Subscription: {
      label: {
        'en-US': 'Subscription',
        'en-GB': 'Subscription'
      }
    },
    Fuel: {
      label: {
        'en-US': 'Fuel',
        'en-GB': 'Fuel'
      }
    },
    InStore: {
      label: {
        'en-US': 'In-Store',
        'en-GB': 'In-Store'
      }
    },
    Online: {
      label: {
        'en-US': 'Online',
        'en-GB': 'Online'
      }
    },
    PayAtPump: {
      label: {
        'en-US': 'Pay at Pump',
        'en-GB': 'Pay at Pump'
      }
    },
    Convenience: {
      label: {
        'en-US': 'Convenience',
        'en-GB': 'Convenience'
      }
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as RedeemingMerchantAnnotation,
      label: {
        'en-US': id,
        'en-GB': id
      }
    };
  }
});

export { redeemingMerchantAnnotationAllDefs, redeemingMerchantAnnotationDef };
export type { RedeemingMerchantAnnotationDefinition };
