import { classnames } from '@library/utils';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import * as RadixLabel from '@radix-ui/react-label';
import * as RadixSwitch from '@radix-ui/react-switch';

const baseSwitchClasses =
  'group peer bg-strong h-5 w-9 rounded-full text-inverse relative flex-shrink-0';

const checkedSwitchClasses = 'radix-state-checked:bg-enabled';

const disabledSwitchClasses =
  'radix-disabled:bg-strong/40 radix-state-checked:radix-disabled:bg-strong/40';

const focusSwitchClasses =
  'focus-visible:ring-4 focus-visible:ring-enabled/25 focus-visible:outline-0';

const switchClasses = classnames(
  baseSwitchClasses,
  checkedSwitchClasses,
  disabledSwitchClasses,
  focusSwitchClasses
);

const indicatorClasses =
  'absolute duration-100 left-0.5 top-0.5 h-4 w-4 rounded-full bg-content group-radix-disabled:bg-strong radix-state-checked:translate-x-full';

const iconClasses =
  'absolute top-0 bottom-0 m-auto h-3 w-3 stroke-currentColor stroke-2';

const labelClasses =
  'ml-2 cursor-pointer peer-radix-disabled:text-disabled peer-radix-disabled:cursor-default';

interface SwitchProps {
  children?: React.ReactNode;
  disabled?: boolean;
  checked: boolean;
  setChecked: (value: boolean) => void;
  name: string;
}

function Switch({
  children,
  disabled = false,
  checked,
  setChecked,
  name
}: SwitchProps) {
  return (
    <div className='flex items-center'>
      <RadixSwitch.Root
        id={name}
        name={name}
        disabled={disabled}
        checked={checked}
        onCheckedChange={setChecked}
        className={classnames(switchClasses)}
      >
        <XMarkIcon className={classnames(iconClasses, 'right-1')} />
        <CheckIcon className={classnames(iconClasses, 'left-1')} />
        <RadixSwitch.Thumb className={indicatorClasses} />
      </RadixSwitch.Root>
      <RadixLabel.Root htmlFor={name} className={labelClasses}>
        {children}
      </RadixLabel.Root>
    </div>
  );
}

export { Switch };
export type { SwitchProps };
