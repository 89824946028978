import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { useToasts } from '@library/components';

import { useApiQuery } from '@hooks/use-api-query';
import { useApiMutation } from '@hooks/use-api-mutation';

import { Customer, EnrollmentOptInStatus } from '@models/customer';

import { customerService } from '@services/customer';

import { useInstitution } from './institution';

interface CustomerProviderProps {
  children: React.ReactNode;
  isCustomerInVertica: boolean;
  sourceCustomerId: string;
  isEditCustomerModalOpen: boolean;
  isRefetchingOffers: boolean;
  setIsEditCustomerModalOpen: Dispatch<SetStateAction<boolean>>;
  refetchOffers: any; //TODO: updte to propper type
}

type CustomerContextType = {
  customer: Customer;
  isLoading: boolean;
  isError: boolean;
  optedInValue: boolean;
  earnedAmount: number;
  isSavingProfile: boolean;
  isRefetchingOffers: boolean;
  refetchOffers: any;
  setIsSavingProfile: Dispatch<SetStateAction<boolean>>;
  setOptedInValue: Dispatch<SetStateAction<boolean>>;
  setEarnedAmount: Dispatch<SetStateAction<number>>;
  saveCustomerProfile: () => void;
  setIsEditCustomerModalOpen: Dispatch<SetStateAction<boolean>>;
};

const CustomerContext = createContext<CustomerContextType>(
  {} as CustomerContextType
);

const useCustomer = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw new Error('useCustomer can only be used within an CustomerContext');
  }
  return context;
};

const CustomerProvider = ({
  children,
  isCustomerInVertica,
  sourceCustomerId,
  isEditCustomerModalOpen,
  isRefetchingOffers,
  setIsEditCustomerModalOpen,
  refetchOffers
}: CustomerProviderProps) => {
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [optedInValue, setOptedInValue] = useState(false);
  const [earnedAmount, setEarnedAmount] = useState<number>(0);

  const queryClient = useQueryClient();
  const updateMutation = useApiMutation(customerService.update());
  const { sendToast } = useToasts();
  const [t] = useTranslation();

  const { institutionInfo, currency } = useInstitution();

  const {
    data: customerProfileResponse,
    isLoading: isLoadingCustomer,
    isError: isErrorCustomer
  } = useApiQuery(
    customerService.getById({
      params: { sourceCustomerId },
      enabled:
        isCustomerInVertica &&
        isEditCustomerModalOpen &&
        sourceCustomerId.length > 0
    })
  );

  const country = useMemo(() => institutionInfo.country, [institutionInfo]);
  const currencyCode = useMemo(() => currency.code, [currency]);

  const customer = useMemo(
    () => customerProfileResponse?.customer || ({} as Customer),
    [customerProfileResponse]
  );

  const saveCustomerProfile = () => {
    setIsSavingProfile(true);

    const optIn: EnrollmentOptInStatus = optedInValue ? 'ACTIVE' : 'INACTIVE';

    const { sourceAccountId, sourceOrganizationId, sourcePortfolioId } =
      customer;

    const payload = {
      sourceCustomerId,
      country,
      enrollmentOptInStatus: optIn,
      lifetimeEarnedAmount: earnedAmount,
      sourceAccountId,
      sourceOrganizationId,
      sourcePortfolioId,
      operatingCurrencyCode: currencyCode
    };

    updateMutation.mutate(
      {
        payload
      },
      {
        async onSuccess() {
          // // invalidate cache to request customer list again after redirection
          await queryClient.invalidateQueries({
            queryKey: customerService.invalidationKey
          });

          setIsEditCustomerModalOpen(false);
          setIsSavingProfile(false);

          sendToast({
            variant: 'variant.success',
            content: `${t('label.customerUpdated')}`
          });
        },
        onError(error) {
          setIsSavingProfile(false);
          sendToast({
            variant: 'variant.error',
            content:
              error?.message || `${t('institution.couldNotUpdateCustomer')}`
          });
        }
      }
    );
  };

  return (
    <CustomerContext.Provider
      value={{
        customer,
        isLoading: isLoadingCustomer,
        isError: isErrorCustomer,
        optedInValue,
        earnedAmount,
        isSavingProfile,
        isRefetchingOffers,
        refetchOffers,
        setIsEditCustomerModalOpen,
        setIsSavingProfile,
        setEarnedAmount,
        setOptedInValue,
        saveCustomerProfile
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerProvider, useCustomer };
