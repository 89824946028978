import {
  ServiceOptions,
  wrapMutationFunction,
  wrapQueryFunction
} from '../service';

import { create } from './create';
import { getById } from './get-by-id';
import { update } from './update';
import { del } from './delete';
import { get } from './get';
import { getTemplates } from './get-templates';

const options: ServiceOptions = {
  name: 'OFFER',
  cacheTime: 'cache.veryLong'
};

const offerService = {
  invalidationKey: [{ name: options.name }],
  get: wrapQueryFunction(options, get),
  getTemplates: wrapQueryFunction(options, getTemplates),
  getById: wrapQueryFunction(options, getById),
  create: wrapMutationFunction(options, create),
  update: wrapMutationFunction(options, update),
  delete: wrapMutationFunction(options, del)
};

export { offerService };
