import { createCalendar, DateValue } from '@internationalized/date';
import { CalendarProps, useCalendar } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { useCalendarState } from '@react-stately/calendar';
import { CalendarGrid } from '../calendar-grid';
import { CalendarHeader } from '../calendar-header';

function SingleCalendar(props: CalendarProps<DateValue>) {
  const { locale } = useLocale();

  const calendarState = useCalendarState({
    ...props,
    visibleDuration: { months: 1 },
    locale,
    createCalendar
  });

  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    calendarState
  );

  return (
    <div {...calendarProps} className='w-64'>
      <CalendarHeader
        monthsToShow={1}
        prevButtonProps={prevButtonProps}
        nextButtonProps={nextButtonProps}
        calendarState={calendarState}
      />
      <div className='flex'>
        <CalendarGrid calendarState={calendarState} />
      </div>
    </div>
  );
}

export type { CalendarProps } from '@react-aria/calendar';
export { SingleCalendar };
