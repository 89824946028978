import i18next from 'i18next';

import globalGB from '@i18n/en-GB/global.json';
import globalUS from '@i18n/en-US/global.json';

const i18Instance = i18next;

// TODO: exported for testing purposes
const i18InstanceInit = i18Instance.init({
  interpolation: { escapeValue: false },
  lng: 'en-US', // TODO: switch during build or add a a component for manually switching (To be discussed)
  fallbackLng: 'en-US',
  defaultNS: 'global',
  resources: {
    'en-US': {
      global: globalUS // TODO: more properties will be added to keep the translations separated by categories
    },
    'en-GB': {
      global: globalGB
    }
  }
});

// To be used inside util files and not component files
const translate = (key: string, options: object = {}): string =>
  i18Instance.t(key, options);

export { i18InstanceInit, i18Instance, translate };
