import { classnames } from '@library/utils';
import * as RadixPortal from '@radix-ui/react-portal';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import { useListBox, useOption } from '@react-aria/listbox';
import { AriaPopoverProps, usePopover } from '@react-aria/overlays';
import { useObjectRef } from '@react-aria/utils';
import type { ComboBoxState } from '@react-stately/combobox';
import type { ListState } from '@react-stately/list';
import { SelectState } from '@react-stately/select';
import { Key, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContainer } from '../modal';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  state: ListState<unknown>;
}

const ListBox = forwardRef<HTMLUListElement, ListBoxProps>(function ListBox(
  props,
  forwardedRef
) {
  const ref = useObjectRef(forwardedRef);
  const { state } = props;

  const { listBoxProps } = useListBox(props, state, ref);

  return (
    <ul
      {...listBoxProps}
      ref={ref}
      className='max-h-72 w-full overflow-auto outline-none'
    >
      {[...state.collection].map((item) => (
        <div key={item.key}>
          <Option id={item.key} listState={state}>
            {item.rendered}
          </Option>
        </div>
      ))}
    </ul>
  );
});

interface OptionProps {
  id: Key;
  children: React.ReactNode;
  listState: ListState<unknown>;
}

const Option = ({ id, children, listState }: OptionProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused } = useOption(
    {
      // @ts-ignore
      key: id
    },
    listState,
    ref
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={classnames(
        'flex cursor-pointer items-center p-3 text-sm outline-none first-of-type:rounded-t last-of-type:rounded-b',
        isFocused ? 'bg-hover' : null,
        isSelected ? 'font-bold' : null
      )}
    >
      {children}
    </li>
  );
};

interface ListBoxPopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  state: ComboBoxState<unknown> | SelectState<unknown>;
  popoverRef?: React.RefObject<HTMLDivElement>;
  listBoxRef?: React.RefObject<HTMLUListElement>;
  listBoxProps: AriaListBoxOptions<unknown>;
  loading?: boolean;
  noDataMessage?: React.ReactNode;
  width?: number;
}

const ListBoxPopover = ({
  state,
  placement,
  triggerRef,
  popoverRef: popoverRefProp,
  listBoxRef,
  listBoxProps,
  loading,
  noDataMessage,
  width
}: ListBoxPopoverProps) => {
  const { t } = useTranslation('global');

  const container = useModalContainer();

  const popoverRef = useObjectRef(popoverRefProp);

  const { popoverProps: ariaPopoverProps } = usePopover(
    {
      isNonModal: true,
      placement,
      popoverRef,
      triggerRef
    },
    state
  );
  const { style, ...popoverProps } = ariaPopoverProps;

  const showNoDataMessage = 'inputValue' in state ? !!state.inputValue : false;

  return (
    <RadixPortal.Root container={container || undefined}>
      <div
        {...popoverProps}
        ref={popoverRef}
        className='mt-0.5 rounded-md bg-content shadow-md'
        style={{ ...style, width }}
      >
        <ListBox {...listBoxProps} state={state} ref={listBoxRef} />
        {state.collection.size === 0 && showNoDataMessage && !loading ? (
          <div className='flex items-center p-3'>
            {noDataMessage || t('library.noResultsFound')}
          </div>
        ) : null}
      </div>
    </RadixPortal.Root>
  );
};

export { ListBoxPopover };
