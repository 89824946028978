import { ReactNode } from 'react';

export interface InfoContainerProps {
  title?: string;
  children: ReactNode;
}

const InfoContainer = ({ title, children }: InfoContainerProps) => (
  <div className='container mb-4 overflow-hidden rounded-3xl bg-gray-lightGray p-8'>
    {title && title.length > 0 && (
      <h2 className='mb-6 text-lg font-bold'>{title}</h2>
    )}
    {children}
  </div>
);

export { InfoContainer };
