import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        // If user is not authenticated, redirect to the login page
        navigate('/login');
      }
    };

    checkAuthStatus();
  }, [navigate]);

  return <>{children}</>;
};

export { RequireAuth };
