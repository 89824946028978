interface HorizontalLoaderProps {
  dataTestId?: string;
  isActive: boolean;
}

const HorizontalLoader = ({ dataTestId, isActive }: HorizontalLoaderProps) => {
  if (!isActive) {
    return null;
  }

  return (
    <div
      className='absolute bottom-0 h-0.5 animate-horizontalLoader bg-active'
      data-testid={dataTestId}
    />
  );
};

export { HorizontalLoader };
export type { HorizontalLoaderProps };
