import { classnames } from '@library/utils';
import React from 'react';
import {
  BasicLink,
  BasicLinkProps,
  LinkButton,
  LinkButtonProps
} from '../buttonlike';

const visibleTableRowActionClasses =
  'absolute opacity-0 flex transition-opacity duration-300 group-hover:opacity-100';

const hiddenTableRowActionClasses = 'h-0 opacity-0 flex';

const baseTableRowActionButtonlikeClasses = 'mr-2';

/*
 * The double elements are needed due to them being absolutely positioned -
 * they need a non-absolutely-positioned helper element to make sure they have
 * enough width; otherwise they will run into the edge of the cell.
 */
function TableRowActions({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div
        className={classnames(hiddenTableRowActionClasses)}
        aria-hidden='true'
      >
        {children}
      </div>
      <div className={classnames(visibleTableRowActionClasses)}>{children}</div>
    </>
  );
}

const TableRowButton = React.forwardRef<HTMLDivElement, LinkButtonProps>(
  function TableRowButtonWithRef({ children, ...delegated }, ref) {
    return (
      <div
        ref={ref}
        className={classnames(baseTableRowActionButtonlikeClasses)}
      >
        <LinkButton {...delegated} size='size.sm'>
          {children}
        </LinkButton>
      </div>
    );
  }
);

const TableRowLink = React.forwardRef<HTMLDivElement, BasicLinkProps>(
  function TableRowLinkWithRef({ children, ...delegated }, ref) {
    return (
      <div
        ref={ref}
        className={classnames(baseTableRowActionButtonlikeClasses)}
      >
        <BasicLink {...delegated} size='size.sm'>
          {children}
        </BasicLink>
      </div>
    );
  }
);

export { TableRowActions, TableRowButton, TableRowLink };
