import { InstitutionLogoType, InstitutionLogo } from '@models/institution';

const getLogoUrl = ({
  logos,
  type,
  institutionId
}: {
  type: InstitutionLogoType;
  logos: InstitutionLogo[];
  institutionId: number | string;
}): string | undefined => {
  const { PM_ASSETS_URL: assetsUrl } = process.env;
  const targetLogo = logos.find((logo) => logo.type === type);
  const fileName = targetLogo ? targetLogo.fileName : '';
  return fileName.length > 0 ? `${assetsUrl}/${institutionId}/${fileName}` : '';
};

export { getLogoUrl };
