import { classnames } from '@library/utils';
import {
  ButtonlikeSize,
  ButtonlikeUsage,
  ButtonlikeVariant,
  ButtonlikeWidth
} from './types';

const sizeClasses = {
  'size.sm': 'btn-sm',
  'size.md': 'btn-md',
  'size.lg': 'btn-lg'
} satisfies Record<ButtonlikeSize, string>;

const usageClasses = {
  'usage.primary': [
    'btn border text-inverse',
    'disabled:border-btn-neutral-disabled disabled:bg-btn-neutral-disabled',
    'aria-disabled:border-btn-neutral-disabled aria-disabled:bg-btn-neutral-disabled'
  ],
  'usage.secondary':
    'btn border bg-content disabled:border-strong aria-disabled:border-strong',
  'usage.tertiary': 'btn border bg-transparent border-transparent'
} satisfies Record<ButtonlikeUsage, string | string[]>;

const variantClasses = {
  'usage.primary': {
    'variant.base': [
      'border-btn-neutral-base bg-btn-neutral-base',
      'hover:border-btn-hover-base hover:bg-btn-hover-base',
      'active:border-btn-pressed-base active:bg-btn-pressed-base'
    ],
    'variant.alt': [
      'border-btn-neutral-alt bg-btn-neutral-alt',
      'hover:border-btn-hover-alt hover:bg-btn-hover-alt',
      'active:border-btn-pressed-alt active:bg-btn-pressed-alt'
    ],
    'variant.activate': [
      'border-btn-neutral-activate bg-btn-neutral-activate',
      'hover:border-btn-hover-activate hover:bg-btn-hover-activate',
      'active:border-btn-pressed-activate active:bg-btn-pressed-activate'
    ],
    'variant.urgent': [
      'border-btn-neutral-urgent bg-btn-neutral-urgent',
      'hover:border-btn-hover-urgent hover:bg-btn-hover-urgent',
      'active:border-btn-pressed-urgent active:bg-btn-pressed-urgent'
    ]
  },
  'usage.secondary': {
    'variant.base': [
      'border-btn-neutral-base text-btn-neutral-base',
      'hover:border-btn-hover-base hover:text-btn-hover-base',
      'active:border-btn-pressed-base active:text-btn-pressed-base'
    ],
    'variant.alt': [
      'border-btn-neutral-alt text-btn-neutral-alt',
      'hover:border-btn-hover-alt hover:text-btn-hover-alt',
      'active:border-btn-pressed-alt active:text-btn-pressed-alt'
    ],
    'variant.activate': [
      'border-btn-neutral-activate text-btn-neutral-activate',
      'hover:border-btn-hover-activate hover:text-btn-hover-activate',
      'active:border-btn-pressed-activate active:text-btn-pressed-activate'
    ],
    'variant.urgent': [
      'border-btn-neutral-urgent text-btn-neutral-urgent',
      'hover:border-btn-hover-urgent hover:text-btn-hover-urgent',
      'active:border-btn-pressed-urgent active:text-btn-pressed-urgent'
    ]
  },
  'usage.tertiary': {
    'variant.base': [
      'text-btn-neutral-base hover:text-btn-hover-base active:text-btn-pressed-base'
    ],
    'variant.alt': [
      'text-btn-neutral-alt hover:text-btn-hover-alt active:text-btn-pressed-alt'
    ],
    'variant.activate': [
      'text-btn-neutral-activate hover:text-btn-hover-activate active:text-btn-pressed-activate'
    ],
    'variant.urgent': [
      'text-btn-neutral-urgent hover:text-btn-hover-urgent active:text-btn-pressed-urgent'
    ]
  }
} satisfies Record<ButtonlikeUsage, Record<ButtonlikeVariant, string[]>>;

const widthClasses = {
  'width.unset': '',
  'width.full': 'w-full block text-center'
} satisfies Record<ButtonlikeWidth, string>;

interface ButtonOptions {
  size: ButtonlikeSize;
  usage: ButtonlikeUsage;
  variant: ButtonlikeVariant;
  width: ButtonlikeWidth;
}

const useButtonClasses = ({ size, usage, variant, width }: ButtonOptions) =>
  classnames(
    sizeClasses[size],
    usageClasses[usage],
    variantClasses[usage][variant],
    widthClasses[width]
  );

export { useButtonClasses };
