import { useMemo } from 'react';

import { OfferHeadline } from '../OfferHeadline';
import { OfferConditions } from '../OfferConditions/OfferConditions';
import { OfferDetails } from '../OfferDetails';

import { Offer } from '@models/campaign';

import '../offer.css';

export interface AddOfferComponentProps extends Offer {
  createOfferResponse?: any[];
}

const AddOfferRow = ({
  campaignId,
  logo,
  merchantName,
  rewardDetails,
  startDate,
  endDate,
  rewardAmount,
  rewardPercent,
  rewardType,
  createOfferResponse
}: AddOfferComponentProps) => {
  const headlineProps = useMemo(
    () => ({
      logo,
      merchantName,
      rewardDetails,
      offerId: campaignId,
      createOfferResponse
    }),
    [logo, merchantName, rewardDetails, campaignId, createOfferResponse]
  );

  const conditionsProps = useMemo(
    () => ({ startDate, endDate }),
    [startDate, endDate]
  );

  const detailsProps = useMemo(
    () => ({ rewardAmount, rewardPercent, rewardType }),
    [rewardAmount, rewardPercent, rewardType]
  );

  return (
    <div className='my-2 flex flex-row justify-around py-2'>
      <OfferHeadline {...headlineProps} />
      <OfferConditions {...conditionsProps} />
      <OfferDetails {...detailsProps} />
    </div>
  );
};

export { AddOfferRow };
