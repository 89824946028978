import { z } from 'zod';
import {
  merchantProductCategorySchema,
  merchantPurchaseChannelSchema,
  merchantStoreLocationSubTypeSchema
} from '../merchant';

// Same as MERCHANT_PRODUCT_CATEGORIES
const REDEEMING_MERCHANT_ANNOTATION_PRODUCT_CATEGORY_ANNOTATIONS = [
  'Fuel',
  'Membership',
  'Subscription'
] as const;
const redeemingMerchantAnnotationProductCategoryAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_PRODUCT_CATEGORY_ANNOTATIONS
);
type RedeemingMerchantAnnotationProductCategoryAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_PRODUCT_CATEGORY_ANNOTATIONS)[number];

/*
 * Subset of MERCHANT_STORE_LOCATION_SUB_TYPES (no Grocery or Restaurant)
 * and of MERCHANT_PURCHASE_CHANNELS (no Other).
 */
const REDEEMING_MERCHANT_ANNOTATION_PURCHASE_CHANNEL_ANNOTATIONS = [
  'Convenience',
  'InStore',
  'Online',
  'PayAtPump'
] as const;
const redeemingMerchantAnnotationPurchaseChannelAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_PURCHASE_CHANNEL_ANNOTATIONS
);
type RedeemingMerchantAnnotationPurchaseChannelAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_PURCHASE_CHANNEL_ANNOTATIONS)[number];

const REDEEMING_MERCHANT_ANNOTATION_ANNOTATIONS = [
  ...REDEEMING_MERCHANT_ANNOTATION_PRODUCT_CATEGORY_ANNOTATIONS,
  ...REDEEMING_MERCHANT_ANNOTATION_PURCHASE_CHANNEL_ANNOTATIONS
] as const;
const redeemingMerchantAnnotationAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_ANNOTATIONS
);
type RedeemingMerchantAnnotationAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_ANNOTATIONS)[number];

/*
 * Yes, this endpoint returns hardcoded English strings that we then write
 * to an image. This is supposedly temporary, so we can supposedly get away
 * with it since we don't support non-English.
 */
// https://github.com/cardlytics/ad-assets-3/blob/3b0de18e462f280b444fe372fd70dd0245ab068d/AdAssets-DB/DB/Data/dbo.RedeemingMerchantRule_Data.sql
const REDEEMING_MERCHANT_ANNOTATION_OPS_PURCHASE_CHANNEL_ANNOTATIONS = [
  'In-Store Only',
  'Online Only',
  'Pay at Pump Only'
] as const;
const redeemingMerchantAnnotationOpsPurchaseChannelAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_OPS_PURCHASE_CHANNEL_ANNOTATIONS
);
type RedeemingMerchantAnnotationOpsPurchaseChannelAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_OPS_PURCHASE_CHANNEL_ANNOTATIONS)[number];

const REDEEMING_MERCHANT_ANNOTATION_OPS_PRODUCT_CATEGORY_ANNOTATIONS = [
  'Fuel Only',
  'Membership Only',
  'Subscription Only'
] as const;
const redeemingMerchantAnnotationOpsProductCategoryAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_OPS_PRODUCT_CATEGORY_ANNOTATIONS
);
type RedeemingMerchantAnnotationOpsProductCategoryAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_OPS_PRODUCT_CATEGORY_ANNOTATIONS)[number];

/*
 * This and REDEEMING_MERCHANT_ANNOTATION_ANNOTATIONS are UI-only constructs. The
 * backend always distinguishes between product category and purchase channel
 * annotations, but the UI presents them as a unified list. Furthermore, in the
 * case of OPS annotations, there are additional options that are not included in
 * the backend response that we must nonetheless make available.
 */
const REDEEMING_MERCHANT_ANNOTATION_OPS_ANNOTATIONS = [
  ...REDEEMING_MERCHANT_ANNOTATION_OPS_PRODUCT_CATEGORY_ANNOTATIONS,
  ...REDEEMING_MERCHANT_ANNOTATION_OPS_PURCHASE_CHANNEL_ANNOTATIONS,
  'Up to 5% back',
  'Up to 10% back',
  'Up to 15% back',
  'Up to 20% back',
  'None'
] as const;
const redeemingMerchantAnnotationOpsAnnotationSchema = z.enum(
  REDEEMING_MERCHANT_ANNOTATION_OPS_ANNOTATIONS
);
type RedeemingMerchantAnnotationOpsAnnotation =
  (typeof REDEEMING_MERCHANT_ANNOTATION_OPS_ANNOTATIONS)[number];

/*
 * Using the redeeming merchant version of the below (vs. the base merchant) is
 * an option too, but the redeeming merchant version also includes blank strings
 * as available values, so the merchant one wins out.
 */
const redeemingMerchantAnnotationMerchants = z.object({
  productCategories: z.array(merchantProductCategorySchema),
  purchaseChannels: z.array(merchantPurchaseChannelSchema),
  purchaseSubChannels: z.array(merchantStoreLocationSubTypeSchema)
});

const redeemingMerchantAnnotationsPostSchema = z.object({
  availableRedeemingMerchants: redeemingMerchantAnnotationMerchants,
  selectedRedeemingMerchants: redeemingMerchantAnnotationMerchants
});

type RedeemingMerchantAnnotationsPost = z.infer<
  typeof redeemingMerchantAnnotationsPostSchema
>;

const redeemingMerchantAnnotationsSchema = z.object({
  productCategoryAnnotations: z
    .array(redeemingMerchantAnnotationProductCategoryAnnotationSchema)
    .optional(),
  purchaseChannelAnnotations: z
    .array(redeemingMerchantAnnotationPurchaseChannelAnnotationSchema)
    .optional(),
  opsProductCategoryAnnotations: z
    .array(redeemingMerchantAnnotationOpsProductCategoryAnnotationSchema)
    .optional(),
  opsPurchaseChannelAnnotations: z
    .array(redeemingMerchantAnnotationOpsPurchaseChannelAnnotationSchema)
    .optional()
});

type RedeemingMerchantAnnotations = z.infer<
  typeof redeemingMerchantAnnotationsSchema
>;

const redeemingMerchantCoreAnnotationsSchema = z.object({
  productCategoryAnnotations: z.array(
    redeemingMerchantAnnotationProductCategoryAnnotationSchema
  ),
  purchaseChannelAnnotations: z.array(
    redeemingMerchantAnnotationPurchaseChannelAnnotationSchema
  )
});

type RedeemingMerchantCoreAnnotations = z.infer<
  typeof redeemingMerchantCoreAnnotationsSchema
>;

export * from './definitions';
export {
  REDEEMING_MERCHANT_ANNOTATION_ANNOTATIONS,
  REDEEMING_MERCHANT_ANNOTATION_OPS_ANNOTATIONS,
  REDEEMING_MERCHANT_ANNOTATION_OPS_PRODUCT_CATEGORY_ANNOTATIONS,
  REDEEMING_MERCHANT_ANNOTATION_OPS_PURCHASE_CHANNEL_ANNOTATIONS,
  REDEEMING_MERCHANT_ANNOTATION_PRODUCT_CATEGORY_ANNOTATIONS,
  REDEEMING_MERCHANT_ANNOTATION_PURCHASE_CHANNEL_ANNOTATIONS,
  redeemingMerchantAnnotationAnnotationSchema,
  redeemingMerchantAnnotationMerchants,
  redeemingMerchantAnnotationOpsAnnotationSchema,
  redeemingMerchantAnnotationOpsProductCategoryAnnotationSchema,
  redeemingMerchantAnnotationOpsPurchaseChannelAnnotationSchema,
  redeemingMerchantAnnotationProductCategoryAnnotationSchema,
  redeemingMerchantAnnotationPurchaseChannelAnnotationSchema,
  redeemingMerchantAnnotationsPostSchema,
  redeemingMerchantAnnotationsSchema,
  redeemingMerchantCoreAnnotationsSchema
};
export type {
  RedeemingMerchantAnnotationAnnotation,
  RedeemingMerchantAnnotationOpsAnnotation,
  RedeemingMerchantAnnotationOpsProductCategoryAnnotation,
  RedeemingMerchantAnnotationOpsPurchaseChannelAnnotation,
  RedeemingMerchantAnnotationProductCategoryAnnotation,
  RedeemingMerchantAnnotationPurchaseChannelAnnotation,
  RedeemingMerchantAnnotations,
  RedeemingMerchantAnnotationsPost,
  RedeemingMerchantCoreAnnotations
};
