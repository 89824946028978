import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';

interface MenuItemsProps {
  children: React.ReactNode;
  className?: string;
  align?: 'center' | 'end' | 'start';
}

const MenuItems = ({
  align = 'start',
  children,
  className
}: MenuItemsProps) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content align={align} className={className}>
      {children}
    </DropdownMenu.Content>
  </DropdownMenu.Portal>
);

interface MenuUncontrolledProps {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
}

interface MenuControlledProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

type MenuProps = Omit<DropdownMenu.DropdownMenuProps, 'onOpenChange' | 'open'> &
  (MenuControlledProps | MenuUncontrolledProps);

const Menu = ({ onOpen, onClose, isOpen, ...delegated }: MenuProps) => (
  <DropdownMenu.Root
    open={isOpen}
    onOpenChange={(newOpenState) => {
      newOpenState ? onOpen?.() : onClose?.();
    }}
    modal={false}
    {...delegated}
  />
);

export {
  Item as MenuItem,
  Trigger as MenuTrigger
} from '@radix-ui/react-dropdown-menu';
export { Menu, MenuItems };
