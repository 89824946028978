import { classnames } from '@library/utils';
import { ColumnDef, FilterFn, RowData, Table } from '@tanstack/react-table';
import { HorizontalLoader } from '../horizontal-loader';
import { TableBody } from './body';
import { TableFooter } from './footer';
import { TableHeader } from './header';
import { TablePageSizeOptions } from './helpers';
import { TablePaginator } from './paginator';

declare module '@tanstack/react-table' {
  /*
   * The below disabling is needed because even though we don't use
   * TData, without it we get an "All declarations of 'TableMeta'
   * must have identical type parameters" error.
   */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface TableMeta<TData extends RowData> {
    baseId: string;
    loading: boolean;
    persistSelectedItemsAcrossPages?: boolean;
    server?: {
      totalRows: number;
    };
  }
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    multiFilterFn: FilterFn<unknown>;
  }
}

interface BaseTableProps<TableData> {
  table: Table<TableData>;
  data: TableData[];
  columns: ColumnDef<TableData>[];
  pageSizeOptions: TablePageSizeOptions;
  borderless?: boolean;
  loading?: boolean;
  isHighlightedRow?: (row: TableData) => boolean;
  noDataMessage?: React.ReactNode;
  'aria-label'?: React.AriaAttributes['aria-label'];
  'aria-invalid'?: React.AriaAttributes['aria-invalid'];
  'aria-describedby'?: React.AriaAttributes['aria-describedby'];
}

function BaseTable<TableData>({
  table,
  columns,
  pageSizeOptions,
  borderless,
  loading,
  isHighlightedRow,
  noDataMessage,
  'aria-label': ariaLabel,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy
}: BaseTableProps<TableData>) {
  const rows = table.getRowModel().rows;

  const footerGroups = table.getFooterGroups();

  let hasFooter = false;

  if (rows.length > 0) {
    // Footer should only show up if there is data
    for (const group of footerGroups) {
      for (const header of group.headers) {
        if (header.column.columnDef.footer) {
          hasFooter = true;
        }
      }
    }
  }

  return (
    <>
      <div className='grid flex-1 grid-rows-[min-content] overflow-auto text-sm'>
        <table
          id={table.options.meta?.baseId}
          className='w-full border-separate border-spacing-0'
          aria-label={ariaLabel}
          aria-invalid={ariaInvalid}
          aria-describedby={ariaDescribedBy}
        >
          <TableBody
            table={table}
            rows={rows}
            numVisibleColumns={columns.length}
            borderless={borderless}
            loading={loading}
            isHighlightedRow={isHighlightedRow}
            noDataMessage={noDataMessage}
          />
          {hasFooter ? (
            <TableFooter footerGroups={footerGroups} borderless={borderless} />
          ) : null}
          <TableHeader table={table} />
        </table>
        <div
          className={classnames(
            'flex-1',
            !borderless && 'border-l border-r border-neutral'
          )}
        />
      </div>
      <div className='relative -top-px'>
        <HorizontalLoader isActive={!!loading} />
      </div>
      <TablePaginator
        table={table}
        pageSizeOptions={pageSizeOptions}
        borderless={borderless}
      />
    </>
  );
}

export { BaseTable };
