import { CampaignType } from '..';
import { t } from 'i18next';
import {
  OPPORTUNITY_PRICING_MODELS_FOR_DYNAMIC_CLO,
  OPPORTUNITY_PRICING_MODELS_FOR_FIXED_CLO,
  OpportunityPricingModel
} from '../../opportunity';
import { createDefinition } from '../../utils';

interface CampaignTypeDefinition {
  readonly id: CampaignType;
  readonly label: string;
  readonly description: string;
  readonly supportedPricingModels: Set<OpportunityPricingModel>;
  readonly maxDuration: number;
  readonly minDuration: number;
}

const { getById: campaignTypeDef, getAll: campaignTypeAllDefs } =
  createDefinition<CampaignType, CampaignTypeDefinition>({
    definitions: {
      FIXED_CLO: {
        label: t('campaignmodel.TYPE_FIXED_CLO_LABEL'),
        description: t('campaignmodel.TYPE_FIXED_CLO_DESCRIPTION'),
        supportedPricingModels: OPPORTUNITY_PRICING_MODELS_FOR_FIXED_CLO,
        maxDuration: Number.POSITIVE_INFINITY,
        minDuration: 1
      },
      DYNAMIC_CLO: {
        label: t('campaignmodel.TYPE_DYNAMIC_CLO_LABEL'),
        description: t('campaignmodel.TYPE_DYNAMIC_CLO_DESCRIPTION'),
        supportedPricingModels: OPPORTUNITY_PRICING_MODELS_FOR_DYNAMIC_CLO,
        maxDuration: 45,
        minDuration: 15
      }
    },
    getUnknownDefinition(id: string) {
      return {
        id: id as CampaignType,
        label: id,
        description: id,
        supportedPricingModels: new Set(),
        maxDuration: Number.POSITIVE_INFINITY,
        minDuration: 1
      };
    }
  });

export { campaignTypeAllDefs, campaignTypeDef };
export type { CampaignTypeDefinition };
