import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { FilterRequestParam } from '@models/table';
import { getFilterString, getPaginationString } from '@utils/rest-api';
import { TablePaginationState } from '@library/components';
import { CustomerMetric } from '@models/customer';

interface GetParams {
  filter: FilterRequestParam[];
  pagination: TablePaginationState;
}

const get: AdapterFunction<CustomerMetric[], GetParams> = (
  { filter, pagination },
  { signal }
) => {
  const parsedFilter =
    getFilterString(filter).length > 0 ? `&${getFilterString(filter)}` : '';

  const parsedPagination =
    getPaginationString(pagination).length > 0
      ? `?${getPaginationString(pagination)}`
      : '';

  const url = `/v1/customers${parsedPagination}${parsedFilter}`;

  return apiTransport.get<CustomerMetric[]>({
    url,
    config: {
      signal
    }
  });
};

export type { GetParams };
export { get };
