const getUpdatedProperties = <T>(updated: Partial<T>, original: T) => {
  const updatedProperties: {
    [x: string]: Partial<T>[Extract<keyof T, string>];
  } = {};

  for (const key in updated) {
    if (original[key] !== undefined && original[key] !== updated[key]) {
      updatedProperties[key] = updated[key];
    }
  }

  return updatedProperties;
};

const setCookie = (cookieName: string) => {
  const cookie = localStorage.getItem(cookieName);
  if (!cookie) {
    localStorage.setItem(cookieName, 'true');
  }
};

export { getUpdatedProperties, setCookie };
