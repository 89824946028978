import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { UsersIcon } from '@heroicons/react/24/solid';

import {
  Badge,
  IconButton,
  ServerSideTable,
  tableColumnDefs,
  Tooltip,
  useToasts
} from '@library/components';
import { CustomerInstructionsModal } from '@/components/Instructions/CustomerInstructionsModal';
import { EditCustomerModal } from '../EditCustomerModal/EditCustomerModal';

import { useApiMutation } from '@hooks/use-api-mutation';

import { Customer } from '@models/customer';

import { customerService } from '@services/customer';

import './CustomersList.css';

interface CustomersListProps {
  isLoading: boolean;
  customers: Customer[];
  totalCustomers: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  onPaginationChange: (arg) => void;
}

const EmptyCustomerList = () => {
  const [t] = useTranslation();

  return (
    <div className='flex items-center justify-center'>
      <UsersIcon className='mr-6 h-40 w-40 text-gray-disabledText' />
      <div>
        <h3 className='text-xl font-bold'>{t('label.noCustomersCreated')}</h3>
        <p>{t('label.addTestCustomers')}</p>
      </div>
    </div>
  );
};

const CustomersList = ({
  customers,
  isLoading,
  totalCustomers,
  pagination,
  onPaginationChange
}: CustomersListProps) => {
  const [t] = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutation = useApiMutation(customerService.delete());

  const queryClient = useQueryClient();
  const { sendToast } = useToasts();

  const columns = [
    tableColumnDefs.custom<Customer>({
      header: `${t('label.sourceCustomerId')}`,
      accessorKey: 'sourceCustomerId',
      enableSorting: false,
      cell({ row }) {
        const isCustomerInVertica = !row.original.customerId;
        return (
          <>
            <div>
              <div className='relative'>{row.original.sourceCustomerId}</div>
              {isCustomerInVertica && (
                <div className='inline-block'>
                  <Badge variant='variant.primary' text='In progress' />
                </div>
              )}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.enrollmentStatus')}`,
      accessorKey: 'enrollmentOptInStatus',
      enableSorting: false,
      cell({ row }) {
        return (
          <>
            <div className='relative'>
              {row.original.enrollmentOptInStatus === 'ACTIVE'
                ? t('label.optedIn')
                : t('label.optedOut')}
            </div>
          </>
        );
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.targetedOffers')}`,
      accessorKey: 'targetedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.targetedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.servedOffers')}`,
      accessorKey: 'servedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.servedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.activatedOffers')}`,
      accessorKey: 'activatedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.activatedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: `${t('label.redeemedOffers')}`,
      accessorKey: 'redeemedOffers',
      enableSorting: false,
      cell({ row }) {
        return <>{row.original.redeemedOffers}</>;
      }
    }),
    tableColumnDefs.custom<Customer>({
      header: '',
      id: 'id2',
      accessorKey: 'id',
      enableSorting: false,
      cell({ row }) {
        const isCustomerInVertica = Boolean(row.original.customerId);
        return (
          <>
            <div className='relative flex items-center'>
              <EditCustomerModal
                sourceCustomerId={row.original.sourceCustomerId}
                isCustomerInVertica={isCustomerInVertica}
                isLoading={isLoading}
              />
              <div className='ml-2'>
                <Tooltip
                  content={t('label.deleteCustomer')}
                  placement='placement.top'
                >
                  <IconButton
                    aria-label={`${t('label.deleteCustomer')}`}
                    size='size.md'
                    variant='variant.primary'
                    onClick={() =>
                      handleDeleteCustomer(row.original.sourceCustomerId)
                    }
                    disabled={isDeleting || isLoading}
                  >
                    <TrashIcon className='remove-stroke text-blue-action' />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </>
        );
      }
    })
  ];

  const handleDeleteCustomer = (customerId: string) => {
    setIsDeleting(true);

    deleteMutation.mutate(
      {
        sourceCustomerId: `${customerId}`
      },
      {
        async onSuccess() {
          sendToast({
            variant: 'variant.success',
            content: 'The Customer was removed'
          });

          await queryClient.invalidateQueries({
            queryKey: customerService.invalidationKey
          });

          setIsDeleting(false);
        },
        onError() {
          sendToast({
            variant: 'variant.error',
            content: t('label.errorRemovingCustomer')
          });
          setIsDeleting(false);
        }
      }
    );
  };

  return (
    <div className='CustomersList'>
      <ServerSideTable
        id='customers-list'
        borderless={false}
        data={customers || []}
        loading={isLoading}
        columns={columns}
        totalRows={totalCustomers}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        pageSizeOptions={[5, 10, 20]}
        noDataMessage={<EmptyCustomerList />}
      />
      <CustomerInstructionsModal autoTrigger={true} />
    </div>
  );
};
export { CustomersList };
