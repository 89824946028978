import { ToastData } from './types';

let idCounter = 0;

type Action =
  | { type: 'ADD'; notification: Omit<ToastData, 'id'> }
  | { type: 'REMOVE'; id: string | number }
  | { type: 'RESET' };

function reducer(state: ToastData[], action: Action): ToastData[] {
  switch (action.type) {
    case 'ADD': {
      return [...state, { ...action.notification, id: idCounter++ }];
    }
    case 'REMOVE': {
      return state.filter(({ id }) => id !== action.id);
    }
    case 'RESET': {
      return [];
    }
    default: {
      return state;
    }
  }
}

export type { Action };
export { reducer };
