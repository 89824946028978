import { PublisherLocaleCode } from '@library/utils';
import { RedeemingMerchantAnnotationOpsAnnotation } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

/*
 * This is all rather excessive because the OPS annotations returned by the API
 * are just straight-up en-US strings, but we have to make the distinction between
 * a label value and the actual annotation value because of the "None" case, and
 * from there also distinguish between en-US and en-GB for consistency with our
 * other annotations and future proofing.
 */

interface RedeemingMerchantOpsAnnotationDefinition {
  readonly id: RedeemingMerchantAnnotationOpsAnnotation;
  readonly label: Record<PublisherLocaleCode, string>;
  readonly annotationValue: Record<PublisherLocaleCode, string>;
}

const TEXT = {
  'Fuel Only': {
    'en-US': 'Fuel Only',
    'en-GB': 'Fuel Only'
  },
  'Membership Only': {
    'en-US': 'Membership Only',
    'en-GB': 'Membership Only'
  },
  'Subscription Only': {
    'en-US': 'Subscription Only',
    'en-GB': 'Subscription Only'
  },
  'In-Store Only': {
    'en-US': 'In-Store Only',
    'en-GB': 'In-Store Only'
  },
  'Online Only': {
    'en-US': 'Online Only',
    'en-GB': 'Online Only'
  },
  'Pay at Pump Only': {
    'en-US': 'Pay at Pump Only',
    'en-GB': 'Pay at Pump Only'
  },
  'Up to 5% back': {
    'en-US': 'Up to 5% back',
    'en-GB': 'Up to 5% back'
  },
  'Up to 10% back': {
    'en-US': 'Up to 10% back',
    'en-GB': 'Up to 10% back'
  },
  'Up to 15% back': {
    'en-US': 'Up to 15% back',
    'en-GB': 'Up to 15% back'
  },
  'Up to 20% back': {
    'en-US': 'Up to 20% back',
    'en-GB': 'Up to 20% back'
  }
} satisfies Partial<
  Record<
    RedeemingMerchantAnnotationOpsAnnotation,
    Record<PublisherLocaleCode, string>
  >
>;

const {
  getById: redeemingMerchantOpsAnnotationDef,
  getAll: redeemingMerchantOpsAnnotationAllDefs
} = createDefinition<
  RedeemingMerchantAnnotationOpsAnnotation,
  RedeemingMerchantOpsAnnotationDefinition
>({
  definitions: {
    None: {
      label: {
        'en-US': t('redeemingmerchantannotationmodel.OPS_ANNOTATION_NONE'),
        'en-GB': t('redeemingmerchantannotationmodel.OPS_ANNOTATION_NONE')
      },
      annotationValue: {
        'en-US': '',
        'en-GB': ''
      }
    },
    'Fuel Only': {
      label: TEXT['Fuel Only'],
      annotationValue: TEXT['Fuel Only']
    },
    'Membership Only': {
      label: TEXT['Membership Only'],
      annotationValue: TEXT['Membership Only']
    },
    'Subscription Only': {
      label: TEXT['Subscription Only'],
      annotationValue: TEXT['Subscription Only']
    },
    'In-Store Only': {
      label: TEXT['In-Store Only'],
      annotationValue: TEXT['In-Store Only']
    },
    'Online Only': {
      label: TEXT['Online Only'],
      annotationValue: TEXT['Online Only']
    },
    'Pay at Pump Only': {
      label: TEXT['Pay at Pump Only'],
      annotationValue: TEXT['Pay at Pump Only']
    },
    'Up to 5% back': {
      label: TEXT['Up to 5% back'],
      annotationValue: TEXT['Up to 5% back']
    },
    'Up to 10% back': {
      label: TEXT['Up to 10% back'],
      annotationValue: TEXT['Up to 10% back']
    },
    'Up to 15% back': {
      label: TEXT['Up to 15% back'],
      annotationValue: TEXT['Up to 15% back']
    },
    'Up to 20% back': {
      label: TEXT['Up to 20% back'],
      annotationValue: TEXT['Up to 20% back']
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as RedeemingMerchantAnnotationOpsAnnotation,
      label: {
        'en-US': id,
        'en-GB': id
      },
      annotationValue: {
        'en-US': id,
        'en-GB': id
      }
    };
  }
});

export {
  redeemingMerchantOpsAnnotationAllDefs,
  redeemingMerchantOpsAnnotationDef
};
export type { RedeemingMerchantOpsAnnotationDefinition };
