import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

import { UpdateCampaignRequest } from '@models/campaign';

interface PutParams {
  body: Partial<UpdateCampaignRequest>;
  id: number;
}

const put: AdapterMutationFunction<
  Partial<UpdateCampaignRequest>,
  PutParams
> = async (params) => {
  const { body, id } = params;

  const url = `/v1/campaigns/${id}`;

  return apiTransport.put<Partial<UpdateCampaignRequest>>({
    url,
    body
  });
};

export type { PutParams };
export { put };
