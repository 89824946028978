import { forwardRef } from 'react';

import { classnames } from '@library/utils';
import { ClearButton } from '../clear-button';
import { BaseInputProps } from '../types';
import { sizeClasses } from '../utils';
import { TextInputType } from './types';

interface TextInputProps extends BaseInputProps<string> {
  decorator?: React.ReactNode;
  type?: TextInputType;
  clearable?: boolean;
  postContent?: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      'aria-describedby': ariaDescribedBy,
      'aria-invalid': ariaInvalid,
      'aria-required': ariaRequired,
      id,
      className,
      decorator,
      disabled,
      onBlur,
      onChange,
      onKeyUp,
      placeholder,
      size = 'size.lg',
      type = 'text',
      value,
      clearable = true,
      postContent
    },
    ref
  ) {
    const onClearButton = () => {
      onChange && onChange('');
      onBlur && onBlur();
    };

    const onInputChange = (e) => {
      onChange && onChange(e.target.value);
    };
    return (
      <div
        data-before={postContent}
        className={classnames(
          className,
          'group relative',
          postContent &&
            `after:absolute after:left-12 after:top-4 after:text-gray-rules after:content-[attr(data-before)]`
        )}
      >
        <input
          aria-describedby={ariaDescribedBy}
          aria-invalid={ariaInvalid}
          aria-required={ariaRequired}
          id={id}
          value={value}
          onChange={onInputChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          disabled={disabled}
          ref={ref}
          type={type}
          placeholder={placeholder}
          className={classnames(
            sizeClasses[size],
            /*
             * peer can't be used with @apply, so it's here instead of the Tailwind
             * config. The pr-10 is also here because not every input element is
             * going to necessarily have right padding to accommodate an X icon.
             */
            'peer input-field pr-10 placeholder-shown:overflow-ellipsis',
            !!decorator && 'pl-10'
          )}
        />
        {decorator ? (
          <span className='input-decorator'>{decorator}</span>
        ) : null}
        {clearable && (
          <ClearButton id={id} onClick={onClearButton} disabled={disabled} />
        )}
      </div>
    );
  }
);

export { TextInput };
export type { TextInputProps };
