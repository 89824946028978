import React from 'react';
import { ButtonlikeSize, ButtonlikeVariant, ButtonlikeWidth } from '../types';
import { useButtonClasses } from '../use-button-classes';
import { BaseButton, BaseButtonProps } from './base';

interface SecondaryButtonProps
  extends Omit<BaseButtonProps, 'className' | 'size' | 'usage' | 'variant'> {
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  width?: ButtonlikeWidth;
}

const SecondaryButton = React.forwardRef<
  HTMLButtonElement,
  SecondaryButtonProps
>(function SecondaryButtonWithRef(
  {
    size = 'size.md',
    variant = 'variant.base',
    width = 'width.unset',
    ...delegated
  },
  ref
) {
  return (
    <BaseButton
      {...delegated}
      className={useButtonClasses({
        size,
        usage: 'usage.secondary',
        variant,
        width
      })}
      ref={ref}
    />
  );
});

export type { SecondaryButtonProps };
export { SecondaryButton };
