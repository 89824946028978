import { classnames } from '@library/utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as RadixDialog from '@radix-ui/react-dialog';
import { createContext, useContext, useState } from 'react';
import { IconButton, PrimaryButton, SecondaryButton } from '../buttonlike';
import { ModalMaxWidth } from './types';

type ModalContainerContextValue = HTMLDivElement | null;
const ModalContainerContext = createContext<ModalContainerContextValue>(null);

const useModalContainer = () => useContext(ModalContainerContext);

interface ModalBaseProps {
  trigger?: React.ReactNode;
  header: React.ReactNode;
  content: React.ReactNode;
  loading?: boolean;
  maxWidth?: ModalMaxWidth;
  maxHeight?: string;
}

interface ModalControlledProps {
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

interface ModalUncontrolledProps {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
}

type ModalProps = ModalBaseProps &
  (ModalUncontrolledProps | ModalControlledProps);

const maxWidthClasses = {
  'maxWidth.small': 'max-w-112',
  'maxWidth.large': 'max-w-body'
} satisfies Record<ModalMaxWidth, string>;

const Modal = ({
  trigger,
  header,
  content,
  isOpen,
  loading,
  onOpen,
  onClose,
  maxWidth = 'maxWidth.small',
  maxHeight = '80vh'
}: ModalProps) => {
  const animationClasses =
    'data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut';

  const preventIfLoading = (event: Event) => {
    if (loading) {
      event.preventDefault();
    }
  };

  // give inner modal content a container to portal itself into
  const [container, setContainer] = useState<ModalContainerContextValue>(null);

  return (
    <ModalContainerContext.Provider value={container}>
      <RadixDialog.Root
        modal={true}
        open={isOpen}
        onOpenChange={(newOpenState) => {
          newOpenState ? onOpen?.() : onClose?.();
        }}
      >
        <RadixDialog.Trigger asChild={true}>{trigger}</RadixDialog.Trigger>
        <RadixDialog.Portal>
          <RadixDialog.Overlay
            className={classnames(
              'fixed top-0 h-full w-full bg-inverse bg-opacity-50',
              animationClasses
            )}
            data-testid='modal-overlay'
          />
          <RadixDialog.Content
            className={classnames(
              'fixed left-1/2 top-1/2 box-content min-w-112 -translate-x-1/2 -translate-y-1/2 overflow-visible rounded-md border border-neutral bg-content shadow-md',
              animationClasses,
              maxWidthClasses[maxWidth]
            )}
            style={{ maxHeight }}
            onEscapeKeyDown={preventIfLoading}
            onPointerDownOutside={preventIfLoading}
            onInteractOutside={preventIfLoading}
            ref={setContainer}
          >
            <div className='max-h-[inherit] overflow-auto rounded-md p-6'>
              <div className='flex justify-end'>
                <RadixDialog.Title className='flex-1 pt-2 text-xl font-bold'>
                  {header}
                </RadixDialog.Title>
                <ModalCloseButton>
                  <IconButton
                    title='Close'
                    aria-label='Close'
                    disabled={loading}
                  >
                    <XMarkIcon />
                  </IconButton>
                </ModalCloseButton>
              </div>
              {content}
            </div>
          </RadixDialog.Content>
        </RadixDialog.Portal>
      </RadixDialog.Root>
    </ModalContainerContext.Provider>
  );
};

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter = ({ children }: ModalFooterProps) => (
  <div className='mt-8'>{children}</div>
);

interface ModalCloseButtonProps {
  children: React.ReactNode;
}

const ModalCloseButton = ({ children }: ModalCloseButtonProps) => (
  <RadixDialog.Close asChild={true}>{children}</RadixDialog.Close>
);

interface ModalConfirmationButtonsProps {
  loading?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ModalConfirmationButtons = ({
  loading,
  onConfirm,
  onCancel
}: ModalConfirmationButtonsProps) => (
  <ModalFooter>
    <div className='flex items-center justify-between'>
      <ModalCloseButton>
        <SecondaryButton disabled={loading} onClick={onCancel}>
          Cancel
        </SecondaryButton>
      </ModalCloseButton>
      <PrimaryButton disabled={loading} onClick={onConfirm}>
        {loading ? 'Proceeding...' : 'Proceed'}
      </PrimaryButton>
    </div>
  </ModalFooter>
);

export {
  Modal,
  ModalFooter,
  ModalCloseButton,
  ModalConfirmationButtons,
  useModalContainer
};
export type { ModalProps };
