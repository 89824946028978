import { classnames } from '@library/utils';
import {
  ChangeEvent,
  ChangeEventHandler,
  createContext,
  FocusEventHandler,
  useContext,
  useId
} from 'react';
import {
  CheckboxGroupSelection,
  CheckboxSelection,
  CheckedIcon
} from './helpers';

interface SimpleCheckboxContextValue {
  disabled?: boolean;
  name: string;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  selections: CheckboxGroupSelection;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const SimpleCheckboxContext = createContext<
  SimpleCheckboxContextValue | undefined
>(undefined);

function useCheckbox() {
  const context = useContext(SimpleCheckboxContext);
  if (context === undefined) {
    throw new Error(
      'useCheckbox can only be used within a SimpleCheckboxGroup'
    );
  }
  return context;
}

interface SimpleCheckboxProps {
  value: string;
  children: React.ReactNode;
  disabled?: boolean;
}

function SimpleCheckbox({ value, children, disabled }: SimpleCheckboxProps) {
  const {
    disabled: groupDisabled,
    name,
    onInputChange,
    onBlur,
    selections
  } = useCheckbox();

  disabled = groupDisabled || disabled;
  const checked = selections[value as CheckboxSelection];
  const id = `${name}-checkbox-${useId()}`;

  return (
    <>
      <div className='checkbox-container relative'>
        <input
          id={id}
          value={value}
          checked={checked || false}
          disabled={disabled}
          name={name}
          onChange={onInputChange}
          onBlur={onBlur}
          type='checkbox'
          className={classnames(
            'peer',
            'opacity-0',
            'absolute',
            'top-0',
            'bottom-0',
            'cursor-pointer',
            'disabled:cursor-not-allowed'
          )}
        />
        <label
          htmlFor={id}
          className={classnames(
            'peer-disabled:text-disabled',
            'flex',
            'cursor-pointer',
            'items-center',
            'peer-disabled:cursor-not-allowed'
          )}
        >
          <div className='checkbox-box'>
            <CheckedIcon />
          </div>
          <div className='ml-2'>{children}</div>
        </label>
      </div>
    </>
  );
}

interface SimpleCheckboxGroupProps {
  children: React.ReactNode;
  disabled?: boolean;
  name: string;
  id?: string;
  onSelectionsChange: (selections: CheckboxGroupSelection) => void | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  selections: CheckboxGroupSelection;
}

function SimpleCheckboxGroup({
  children,
  disabled,
  name,
  onSelectionsChange,
  onBlur,
  selections
}: SimpleCheckboxGroupProps) {
  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const newSelections = { ...selections };

    if (newSelections[newValue]) {
      delete newSelections[newValue];
    } else {
      newSelections[newValue] = true;
    }

    onSelectionsChange(newSelections);
  }

  const value = {
    disabled,
    name,
    onInputChange,
    onBlur,
    selections
  };

  return (
    <SimpleCheckboxContext.Provider value={value}>
      <div>{children}</div>
    </SimpleCheckboxContext.Provider>
  );
}

export type { SimpleCheckboxGroupProps };
export { SimpleCheckbox, SimpleCheckboxGroup };
