import { MerchantPurchaseChannel } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface MerchantPurchaseChannelDefinition {
  readonly id: MerchantPurchaseChannel;
  readonly label: string;
}

const {
  getById: merchantPurchaseChannelDef,
  getAll: merchantPurchaseChannelAllDefs
} = createDefinition<
  MerchantPurchaseChannel,
  MerchantPurchaseChannelDefinition
>({
  definitions: {
    InStore: {
      label: t('merchantmodel.PURCHASE_CHANNEL_IN_STORE')
    },
    Online: {
      label: t('merchantmodel.PURCHASE_CHANNEL_ONLINE')
    },
    Other: {
      label: t('merchantmodel.PURCHASE_CHANNEL_OTHER')
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as MerchantPurchaseChannel,
      label: id
    };
  }
});

export { merchantPurchaseChannelAllDefs, merchantPurchaseChannelDef };
export type { MerchantPurchaseChannelDefinition };
