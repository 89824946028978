import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { SingleCustomerResponse } from '@models/customer';

interface GetByIdParams {
  sourceCustomerId: string;
}

const getById: AdapterFunction<SingleCustomerResponse, GetByIdParams> = (
  { sourceCustomerId },
  { signal }
) => {
  const url = `/v1/customers/profiles`;

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: sourceCustomerId
  });

  const response = apiTransport.get<SingleCustomerResponse>({
    url,
    config: {
      signal
    }
  });

  apiTransport.setHeader({ key: 'X-Cdlx-Customer-Id', value: '' });

  return response;
};

export type { GetByIdParams };
export { getById };
