import { customerAdapter } from '@adapters/customer';

import { ServiceData, ServiceQuery } from '../service';

import { CustomerMetric } from '@models/customer';
import { FilterRequestParam } from '@models/table';
import { TablePaginationState } from '@library/components';

interface GetData extends ServiceData {
  customers: CustomerMetric[];
}

interface GetParams {
  filter?: FilterRequestParam[];
  pagination?: TablePaginationState;
}

const get: ServiceQuery<GetData, GetParams> = async (context) => {
  const { queryKey, signal } = context;

  const [{ filter = [], pagination = {} as TablePaginationState }] = queryKey;

  const { data, meta } = await customerAdapter.get(
    { filter, pagination },
    { signal }
  );

  return { customers: data, meta };
};

export type { GetData, GetParams };
export { get };
