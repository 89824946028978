import { ServiceMutation } from '../service';
import { customerAdapter } from '@adapters/customer';

interface CustomerDeleteParams {
  sourceCustomerId: string;
}

const del: ServiceMutation<void, CustomerDeleteParams> = async function (
  params
) {
  const { sourceCustomerId } = params;

  await customerAdapter.del({
    sourceCustomerId
  });
};

export { del };
export type { CustomerDeleteParams };
