import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  ExclamationTriangleIcon,
  XCircleIcon
} from '@heroicons/react/24/solid';
import { classnames } from '@library/utils';
import { MessageVariant } from './types';

const baseIconClasses = 'w-4 h-4 mr-1';

const variantMessageClasses = {
  'variant.error': 'text-error',
  'variant.warning': '',
  'variant.info': ''
} satisfies Record<MessageVariant, string>;

const variantMessageIcon = {
  'variant.error': (
    <XCircleIcon className={classnames(baseIconClasses, 'fill-error')} />
  ),
  'variant.warning': (
    <ExclamationTriangleIcon
      className={classnames(baseIconClasses, 'fill-warning')}
    />
  ),
  'variant.info': (
    <InformationCircleIcon
      className={classnames(baseIconClasses, 'stroke-activeInput')}
    />
  )
} satisfies Record<MessageVariant, JSX.Element>;

interface MessageProps {
  id?: string;
  message: string;
  variant?: MessageVariant;
}

const Message = ({ id, message, variant = 'variant.error' }: MessageProps) => (
  <div className='inline-flex items-center'>
    {variantMessageIcon[variant]}
    <div
      id={id}
      className={classnames(variantMessageClasses[variant], 'flex-1 text-sm')}
    >
      {message}
    </div>
  </div>
);

export { Message };
export type { MessageProps };
