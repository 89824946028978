import { classnames } from '@library/utils';
import {
  ChangeEvent,
  ChangeEventHandler,
  createContext,
  FocusEventHandler,
  useContext
} from 'react';
import {
  CheckboxGroupSelection,
  CheckboxSelection,
  CheckedIcon,
  getCheckboxState,
  getLeaves,
  getUpdatedSelections,
  IndeterminateIcon
} from './helpers';

interface NestedHeadlessCheckboxContextValue {
  disabled?: boolean;
  name: string;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  selections: CheckboxGroupSelection;
  allItems: object[];
  parentKey: string;
  valueKey: string;
}

const NestedHeadlessCheckboxContext = createContext<
  NestedHeadlessCheckboxContextValue | undefined
>(undefined);

function useCheckbox() {
  const context = useContext(NestedHeadlessCheckboxContext);
  if (context === undefined) {
    throw new Error(
      'useCheckbox can only be used within a NestedHeadlessCheckboxGroup'
    );
  }
  return context;
}

interface NestedHeadlessCheckboxLabelProps {
  htmlFor: string;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

function NestedHeadlessCheckboxLabel({
  htmlFor,
  disabled,
  className,
  children
}: NestedHeadlessCheckboxLabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={classnames(
        className,
        disabled ? 'cursor-default' : 'cursor-pointer'
      )}
    >
      {children}
    </label>
  );
}

interface NestedHeadlessCheckboxProps {
  id: string;
  value: CheckboxSelection;
  disabled?: boolean;
}

function NestedHeadlessCheckbox({
  id,
  value,
  disabled
}: NestedHeadlessCheckboxProps) {
  const {
    disabled: groupDisabled,
    name,
    onInputChange,
    onBlur,
    selections,
    allItems,
    parentKey,
    valueKey
  } = useCheckbox();

  disabled = groupDisabled || disabled;

  const leafChildren: object[] = getLeaves({
    valueKey,
    parentKey,
    parentValue: value,
    allItems
  });

  const { checked, indeterminate } = getCheckboxState({
    value,
    leafChildren,
    valueKey,
    selections
  });

  return (
    <>
      <div className='checkbox-container relative'>
        <input
          id={id}
          value={value}
          checked={checked || false}
          disabled={disabled}
          name={name}
          onChange={onInputChange}
          onBlur={onBlur}
          type='checkbox'
          aria-checked={indeterminate ? 'mixed' : checked ? 'true' : 'false'}
          className='peer absolute cursor-pointer opacity-0 disabled:cursor-not-allowed'
        />
        <label htmlFor={id}>
          <div className='checkbox-box'>
            {indeterminate ? <IndeterminateIcon /> : <CheckedIcon />}
          </div>
        </label>
      </div>
    </>
  );
}

interface NestedHeadlessCheckboxGroupProps {
  children: React.ReactNode;
  disabled?: boolean;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onSelectionsChange: (selections: CheckboxGroupSelection) => void | undefined;
  selections: CheckboxGroupSelection;
  allItems: object[];
  parentKey: string;
  valueKey: string;
}

function NestedHeadlessCheckboxGroup({
  children,
  disabled,
  name,
  onSelectionsChange,
  onBlur,
  selections,
  allItems,
  parentKey,
  valueKey
}: NestedHeadlessCheckboxGroupProps) {
  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    const targetValue = e.target.value;

    const leafChildren: object[] = getLeaves({
      valueKey,
      parentKey,
      parentValue: targetValue,
      allItems
    });

    const { checked, indeterminate } = getCheckboxState({
      value: targetValue,
      leafChildren,
      valueKey,
      selections
    });

    const newSelections = getUpdatedSelections({
      newValue: e.target.value,
      leafChildren,
      selections,
      indeterminate,
      checked,
      valueKey
    });

    onSelectionsChange(newSelections);
  }

  const value = {
    disabled,
    name,
    onInputChange,
    onBlur,
    selections,
    allItems,
    parentKey,
    valueKey
  };

  return (
    <NestedHeadlessCheckboxContext.Provider value={value}>
      <div>{children}</div>
    </NestedHeadlessCheckboxContext.Provider>
  );
}

export type {
  NestedHeadlessCheckboxContextValue,
  NestedHeadlessCheckboxGroupProps,
  NestedHeadlessCheckboxProps,
  NestedHeadlessCheckboxLabelProps
};
export {
  NestedHeadlessCheckbox,
  NestedHeadlessCheckboxGroup,
  NestedHeadlessCheckboxLabel
};
