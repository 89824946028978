interface FieldContainerProps {
  children: React.ReactNode;
  id: string;
}

function FieldContainer({ children, id }: FieldContainerProps) {
  return (
    <div data-testid={`${id}-container`} className='mb-4 flex flex-col gap-1'>
      {children}
    </div>
  );
}

type TableFieldContainerProps = FieldContainerProps;

function TableFieldContainer({ children, id }: FieldContainerProps) {
  return (
    <div
      data-testid={`${id}-container`}
      className='mb-4 flex h-full flex-col gap-0'
    >
      {children}
    </div>
  );
}

export type { FieldContainerProps, TableFieldContainerProps };
export { FieldContainer, TableFieldContainer };
