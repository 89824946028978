// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgRocketLogo(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      viewBox='0 0 20 20'
      fill='none'
      className='rocket-logo_svg__h-6 rocket-logo_svg__w-6'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.595 0H0v10.785h3.595V7.19H7.19V3.595h3.595V0h-7.19Z'
        fill='#46C8F2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.19 3.595h3.595V7.19H7.19V3.595Zm0 7.19v3.595h7.19V7.19h-3.595v3.595H7.19Zm0 0V7.19H3.595v3.595H7.19Z'
        fill='#1B89CA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.19 10.785H3.595v7.19H14.38V14.38H7.19v-3.595Z'
        fill='#1DB0E6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.38 3.595h-3.595V7.19h3.595v7.19h3.595V3.595H14.38Z'
        fill='#46C8F2'
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgRocketLogo);
export default ForwardRef;
