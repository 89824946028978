// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgAcquireNewCustomers(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      viewBox='0 0 143 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        d='M142.61 91.849c0 7.025-2.965 13.655-7.558 18.247-4.593 4.593-11.222 7.558-18.247 7.558s-13.654-2.965-18.247-7.558C93.965 105.504 91 98.874 91 91.849c0-7.008 2.951-13.622 7.525-18.213l.732 1.037a1.54 1.54 0 0 0 1.258.652h.298c-.09.087-.179.175-.268.264-4.238 4.239-6.735 9.809-6.735 16.26 0 6.451 2.497 12.022 6.735 16.26 4.239 4.239 9.809 6.735 16.26 6.735 6.451 0 12.021-2.496 16.26-6.735 4.238-4.238 6.735-9.809 6.735-16.26 0-6.451-2.497-12.021-6.735-16.26-4.239-4.238-9.809-6.735-16.26-6.735-1.723 0-3.382.178-4.971.52l-.228-1.16v-1.628a25.36 25.36 0 0 1 5.199-.542c7.025 0 13.654 2.966 18.247 7.558 4.593 4.593 7.558 11.222 7.558 18.247Z'
        style={{
          fill: '#0078bf'
        }}
      />
      <path d='M143.11 91.849c0 7.127-3.06 13.934-7.704 18.601-4.668 4.644-11.474 7.704-18.601 7.704-7.127 0-13.933-3.06-18.601-7.704-4.644-4.667-7.704-11.474-7.704-18.601 0-7.127 3.06-13.933 7.704-18.601 4.668-4.643 11.474-7.704 18.601-7.704 7.127 0 13.933 3.061 18.601 7.704 4.644 4.668 7.704 11.474 7.704 18.601Zm-8.412-17.893c-4.518-4.541-10.97-7.412-17.893-7.412s-13.375 2.871-17.893 7.412C94.37 78.474 91.5 84.926 91.5 91.849s2.87 13.376 7.412 17.893c4.518 4.542 10.97 7.412 17.893 7.412s13.375-2.87 17.893-7.412c4.542-4.517 7.412-10.97 7.412-17.893s-2.87-13.375-7.412-17.893Zm5.602 17.893c0 6.553-2.581 12.313-6.881 16.614-4.302 4.302-10.061 6.881-16.614 6.881-6.553 0-12.312-2.579-16.614-6.881-4.3-4.301-6.881-10.061-6.881-16.614 0-6.553 2.592-12.3 6.881-16.614 4.313-4.289 10.061-6.881 16.614-6.881 6.553 0 12.3 2.592 16.614 6.881 4.289 4.314 6.881 10.061 6.881 16.614Zm-7.589-15.906c-4.164-4.187-9.557-6.589-15.906-6.589s-11.743 2.402-15.906 6.589c-4.187 4.164-6.589 9.557-6.589 15.906s2.413 11.731 6.589 15.906c4.176 4.177 9.557 6.589 15.906 6.589s11.73-2.413 15.906-6.589c4.176-4.175 6.589-9.557 6.589-15.906s-2.402-11.742-6.589-15.906Z' />
      <path
        d='M59.35 91.582c0 7.025-2.965 13.655-7.558 18.247-4.592 4.593-11.222 7.559-18.247 7.559s-13.654-2.966-18.247-7.559c-4.592-4.592-7.558-11.222-7.558-18.247s2.966-13.654 7.558-18.247a25.863 25.863 0 0 1 7.24-5.067c.202.81.364 1.681.506 2.82a23.221 23.221 0 0 0-5.759 4.234c-4.238 4.239-6.735 9.81-6.735 16.26 0 6.451 2.497 12.022 6.735 16.26 4.239 4.239 9.809 6.736 16.26 6.736 6.451 0 12.022-2.497 16.26-6.736 4.239-4.238 6.735-9.809 6.735-16.26 0-6.45-2.496-12.021-6.735-16.26-4.238-4.238-9.809-6.735-16.26-6.735-.972 0-1.924.057-2.854.167a37.89 37.89 0 0 1-.479-2.755 25.26 25.26 0 0 1 3.333-.222c7.025 0 13.655 2.966 18.247 7.558 4.593 4.593 7.558 11.222 7.558 18.247Z'
        style={{
          fill: '#0078bf'
        }}
      />
      <path
        d='M31.427 66.162c2.054 12.582 11.747 25.42 33.123 15.021M104.095 99.552c5.306 9.442 18.611 10.206 24.961 1.432l1.037-1.432M20.835 99.285c5.306 9.443 18.611 10.206 24.962 1.432l1.036-1.432M113 67v6a6 6 0 0 0 6 6h1.2a4.8 4.8 0 0 0 4.8-4.8M21.413 69.436c1.476 9.436 1.155 24.65-20.413 18.68'
        stroke='#0078BF'
        strokeWidth={2.81}
      />
      <path d='M27 11h12M33 5v12' stroke='#0CB9F8' strokeWidth={2.3} />
      <circle cx={33} cy={11} r={10} stroke='#0CB9F8' strokeWidth={2} />
      <path d='M108 11h12M114 5v12' stroke='#0CB9F8' strokeWidth={2.3} />
      <circle cx={114} cy={11} r={10} stroke='#0CB9F8' strokeWidth={2} />
      <path
        d='M37 95.5h-5.5l3-9.5M119.5 95.5H114l3-9.5'
        stroke='#0078BF'
        strokeWidth={2.81}
      />
      <path d='M33 56V30M114 56V30' stroke='#0CB9F8' strokeWidth={2.3} />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgAcquireNewCustomers);
export default ForwardRef;
