import { forwardRef } from 'react';
import { NumberInput, NumberInputProps } from '../number';

// an attempt to get around horrible IEEE 754 floating point math
// resulting in 10.0000000001 percentages
const toFixedFloat = (value: number, precision: number) =>
  Number.parseFloat(value.toFixed(precision));

interface PercentageInputProps extends NumberInputProps {
  // This only supports no decimals (50%), or 2 decimal places (50.50%)
  decimals?: 0 | 2;
}

const PercentageInput = forwardRef<HTMLInputElement, PercentageInputProps>(
  function PercentageInput(
    { value, onChange, decimals = 0, ...delegated },
    ref
  ) {
    return (
      <NumberInput
        {...delegated}
        ref={ref}
        decimals={decimals}
        suffix='%'
        value={
          value === null || value === undefined
            ? null
            : toFixedFloat(value * 100, decimals)
        }
        onChange={(newValue) => {
          onChange?.(
            // a max precisions of 4 allows for a percentage value with 2 decimals
            // e.g 50.50% becomes 0.5050, 150.55% becomes 1.5055, etc
            newValue === null ? null : toFixedFloat(newValue / 100, 4)
          );
        }}
      />
    );
  }
);

export type { PercentageInputProps };
export { PercentageInput };
