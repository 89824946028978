import { Modal } from '@/library/components';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PreviewCustomersModalContent,
  PreviewMultipleCustomersProps
} from './PreviewCustomersModalContent';

interface PreviewCustomersModalProps extends PreviewMultipleCustomersProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<boolean>;
  setIsAddCustomerModalOpen: Dispatch<boolean>;
}

const PreviewCustomersModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsAddCustomerModalOpen,
  customers
}: PreviewCustomersModalProps) => {
  const [t] = useTranslation();
  return (
    <Modal
      isOpen={isModalOpen}
      header={
        <div className='p-4'>
          <h3 className='mb-2 text-2xl'>{t('label.customerPreview')}</h3>
          <p className='mb-2 text-sm font-normal'>
            {t('label.previewCustomersToAdd')}
          </p>
          <p className='text-sm font-bold'>
            {t('common.nonExistingValidation')}
          </p>
        </div>
      }
      maxWidth='maxWidth.large'
      onClose={() => {
        setIsModalOpen(false);
      }}
      content={
        <PreviewCustomersModalContent
          customers={customers}
          setIsModalOpen={setIsModalOpen}
          setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
        />
      }
    />
  );
};

export { PreviewCustomersModal };
