import { ServiceOptions, wrapQueryFunction } from '../service';

import { get } from './get';
import { getGroup } from './get-group';

const options: ServiceOptions = {
  name: 'PORTFOLIO',
  cacheTime: 'cache.veryLong'
};

const portfolioService = {
  invalidationKey: [{ name: options.name }],
  get: wrapQueryFunction(options, get),
  getGroup: wrapQueryFunction(options, getGroup)
};

export { portfolioService };
