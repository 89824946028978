// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
const SvgError500 = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={466}
    height={550}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <ellipse
      opacity={0.03}
      cx={233}
      cy={254.172}
      rx={219}
      ry={219.172}
      fill='#5CD6FF'
    />
    <ellipse
      opacity={0.1}
      cx={232.529}
      cy={248.379}
      rx={204.529}
      ry={204.69}
      fill='#5CD6FF'
    />
    <path
      opacity={0.5}
      d='M303 113.254c0-11.305 11.057-17.542 11.057-26.898 0 12.864-5.133 17.932-5.133 24.169 0 17.543 8.687 13.255 8.687 28.848 0 17.152-11.847 15.983-11.847 29.627 0-19.881 5.529-15.203 5.529-28.068 0-12.864-8.293-11.695-8.293-27.678Z'
      fill='#F8F8F8'
      stroke='#484848'
      strokeWidth={0.5}
    />
    <path
      opacity={0.25}
      d='M352 119.132c0-9.302 9.081-14.434 9.081-22.132 0 10.585-4.216 14.755-4.216 19.887 0 14.434 7.135 10.905 7.135 23.736 0 14.113-9.73 13.151-9.73 24.377 0-16.358 4.541-12.509 4.541-23.094S352 132.283 352 119.132Z'
      fill='#F8F8F8'
      stroke='#484848'
      strokeWidth={0.5}
    />
    <path
      d='M323.667 82.371c0-16.028 15.639-24.87 15.639-38.135 0 18.239-7.261 25.423-7.261 34.267 0 24.87 12.288 18.79 12.288 40.898 0 24.319-16.756 22.661-16.756 42.005 0-28.187 7.819-21.555 7.819-39.794 0-18.239-11.729-16.58-11.729-39.24Z'
      fill='#F8F8F8'
      stroke='#484848'
      strokeWidth={0.5}
    />
    <path
      d='M107.744 426.809a6.274 6.274 0 0 1-5.619-6.071c-1.118.097-2.26.09-3.417-.031-9.788-1.024-16.894-9.784-15.871-19.567.749-7.167 5.653-12.893 12.068-15.042a7.235 7.235 0 0 1 11.425-8.158 25.79 25.79 0 0 1 15.92-6.418 29.86 29.86 0 0 1-1.941-14.166c1.723-16.474 16.482-28.431 32.966-26.708 1.871.196 3.685.56 5.427 1.075a6.272 6.272 0 0 1 5.685-2.491 6.272 6.272 0 0 1 5.232 8.416 30.134 30.134 0 0 1 8.811 12.767c3.812-4.497 9.71-7.096 16.02-6.436 6.442.673 11.762 4.582 14.514 9.94a21.394 21.394 0 0 1 5.421-.12 21.266 21.266 0 0 1 12.318 5.595 12.647 12.647 0 0 1 7.579-1.581c1.954.205 3.76.843 5.329 1.809 3.846-4.989 10.115-7.929 16.845-7.225 1.293.135 2.543.399 3.737.777a6.261 6.261 0 0 1 5.076-1.789 6.27 6.27 0 0 1 4.85 9.252 18.81 18.81 0 0 1 1.704 3.16 29.309 29.309 0 0 1 10.948-.913c12.613 1.319 22.522 10.433 25.439 22.052.315-.002.632.013.952.046 4.679.49 8.076 4.677 7.587 9.354a8.518 8.518 0 0 1-6.623 7.428 19.529 19.529 0 0 1 3.625 5.1 11.064 11.064 0 0 1 4.124-.343c6.088.637 10.508 6.086 9.872 12.172-.637 6.085-6.089 10.502-12.177 9.865a11.055 11.055 0 0 1-3.964-1.188c-3.985 5.081-10.418 8.064-17.319 7.343-7.89-.825-14.179-6.261-16.471-13.348a30.054 30.054 0 0 1-1.15-.098c-9.382-.981-17.268-6.275-21.893-13.704a16.83 16.83 0 0 1-6.07.466c-8.77-.917-15.263-8.399-15.104-17.051a11.04 11.04 0 0 1-5.247-2.042c-4.41 5.116-11.174 8.061-18.405 7.305a21.265 21.265 0 0 1-12.687-5.947c-4.932 7.941-14.114 12.813-24.042 11.775a25.11 25.11 0 0 1-15.817-7.882 11.431 11.431 0 0 1-8.342 6.227c-1.68 13.977-14.282 24.065-28.34 22.595a25.901 25.901 0 0 1-6.134-1.406 6.273 6.273 0 0 1-6.841 5.206Z'
      fill='#F8F8F8'
      stroke='#484848'
    />
    <path
      d='M354.174 418.732a3.026 3.026 0 0 0 2.835-2.478 8.077 8.077 0 0 0 8.953-8.409 8.076 8.076 0 0 0-4.812-7.009 3.446 3.446 0 0 0-5.126-4.061 11.576 11.576 0 0 0-6.41-3.071 13.35 13.35 0 0 0 1.069-5.918c-.35-7.395-6.631-13.105-14.03-12.755-.774.036-1.53.138-2.264.299a3.025 3.025 0 0 0-5.357 2.524 13.474 13.474 0 0 0-3.916 4.929 8.35 8.35 0 0 0-6.781-2.955 8.354 8.354 0 0 0-6.714 3.948 9.596 9.596 0 0 0-2.239-.154 9.588 9.588 0 0 0-5.529 2.073 5.817 5.817 0 0 0-3.263-.815 5.827 5.827 0 0 0-2.271.576 8.495 8.495 0 0 0-8.828-3.279 3.025 3.025 0 0 0-4.936 3.289 8.52 8.52 0 0 0-.641 1.03 13.135 13.135 0 0 0-4.632-.609c-5.588.264-10.194 3.983-11.851 8.992a4.009 4.009 0 0 0-4.043 4.196 4.008 4.008 0 0 0 2.463 3.51 8.85 8.85 0 0 0-1.314 1.687 5.13 5.13 0 0 0-6.559 5.168 5.13 5.13 0 0 0 7.018 4.526 8.77 8.77 0 0 0 7.493 3.573 8.78 8.78 0 0 0 7.706-5.423 13.108 13.108 0 0 0 10.328-5.404 7.66 7.66 0 0 0 2.543.306 7.656 7.656 0 0 0 7.277-7.093 5.098 5.098 0 0 0 2.009-.651 9.613 9.613 0 0 0 7.938 3.56 9.59 9.59 0 0 0 5.599-2.13 11.306 11.306 0 0 0 10.3 5.591 11.266 11.266 0 0 0 7.001-2.863 5.284 5.284 0 0 0 3.315 2.684c.498 6.2 5.834 10.929 12.096 10.633a11.63 11.63 0 0 0 2.475-.385 3.026 3.026 0 0 0 3.098 2.368Z'
      fill='#F8F8F8'
      stroke='#484848'
    />
    <path
      d='M130.416 313.405c-30.263-29.084-31.34-77.068-2.405-107.176 28.934-30.108 76.924-30.938 107.187-1.854l29.9 28.735-104.781 109.03-29.901-28.735Z'
      fill='#fff'
    />
    <path
      d='m184.989 298.295.71.705.704-.709 33.931-34.155.705-.71-.71-.705-38.186-37.935c-9.838-9.773-25.724-9.735-35.484.09-9.76 9.824-9.694 25.711.144 35.483l38.186 37.936Z'
      fill='#64B5F6'
    />
    <path
      d='m184.989 298.295.71.705.704-.709 33.931-34.155.705-.71-.71-.705-38.186-37.935c-9.838-9.773-25.724-9.735-35.484.09-9.76 9.824-9.694 25.711.144 35.483l38.186 37.936Z'
      fill='url(#error-500_svg__a)'
    />
    <path
      d='m184.989 298.295.71.705.704-.709 33.931-34.155.705-.71-.71-.705-38.186-37.935c-9.838-9.773-25.724-9.735-35.484.09-9.76 9.824-9.694 25.711.144 35.483l38.186 37.936Z'
      stroke='#484848'
      strokeWidth={2}
    />
    <ellipse
      cx={164.473}
      cy={242.572}
      rx={18.474}
      ry={18.503}
      transform='rotate(-45.189 164.473 242.572)'
      fill='#484848'
    />
    <ellipse
      cx={166.063}
      cy={244.153}
      rx={15.115}
      ry={15.139}
      transform='rotate(-45.189 166.063 244.153)'
      fill='#fff'
    />
    <ellipse
      cx={166.063}
      cy={244.153}
      rx={15.115}
      ry={15.139}
      transform='rotate(-45.189 166.063 244.153)'
      fill='#fff'
    />
    <ellipse
      cx={172.5}
      cy={246.856}
      rx={6.161}
      ry={6.17}
      transform='rotate(69.057 172.5 246.856)'
      fill='#484848'
    />
    <path
      d='M176.774 250.35a2.055 2.055 0 0 0 1.187-2.653 2.057 2.057 0 0 0-3.842 1.471 2.055 2.055 0 0 0 2.655 1.182ZM172.745 247.495a.686.686 0 1 0-.49-1.28.686.686 0 0 0 .49 1.28Z'
      fill='#fff'
    />
    <path
      d='m159.71 342.771.631.607.607-.631 104.781-109.03.607-.632-.631-.607-29.901-28.735c-30.608-29.415-79.152-28.58-108.424 1.879-29.273 30.46-28.179 78.999 2.43 108.414l29.9 28.735Z'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      opacity={0.21}
      d='M126.838 307.627c-27.61-26.535-27.696-71.247-.191-99.868 27.506-28.621 72.186-30.312 99.797-3.777l27.28 26.216-99.607 103.645-27.279-26.216Z'
      fill='url(#error-500_svg__b)'
    />
    <path
      d='M261.061 334.094c33.408-34.763 60.541-69.262 77.399-96.951 8.425-13.838 14.31-26.017 17.114-35.702 1.402-4.842 2.047-9.106 1.83-12.667-.218-3.567-1.309-6.494-3.444-8.546-2.124-2.042-5.222-3.157-9.024-3.489-3.808-.331-8.402.115-13.626 1.276-10.452 2.322-23.573 7.54-38.26 15.374-29.382 15.674-65.165 41.897-98.601 76.689-33.436 34.792-58.216 71.588-72.711 101.57-7.245 14.986-11.937 28.304-13.842 38.84-.953 5.267-1.216 9.875-.733 13.666.482 3.786 1.72 6.837 3.845 8.879 2.135 2.052 5.103 3.026 8.676 3.102 3.566.075 7.802-.739 12.584-2.332 9.565-3.187 21.501-9.551 34.994-18.519 26.997-17.945 60.391-46.427 93.799-81.19Z'
      fill='#D7F1FA'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M353.05 181.176c1.826 1.755 2.838 4.326 3.043 7.678.205 3.355-.402 7.459-1.781 12.221-2.757 9.524-8.575 21.589-16.974 35.385-16.795 27.585-43.862 62.009-77.224 96.724-33.362 34.714-66.683 63.128-93.579 81.006-13.452 8.941-25.276 15.233-34.683 18.366-4.703 1.567-8.78 2.337-12.14 2.265-3.358-.071-5.968-.98-7.794-2.735-1.826-1.755-2.838-4.327-3.043-7.679-.205-3.355.402-7.459 1.781-12.221 2.758-9.524 8.575-21.588 16.974-35.384 16.795-27.586 43.863-62.009 77.224-96.724 33.362-34.715 66.683-63.129 93.58-81.006 13.451-8.941 25.275-15.233 34.682-18.367 4.704-1.567 8.78-2.336 12.141-2.265 3.357.072 5.967.98 7.793 2.736Z'
      fill='#0075C9'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m217.767 384.369-.044-.021c-.152-.072-.6-.211-1.378-.416-.743-.196-1.698-.43-2.794-.699l-.013-.003c-2.745-.672-6.339-1.552-9.415-2.531-1.539-.49-2.975-1.012-4.122-1.556a10.762 10.762 0 0 1-1.523-.858c-.422-.294-.811-.638-1.066-1.044l20.355 7.128Zm0 0 .046.016m-.046-.016.046.016m0 0c2.337.81 5.463.244 9.068-1.262 3.647-1.524 7.967-4.088 12.799-7.548 9.67-6.923 21.503-17.512 34.282-30.81 12.779-13.298 22.891-25.542 29.424-35.479 3.265-4.966 5.655-9.384 7.033-13.089 1.361-3.661 1.803-6.808.901-9.111l-.018-.045m-93.489 97.344 93.489-97.344m0 0-.022-.043m.022.043-.022-.043m0 0c-.079-.149-.236-.592-.472-1.361m.472 1.361-.472-1.361m0 0c-.225-.734-.496-1.679-.808-2.764m.808 2.764-.808-2.764m0 0-.004-.012m.004.012-.004-.012m0 0c-.781-2.716-1.803-6.272-2.903-9.307m2.903 9.307-2.903-9.307m0 0c-.551-1.519-1.13-2.933-1.719-4.057m1.719 4.057-1.719-4.057m0 0a10.7 10.7 0 0 0-.918-1.488m.918 1.488-.918-1.488m0 0c-.31-.41-.67-.785-1.086-1.024m1.086 1.024-1.086-1.024m0 0c-1.043-.599-2.47-.548-3.997-.194m3.997.194-3.997-.194m0 0c-1.571.364-3.455 1.101-5.573 2.138m5.573-2.138-5.573 2.138m0 0c-4.244 2.077-9.566 5.424-15.45 9.665m15.45-9.665-15.45 9.665m0 0c-11.775 8.488-25.901 20.627-38.325 33.556m38.325-33.556-38.325 33.556m0 0c-12.425 12.929-23.994 27.526-32.007 39.628m32.007-39.628-32.007 39.628m0 0c-4.004 6.049-7.138 11.499-9.044 15.822m9.044-15.822-9.044 15.822m0 0c-.952 2.158-1.613 4.07-1.915 5.654m1.915-5.654-1.915 5.654m0 0c-.293 1.539-.287 2.967.353 3.987l-.353-3.987Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M273.646 344.462c12.771-13.289 23.33-26.301 30.076-36.6 3.371-5.146 5.803-9.634 7.078-13.152.636-1.757.994-3.297 1.023-4.569.029-1.271-.269-2.334-1.015-3.051-.746-.717-1.82-.973-3.089-.893-1.27.079-2.795.498-4.525 1.204-3.464 1.413-7.852 4.021-12.86 7.594-10.023 7.15-22.605 18.218-35.376 31.507-12.772 13.289-23.331 26.301-30.077 36.6-3.371 5.146-5.802 9.634-7.077 13.152-.637 1.757-.994 3.297-1.024 4.569-.029 1.271.27 2.334 1.016 3.051.745.717 1.82.973 3.089.893 1.27-.08 2.794-.498 4.525-1.204 3.464-1.414 7.851-4.021 12.86-7.594 10.023-7.15 22.605-18.218 35.376-31.507Z'
      fill='#0078BF'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.35}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M272.433 343.371c24.833-25.84 41.307-50.303 36.796-54.639-4.511-4.335-28.3 13.098-53.134 38.939-24.833 25.84-41.308 50.303-36.796 54.639 4.511 4.335 28.3-13.098 53.134-38.939Zm0 0c23.922-24.892 39.85-48.401 35.576-52.508-4.274-4.108-27.132 12.742-51.054 37.634s-39.85 48.401-35.576 52.509c4.274 4.107 27.131-12.742 51.054-37.635Z'
      fill='#5CD6FF'
    />
    <path
      opacity={0.35}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M258.496 330.258c64.485-67.1 105.417-132.397 91.424-145.844-13.993-13.448-77.612 30.046-142.097 97.146-64.485 67.1-105.417 132.397-91.424 145.845 13.993 13.447 77.612-30.047 142.097-97.147Zm0 0c62.119-64.637 101.729-127.364 88.473-140.104-13.257-12.74-74.361 29.331-136.479 93.969-62.119 64.638-101.729 127.365-88.473 140.105 13.257 12.74 74.36-29.332 136.479-93.97Z'
      fill='#5CD6FF'
    />
    <path
      d='M301.682 298.307c.17.163.345.528.279 1.331-.064.79-.351 1.842-.89 3.153-1.073 2.613-3.057 6.051-5.819 10.088-5.517 8.061-14.043 18.376-24.273 29.02-10.229 10.644-20.197 19.574-28.033 25.406-3.924 2.921-7.28 5.04-9.848 6.216-1.288.59-2.329.919-3.115 1.015-.8.097-1.172-.063-1.342-.227-.169-.163-.344-.528-.279-1.331.064-.789.352-1.842.89-3.153 1.073-2.613 3.057-6.05 5.82-10.087 5.516-8.062 14.043-18.376 24.272-29.021 10.23-10.644 20.197-19.573 28.033-25.406 3.924-2.921 7.281-5.04 9.849-6.216 1.288-.59 2.328-.919 3.115-1.014.8-.098 1.171.063 1.341.226Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M139.279 410.272c1.971-2.051 3.379-4.299 4.05-6.292.66-1.957.655-3.831-.489-4.93-1.144-1.099-3.017-1.03-4.946-.293-1.964.75-4.154 2.246-6.126 4.297-1.971 2.051-3.379 4.299-4.05 6.292-.66 1.956-.654 3.831.489 4.93 1.144 1.099 3.017 1.03 4.946.293 1.964-.75 4.155-2.246 6.126-4.297Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m137.742 399.105-.213-.205-.27.122c-1.792.809-3.728 2.198-5.491 4.032-1.762 1.834-3.073 3.824-3.811 5.646l-.111.275.214.205 2.622 2.52c2.786 2.678 7.212 2.594 9.887-.189 2.674-2.782 2.582-7.208-.204-9.886l-2.623-2.52Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M140.061 401.941c2.612 2.51 2.698 6.659.192 9.267 1.65-1.717.629-3.524-.087-4.212l-5.868-5.639c1.059-.825 2.127-1.478 3.141-1.936l2.622 2.52Z'
      fill='#fff'
    />
    <path
      d='M173.156 395.764c1.177-1.224 2.006-2.589 2.384-3.822.369-1.207.339-2.426-.443-3.177-.782-.752-2.002-.734-3.193-.317-1.217.426-2.547 1.31-3.724 2.534-1.177 1.224-2.006 2.589-2.384 3.822-.369 1.206-.339 2.426.443 3.177.782.751 2.002.734 3.193.317 1.217-.427 2.547-1.31 3.724-2.534Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m171.984 388.688-.208-.2-.265.112c-1.105.466-2.279 1.287-3.331 2.382-1.052 1.095-1.826 2.301-2.247 3.423l-.102.269.208.2 1.656 1.592c1.802 1.732 4.613 1.81 6.276.081 1.662-1.73 1.472-4.536-.33-6.267l-1.657-1.592Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M173.337 390.595c1.65 1.586 1.792 4.114.318 5.648.97-1.01.308-2.132-.145-2.567l-3.706-3.562a8.778 8.778 0 0 1 1.877-1.11l1.656 1.591Z'
      fill='#fff'
    />
    <path
      d='M320.84 242.091c1.176-1.224 2.006-2.589 2.383-3.822.37-1.206.339-2.426-.443-3.177-.782-.751-2.001-.733-3.192-.316-1.217.426-2.548 1.309-3.724 2.533-1.177 1.225-2.007 2.589-2.384 3.822-.369 1.207-.339 2.426.443 3.178.782.751 2.001.733 3.192.316 1.217-.426 2.548-1.309 3.725-2.534Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m319.668 235.015-.208-.199-.266.112c-1.104.466-2.278 1.286-3.33 2.381-1.053 1.095-1.826 2.301-2.248 3.423l-.101.27.208.2 1.656 1.592c1.802 1.731 4.613 1.81 6.275.08 1.662-1.729 1.472-4.535-.33-6.267l-1.656-1.592Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M321.021 236.923c1.649 1.585 1.792 4.114.317 5.648.971-1.01.309-2.133-.144-2.567l-3.706-3.562a8.76 8.76 0 0 1 1.877-1.111l1.656 1.592Z'
      fill='#fff'
    />
    <path
      d='M332.34 209.381c1.971-2.051 3.379-4.299 4.051-6.291.659-1.957.654-3.832-.49-4.931-1.143-1.099-3.016-1.03-4.945-.293-1.965.75-4.155 2.247-6.126 4.298s-3.379 4.299-4.051 6.291c-.659 1.957-.654 3.831.49 4.93 1.143 1.1 3.016 1.03 4.945.294 1.965-.75 4.155-2.247 6.126-4.298Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m330.804 198.215-.214-.206-.27.122c-1.792.81-3.728 2.199-5.49 4.033-1.763 1.834-3.073 3.823-3.811 5.646l-.111.274.213.206 2.623 2.52c2.785 2.677 7.212 2.593 9.886-.189 2.674-2.783 2.582-7.209-.204-9.886l-2.622-2.52Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M333.123 201.051c2.612 2.51 2.697 6.659.191 9.266 1.65-1.717.63-3.523-.087-4.212l-5.868-5.639c1.059-.824 2.127-1.477 3.141-1.936l2.623 2.521Z'
      fill='#fff'
    />
    <path
      d='M281.307 243.733c2.54-2.643 4.362-5.524 5.243-8.06.868-2.498.873-4.835-.524-6.178-1.398-1.343-3.733-1.244-6.194-.278-2.5.981-5.306 2.916-7.845 5.559-2.54 2.642-4.362 5.523-5.243 8.06-.868 2.497-.874 4.834.524 6.178 1.398 1.343 3.733 1.244 6.194.278 2.499-.981 5.305-2.916 7.845-5.559Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m279.51 229.644-.215-.207-.272.126c-2.284 1.055-4.766 2.85-7.036 5.213-2.271 2.362-3.966 4.913-4.93 7.238l-.114.276.215.207 3.313 3.183c3.48 3.345 9.061 3.161 12.465-.381 3.405-3.543 3.367-9.126-.113-12.471l-3.313-3.184Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M282.519 233.144c3.3 3.17 3.345 8.476.102 11.851 2.135-2.222.859-4.517-.046-5.387l-7.412-7.123c1.365-1.071 2.741-1.923 4.044-2.525l3.312 3.184Z'
      fill='#fff'
    />
    <path
      d='M173.226 356.197c2.539-2.642 4.361-5.523 5.243-8.06.867-2.498.873-4.835-.525-6.178-1.397-1.343-3.732-1.244-6.193-.278-2.5.981-5.306 2.916-7.846 5.559-2.54 2.643-4.361 5.523-5.243 8.06-.867 2.498-.873 4.835.525 6.178 1.397 1.343 3.732 1.244 6.193.278 2.5-.981 5.306-2.916 7.846-5.559Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m171.429 342.109-.216-.207-.271.125c-2.284 1.055-4.766 2.85-7.037 5.213-2.27 2.363-3.966 4.914-4.929 7.238l-.115.276.216.207 3.313 3.184c3.48 3.344 9.061 3.16 12.465-.382 3.404-3.542 3.367-9.126-.114-12.471l-3.312-3.183Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M174.438 345.608c3.299 3.171 3.344 8.477.101 11.852 2.136-2.222.859-4.518-.046-5.388l-7.412-7.123c1.366-1.07 2.741-1.923 4.044-2.524l3.313 3.183Z'
      fill='#fff'
    />
    <path
      d='M223.968 295.969c2.995-3.116 5.148-6.503 6.197-9.476 1.034-2.931 1.047-5.637-.553-7.174-1.599-1.538-4.302-1.417-7.19-.267-2.929 1.166-6.228 3.452-9.223 6.568-2.995 3.117-5.148 6.504-6.197 9.476-1.034 2.932-1.047 5.638.552 7.175 1.6 1.537 4.303 1.417 7.191.267 2.929-1.166 6.228-3.452 9.223-6.569Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m221.962 279.544-.216-.209-.272.128c-2.679 1.251-5.598 3.371-8.275 6.157-2.677 2.786-4.68 5.787-5.824 8.513l-.116.277.216.208 3.865 3.714c4.034 3.877 10.538 3.616 14.529-.536 3.99-4.152 3.992-10.662-.042-14.538l-3.865-3.714Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M225.524 283.573c3.849 3.699 3.862 9.931.029 13.92 2.524-2.626 1.042-5.313-.013-6.327l-8.648-8.311c1.612-1.267 3.233-2.279 4.767-2.996l3.865 3.714Z'
      fill='#fff'
    />
    <path
      d='M33.64 417.741c9.24-42.332 36.47-59.579 60.296-52.785 3.403-12.02 20.909-17.769 28.202-3.135 10.698-8.362 47.167-4.181 49.112 28.221 13.615-10.87 24.474-4.529 28.202 0 .324-5.226 3.793-15.679 15.074-15.679 4.376-12.542 15.56-9.929 18.964-6.271 14.587-6.271 39.872 6.794 43.276 29.789 17.019-7.839 75.369-9.407 107.948 19.337 11.67-7.316 18.477-1.045 18.963 5.227 11.67-.523 51.057 8.884 59.323 15.678H2c6.027-9.33 16.624-19.773 31.64-20.382Z'
      fill='#fff'
    />
    <path
      d='M463 438.123c-8.266-6.794-47.653-16.201-59.323-15.678-.486-6.272-7.293-12.543-18.963-5.227-32.579-28.744-90.929-27.176-107.948-19.337-3.404-22.995-28.689-36.06-43.276-29.789-3.404-3.658-14.588-6.271-18.964 6.271-11.281 0-14.75 10.453-15.074 15.679-3.728-4.529-14.587-10.87-28.202 0-1.945-32.402-38.414-36.583-49.112-28.221-7.293-14.634-24.799-8.885-28.202 3.135-23.827-6.794-51.057 10.453-60.295 52.785C18.624 418.35 8.027 428.793 2 438.123m461 0H2m461 0H2'
      stroke='#484848'
      strokeWidth={2}
    />
    <path
      d='M42.376 437.625c.174-35.573 25.061-64.25 55.624-64.25 30.563 0 55.45 28.677 55.624 64.25H42.376Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.75}
    />
    <path
      d='M47.985 405.733c-2.022-2.28-2.028-6.264.054-11.141 2.057-4.818 6.06-10.245 11.541-15.105 5.482-4.859 11.35-8.181 16.38-9.646 5.092-1.483 9.046-.998 11.068 1.282 2.021 2.281 2.028 6.265-.055 11.141-2.057 4.819-6.06 10.246-11.54 15.105-5.483 4.86-11.35 8.182-16.381 9.647-5.091 1.482-9.046.998-11.067-1.283ZM241.07 382.401c1.034-1.853 3.339-2.929 6.476-2.98 3.114-.052 6.89.922 10.564 2.972 3.674 2.049 6.486 4.752 8.077 7.429 1.603 2.697 1.899 5.223.865 7.076-1.034 1.854-3.339 2.929-6.476 2.98-3.114.052-6.89-.921-10.564-2.971-3.674-2.05-6.486-4.753-8.077-7.43-1.604-2.696-1.899-5.223-.865-7.076ZM103.239 360.555c.002-.878.561-1.777 1.683-2.492 1.113-.709 2.695-1.17 4.48-1.165 1.784.004 3.364.473 4.473 1.188 1.118.721 1.672 1.622 1.67 2.501-.002.879-.561 1.778-1.683 2.492-1.113.709-2.695 1.17-4.48 1.166-1.784-.005-3.364-.474-4.473-1.189-1.118-.721-1.673-1.622-1.67-2.501Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={1.5}
    />
    <path
      d='M33.838 425.517c.25.811-.172 1.855-1.298 2.915-1.106 1.043-2.806 2.012-4.842 2.638-2.036.626-3.987.781-5.487.54-1.528-.245-2.463-.871-2.712-1.682-.25-.812.172-1.855 1.297-2.916 1.106-1.043 2.806-2.011 4.842-2.638 2.036-.626 3.987-.78 5.488-.54 1.527.245 2.462.871 2.712 1.683ZM257.379 437.625c.164-8.784 6.114-16.787 15.778-22.639 9.795-5.931 23.35-9.611 38.343-9.611 14.993 0 28.548 3.68 38.343 9.611 9.664 5.852 15.614 13.855 15.778 22.639H257.379ZM194.625 391.5c0 .734-.653 1.515-1.98 2.135-1.298.605-3.116.99-5.145.99-2.029 0-3.847-.385-5.145-.99-1.327-.62-1.98-1.401-1.98-2.135 0-.734.653-1.516 1.98-2.135 1.298-.606 3.116-.99 5.145-.99 2.029 0 3.847.384 5.145.99 1.327.619 1.98 1.401 1.98 2.135ZM398.906 419.978c.257.836-.129 1.876-1.16 2.908-1.017 1.019-2.595 1.953-4.496 2.538-1.902.585-3.732.7-5.146.428-1.433-.274-2.337-.917-2.594-1.753-.257-.837.129-1.876 1.159-2.909 1.018-1.019 2.596-1.953 4.497-2.538 1.902-.585 3.732-.699 5.146-.428 1.433.275 2.336.918 2.594 1.754Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.75}
    />
    <path
      d='M342 437.06v1h78v-85.971c0-21.57-17.459-39.06-39-39.06s-39 17.49-39 39.06v84.971Z'
      fill='#64B5F6'
    />
    <path
      d='M342 437.06v1h78v-85.971c0-21.57-17.459-39.06-39-39.06s-39 17.49-39 39.06v84.971Z'
      fill='url(#error-500_svg__c)'
    />
    <path
      d='M342 437.06v1h78v-85.971c0-21.57-17.459-39.06-39-39.06s-39 17.49-39 39.06v84.971Z'
      stroke='#484848'
      strokeWidth={2}
    />
    <ellipse cx={381} cy={352.089} rx={29.163} ry={29.209} fill='#484848' />
    <ellipse cx={381} cy={355.629} rx={23.86} ry={23.898} fill='#fff' />
    <ellipse cx={381} cy={355.629} rx={23.86} ry={23.898} fill='#fff' />
    <ellipse
      rx={8.742}
      ry={8.755}
      transform='scale(-1 1) rotate(13.044 -1716.17 -1443.12)'
      fill='#484848'
    />
    <path
      d='M362.476 347.833a2.916 2.916 0 0 1 2.18-3.501 2.916 2.916 0 0 1 3.497 2.186 2.915 2.915 0 0 1-2.18 3.5 2.915 2.915 0 0 1-3.497-2.185ZM369.031 350.309a.972.972 0 1 1 1.893-.44.972.972 0 0 1-1.893.44Z'
      fill='#fff'
    />
    <defs>
      <linearGradient
        id='error-500_svg__a'
        x1={124.227}
        y1={202.591}
        x2={187.614}
        y2={265.562}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-500_svg__b'
        x1={147.044}
        y1={227.361}
        x2={196.86}
        y2={275.235}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-500_svg__c'
        x1={381}
        y1={262.536}
        x2={381}
        y2={403.582}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgError500);
export default ForwardRef;
