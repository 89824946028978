// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgError404(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width={438}
      height={616}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <ellipse
        opacity={0.03}
        cx={219}
        cy={219.172}
        rx={219}
        ry={219.172}
        fill='#5CD6FF'
      />
      <ellipse
        opacity={0.1}
        cx={219}
        cy={213.379}
        rx={204.529}
        ry={204.69}
        fill='#5CD6FF'
      />
      <path
        d='M146.882 130.079c0-39.846 32.137-72.148 71.779-72.148 39.642 0 71.778 32.302 71.778 72.148v39.369H146.882v-39.369Z'
        fill='#fff'
      />
      <path
        d='M192.352 165.091v.875H246.1v-59.01c0-14.864-12.031-26.916-26.874-26.916-14.844 0-26.874 12.052-26.874 26.916v58.135Z'
        fill='#64B5F6'
      />
      <path
        d='M192.352 165.091v.875H246.1v-59.01c0-14.864-12.031-26.916-26.874-26.916-14.844 0-26.874 12.052-26.874 26.916v58.135Z'
        fill='url(#error-404_svg__a)'
      />
      <path
        d='M192.352 165.091v.875H246.1v-59.01c0-14.864-12.031-26.916-26.874-26.916-14.844 0-26.874 12.052-26.874 26.916v58.135Z'
        stroke='#484848'
        strokeWidth={1.752}
      />
      <ellipse
        cx={219.226}
        cy={106.956}
        rx={19.952}
        ry={19.984}
        fill='#484848'
      />
      <ellipse cx={219.226} cy={109.378} rx={16.325} ry={16.35} fill='#fff' />
      <ellipse cx={219.226} cy={109.378} rx={16.325} ry={16.35} fill='#fff' />
      <ellipse cx={219.226} cy={119.067} rx={5.442} ry={5.45} fill='#484848' />
      <path
        d='M223.458 116.645a1.816 1.816 0 0 0-1.813-1.817 1.816 1.816 0 0 0-1.814 1.817c0 1.003.812 1.817 1.814 1.817a1.816 1.816 0 0 0 1.813-1.817ZM219.831 119.067a.605.605 0 1 0-1.21 0 .605.605 0 0 0 1.21 0Z'
        fill='#fff'
      />
      <path
        d='M146.007 169.448v.876h145.308v-40.245c0-40.326-32.524-73.024-72.654-73.024-40.13 0-72.654 32.699-72.654 73.024v39.369Z'
        stroke='#484848'
        strokeWidth={1.752}
      />
      <path
        opacity={0.21}
        d='M146.643 127.268c0-38.294 32.18-69.337 71.875-69.337s71.874 31.043 71.874 69.337v37.835H146.643v-37.835Z'
        fill='url(#error-404_svg__b)'
      />
      <path
        d='M218.661 233.158c45.773 0 87.24-4.121 117.286-10.797 15.016-3.337 27.228-7.322 35.707-11.776 4.238-2.226 7.587-4.592 9.886-7.089 2.303-2.501 3.596-5.183 3.596-8.008 0-2.812-1.28-5.675-3.558-8.501-2.282-2.829-5.613-5.683-9.847-8.497-8.469-5.629-20.676-11.18-35.702-16.08-30.062-9.802-71.554-17.047-117.368-17.047-45.814 0-87.306 7.245-117.368 17.047-15.026 4.9-27.233 10.451-35.702 16.08-4.234 2.814-7.565 5.668-9.847 8.497-2.278 2.826-3.558 5.689-3.558 8.501 0 2.825 1.293 5.507 3.596 8.008 2.299 2.497 5.648 4.863 9.886 7.089 8.479 4.454 20.69 8.439 35.707 11.776 30.046 6.676 71.513 10.797 117.286 10.797Z'
        fill='#D7F1FA'
        stroke='#484848'
        strokeWidth={1.752}
      />
      <path
        d='M383.822 195.488c0 2.397-1.091 4.776-3.248 7.119-2.16 2.344-5.366 4.627-9.531 6.815-8.33 4.376-20.413 8.331-35.381 11.656-29.928 6.65-71.294 10.767-117.001 10.767-45.707 0-87.073-4.117-117.001-10.767-14.968-3.325-27.05-7.28-35.381-11.656-4.166-2.188-7.371-4.471-9.53-6.815-2.158-2.343-3.25-4.722-3.25-7.119 0-2.398 1.092-4.777 3.25-7.119 2.159-2.345 5.364-4.628 9.53-6.816 8.33-4.376 20.413-8.33 35.381-11.656 29.928-6.65 71.294-10.766 117.001-10.766 45.707 0 87.073 4.116 117.001 10.766 14.968 3.326 27.051 7.28 35.381 11.656 4.165 2.188 7.371 4.471 9.531 6.816 2.157 2.342 3.248 4.721 3.248 7.119Z'
        fill='#0075C9'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='m155.727 236.568-.015-.046c-.049-.147-.246-.542-.618-1.21-.355-.637-.823-1.445-1.36-2.371l-.007-.012c-1.345-2.321-3.107-5.361-4.461-8.112-.678-1.376-1.266-2.705-1.649-3.85a10.27 10.27 0 0 1-.416-1.615c-.077-.484-.098-.984.014-1.433l8.512 18.649Zm0 0 .02.044m-.02-.044.02.044m0 0c.99 2.148 3.448 3.921 6.852 5.399 3.447 1.496 8.047 2.767 13.595 3.799 11.104 2.065 26.139 3.199 43.649 3.199s32.546-1.134 43.649-3.199c5.548-1.032 10.149-2.303 13.595-3.799 3.404-1.478 5.862-3.251 6.852-5.399l.02-.044m-128.212.044 128.212-.044m0 0 .016-.046m-.016.046.016-.046m0 0c.048-.147.246-.542.618-1.21m-.618 1.21.618-1.21m0 0c.354-.637.822-1.445 1.359-2.371m-1.359 2.371 1.359-2.371m0 0 .007-.012m-.007.012.007-.012m0 0c1.345-2.321 3.107-5.361 4.462-8.112m-4.462 8.112 4.462-8.112m0 0c.677-1.376 1.265-2.705 1.648-3.85m-1.648 3.85 1.648-3.85m0 0a10.28 10.28 0 0 0 .417-1.615m-.417 1.615.417-1.615m0 0c.076-.484.097-.984-.015-1.433m.015 1.433-.015-1.433m0 0c-.281-1.126-1.266-2.078-2.515-2.891m2.515 2.891-2.515-2.891m0 0c-1.287-.838-3.034-1.644-5.139-2.413m5.139 2.413-5.139-2.413m0 0c-4.216-1.54-10.011-2.981-16.786-4.219m16.786 4.219-16.786-4.219m0 0c-13.558-2.477-31.162-4.161-48.188-4.161m48.188 4.161-48.188-4.161m0 0c-17.025 0-34.629 1.684-48.187 4.161m48.187-4.161-48.187 4.161m0 0c-6.776 1.238-12.57 2.679-16.787 4.219m16.787-4.219-16.787 4.219m0 0c-2.105.769-3.852 1.575-5.139 2.413m5.139-2.413-5.139 2.413m0 0c-1.248.813-2.234 1.765-2.515 2.891l2.515-2.891Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={1.752}
      />
      <path
        d='M219.843 248.571c17.498 0 33.352-1.332 44.841-3.489 5.741-1.079 10.414-2.367 13.663-3.809 1.622-.721 2.914-1.49 3.807-2.309.891-.819 1.427-1.728 1.427-2.719 0-.99-.536-1.9-1.427-2.718-.893-.819-2.185-1.589-3.807-2.309-3.249-1.442-7.922-2.731-13.663-3.809-11.489-2.157-27.343-3.489-44.841-3.489-17.499 0-33.352 1.332-44.841 3.489-5.741 1.078-10.415 2.367-13.663 3.809-1.623.72-2.914 1.49-3.807 2.309-.892.818-1.428 1.728-1.428 2.718 0 .991.536 1.9 1.428 2.719.893.819 2.184 1.588 3.807 2.309 3.248 1.442 7.922 2.73 13.663 3.809 11.489 2.157 27.342 3.489 44.841 3.489Z'
        fill='#0078BF'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.35}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M219.791 247.001c34.024 0 61.605-4.816 61.605-10.756s-27.581-10.755-61.605-10.755c-34.023 0-61.605 4.815-61.605 10.755s27.582 10.756 61.605 10.756Zm0 0c32.775 0 59.345-4.562 59.345-10.19 0-5.627-26.57-10.189-59.345-10.189s-59.344 4.562-59.344 10.189c0 5.628 26.569 10.19 59.344 10.19Z'
        fill='#5CD6FF'
      />
      <path
        opacity={0.35}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M219.599 228.835c88.349 0 159.97-14.935 159.97-33.36 0-18.424-71.621-33.36-159.97-33.36s-159.97 14.936-159.97 33.36c0 18.425 71.621 33.36 159.97 33.36Zm0 0c85.107 0 154.099-14.149 154.099-31.604 0-17.454-68.992-31.604-154.099-31.604-85.107 0-154.1 14.15-154.1 31.604 0 17.455 68.993 31.604 154.1 31.604Z'
        fill='#5CD6FF'
      />
      <path
        d='M269.834 237.378c0 .204-.123.552-.711 1.032-.578.471-1.483.965-2.732 1.458-2.49.983-6.145 1.886-10.724 2.65-9.144 1.526-21.811 2.475-35.824 2.475-14.014 0-26.68-.949-35.824-2.475-4.579-.764-8.234-1.667-10.724-2.65-1.249-.493-2.154-.987-2.732-1.458-.588-.48-.711-.828-.711-1.032 0-.205.123-.553.711-1.033.578-.471 1.483-.965 2.732-1.458 2.49-.983 6.145-1.885 10.724-2.65 9.144-1.526 21.81-2.475 35.824-2.475 14.013 0 26.68.949 35.824 2.475 4.579.765 8.234 1.667 10.724 2.65 1.249.493 2.154.987 2.732 1.458.588.48.711.828.711 1.033Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={1.752}
      />
      <path
        d='M86.407 199.888c2.704 0 5.172-.515 6.981-1.368 1.775-.837 3.066-2.079 3.066-3.598 0-1.52-1.29-2.762-3.066-3.599-1.809-.852-4.278-1.368-6.98-1.368-2.704 0-5.172.516-6.981 1.368-1.775.837-3.066 2.079-3.066 3.599 0 1.519 1.29 2.761 3.066 3.598 1.809.853 4.277 1.368 6.98 1.368Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M93.062 191.469v-.297l-.275-.11c-1.736-.695-3.963-1.107-6.38-1.107-2.416 0-4.644.412-6.38 1.107l-.274.11v3.75a6.66 6.66 0 0 0 6.654 6.664 6.66 6.66 0 0 0 6.655-6.664v-3.453Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92.624 194.922a6.222 6.222 0 0 1-6.216 6.227c2.26 0 2.825-1.887 2.825-2.831v-7.726c1.26.183 2.41.484 3.391.877v3.453Z'
        fill='#fff'
      />
      <path
        d='M118.623 213.534c1.615 0 3.098-.331 4.194-.885 1.072-.542 1.896-1.371 1.896-2.413 0-1.043-.824-1.872-1.896-2.414-1.096-.554-2.579-.885-4.194-.885-1.615 0-3.098.331-4.194.885-1.071.542-1.896 1.371-1.896 2.414 0 1.042.825 1.871 1.896 2.413 1.096.554 2.579.885 4.194.885Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M122.718 208.055v-.289l-.265-.114c-1.048-.45-2.385-.715-3.83-.715-1.444 0-2.781.265-3.83.715l-.265.114v2.47c0 2.383 1.804 4.37 4.095 4.37 2.291 0 4.095-1.987 4.095-4.37v-2.181Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M122.28 210.236c0 2.172-1.637 3.932-3.657 3.932 1.33 0 1.662-1.191 1.662-1.787v-4.88a8.31 8.31 0 0 1 1.995.554v2.181Z'
        fill='#fff'
      />
      <path
        d='M320.959 213.534c1.615 0 3.099-.331 4.195-.885 1.071-.542 1.895-1.371 1.895-2.413 0-1.043-.824-1.872-1.895-2.414-1.096-.554-2.58-.885-4.195-.885-1.614 0-3.098.331-4.194.885-1.071.542-1.895 1.371-1.895 2.414 0 1.042.824 1.871 1.895 2.413 1.096.554 2.58.885 4.194.885Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M325.055 208.055v-.289l-.265-.114c-1.049-.45-2.386-.715-3.83-.715-1.445 0-2.782.265-3.83.715l-.265.114v2.47c0 2.383 1.804 4.37 4.095 4.37 2.291 0 4.095-1.987 4.095-4.37v-2.181Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M324.617 210.236c0 2.172-1.638 3.932-3.657 3.932 1.329 0 1.662-1.191 1.662-1.787v-4.88a8.323 8.323 0 0 1 1.995.554v2.181Z'
        fill='#fff'
      />
      <path
        d='M350.914 199.888c2.703 0 5.172-.515 6.981-1.368 1.775-.837 3.065-2.079 3.065-3.598 0-1.52-1.29-2.762-3.065-3.599-1.809-.852-4.278-1.368-6.981-1.368-2.703 0-5.171.516-6.98 1.368-1.775.837-3.066 2.079-3.066 3.599 0 1.519 1.291 2.761 3.066 3.598 1.809.853 4.277 1.368 6.98 1.368Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M357.569 191.469v-.297l-.275-.11c-1.735-.695-3.962-1.107-6.379-1.107-2.417 0-4.644.412-6.38 1.107l-.275.11v3.75a6.659 6.659 0 0 0 6.655 6.664c3.676 0 6.654-2.984 6.654-6.664v-3.453Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M357.132 194.922a6.222 6.222 0 0 1-6.217 6.227c2.26 0 2.825-1.887 2.825-2.831v-7.726c1.261.183 2.411.484 3.392.877v3.453Z'
        fill='#fff'
      />
      <path
        d='M293.831 187.554c3.482 0 6.655-.649 8.975-1.716 2.282-1.05 3.897-2.588 3.897-4.443 0-1.854-1.615-3.393-3.897-4.442-2.32-1.067-5.493-1.716-8.975-1.716-3.482 0-6.656.649-8.976 1.716-2.281 1.049-3.896 2.588-3.896 4.442 0 1.855 1.615 3.393 3.896 4.443 2.32 1.067 5.494 1.716 8.976 1.716Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M302.314 177.034v-.299l-.278-.109c-2.228-.871-5.092-1.389-8.205-1.389-3.114 0-5.978.518-8.205 1.389l-.279.109v4.66c0 4.596 3.808 8.304 8.484 8.304s8.483-3.708 8.483-8.304v-4.361Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M301.876 181.395c0 4.344-3.602 7.866-8.045 7.866 2.925 0 3.657-2.384 3.657-3.575v-9.76c1.631.231 3.119.612 4.388 1.108v4.361Z'
        fill='#fff'
      />
      <path
        d='M145.752 187.554c3.482 0 6.656-.649 8.975-1.716 2.282-1.05 3.897-2.588 3.897-4.443 0-1.854-1.615-3.393-3.897-4.442-2.319-1.067-5.493-1.716-8.975-1.716-3.482 0-6.656.649-8.975 1.716-2.282 1.049-3.897 2.588-3.897 4.442 0 1.855 1.615 3.393 3.897 4.443 2.319 1.067 5.493 1.716 8.975 1.716Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M154.235 177.034v-.299l-.278-.109c-2.228-.871-5.092-1.389-8.205-1.389-3.113 0-5.977.518-8.205 1.389l-.278.109v4.66c0 4.596 3.807 8.304 8.483 8.304s8.483-3.708 8.483-8.304v-4.361Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M153.798 181.395c0 4.344-3.603 7.866-8.046 7.866 2.926 0 3.657-2.384 3.657-3.575v-9.76c1.631.231 3.12.612 4.389 1.108v4.361Z'
        fill='#fff'
      />
      <path
        d='M220.357 182.667c4.105 0 7.843-.754 10.571-1.993 2.689-1.221 4.561-2.998 4.561-5.118 0-2.121-1.872-3.897-4.561-5.118-2.728-1.239-6.466-1.994-10.571-1.994-4.106 0-7.844.755-10.572 1.994-2.689 1.221-4.561 2.997-4.561 5.118 0 2.12 1.872 3.897 4.561 5.118 2.728 1.239 6.466 1.993 10.572 1.993Z'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        d='M230.303 170.467v-.3l-.28-.108c-2.622-1.012-5.996-1.615-9.666-1.615-3.671 0-7.045.603-9.666 1.615l-.281.108v5.389c0 5.324 4.468 9.614 9.947 9.614 5.478 0 9.946-4.29 9.946-9.614v-5.089Z'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={0.876}
      />
      <path
        opacity={0.42}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M229.865 175.556c0 5.068-4.257 9.176-9.508 9.176 3.457 0 4.322-2.781 4.322-4.171v-11.386c1.927.269 3.686.714 5.186 1.292v5.089Z'
        fill='#fff'
      />
      <g opacity={0.65}>
        <path
          opacity={0.42}
          d='m169.526 239.205.005-.022v-.024c0-.488.295-1.001.945-1.532.647-.529 1.614-1.05 2.881-1.551 2.532-1.002 6.209-1.909 10.767-2.672 9.115-1.526 21.714-2.47 35.637-2.47 13.99 0 26.65.944 35.808 2.47 4.581.763 8.276 1.67 10.82 2.672 1.273.501 2.245 1.022 2.896 1.551.653.532.949 1.045.949 1.532v.024l.005.023 77.158 354.961H92.739l76.787-354.962Z'
          fill='url(#error-404_svg__c)'
          stroke='url(#error-404_svg__d)'
          strokeWidth={0.438}
        />
        <path
          opacity={0.65}
          d='m186.058 241.043.004-.024v-.024c0-.97.807-1.99 2.518-2.986 1.688-.983 4.153-1.879 7.227-2.635 6.143-1.513 14.648-2.451 24.053-2.451 9.451 0 17.996.939 24.17 2.451 3.089.757 5.566 1.653 7.262 2.635 1.721.997 2.531 2.017 2.531 2.986v.024l.004.024 52.178 353.015H134.13l51.928-353.015Z'
          fill='url(#error-404_svg__e)'
          stroke='url(#error-404_svg__f)'
          strokeWidth={0.657}
        />
        <path
          d='m203.71 243.78.002-.015v-.016c0-2.047 1.672-4.017 4.646-5.502 2.949-1.473 7.052-2.396 11.608-2.396 4.579 0 8.703.924 11.666 2.396 2.99 1.486 4.669 3.457 4.669 5.502v.016l.002.016 25.515 350.168h-83.5L203.71 243.78Z'
          fill='url(#error-404_svg__g)'
          stroke='url(#error-404_svg__h)'
          strokeWidth={0.876}
        />
      </g>
      <path
        transform='rotate(-29.05 901.857 -1.427) skewX(-.077)'
        fill='#64B5F6'
        stroke='#484848'
        strokeWidth={1.752}
        d='M1.19.341h62.283v62.336H1.19z'
      />
      <path
        transform='rotate(11.272 -1938.895 1273.26) skewX(.035)'
        fill='#fff'
        stroke='#484848'
        strokeWidth={0.876}
        d='M.344.515h12.293v12.313H.344z'
      />
      <path
        transform='rotate(-55.71 397.69 -38.464) skewX(-.084)'
        fill='#96CCF9'
        stroke='#484848'
        strokeWidth={0.876}
        d='M.608-.115h38.154v38.131H.608z'
      />
      <path
        transform='rotate(27.54 -871.437 793.775) skewX(.074)'
        fill='#0075C9'
        stroke='#484848'
        strokeWidth={1.314}
        d='M.279.886h37.113v37.148H.279z'
      />
      <g opacity={0.65} fill='#fff' stroke='#484848'>
        <path
          opacity={0.2}
          d='M347.45 591.567c0 1.604-.848 3.197-2.523 4.764-1.676 1.568-4.159 3.09-7.376 4.547-6.434 2.913-15.758 5.541-27.297 7.751-23.074 4.418-54.96 7.152-90.186 7.152-35.226 0-67.112-2.734-90.186-7.152-11.539-2.21-20.863-4.838-27.297-7.751-3.217-1.457-5.7-2.979-7.375-4.547-1.676-1.567-2.523-3.16-2.523-4.764 0-1.603.847-3.196 2.523-4.764 1.675-1.568 4.158-3.09 7.375-4.547 6.434-2.912 15.758-5.541 27.297-7.75 23.074-4.418 54.96-7.153 90.186-7.153 35.226 0 67.112 2.735 90.186 7.153 11.539 2.209 20.863 4.838 27.297 7.75 3.217 1.457 5.7 2.979 7.376 4.547 1.675 1.568 2.523 3.161 2.523 4.764Z'
          strokeWidth={0.438}
        />
        <path
          opacity={0.5}
          d='M306.057 589.218c0 1.082-.542 2.174-1.655 3.27-1.114 1.096-2.774 2.169-4.941 3.2-4.332 2.063-10.623 3.93-18.422 5.501-15.593 3.141-37.15 5.085-60.971 5.085-23.821 0-45.378-1.944-60.971-5.085-7.8-1.571-14.09-3.438-18.423-5.501-2.167-1.031-3.827-2.104-4.94-3.2-1.113-1.096-1.656-2.188-1.656-3.27 0-1.082.543-2.174 1.656-3.27s2.773-2.169 4.94-3.2c4.333-2.064 10.623-3.93 18.423-5.501 15.593-3.141 37.15-5.085 60.971-5.085 23.821 0 45.378 1.944 60.971 5.085 7.799 1.571 14.09 3.437 18.422 5.501 2.167 1.031 3.827 2.104 4.941 3.2 1.113 1.096 1.655 2.188 1.655 3.27Z'
          strokeWidth={0.657}
        />
        <path
          d='M261.851 586.399c0-1.247-.996-2.555-3.107-3.832-2.084-1.261-5.129-2.413-8.93-3.386-7.596-1.944-18.113-3.151-29.746-3.151s-22.15 1.207-29.746 3.151c-3.801.973-6.846 2.125-8.93 3.386-2.111 1.277-3.107 2.585-3.107 3.832 0 1.247.996 2.554 3.107 3.832 2.084 1.261 5.129 2.412 8.93 3.385 7.596 1.944 18.113 3.152 29.746 3.152s22.15-1.208 29.746-3.152c3.801-.973 6.846-2.124 8.93-3.385 2.111-1.278 3.107-2.585 3.107-3.832Z'
          strokeWidth={0.876}
        />
      </g>
      <defs>
        <linearGradient
          id='error-404_svg__a'
          x1={219.226}
          y1={45.686}
          x2={219.226}
          y2={142.186}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__b'
          x1={218.518}
          y1={86.22}
          x2={218.518}
          y2={155.311}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__c'
          x1={220.068}
          y1={230.715}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__d'
          x1={220.068}
          y1={230.715}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#484848' />
          <stop offset={1} stopColor='#484848' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__e'
          x1={220.068}
          y1={232.594}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__f'
          x1={220.068}
          y1={232.594}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#484848' />
          <stop offset={1} stopColor='#484848' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__g'
          x1={220.068}
          y1={235.413}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='error-404_svg__h'
          x1={220.068}
          y1={235.413}
          x2={220.068}
          y2={594.386}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#484848' />
          <stop offset={1} stopColor='#484848' stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = forwardRef(SvgError404);
export default ForwardRef;
