import { classnames } from '@library/utils';
import { Column, HeaderContext, Table } from '@tanstack/react-table';
import { ColumnFilter } from './column-filter';
import { ColumnHeaderName } from './column-header-name';
import { CHECKBOX_COLUMN_ID, commonCellClasses } from './helpers';

interface ColumnHeaderProps<TableData> {
  table: Table<TableData>;
  column: Column<TableData>;
  context: HeaderContext<TableData, unknown>;
}

function ColumnHeader<TableData>({
  table,
  column,
  context
}: ColumnHeaderProps<TableData>) {
  return (
    <div className='flex items-center justify-between'>
      <ColumnHeaderName table={table} column={column} context={context} />
      <ColumnFilter table={table} column={column} />
    </div>
  );
}

interface TableHeaderProps<TableData> {
  table: Table<TableData>;
}

function TableHeader<TableData>({ table }: TableHeaderProps<TableData>) {
  const headerGroups = table.getHeaderGroups();

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              className={classnames(
                commonCellClasses,
                // Hack to remove the weird jitter that appears when scrolling
                // normally this would be top-0, but there is a strange half-pixel
                // where content can be seen behind the header while scrolling
                // that primarily appears on tables in modals.
                // https://twitter.com/youyuxi/status/1436026679059787781?lang=en
                'sticky top-[-0.5px] border-b border-neutral bg-content',
                'text-left font-bold',
                header.id === CHECKBOX_COLUMN_ID ? 'w-4' : null
              )}
            >
              <ColumnHeader
                table={table}
                column={header.column}
                context={header.getContext()}
              />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

export { TableHeader };
