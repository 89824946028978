import {
  RedeemingMerchantAnnotationProductCategoryAnnotation,
  RedeemingMerchantAnnotationPurchaseChannelAnnotation
} from '@library/models/redeeming-merchant-annotation';
import { AdapterRewardAnnotation } from '@library/models/reward';

import { PublisherLocaleCode, classnames } from '@library/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { createContext, useContext } from 'react';
import { CallToAction, RewardDetails, TermsAndConditions } from './ad-copy';
import { BrandImage } from './brand-image';
import { Logo } from './logo';
import { Annotations, RewardInfo } from './reward-info';

import { CurrencyCode } from '@models/institution';

interface AdPreviewValues {
  brandImageSrc?: string;
  logoImageSrc?: string;
  merchantName?: string;
  minSpend?: number | null;
  productCategoryAnnotations?:
    | RedeemingMerchantAnnotationProductCategoryAnnotation[]
    | null;
  purchaseChannelAnnotations?:
    | RedeemingMerchantAnnotationPurchaseChannelAnnotation[]
    | null;
  rewardAnnotation?: AdapterRewardAnnotation;
  callToAction?: string;
  callToActionUrl?: string;
  headline?: string;
  rewardDetails?: string;
  marketingCopy?: string;
  termsAndConditions?: string;
  rewardCapMessage?: string;
  expiresMessage?: string;
  locale: PublisherLocaleCode;
  currency: CurrencyCode;
}

/*
 * Get rid of the nullable values and the files, which we will convert to
 * data URLs before passing through.
 */
type AdPreviewContextValue = Omit<
  Required<AdPreviewValues>,
  | 'annotatedLogoImageFile'
  | 'brandImageFile'
  | 'logoImageFile'
  | 'minSpend'
  | 'rewardAmount'
  | 'rewardAnnotation'
  | 'productCategoryAnnotations'
  | 'purchaseChannelAnnotations'
> & {
  minSpend: number;
  rewardAnnotation: AdapterRewardAnnotation;
  rewardCapMessage: string;
  expiresMessage: string;
  productCategoryAnnotations: RedeemingMerchantAnnotationProductCategoryAnnotation[];
  purchaseChannelAnnotations: RedeemingMerchantAnnotationPurchaseChannelAnnotation[];
};

const AdPreviewContext = createContext<AdPreviewContextValue | undefined>(
  undefined
);

function useAdPreviewValues() {
  const context = useContext(AdPreviewContext);
  if (context === undefined) {
    throw new Error('useAdPreview can only be used within an AdPreviewContext');
  }
  return context;
}

function Preview() {
  const { brandImageSrc, merchantName, logoImageSrc } = useAdPreviewValues();

  const brandImageSrcToLoad = brandImageSrc;

  return (
    <div className='w-adPreview rounded-[30px] border-2 border-frame bg-content'>
      <BrandImage src={brandImageSrc} alt={merchantName} />
      <div className='flex flex-col gap-6 px-6 pb-6'>
        <div
          className={classnames(
            'flex items-center gap-2',
            brandImageSrcToLoad ? null : 'pt-10'
          )}
        >
          <div
            className={classnames(
              'overflow-hidden rounded-md shadow-md',
              brandImageSrcToLoad ? '-mt-10 ' : null
            )}
          >
            <Logo src={logoImageSrc} alt={merchantName} />
          </div>
          <div className='flex-1 break-words text-lg'>{merchantName}</div>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center gap-0.5'>
            <CheckCircleIcon className='h-6 w-6 text-positive' />
            <span className='font-bold'>Activated</span>
          </div>
          <div>
            <RewardInfo />
            <Annotations />
          </div>
          <RewardDetails />
          <CallToAction />
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

function AdPreview({
  brandImageSrc = '',
  logoImageSrc = '',
  merchantName = '',
  minSpend,
  productCategoryAnnotations,
  purchaseChannelAnnotations,
  rewardAnnotation = 'None',
  callToAction = '',
  callToActionUrl = '',
  headline = '',
  rewardDetails = '',
  rewardCapMessage = '',
  expiresMessage = '',
  marketingCopy = '',
  termsAndConditions = '',
  ...delegated
}: AdPreviewValues) {
  /*
   * Some fields can be null (instead of undefined) for API or form reasons,
   * so we set the defaults down here instead of the destructuring above.
   */
  const value = {
    brandImageSrc,
    logoImageSrc,
    merchantName,
    minSpend: minSpend || 0,
    productCategoryAnnotations: productCategoryAnnotations || [],
    purchaseChannelAnnotations: purchaseChannelAnnotations || [],
    rewardAnnotation,
    callToAction,
    callToActionUrl,
    rewardCapMessage,
    expiresMessage,
    headline,
    rewardDetails,
    marketingCopy,
    termsAndConditions,
    ...delegated
  };

  return (
    <AdPreviewContext.Provider value={value}>
      <div className='flex w-adPreview flex-col items-center gap-4'>
        <Preview />
      </div>
    </AdPreviewContext.Provider>
  );
}

export { AdPreview, useAdPreviewValues };
export type { AdPreviewValues };
