import { dateFormatters, dateTimeToDate } from '@library/utils';
import { z } from 'zod';
import {
  OpportunityCurrencyIsoCode,
  OpportunityPricingModel,
  opportunityCurrencyIsoCodeSchema,
  opportunityPricingModelSchema
} from '../opportunity';

const campaignPricingModelSchema = opportunityPricingModelSchema;
type CampaignPricingModel = OpportunityPricingModel;

const campaignCurrencySchema = opportunityCurrencyIsoCodeSchema;
type CampaignCurrency = OpportunityCurrencyIsoCode;

const CAMPAIGN_ORDER_BY_FIELDS = [
  'budget',
  'campaignId',
  'endDateTime',
  'name',
  'startDateTime'
] as const;
const campaignOrderByFieldSchema = z.enum(CAMPAIGN_ORDER_BY_FIELDS);
type CampaignOrderByField = z.infer<typeof campaignOrderByFieldSchema>;

const CAMPAIGN_STATUSES = [
  'Active',
  'Draft',
  'InReview',
  'Inactive',
  'Scheduled'
] as const;
const campaignStatusSchema = z.enum(CAMPAIGN_STATUSES);
type CampaignStatus = z.infer<typeof campaignStatusSchema>;

const CAMPAIGN_SCHEDULE_TYPES = ['Lifetime', 'Daily'] as const;
const campaignScheduleTypeSchema = z.enum(CAMPAIGN_SCHEDULE_TYPES);
type CampaignScheduleType = z.infer<typeof campaignScheduleTypeSchema>;

const CAMPAIGN_OBJECTIVES = [
  'DriveSales',
  'BuildLoyalty',
  'AcquireNewCustomers'
] as const;
const campaignObjectiveSchema = z.enum(CAMPAIGN_OBJECTIVES);
type CampaignObjective = z.infer<typeof campaignObjectiveSchema>;

const CAMPAIGN_TYPES = [
  'FIXED_CLO', // legacy static marketplace card level offers/ BAU
  'DYNAMIC_CLO' // dynamic marketplace troas / phoenix card level offers
  // 'CHECKOUT_PLUS', // future thankyou savings / checkout+
  // 'RLO' // future receipt level offers
] as const;
const campaignTypeSchema = z.enum(CAMPAIGN_TYPES);
type CampaignType = z.infer<typeof campaignTypeSchema>;

const campaignSchema = z.object({
  accountId: z.string(),
  // we aren't using the default values typing for below because this field needs to support arbitrary numbers
  attributionWindow: z.number().nullable(),
  budget: z.number(),
  campaignId: z.number(),
  campaignObjective: campaignObjectiveSchema,
  campaignPricingModel: campaignPricingModelSchema,
  campaignStatus: campaignStatusSchema,
  campaignType: campaignTypeSchema,
  createDateTime: z.string(),
  createUser: z.string(),
  currency: campaignCurrencySchema,
  endDateTime: z.string(),
  isOfferAutoActivated: z.boolean(),
  name: z.string(),
  orderNumber: z.string(),
  scheduleType: campaignScheduleTypeSchema,
  startDateTime: z.string(),
  targetRoas: z.number().nullable(),
  transactionDateTime: z.string(),
  updateDateTime: z.string(),
  updateUser: z.string()
});
type Campaign = z.infer<typeof campaignSchema>;

type DynamicCampaign = Omit<
  Campaign,
  'campaignType' | 'attributionWindow' | 'targetRoas'
> & {
  campaignType: 'DYNAMIC_CLO';
  attributionWindow: number;
  targetRoas: number;
};

type FixedCampaign = Omit<Campaign, 'campaignType' | 'attributionWindow'> & {
  campaignType: 'FIXED_CLO';
  attributionWindow: null;
};

function isDynamicCampaign(campaign: Campaign): campaign is DynamicCampaign {
  return campaign.campaignType === 'DYNAMIC_CLO';
}

function isFixedCampaign(campaign: Campaign): campaign is FixedCampaign {
  return campaign.campaignType === 'FIXED_CLO';
}

type CampaignIdentifiers = Pick<Campaign, 'campaignId' | 'accountId'>;

type CampaignVariables = Pick<
  Campaign,
  | 'attributionWindow'
  | 'budget'
  | 'campaignObjective'
  | 'campaignPricingModel'
  | 'campaignType'
  | 'currency'
  | 'endDateTime'
  | 'isOfferAutoActivated'
  | 'name'
  | 'orderNumber'
  | 'scheduleType'
  | 'startDateTime'
  | 'targetRoas'
>;

type CampaignCreateVariables = {
  variables: CampaignVariables;
  accountId: string;
};

type CampaignUpdateVariables = {
  variables: CampaignVariables;
  original: Campaign;
};

type CampaignOptimizeVariables = {
  variables: Pick<Campaign, 'budget' | 'targetRoas'>;
  original: Campaign;
};

type CampaignStatusUpdateVariables = {
  status: CampaignStatus;
};

function campaignToApi(campaign: CampaignVariables) {
  return {
    ...campaign,
    endDateTime: `${dateFormatters.isoDate(
      campaign.endDateTime
    )}T23:59:59.0000000`,
    startDateTime: `${dateFormatters.isoDate(
      campaign.startDateTime
    )}T00:00:00.0000000`
  };
}

function campaignToUi(campaign: Campaign) {
  return {
    ...campaign,
    endDateTime: dateTimeToDate(campaign.endDateTime),
    startDateTime: dateTimeToDate(campaign.startDateTime)
  };
}

function isAttributionWindowValidForCampaignType(campaignType: CampaignType) {
  return campaignType === 'DYNAMIC_CLO';
}

function isTargetRoasValidForCampaignProperties({
  campaignObjective,
  campaignPricingModel,
  campaignType
}: {
  campaignObjective: CampaignObjective;
  campaignPricingModel: CampaignPricingModel;
  campaignType: CampaignType;
}) {
  if (campaignType === 'DYNAMIC_CLO') {
    return true;
  }

  if (campaignPricingModel === 'CPM' && campaignObjective === 'DriveSales') {
    return true;
  }

  return false;
}

function isTargetRoasValidForCampaign(campaign: Campaign | undefined) {
  if (!campaign) {
    return false;
  }

  return isTargetRoasValidForCampaignProperties({
    campaignObjective: campaign.campaignObjective,
    campaignPricingModel: campaign.campaignPricingModel,
    campaignType: campaign.campaignType
  });
}

const campaignStructureEntityTypeSchema = z.enum([
  'entity.campaign',
  'entity.adGroup',
  'entity.ad'
]);
type CampaignStructureEntityType = z.infer<
  typeof campaignStructureEntityTypeSchema
>;

const DEFAULT_ATTRIBUTION_WINDOW_VALUES: number[] = [7, 14, 28];

export * from './definitions';
export {
  CAMPAIGN_OBJECTIVES,
  CAMPAIGN_ORDER_BY_FIELDS,
  CAMPAIGN_SCHEDULE_TYPES,
  CAMPAIGN_STATUSES,
  CAMPAIGN_TYPES,
  DEFAULT_ATTRIBUTION_WINDOW_VALUES,
  campaignCurrencySchema,
  campaignObjectiveSchema,
  campaignPricingModelSchema,
  campaignScheduleTypeSchema,
  campaignSchema,
  campaignStatusSchema,
  campaignStructureEntityTypeSchema,
  campaignToApi,
  campaignToUi,
  campaignTypeSchema,
  isAttributionWindowValidForCampaignType,
  isDynamicCampaign,
  isFixedCampaign,
  isTargetRoasValidForCampaign,
  isTargetRoasValidForCampaignProperties
};
export type {
  Campaign,
  CampaignCreateVariables,
  CampaignCurrency,
  CampaignIdentifiers,
  CampaignObjective,
  CampaignOptimizeVariables,
  CampaignOrderByField,
  CampaignPricingModel,
  CampaignScheduleType,
  CampaignStatus,
  CampaignStatusUpdateVariables,
  CampaignStructureEntityType,
  CampaignType,
  CampaignUpdateVariables,
  CampaignVariables,
  DynamicCampaign,
  FixedCampaign
};
