import { classnames } from '@library/utils';
import { BadgeVariant, BadgeSize } from './types';

const variantClasses = {
  'variant.neutral': ['bg-neutral'],
  'variant.strong': ['bg-strong', 'text-inverse'],
  'variant.success': ['bg-success', 'text-inverse'],
  'variant.info': ['bg-info', 'text-inverse'],
  'variant.warning': ['bg-warning'],
  'variant.dosh': ['bg-purple', 'text-inverse'],
  'variant.primary': ['bg-blue-action', 'text-inverse'],
  'variant.callout': ['bg-callout'],
  'variant.outline': ['bg-content', 'border', 'border-neutral'],
  'variant.error': ['bg-error', 'text-inverse']
} satisfies Record<BadgeVariant, string[]>;

const sizeClasses = {
  'size.sm': ['text-xxs'],
  'size.md': ['text-xs']
} satisfies Record<BadgeSize, string[]>;

interface BadgeProps {
  variant?: BadgeVariant;
  text: string;
  size?: BadgeSize;
}

const Badge = ({
  variant = 'variant.neutral',
  text,
  size = 'size.md'
}: BadgeProps) => (
  <div
    className={classnames(
      ['rounded-md', 'py-1', 'px-2', 'uppercase', 'whitespace-nowrap'],
      variantClasses[variant],
      sizeClasses[size]
    )}
  >
    {text}
  </div>
);

export { Badge };
export type { BadgeProps };
