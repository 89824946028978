import {
  dateFormatters,
  dateTimeToDate,
  getParsedDateTime
} from '@library/utils';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { Campaign, campaignCurrencySchema } from '../campaign';
import { CREATIVE_REWARD_TYPES, CreativeRewardType } from '../creative';
import {
  AdapterMerchantCategoryCode,
  adapterMerchantCategoryCodeSchema
} from '../merchant-category-code';
import { AdapterRewardTemplate } from '../reward-template';
import {
  ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP,
  rewardTypeDef
} from './definitions';

const rewardCurrencySchema = campaignCurrencySchema;

const CAMPAIGN_BUILD_API_REWARD_TYPES = [
  'SingleRewardPercentAmountBack',
  'SingleRewardFlatAmountBack',
  'SingleRewardSpendXGetY',
  'MultipleRewardPercentAmountBack'
] as const;
const campaignBuildApiRewardTypeSchema = z.enum(
  CAMPAIGN_BUILD_API_REWARD_TYPES
);
type CampaignBuildApiRewardType =
  (typeof CAMPAIGN_BUILD_API_REWARD_TYPES)[number];

/*
 * "0" (the number, not the string) can occasionally be returned by the
 * backend under specific circumstances. The UI will not deal with this
 * type directly because if a reward does have that type, it means it's
 * the kind of reward that's going to go through a specific transformation
 * to change said reward type. We thus split raw reward types (which have
 * "0") from our "normal" reward types (which do not have "0") for ease of
 * typing.
 */
const campaignBuildApiRawRewardTypeSchema = z
  .enum(CAMPAIGN_BUILD_API_REWARD_TYPES)
  .or(z.literal(0));

const ADAPTER_REWARD_TYPES = [
  ...CAMPAIGN_BUILD_API_REWARD_TYPES,
  'MccSingleRewardPercentAmountBack',
  'MccSingleRewardFlatAmountBack',
  'CustomRewardRedemption'
] as const;
const adapterRewardTypeSchema = z.enum(ADAPTER_REWARD_TYPES);
type AdapterRewardType = (typeof ADAPTER_REWARD_TYPES)[number];

const API_REWARD_ANNOTATIONS = ['FlashDeal', 'ReserveExcl'] as const;
const apiRewardAnnotationSchema = z.enum(API_REWARD_ANNOTATIONS);
type ApiRewardAnnotation = (typeof API_REWARD_ANNOTATIONS)[number];

const ADAPTER_REWARD_ANNOTATIONS = ['None', ...API_REWARD_ANNOTATIONS] as const;
const adapterRewardAnnotationSchema = z.enum(ADAPTER_REWARD_ANNOTATIONS);
type AdapterRewardAnnotation = (typeof ADAPTER_REWARD_ANNOTATIONS)[number];

const CREATIVE_REWARD_TYPE_TO_ADAPTER_REWARD_TYPE_MAP = {
  PercentBack: new Set<AdapterRewardType>(),
  FlatAmountBack: new Set<AdapterRewardType>(),
  SpendXGetY: new Set<AdapterRewardType>()
} satisfies Record<CreativeRewardType, Set<AdapterRewardType>>;

// Programmatically make this map so that future reward types don't get missed
for (const [rewardType, creativeRewardType] of Object.entries(
  ADAPTER_REWARD_TYPE_TO_CREATIVE_REWARD_TYPE_MAP
)) {
  const set =
    CREATIVE_REWARD_TYPE_TO_ADAPTER_REWARD_TYPE_MAP[creativeRewardType];
  set.add(rewardType as AdapterRewardType);
}

/*
 * "None" is a value returned by the campaign build API for similar reasons as
 * 0 in reward type (see comment for campaignBuildApiRawRewardTypeSchema),
 * but it will always get converted to be something else. As such, while this
 * type exists to prevent schema errors, we will never use it outside of this
 * file and will stick with creative reward types for the most part.
 */
const CAMPAIGN_BUILD_REWARD_TEMPLATES = [
  ...CREATIVE_REWARD_TYPES,
  'None'
] as const;
const campaignBuildRewardTemplateSchema = z.enum(
  CAMPAIGN_BUILD_REWARD_TEMPLATES
);

const apiRewardModelConfigValueSchema = z.object({
  currencyUnit: rewardCurrencySchema,
  mccList: z.array(z.number()).optional(),
  minSpend: z.number(),
  rewardAmount: z.number().optional(),
  rewardCap: z.number(),
  rewardPercent: z.number().optional()
});

type ApiRewardModelConfigValue = z.infer<
  typeof apiRewardModelConfigValueSchema
>;

const apiRewardAttributesSchema = z.object({
  endDateOffset: z.number(),
  isManualRedemption: z.boolean(),
  isSuspendedProcessing: z.boolean(),
  redemptionEndDateTime: z.string().optional(),
  redemptionStartDateTime: z.string(),
  remediationModelConfigValue: z.null(),
  rewardAnnotations: z.array(apiRewardAnnotationSchema).nullable(),
  // Should be a stringified JSON object of type apiRewardModelConfigValue
  rewardModelConfigValue: z.string()
});

type ApiRewardAttributes = z.infer<typeof apiRewardAttributesSchema>;

const apiRewardRelationshipsSchema = z.object({
  remediationModel: z.object({
    data: z.null()
  }),
  rewardModel: z.object({
    data: z.object({
      type: z.literal('rewardmodel'),
      id: z.string()
    })
  })
});

type ApiRewardRelationships = z.infer<typeof apiRewardRelationshipsSchema>;

const apiRewardSchema = z.object({
  data: z.object({
    type: z.literal('reward'),
    id: z.string(),
    attributes: apiRewardAttributesSchema,
    relationships: apiRewardRelationshipsSchema
  })
});

type ApiReward = z.infer<typeof apiRewardSchema>;

const apiRewardPatchSchema = z.object({
  data: z.object({
    type: z.literal('reward'),
    id: z.string(),
    attributes: apiRewardAttributesSchema
  })
});

type ApiRewardPatch = z.infer<typeof apiRewardPatchSchema>;

const campaignBuildApiRewardSchema = z.object({
  accountId: z.string(),
  adId: z.number(),
  createDateTime: z.string(),
  createUser: z.string(),
  currency: rewardCurrencySchema,
  endDateOffset: z.number(),
  isHoldRedemption: z.boolean(),
  isMultiple: z.boolean(),
  minSpend: z.number(),
  minVisits: z.number(),
  redemptionEndDateTime: z.string().nullable(),
  redemptionStartDateTime: z.string().nullable(),
  // This and rewardPercent can be undefined if it's an RMS reward
  rewardAmount: z.number().nullable().optional(),
  rewardAnnotations: z.array(apiRewardAnnotationSchema).nullable(),
  rewardCap: z.number(),
  rewardId: z.number(),
  rewardPercent: z.number().nullable().optional(),
  rewardTemplate: campaignBuildRewardTemplateSchema,
  rewardType: campaignBuildApiRawRewardTypeSchema,
  // Legacy rewards may not have the rmsResponse field at all, so it needs to be optional
  rmsResponse: apiRewardSchema.nullable().optional(),
  rmsRewardId: z.string().nullable(),
  /*
   * I've only seen this as an empty array but it's probably supposed to be an array of enums,
   * so we'll just set it as z.string() for now and fill it in with enums later if/when we
   * figure out what they are.
   */
  storeLocationTypes: z.array(z.string()),
  updateDateTime: z.string(),
  updateUser: z.string()
});

type CampaignBuildApiReward = z.infer<typeof campaignBuildApiRewardSchema>;

/*
 * We're making the adapter reward type from scratch so as to not muddy the waters
 * too much. It needs to support the intersection of campaign build and RMS rewards,
 * plus a bit more. The most important thing to point out are the optional fields,
 * which are such because they cannot be backfilled from just the reward data:
 * - adId does not get saved onto RMS rewards.
 * - rewardType only exists on the campaign build reward and cannot be backfilled
 *   accurately into an RMS reward without making a separate API call.
 * - redemptionEndDateTime is the same (it needs campaign info). (Note that as of
 *   this particular moment, redemptionEndDateTime DOES exist on the RMS reward
 *   object, but the plan is to deprecate it. TODO MINNIE to handle that when the
 *   time comes.)
 * - rewardModelId is like the above, but for RMS, and cannot be backfilled due to
 *   not every reward type having a corresponding reward model at this time. This
 *   is set as an empty string if it doesn't exist, so it's not optional, but is
 *   something to keep in mind.
 * - rewardModelConfigValue only exists for RMS and has no corresponding version on
 *   the campaign build reward. The optionality comment for rewardModelId applies
 *   here as well.
 * - isManualRedemption, isSuspendedProcessing, and remediationModelConfigValue are
 *   not used in the UI but are persisted because if the value was manually set via
 *   the API, we want to make sure we don't overwrite them.
 */
const adapterRewardSchema = z.object({
  accountId: z.string(),
  adId: z.number().optional(),
  currency: rewardCurrencySchema,
  endDateOffset: z.number(),
  isHoldRedemption: z.boolean(),
  isManualRedemption: z.boolean(),
  isSuspendedProcessing: z.boolean(),
  mccList: z.array(adapterMerchantCategoryCodeSchema),
  minSpend: z.number(),
  minVisits: z.number(),
  rewardAmount: z.number().nullable(),
  rewardCap: z.number(),
  rewardId: z.union([z.string(), z.number()]),
  rewardPercent: z.number().nullable(),
  redemptionEndDateTime: z.string().optional(),
  redemptionStartDateTime: z.string(),
  remediationModelConfigValue: z.null(),
  rewardAnnotation: adapterRewardAnnotationSchema,
  rewardModelConfigValue: z.string(),
  rewardModelId: z.string(),
  rewardType: adapterRewardTypeSchema.optional(),
  rmsRewardId: z.string().nullable()
});

type AdapterReward = z.infer<typeof adapterRewardSchema>;

/*
 * This is the type returned by the reward service - basically, the same thing
 * but with some additional fields filled out (the ones that require a separate
 * API call - reward type, which needs reward template info, and redemption end
 * date time, which needs campaign end date).
 */
const adapterRewardExtendedSchema = adapterRewardSchema.and(
  z.object({
    adId: z.number(),
    redemptionEndDateTime: z.string(),
    rewardType: adapterRewardTypeSchema
  })
);

type AdapterRewardExtended = z.infer<typeof adapterRewardExtendedSchema>;

type AdapterRewardIdentifiers = Pick<AdapterReward, 'accountId' | 'rewardId'>;

type BaseRewardVariables = Pick<
  AdapterReward,
  | 'currency'
  | 'minSpend'
  | 'minVisits'
  | 'redemptionEndDateTime'
  | 'redemptionStartDateTime'
  | 'rewardAmount'
  | 'rewardCap'
  | 'rewardPercent'
>;

type CampaignBuildApiRewardVariables = BaseRewardVariables &
  Pick<
    CampaignBuildApiReward,
    | 'adId'
    | 'isHoldRedemption'
    | 'isMultiple'
    | 'rewardAnnotations'
    | 'rewardTemplate'
  > & {
    rewardType: CampaignBuildApiRewardType;
  };

// This is the same as ApiReward, but without the ID
type ApiRewardVariables = {
  data: {
    type: 'reward';
    attributes: ApiRewardAttributes;
    relationships: ApiRewardRelationships;
  };
};

/*
 * The type passed in to the service layer. Even though rewardModelId is optional
 * on AdapterRewardExtended (because it may not necessarily be set when you load
 * in a reward), it's required here because it will always be set to some value
 * upon create/update.
 *
 * There's also isInitialRmsReward, which is a special flag that is set based on
 * the current reward (if it already exists) or the first reward of the first ad
 * of the first ad group of the campaign in order to determine if the reward is
 * initializing as RMS. From a service perspective, this is only relevant for
 * the create case.
 */
type AdapterRewardServiceVariables = BaseRewardVariables &
  Required<
    Pick<
      AdapterRewardExtended,
      | 'endDateOffset'
      | 'isHoldRedemption'
      | 'mccList'
      | 'redemptionEndDateTime'
      | 'rewardAnnotation'
      | 'rewardModelConfigValue'
      | 'rewardModelId'
      | 'rewardType'
    >
  > & { isInitialRmsReward: boolean };

// The type the service layer passes to the adapter after all prerequisite calls are made
// (for campaign build)
type CampaignBuildAdapterRewardVariables = Omit<
  AdapterRewardServiceVariables,
  'isInitialRmsReward' | 'rewardType'
> &
  Pick<CampaignBuildApiRewardVariables, 'adId' | 'rewardType'>;

// The type the service layer passes to the adapter after all prerequisite calls are made
// (for RMS)
type AdapterRewardVariables = Omit<
  AdapterRewardServiceVariables,
  'isInitialRmsReward' | 'isHoldRedemption'
> &
  Pick<AdapterRewardExtended, 'rewardModelConfigValue' | 'rewardModelId'> & /*
   * The below three are optional because they won't exist for the create case, which
   * is because these three fields are not supported in the UI. They will, however,
   * exist for the update case (due to coming from the original reward). We're thus
   * doing a passthrough and using them if they exist, and using a fallback if they
   * don't. The interface is also using "| undefined" instead of "?"" because then we
   * can make sure they're explicitly set and not forgotten about.
   */ {
    isManualRedemption: boolean | undefined;
    isSuspendedProcessing: boolean | undefined;
    remediationModelConfigValue: null | undefined;
  };

// The type used by the adapter (for campaign build)
type CampaignBuildAdapterRewardPostVariables = {
  accountId: string;
  variables: CampaignBuildAdapterRewardVariables;
};

// The type used by the adapter (for RMS)
type AdapterRewardPostVariables = {
  accountId: string;
  adId: number;
  variables: AdapterRewardVariables;
};

function instanceOfAdapterRewardExtended(
  reward: AdapterReward
): reward is AdapterRewardExtended {
  return !!reward.rewardType && !!reward.adId;
}

function rewardAdapterToApi(
  adapterData: AdapterRewardVariables
): ApiRewardVariables {
  const {
    currency,
    endDateOffset,
    isManualRedemption,
    isSuspendedProcessing,
    mccList,
    minSpend,
    redemptionEndDateTime,
    redemptionStartDateTime,
    remediationModelConfigValue,
    rewardAmount,
    rewardAnnotation,
    rewardCap,
    rewardModelConfigValue,
    rewardModelId,
    rewardPercent,
    rewardType
  } = adapterData;

  const { isMcc, rewardTemplate } = rewardTypeDef(rewardType);

  const rewardModelConfigValueObj: ApiRewardModelConfigValue = {
    currencyUnit: currency,
    mccList:
      isMcc && mccList.length > 0 ? mccList.map(({ mcc }) => mcc) : undefined,
    minSpend,
    rewardAmount:
      !rewardAmount || rewardTemplate === 'PercentBack' ? 0 : rewardAmount,
    rewardCap,
    rewardPercent:
      !rewardPercent || rewardTemplate !== 'PercentBack' ? 0 : rewardPercent
  };

  /*
   * If reward type is CustomRewardRedemption, then we don't want to use the config
   * value we constructed above, because the above assumes that it's a reward that
   * the UI knows about and is able to handle. We thus use the reward model config
   * value from the form.
   */
  const effectiveRewardModelConfigValue =
    rewardType === 'CustomRewardRedemption'
      ? rewardModelConfigValue
      : JSON.stringify(rewardModelConfigValueObj);

  return {
    data: {
      type: 'reward',
      attributes: {
        endDateOffset,
        isManualRedemption:
          isManualRedemption === undefined ? false : isManualRedemption,
        isSuspendedProcessing:
          isSuspendedProcessing === undefined ? false : isSuspendedProcessing,
        // TODO MINNIE: When this field is deprecated, it should be removed
        redemptionEndDateTime: `${dateFormatters.isoDate(
          redemptionEndDateTime
        )}T23:59:59Z`,
        redemptionStartDateTime: `${dateFormatters.isoDate(
          redemptionStartDateTime
        )}T00:00:00Z`,
        remediationModelConfigValue: remediationModelConfigValue || null,
        rewardAnnotations:
          rewardAnnotation === 'None' ? null : [rewardAnnotation],
        rewardModelConfigValue: effectiveRewardModelConfigValue
      },
      relationships: {
        remediationModel: {
          data: null
        },
        rewardModel: {
          data: {
            type: 'rewardmodel',
            id: rewardModelId
          }
        }
      }
    }
  };
}

function rewardAdapterToCampaignBuildApi(
  adapterData: CampaignBuildAdapterRewardVariables
): CampaignBuildApiRewardVariables {
  const {
    adId,
    currency,
    isHoldRedemption,
    minSpend,
    minVisits,
    redemptionEndDateTime,
    redemptionStartDateTime,
    rewardAmount,
    rewardAnnotation,
    rewardCap,
    rewardPercent,
    rewardType
  } = adapterData;

  const { isMultiple, rewardTemplate } = rewardTypeDef(rewardType);

  return {
    adId,
    currency,
    isHoldRedemption,
    isMultiple,
    minSpend,
    minVisits,
    redemptionEndDateTime: `${dateFormatters.isoDate(
      redemptionEndDateTime
    )}T23:59:59.0000000`,
    redemptionStartDateTime: `${dateFormatters.isoDate(
      redemptionStartDateTime
    )}T00:00:00.0000000`,
    rewardAmount: rewardTemplate === 'PercentBack' ? null : rewardAmount,
    rewardAnnotations: rewardAnnotation === 'None' ? null : [rewardAnnotation],
    rewardCap,
    rewardPercent: rewardTemplate === 'PercentBack' ? rewardPercent : null,
    rewardTemplate,
    rewardType
  };
}

/*
 * RMS rewards don't come with account ID by default, so we need it passed
 * in separately.
 */
function rewardApiToAdapter(
  apiData: ApiReward,
  accountId: string
): AdapterReward {
  const { data } = apiData;
  const { attributes, id, relationships } = data;

  const {
    endDateOffset,
    isManualRedemption,
    isSuspendedProcessing,
    redemptionStartDateTime,
    remediationModelConfigValue,
    rewardAnnotations,
    rewardModelConfigValue
  } = attributes;

  let config: ApiRewardModelConfigValue = {
    currencyUnit: 'USD',
    minSpend: 0,
    rewardAmount: 0,
    rewardCap: 0,
    rewardPercent: 0
  };

  try {
    config = {
      ...config,
      ...(JSON.parse(rewardModelConfigValue) as ApiRewardModelConfigValue)
    };
  } catch {
    // Do nothing
  }

  return {
    accountId,
    currency: config.currencyUnit,
    endDateOffset,
    // Not used - this is just here for campaign build's sake
    isHoldRedemption: true,
    isManualRedemption,
    isSuspendedProcessing,
    mccList: (config.mccList || [])
      .sort((a, b) => a - b)
      .map((mcc) => ({ mcc, name: `${mcc}` })),
    minSpend: config.minSpend,
    // Hardcoded as MCC rewards do not support min visits
    minVisits: 1,
    redemptionStartDateTime: dateTimeToDate(redemptionStartDateTime),
    remediationModelConfigValue,
    rewardAmount: config.rewardAmount || null,
    rewardAnnotation:
      Array.isArray(rewardAnnotations) && rewardAnnotations.length > 0
        ? rewardAnnotations[0]
        : 'None',
    rewardCap: config.rewardCap,
    rewardId: id,
    /*
     * We save this onto the adapter reward for the custom redemption use case - if it's
     * custom, then we have no guarantee that the UI is able to properly parse/display
     * the config and will have to pass it back to the adapter upon save.
     */
    rewardModelConfigValue,
    rewardModelId: relationships.rewardModel.data.id,
    rewardPercent: config.rewardPercent || null,
    rmsRewardId: id
  };
}

function rewardCampaignBuildApiToAdapter(
  apiData: CampaignBuildApiReward
): AdapterReward {
  const {
    redemptionEndDateTime,
    redemptionStartDateTime,
    rewardAmount,
    rewardAnnotations,
    rewardPercent,
    rewardType,
    ...rest
  } = apiData;

  return {
    ...rest,
    // Value not used for campaign build (RMS only)
    isManualRedemption: false,
    // Value not used for campaign build (RMS only)
    isSuspendedProcessing: false,
    mccList: [],
    /*
     * If start/end date don't exist, default it. Similar to the comment
     * for reward type conversion below, this is not going to happen in
     * a normal situation, because these fields are generally only null
     * under circumstances in which the plan is to go through a different
     * flow anyway.
     */
    redemptionEndDateTime: dateTimeToDate(
      redemptionEndDateTime || '2000-01-01'
    ),
    redemptionStartDateTime: dateTimeToDate(
      redemptionStartDateTime || '2000-01-01'
    ),
    // Value not used for campaign build (RMS only)
    remediationModelConfigValue: null,
    rewardAmount: rewardAmount || null,
    rewardAnnotation:
      Array.isArray(rewardAnnotations) && rewardAnnotations.length > 0
        ? rewardAnnotations[0]
        : 'None',
    rewardModelConfigValue: '',
    rewardModelId: '',
    rewardPercent: rewardPercent || null,
    /*
     * This reward type conversion is not expected to happen in a normal
     * situation. This is because if a reward actually does have a weird
     * type, it's either not something the UI is expected to handle anyway,
     * OR the reward is an RMS reward, which means this function is not
     * going to be called because it will go through the RMS adapter
     * conversion function instead, not the campaign build one.
     */
    rewardType: rewardType === 0 ? 'SingleRewardFlatAmountBack' : rewardType
  };
}

function rewardAdapterToExtendedAdapter({
  adId,
  campaign,
  reward,
  rewardTemplatesMap,
  merchantCategoryCodesMap
}: {
  adId: number;
  campaign?: Campaign;
  reward: AdapterReward;
  rewardTemplatesMap: Record<AdapterRewardType, AdapterRewardTemplate>;
  merchantCategoryCodesMap: Map<number, AdapterMerchantCategoryCode>;
}): AdapterRewardExtended {
  /*
   * An AdapterReward at this point may come from either campaign build, or it
   * may come from RMS. The former will have the fields needed to type it as
   * AdapterRewardExtended without further work. The latter will need to read
   * from the templates passed in to determine which is correct.
   */
  if (instanceOfAdapterRewardExtended(reward)) {
    return reward;
  }

  /*
   * If we're here, we know the campaign exists because we make the call for
   * the campaign only if it fails the instanceOfAdapterRewardExtended check.
   */
  invariant(campaign);

  const { endDateOffset, rewardModelId, rewardPercent } = reward;

  const extended: AdapterRewardExtended = {
    ...reward,
    adId,
    redemptionEndDateTime: dateTimeToDate(
      getParsedDateTime(dateTimeToDate(campaign.endDateTime))
        .add({ days: endDateOffset })
        .toString()
    ),
    rewardType: 'CustomRewardRedemption'
  };

  for (const type of ADAPTER_REWARD_TYPES) {
    const def = rewardTypeDef(type);

    /*
     * One model may correspond to multiple reward types. For example, there is one
     * that could be MccSingleRewardPercentAmountBack OR MccSingleRewardFlatAmountBack,
     * so we have to see if the reward in question contains a reward percent or not to
     * know which type it corresponds to.
     */
    if (
      rewardTemplatesMap[type].rewardModelId === rewardModelId &&
      !!rewardPercent === (def.rewardTemplate === 'PercentBack')
    ) {
      extended.rewardType = type;
    }
  }

  extended.mccList.map((mccObj) => {
    const foundMcc = merchantCategoryCodesMap.get(mccObj.mcc);
    if (foundMcc) {
      mccObj.name = foundMcc.name;
    }
  });

  /*
   * If it's a custom reward redemption, then set a bunch of defaults. The UI
   * won't show or save them (it cannot, because the nature of it being a custom
   * reward means we don't know things like whether it's flat or percent), but
   * it will prevent schema errors.
   */
  if (extended.rewardType === 'CustomRewardRedemption') {
    extended.minSpend = 1;
    extended.minVisits = 1;
    extended.rewardAmount = 1;
    extended.rewardCap = 1;
    extended.rewardPercent = 1;
  }

  return extended;
}

export * from './definitions';
export {
  ADAPTER_REWARD_ANNOTATIONS,
  ADAPTER_REWARD_TYPES,
  API_REWARD_ANNOTATIONS,
  CAMPAIGN_BUILD_API_REWARD_TYPES,
  CREATIVE_REWARD_TYPE_TO_ADAPTER_REWARD_TYPE_MAP,
  adapterRewardAnnotationSchema,
  adapterRewardExtendedSchema,
  adapterRewardSchema,
  adapterRewardTypeSchema,
  apiRewardAnnotationSchema,
  apiRewardPatchSchema,
  apiRewardSchema,
  campaignBuildApiRewardSchema,
  campaignBuildApiRewardTypeSchema,
  instanceOfAdapterRewardExtended,
  rewardAdapterToApi,
  rewardAdapterToCampaignBuildApi,
  rewardAdapterToExtendedAdapter,
  rewardApiToAdapter,
  rewardCampaignBuildApiToAdapter,
  rewardCurrencySchema
};
export type {
  AdapterReward,
  AdapterRewardAnnotation,
  AdapterRewardExtended,
  AdapterRewardIdentifiers,
  AdapterRewardPostVariables,
  AdapterRewardServiceVariables,
  AdapterRewardType,
  AdapterRewardVariables,
  ApiReward,
  ApiRewardAnnotation,
  ApiRewardPatch,
  ApiRewardVariables,
  CampaignBuildAdapterRewardPostVariables,
  CampaignBuildAdapterRewardVariables,
  CampaignBuildApiReward,
  CampaignBuildApiRewardType,
  CampaignBuildApiRewardVariables
};
