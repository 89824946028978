import { FocusEventHandler } from 'react';
import { CheckedIcon, IndeterminateIcon } from './helpers';
import { classnames } from '../../utils';

interface SingleCheckboxProps {
  checked: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  id: string;
  indeterminate?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onSelectedItemChange?: (selected: boolean) => void;
  value?: string;
  classes?: string;
}

function SingleCheckbox({
  checked,
  children,
  disabled,
  id,
  indeterminate,
  onBlur,
  onSelectedItemChange,
  value,
  classes
}: SingleCheckboxProps) {
  function onChange() {
    onSelectedItemChange && onSelectedItemChange(!checked);
  }

  return (
    <>
      <div className={classnames('checkbox-container relative ', classes)}>
        <input
          id={id}
          data-testid={id}
          value={value}
          checked={indeterminate || checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          type='checkbox'
          className='peer absolute bottom-0 top-0 cursor-pointer opacity-0 disabled:cursor-not-allowed'
        />
        <label
          htmlFor={id}
          className='flex cursor-pointer items-center peer-disabled:cursor-not-allowed peer-disabled:text-disabled'
        >
          <div className='checkbox-box'>
            {indeterminate ? <IndeterminateIcon /> : <CheckedIcon />}
          </div>
          {children ? <div className='ml-2'>{children}</div> : null}
        </label>
      </div>
    </>
  );
}

export type { SingleCheckboxProps };
export { SingleCheckbox };
