import { Outlet } from 'react-router-dom';

import { AppHeader } from '@components/AppHeader/AppHeader';

const Chrome = () => (
  <div className='bg-neutral'>
    <div className='z-10'>
      <AppHeader />
    </div>
    <div className='z-10 min-h-screen pb-5 pt-24'>
      <Outlet />
    </div>
  </div>
);
export { Chrome };
