import { classnames } from '@library/utils';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { commonCellClasses } from './helpers';

const footerCellClasses =
  'border-l border-b last:border-r border-neutral bg-contentNested text-left font-normal';

const borderlessFooterCellClasses = 'first:border-l-0 last:border-r-0';

interface TableFooterProps<TableData> {
  footerGroups: HeaderGroup<TableData>[];
  borderless?: boolean;
}

function TableFooter<TableData>({
  footerGroups,
  borderless
}: TableFooterProps<TableData>) {
  return (
    <tfoot>
      {footerGroups.map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <th
              key={header.id}
              className={classnames(
                commonCellClasses,
                footerCellClasses,
                borderless ? borderlessFooterCellClasses : null
              )}
            >
              {flexRender(header.column.columnDef.footer, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}

export { TableFooter };
