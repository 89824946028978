// TODO: Create a flexible abstract component to work as loader and remove this
const InstitutionCardLoader = () => (
  <div className='my-2 rounded border border-gray-rules bg-white'>
    <div className='p-4'>
      <div>
        <div className='pulsate h-4 animate-pulsate' />
      </div>
      <div className='pt-4'>
        <div className='pulsate mb-2 h-2 w-3/5 animate-pulsate' />
        <div className='pulsate mb-4 h-2 w-4/5 animate-pulsate' />
        <div className='pulsate mb-2 h-2 w-3/5 animate-pulsate' />
        <div className='pulsate mb-4 h-2 w-4/5 animate-pulsate' />
      </div>
    </div>
  </div>
);

export { InstitutionCardLoader };
