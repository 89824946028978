import { CustomerMetric } from '@models/customer';

export const parseCustomerList = (customers: CustomerMetric[]) =>
  customers.map((customer) => ({
    ...customer,
    targetedOffers: customer.offers.targeted,
    servedOffers: customer.offers.served,
    activatedOffers: customer.offers.activated,
    redeemedOffers: customer.offers.redeemed
  }));
