import { classnames } from '@library/utils';
import { useTranslation } from 'react-i18next';
import { Message } from '../message';
import { bodyCellClasses, borderlessBodyCellClasses } from './helpers';

interface TableHiddenAndSelectedMessageRowProps {
  borderless?: boolean;
  numHiddenAndSelected: number;
  numVisibleColumns: number;
}

function TableHiddenAndSelectedMessageRow({
  borderless,
  numHiddenAndSelected,
  numVisibleColumns
}: TableHiddenAndSelectedMessageRowProps) {
  const { t } = useTranslation('global');

  if (numHiddenAndSelected < 1) {
    return null;
  }

  return (
    <tr>
      <td
        className={classnames(
          bodyCellClasses,
          borderless ? borderlessBodyCellClasses : null,
          'px-4 py-2 text-center'
        )}
        colSpan={numVisibleColumns}
      >
        <Message
          variant='variant.info'
          message={t('library.xSelectedItemsHiddenWithFilters', {
            count: numHiddenAndSelected
          })}
        />
      </td>
    </tr>
  );
}

export { TableHiddenAndSelectedMessageRow };
