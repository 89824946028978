import { useEffect, useState } from 'react';
import { useAuth } from '@/context-providers/auth';

const IdleTimer = ({ children }: { children: React.ReactNode }) => {
  const [logoutFlag, setLogoutFlag] = useState(false);
  let idleTimer;

  const { logout } = useAuth();

  useEffect(() => {
    const handleUserActivity = () => {
      clearTimeout(idleTimer as NodeJS.Timeout);
      idleTimer = setTimeout(() => {
        setLogoutFlag(true);
      }, 600000); // 10 minutes in milliseconds
    };

    // Add event listeners to track user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    document.addEventListener('scroll', handleUserActivity);

    // Clear timer and remove event listeners on component unmount
    return () => {
      clearTimeout(idleTimer as NodeJS.Timeout);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('scroll', handleUserActivity);
    };
  }, [idleTimer]);

  useEffect(() => {
    if (logoutFlag) {
      logout();
    }
  }, [logoutFlag]);

  return <>{children}</>;
};

export { IdleTimer };
