import { classnames } from '@library/utils';
import { useTranslation } from 'react-i18next';
import { ParagraphLoader } from '../skeleton-loader';
import {
  bodyCellClasses,
  borderlessBodyCellClasses,
  commonCellClasses
} from './helpers';
import { TableHiddenAndSelectedMessageRow } from './hidden-and-selected-message-row';

interface TableBodyEmptyProps {
  borderless?: boolean;
  loading?: boolean;
  numVisibleColumns: number;
  noDataMessage?: React.ReactNode;
  numHiddenAndSelected: number;
}

function TableBodyEmpty({
  borderless,
  loading,
  numVisibleColumns,
  noDataMessage,
  numHiddenAndSelected
}: TableBodyEmptyProps) {
  const { t } = useTranslation('global');

  return (
    <tbody>
      <TableHiddenAndSelectedMessageRow
        borderless={borderless}
        numHiddenAndSelected={numHiddenAndSelected}
        numVisibleColumns={numVisibleColumns}
      />
      <tr className='group'>
        <td
          className={classnames(
            'text-center',
            commonCellClasses,
            bodyCellClasses,
            'border-b-0',
            borderless ? borderlessBodyCellClasses : null
          )}
          colSpan={numVisibleColumns}
        >
          {loading ? (
            <ParagraphLoader />
          ) : (
            noDataMessage || t('library.noResultsFound')
          )}
        </td>
      </tr>
    </tbody>
  );
}

export { TableBodyEmpty };
