import { Dispatch, SetStateAction } from 'react';
import { AddMultipleCustomers } from './AddMultipleCustomers';
import { AddSingleCustomer } from './AddSingleCustomer';

const AddCustomersModalContent = ({
  setIsModalOpen
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className='w-256 p-4'>
    <div>
      <AddMultipleCustomers setIsModalOpen={setIsModalOpen} />
      <div className='flex items-center py-14'>
        <span className='w-full border border-solid border-white border-t-gray-rules' />
        <p className='px-1 text-sm font-bold text-blue-action'>OR</p>
        <span className='w-full border border-solid border-white border-t-gray-rules' />
      </div>
      <AddSingleCustomer setIsModalOpen={setIsModalOpen} />
    </div>
  </div>
);
export { AddCustomersModalContent };
