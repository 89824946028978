import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { classnames } from '@library/utils';
import { ZodSchema } from 'zod';
import { Message } from '../../../message';
import { FieldWarning } from './field-warning';

interface FieldErrorProps<TFormSchema extends FieldValues> {
  collapsible?: boolean;
  id: string;
  name: FieldPath<TFormSchema>;
  warningSchema?: ZodSchema; // an optional schema that can be checked to display warnings
}

function FieldError<TFormSchema extends FieldValues>({
  collapsible,
  id,
  name,
  warningSchema
}: FieldErrorProps<TFormSchema>) {
  const {
    formState: { errors }
  } = useFormContext();

  const error = errors[name]?.message as string | undefined;

  if (error) {
    return (
      <FieldErrorContainer collapsible={collapsible}>
        <Message id={id} message={error} variant='variant.error' />
      </FieldErrorContainer>
    );
  }

  if (warningSchema) {
    return (
      <FieldWarning
        id={id}
        name={name}
        schema={warningSchema}
        collapsible={collapsible}
      />
    );
  }

  return <FieldErrorContainer collapsible={collapsible} />;
}

const defaultErrorClasses = 'min-h-6';

interface FieldErrorContainerProps {
  children?: React.ReactNode;
  collapsible?: boolean;
}

function FieldErrorContainer({
  children,
  collapsible
}: FieldErrorContainerProps) {
  const classes = classnames(collapsible ? '' : defaultErrorClasses);

  return <div className={classes}>{children}</div>;
}

export { FieldError, FieldErrorContainer };
