import { FunnelIcon } from '@heroicons/react/24/outline';
import { classnames } from '@library/utils';
import { Column, Table } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxGroupSelection,
  NestedHeadlessCheckbox,
  NestedHeadlessCheckboxGroup,
  NestedHeadlessCheckboxLabel
} from '../checkbox';
import { Content, ContentSection } from '../content-container';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

interface FilterItemProps {
  id: string;
  value: string;
  children: React.ReactNode;
}

function FilterItem({ id, value, children }: FilterItemProps) {
  return (
    <div className='flex items-center'>
      <NestedHeadlessCheckbox id={id} value={value} />
      <NestedHeadlessCheckboxLabel htmlFor={id} className='ml-2 flex-1'>
        {children}
      </NestedHeadlessCheckboxLabel>
    </div>
  );
}

interface ColumnFilterProps<TableData> {
  column: Column<TableData>;
  table: Table<TableData>;
}

function ColumnFilter<TableData>({
  column,
  table
}: ColumnFilterProps<TableData>) {
  const { t } = useTranslation('global');

  const [selections, setSelections] = useState({});

  if (!column.getCanFilter() || column.columnDef.filterFn !== 'multiFilterFn') {
    return null;
  }

  const baseId = `filter-column-${String(table.options.meta?.baseId)}-${String(
    column.columnDef.header
  )}`;

  const allValue = `${baseId}-all`;

  const onSelectionsChange = (newValue: CheckboxGroupSelection) => {
    setSelections(newValue);
    column.setFilterValue(Object.keys(newValue));
  };

  const filterVal = column.getFilterValue();
  const columnFilterValue = filterVal ? (filterVal as string[]) : undefined;

  const facetedUniqueValuesArr = [...column.getFacetedUniqueValues()]
    .map(([key, value]) => ({
      id: String(key),
      count: value
    }))
    .sort((a, b) => a.id.localeCompare(b.id));

  if (facetedUniqueValuesArr.length === 0) {
    return null;
  }

  const allItems = [
    {
      id: allValue,
      children: facetedUniqueValuesArr
    }
  ];

  // Having no items selected and all items selected is functionally the same thing
  const isFilterActive =
    columnFilterValue &&
    columnFilterValue.length > 0 &&
    columnFilterValue.length < facetedUniqueValuesArr.length;

  const ariaLabel: string = t('library.filterOnColumnX', {
    columnName: column.columnDef.header
  });

  return (
    <Popover>
      <PopoverTrigger>
        {/* Same hover as the icon buttons. When active, has a different background
        color handled the same way as the ad copy editor. */}
        <button
          type='button'
          aria-label={ariaLabel}
          className={classnames(
            'ml-2 cursor-pointer rounded-md border hover:bg-inverse/20',
            isFilterActive
              ? 'border-neutral bg-inverse/10'
              : 'border-transparent'
          )}
        >
          <FunnelIcon className='m-1 h-4 w-4' />
        </button>
      </PopoverTrigger>
      <PopoverContent alignment='alignment.end'>
        <Content>
          <NestedHeadlessCheckboxGroup
            selections={selections}
            name={column.id}
            onSelectionsChange={onSelectionsChange}
            allItems={allItems}
            parentKey='children'
            valueKey='id'
          >
            <ContentSection spacing='spacing.sm'>
              <FilterItem id={allValue} value={allValue}>
                {t('library.selectAll')}
              </FilterItem>
            </ContentSection>
            <hr className='h-px border-neutral' />
            <div className='max-h-96 overflow-auto'>
              <ContentSection spacing='spacing.sm'>
                {facetedUniqueValuesArr.map(({ id, count }) => (
                  <FilterItem
                    key={`${baseId}-${id}`}
                    id={`${baseId}-${id}`}
                    value={id}
                  >
                    {id} {t('library.numParentheses', { value: count })}
                  </FilterItem>
                ))}
              </ContentSection>
            </div>
          </NestedHeadlessCheckboxGroup>
        </Content>
      </PopoverContent>
    </Popover>
  );
}

export { ColumnFilter };
