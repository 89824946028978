import { Institution } from '@models/institution';
import { useTranslation } from 'react-i18next';

import { InstitutionCard } from '@components/Institution/InstitutionCard/InstitutionCard';
import { InstitutionCardLoader } from '@components/Institution/InstitutionCard/InstitutionCardLoader';

interface InstitutionGridProps {
  institutions: Institution[];
  isLoading: boolean;
}

const InstitutionGrid = ({
  institutions = [],
  isLoading
}: InstitutionGridProps) => {
  const [t] = useTranslation();

  return (
    <>
      <div className='grid grid-cols-5 gap-x-4'>
        {institutions.map((institution: Institution) => (
          <InstitutionCard
            key={institution.id}
            id={institution.id}
            institutionLogos={institution.logos}
            name={institution.name}
            codeName={institution.codeName}
            isActive={institution.isActive}
          />
        ))}

        {isLoading && (
          <>
            <InstitutionCardLoader />
            <InstitutionCardLoader />
            <InstitutionCardLoader />
            <InstitutionCardLoader />
            <InstitutionCardLoader />
          </>
        )}
      </div>
      {!isLoading && institutions && institutions.length === 0 && (
        <h2 className='text-xl font-bold'>{t('institution.noPublishers')}</h2>
      )}
    </>
  );
};

export { InstitutionGrid };
