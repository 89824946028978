// @ts-nocheck
import { classnames } from '@library/utils';
import { DateValue, parseDate } from '@internationalized/date';
import { AriaDatePickerProps, useDatePicker } from '@react-aria/datepicker';
import { useDatePickerState } from '@react-stately/datepicker';
import { FocusEventHandler, useRef } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../../popover';
import { ClearButton } from '../clear-button';
import { DateButton } from '../date-button';
import { DateInput } from '../date-input';
import {
  baseGroupClasses,
  baseInputClasses,
  disabledGroupClasses,
  getMinMaxDates,
  sizeClasses
} from '../helpers';
import { useCombinedIsOpen } from '../hooks';
import { PickerSize } from '../types';
import { SingleCalendar } from './calendar';

type DatePickerValue = string;

interface DatePickerProps {
  id: string;
  value: DatePickerValue;
  onChange: (value: DatePickerValue) => void;
  onBlur?: FocusEventHandler;
  minValue?: string;
  maxValue?: string;
  disabled?: boolean;
  size?: PickerSize;
  // Not using React.AriaAttributes['aria-label'] because that can be undefined
  'aria-label': string;
  'aria-invalid'?: React.AriaAttributes['aria-invalid'];
  'aria-describedby'?: React.AriaAttributes['aria-describedby'];
}

function DatePicker({
  id,
  value,
  onChange,
  onBlur,
  minValue,
  maxValue,
  disabled = false,
  size = 'size.lg',
  'aria-label': ariaLabel,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy
}: DatePickerProps) {
  const { minDate, maxDate } = getMinMaxDates({ minValue, maxValue });

  let date = null;
  try {
    date = parseDate(value) as any;
  } catch {
    date = null;
  }

  const datePickerHookProps: AriaDatePickerProps<DateValue> = {
    id,
    value: date,
    onChange(dateValue) {
      if (dateValue === null) {
        onChange('');
        return;
      }

      onChange(dateValue.toString());
    },
    onBlur,
    minValue: minDate,
    maxValue: maxDate,
    isDisabled: disabled,
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedBy
  };

  const datePickerState = useDatePickerState(datePickerHookProps);

  const ref = useRef(null);

  const { groupProps, fieldProps, buttonProps, calendarProps } = useDatePicker(
    datePickerHookProps,
    datePickerState,
    ref
  );

  const [isOpen, setIsOpen] = useCombinedIsOpen(datePickerState.isOpen);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div
        {...groupProps}
        aria-invalid={ariaInvalid}
        ref={ref}
        className={classnames(
          baseGroupClasses,
          disabled && disabledGroupClasses,
          isOpen && 'border-active'
        )}
      >
        <div className={classnames(baseInputClasses, sizeClasses[size])}>
          <DateInput {...fieldProps} />
        </div>
        <ClearButton
          id={ariaLabel}
          disabled={disabled}
          onClick={() => {
            onChange('');
          }}
        />
        <PopoverTrigger>
          <DateButton
            buttonProps={buttonProps}
            disabled={disabled}
            isCalendarOpen={isOpen}
            size={size}
          />
        </PopoverTrigger>
      </div>
      <PopoverContent alignment='alignment.end'>
        <div className='p-4'>
          <SingleCalendar {...calendarProps} />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export { DatePicker };
export type { DatePickerProps, DatePickerValue };
