import { Tab as HeadlessTab } from '@headlessui/react';
import React, { createContext, useContext } from 'react';
import { classnames } from '../../utils';
import { TabVariant } from './types';

interface TabContextValue {
  variant: TabVariant;
  disabled?: boolean;
}

const TabContext = createContext<TabContextValue | undefined>(undefined);

const useTab = () => {
  const context = useContext(TabContext);
  if (context === undefined) {
    throw new Error('useTab can only be used within a TabGroup');
  }
  return context;
};

const baseTabClasses = {
  'variant.folder': [
    'bg-content border-neutral relative mr-2 w-64 rounded-t border p-4 text-left',
    // active border
    'before:absolute before:-top-px before:-left-px before:-right-px before:mx-auto before:h-0.75 before:w-0 before:rounded-t before:transition-[width] before:duration-200 before:ease-in-out'
  ],
  'variant.inset': 'text-link-neutral m-0.5 flex-1 p-1.5 text-sm'
} satisfies Record<TabVariant, string | string[]>;

const selectedTabClasses = {
  'variant.folder': [
    'ui-selected:font-bold ui-selected:text-link-neutral ui-selected:border-b-transparent',
    // active border
    'ui-selected:before:bg-active ui-selected:before:w-[calc(100%_+_2px)]'
  ],
  'variant.inset':
    'ui-selected:bg-content ui-selected:border-neutral ui-selected:rounded-md ui-selected:border ui-selected:font-bold'
} satisfies Record<TabVariant, string | string[]>;

const disabledTabClasses = 'disabled:opacity-50';

interface TabProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const Tab = ({ children, disabled }: TabProps) => {
  const { variant, disabled: groupDisabled } = useTab();

  const classes = classnames(
    baseTabClasses[variant],
    selectedTabClasses[variant],
    disabledTabClasses
  );
  disabled = groupDisabled || disabled;

  return (
    <HeadlessTab className={classes} disabled={disabled}>
      {children}
    </HeadlessTab>
  );
};

interface TabListProps {
  children: React.ReactNode;
}

const TabList = ({ children }: TabListProps) => {
  const { variant } = useTab();

  const tabListClasses = {
    'variant.folder': 'relative top-px flex',
    'variant.inset':
      'bg-contentNested border-neutral flex w-full items-center justify-between rounded-md border'
  } satisfies Record<TabVariant, string>;

  return (
    <HeadlessTab.List className={tabListClasses[variant]}>
      {children}
    </HeadlessTab.List>
  );
};

interface TabPanelProps {
  children: React.ReactNode;
}

const TabPanel = ({ children }: TabPanelProps) => (
  <HeadlessTab.Panel>{children}</HeadlessTab.Panel>
);

interface TabPanelsProps {
  children: React.ReactNode;
}

const TabPanels = ({ children }: TabPanelsProps) => {
  const { variant } = useTab();

  const tabPanelsClasses = {
    'variant.folder':
      'bg-content border-neutral rounded-tr-md rounded-bl-md rounded-br-md border',
    'variant.inset': ''
  } satisfies Record<TabVariant, string>;

  return (
    <HeadlessTab.Panels className={tabPanelsClasses[variant]}>
      {children}
    </HeadlessTab.Panels>
  );
};

interface TabGroupProps {
  children: React.ReactNode;
  variant: TabVariant;
  defaultIndex?: number;
  onChange?: (index: number) => void;
  disabled?: boolean;
}

const TabGroup = ({
  children,
  variant,
  defaultIndex,
  onChange,
  disabled
}: TabGroupProps) => {
  const value = { variant, disabled };
  return (
    <TabContext.Provider value={value}>
      <HeadlessTab.Group defaultIndex={defaultIndex} onChange={onChange}>
        {children}
      </HeadlessTab.Group>
    </TabContext.Provider>
  );
};

export type { TabGroupProps };
export { Tab, TabGroup, TabList, TabPanel, TabPanels };
