interface PropertyInfoProps {
  text: string;
  value?: string | number;
}

const PropertyInfo = ({ text, value }: PropertyInfoProps) => (
  <div className='text-sm'>
    <h3 className='inline-block font-bold'>{text}: </h3>{' '}
    <p className='inline-block'>{value}</p>
  </div>
);

export { PropertyInfo };
