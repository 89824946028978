import { FilterRequestParam } from '@models/table';
import { TablePaginationState } from '@library/components';

const getFilterString = (filters: FilterRequestParam[]) => {
  if (!Array.isArray(filters)) {
    throw new TypeError('Invalid input. Expected an array of filters.');
  }

  const filterString = filters
    .filter(
      (filter) =>
        // Exclude boolean filters with a value of false
        // Exclude string filters with an empty value
        (filter.type !== 'boolean' || filter.value) &&
        (filter.type !== 'string' || filter.value !== '')
    )
    .map((filter) => {
      if (
        !filter.key ||
        !filter.type ||
        (filter.type !== 'string' &&
          filter.type !== 'number' &&
          filter.type !== 'boolean')
      ) {
        throw new Error('Invalid filter object.');
      }

      const valueString =
        filter.type === 'string' ? `~${filter.value}` : `==${filter.value}`;

      return `${filter.key}${valueString}`;
    })
    .join('&');

  return filterString.length > 0 ? `filter=${filterString}` : '';
};

const getPaginationString = (pagination: TablePaginationState) =>
  pagination.pageSize
    ? `page=${pagination.pageIndex + 1}&perPage=${pagination.pageSize}`
    : '';

export { getFilterString, getPaginationString };
