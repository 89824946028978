import { OfferProvider } from '@/context-providers/offer';

import { SingleOffer } from './SingleOffer';

const EditCustomerOfferRow = ({ originalOffer }: any) => (
  <OfferProvider>
    <SingleOffer originalOffer={originalOffer} />
  </OfferProvider>
);

export { EditCustomerOfferRow };
