import { useFilter } from '@react-aria/i18n';
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  SortingState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table';
import { useEffect } from 'react';
import { BaseTable } from './base';
import {
  getCheckboxColumn,
  getRowSelectionFromSelectedItems,
  onRowSelectionChange,
  SelectableTableProps,
  TablePageSizeOptions,
  UnselectableTableProps
} from './helpers';

/*
 * Ideally the default filters provided by react-table would be safe to use with
 * numbers, but that isn't the case: https://github.com/TanStack/table/issues/4280
 * On the plus side, we can use useFilter now to do accent-/case-insensitive search.
 */
function safeFilterFn<TableData>(
  contains: (string: string, substring: string) => boolean
): FilterFnOption<TableData> {
  return (row: Row<TableData>, columnId: string, filterValue: string) => {
    const value = row.getValue(columnId);

    const safeValue = typeof value === 'string' ? value : String(value);

    return contains(safeValue, filterValue);
  };
}

function multiFilterFn(
  row: Row<unknown>,
  columnId: string,
  filterValue: string[]
) {
  // Ensures that if user deselected all filter options, that all items are returned
  if (filterValue.length === 0) {
    return true;
  }

  const value = String(row.getValue(columnId));

  return filterValue.includes(value);
}

interface CommonTableProps<TableData> {
  id: string;
  data: TableData[];
  columns: ColumnDef<TableData>[];
  pageSizeOptions?: TablePageSizeOptions;
  pagination?: Partial<PaginationState>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  globalFilter?: string;
  borderless?: boolean;
  loading?: boolean;
  isHighlightedRow?: (row: TableData) => boolean;
  noDataMessage?: JSX.Element | string;
  'aria-label'?: React.AriaAttributes['aria-label'];
  'aria-invalid'?: React.AriaAttributes['aria-invalid'];
  'aria-describedby'?: React.AriaAttributes['aria-describedby'];
}

type ClientSideUnselectableTableProps<TableData> = CommonTableProps<TableData> &
  UnselectableTableProps;

type ClientSideSelectableTableProps<TableData> = CommonTableProps<TableData> &
  SelectableTableProps<TableData>;

type ClientSideTableProps<TableData> =
  | ClientSideUnselectableTableProps<TableData>
  | ClientSideSelectableTableProps<TableData>;

function ClientSideTable<TableData>({
  id,
  data,
  columns,
  enableSelectedItems,
  persistSelectedItemsAcrossPages,
  selectedItems,
  selectedItemsKey,
  onSelectedItemsChange,
  pageSizeOptions = [10, 25, 50, 100],
  pagination,
  sorting,
  onSortingChange,
  globalFilter,
  borderless,
  loading = false,
  isHighlightedRow,
  noDataMessage,
  'aria-label': ariaLabel,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy
}: ClientSideTableProps<TableData>) {
  const { contains } = useFilter({ sensitivity: 'base' });

  const effectiveColumns = columns.map((col) => {
    col.sortDescFirst = false;
    return col;
  });

  const tableOptions: TableOptions<TableData> &
    Required<Pick<TableOptions<TableData>, 'state'>> = {
    data,
    enableSorting: true,
    enableSortingRemoval: false,
    columns: effectiveColumns,
    meta: {
      baseId: id,
      loading,
      persistSelectedItemsAcrossPages
    },
    globalFilterFn: safeFilterFn(contains),
    filterFns: {
      multiFilterFn
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      pagination: {
        pageSize:
          pagination?.pageSize && pageSizeOptions.includes(pagination.pageSize)
            ? pagination.pageSize
            : pageSizeOptions[0]
      }
    },
    state: {
      globalFilter
    }
  };

  if (enableSelectedItems) {
    effectiveColumns.unshift(getCheckboxColumn({ id }));

    tableOptions.state.rowSelection = getRowSelectionFromSelectedItems({
      selectedItems,
      selectedItemsKey,
      data
    });

    tableOptions.onRowSelectionChange = onRowSelectionChange({
      onSelectedItemsChange,
      rowSelection: tableOptions.state.rowSelection,
      data
    });
  }

  if (onSortingChange) {
    /*
     * If this gets set during tableOptions' initialization, it will override the
     * default sorting behavior, even if you explicitly set it to undefined. Thus
     * we have to set the onSortingChange field only if it explicitly exists.
     */
    tableOptions.onSortingChange = onSortingChange;
    tableOptions.state.sorting = sorting;
  }

  const table = useReactTable(tableOptions);

  useEffect(() => {
    /*
     * We need to watch the global filter, which is provided externally, for changes
     * so that we can uncheck the user's selections when appropriate.
     */
    if (!table.options.meta?.persistSelectedItemsAcrossPages) {
      table.toggleAllRowsSelected(false);
    }
  }, [globalFilter, table]);

  return (
    <BaseTable
      table={table}
      data={data}
      columns={effectiveColumns}
      pageSizeOptions={pageSizeOptions}
      borderless={borderless}
      loading={loading}
      isHighlightedRow={isHighlightedRow}
      noDataMessage={noDataMessage}
      aria-label={ariaLabel}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
    />
  );
}

export { ClientSideTable };
export type { ClientSideTableProps };
