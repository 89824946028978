import { useEffect, useMemo, useState } from 'react';

import { OffersList } from '@/components/Offers/OffersList/OffersList';

import { useInstitution } from '@/context-providers/institution';

import { useApiQuery } from '@hooks/use-api-query';

import { Offer } from '@models/campaign';
import { TablePaginationState } from '@library/components';

import { offerService } from '@services/offer';
import { customerService } from '@services/customer';

const OffersListView = () => {
  const { currentInstitutionId } = useInstitution();

  const [pagination, setPagination] = useState<TablePaginationState>({
    pageIndex: 0,
    pageSize: 5
  });

  const {
    data: paginatedOffersResponse,
    isLoading: isLoadingPaginated,
    isError: isErrorPaginated,
    refetch: refetchPaginated,
    isFetching: isFetchingPaginatedOffers
  } = useApiQuery(
    offerService.get({
      params: {
        pagination
      },
      enabled: currentInstitutionId.length > 0
    })
  );

  const {
    data: customersResponse,
    isError: isErrorCustomers,
    isFetching
  } = useApiQuery(
    customerService.get({
      params: {
        pagination: {
          pageIndex: 0,
          pageSize: 2
        }
      },
      enabled: currentInstitutionId.length > 0
    })
  );

  const isCustomersListEmpty = useMemo(
    () =>
      isFetching ||
      isErrorCustomers ||
      customersResponse?.customers.length === 0,
    [customersResponse, isFetching, isErrorCustomers]
  );

  const totalOffers = useMemo(
    () =>
      isFetchingPaginatedOffers || !paginatedOffersResponse?.meta
        ? 0
        : paginatedOffersResponse?.meta.total,
    [paginatedOffersResponse]
  );

  useEffect(() => {
    refetchPaginated();
  }, []);

  const offers = useMemo(() => {
    if (!isLoadingPaginated && paginatedOffersResponse?.offers) {
      return paginatedOffersResponse.offers.map((offer) => ({
        ...offer,
        rewardPercent: offer.rewardPercent * 100
      }));
    }
    return [] as Offer[];
  }, [paginatedOffersResponse]);

  return (
    <OffersList
      offers={offers}
      isLoading={isLoadingPaginated || isFetchingPaginatedOffers}
      isError={isErrorPaginated}
      isCustomersListEmpty={isCustomersListEmpty}
      totalOffers={totalOffers}
      pagination={pagination}
      onPaginationChange={setPagination}
    />
  );
};

export { OffersListView };
