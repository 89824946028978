import { useFilter } from '@react-aria/i18n';
import { useComboBoxState as useAriaComboBoxState } from '@react-stately/combobox';
import { useListState } from '@react-stately/list';
import { Key, useRef } from 'react';
import { SelectionMode } from '../types';
import { getItemKey, useSelectedKey } from '../utils';
import { ComboBoxProps } from './types';

const selectionModes = {
  'mode.single': 'single',
  'mode.multiple': 'multiple'
} satisfies Record<SelectionMode, 'single' | 'multiple'>;

function useComboBoxState<TItemType extends object>({
  selectionMode,
  itemKey,
  selectedItem,
  selectedItems,
  onSelectionChange,
  textInputValue,
  onTextInputChange,
  ...props
}: ComboBoxProps<TItemType>) {
  const lastSingleSelectionTextInputValue = useRef(textInputValue || '');

  const selectionProps = useSelectedKey(
    selectionMode === 'mode.multiple'
      ? {
          itemKey,
          selectedItems,
          selectionMode
        }
      : {
          itemKey,
          selectedItem,
          selectionMode
        }
  );

  const { contains } = useFilter({ sensitivity: 'base' });

  // combo box only handles single selection
  // @ts-ignore
  const comboBoxState = useAriaComboBoxState({
    ...props,
    ...selectionProps,
    allowsEmptyCollection: true,
    defaultFilter: contains,
    menuTrigger: 'focus',
    shouldCloseOnBlur: true,
    inputValue: textInputValue,
    onInputChange:
      selectionMode === 'mode.multiple'
        ? onTextInputChange
        : (inputValue) => {
            // if input value is controlled, manually clear current selection
            // since React Aria won't do it
            if (textInputValue !== undefined && inputValue === '') {
              lastSingleSelectionTextInputValue.current = '';
              onSelectionChange?.(null);
            }
            onTextInputChange?.(inputValue);
          },
    onSelectionChange:
      selectionMode === 'mode.multiple'
        ? undefined
        : (selectedKey) => {
            if (selectedKey === null) {
              lastSingleSelectionTextInputValue.current = '';
              onSelectionChange?.(null);
            } else {
              // get the selected textValue, reverting to previous value if
              // nothing found
              const newSelection =
                comboBoxState.collection.getItem(selectedKey);
              if (newSelection) {
                lastSingleSelectionTextInputValue.current =
                  newSelection.textValue;
                onSelectionChange?.(newSelection.value);
              }
            }
          }
  });

  const items: TItemType[] = [];
  for (const node of comboBoxState.collection) {
    // this else basically can't happen
    /* istanbul ignore else -- @preserve */
    if (node.value) {
      items.push(node.value);
    }
  }

  // multiple selection state management that we will compose on top of combo
  // box as needed
  const listState = useListState({
    ...props,
    ...selectionProps,
    selectionMode: selectionModes[selectionMode],
    items,
    onSelectionChange:
      selectionMode === 'mode.multiple'
        ? (selection) => {
            /**
             * As far as I can tell 'all' is only possible in two cases:
             * 1. When the default selection is 'all' (but I have disabled that
             *    possibility via typing)
             * 2. When there is a select all button and a call is made to
             *    selectionManager.selectAll()
             *
             * Neither of those are allowed here, so we can't test the 'all'
             * case.
             */
            /* istanbul ignore next -- @preserve */
            const safeSelection =
              selection === 'all'
                ? new Set(listState.collection.getKeys())
                : selection;

            // convert set of Key into set of TItemType
            const newSelection: TItemType[] = [];

            // make map of previous selection for quick access
            const previousSelectionMap = new Map<Key, TItemType>();
            for (const previousSelectedItem of selectedItems) {
              previousSelectionMap.set(
                getItemKey(previousSelectedItem, itemKey),
                previousSelectedItem
              );
            }

            for (const selectedKey of safeSelection) {
              // try to find item in current collection, i.e., options shown in
              // the list
              const listItem = listState.collection.getItem(selectedKey);
              if (listItem?.value) {
                newSelection.push(listItem.value);
              } else {
                // if not in new selection, check previous selection and
                // reuse that item; this is to cover async options that are no
                // longer in the current collection or items that were added
                // via other means
                const previousSelectedItem =
                  previousSelectionMap.get(selectedKey);

                // should not be possible to have a selected key that doesn't
                // correspond to any item
                /* istanbul ignore else -- @preserve */
                if (previousSelectedItem) {
                  newSelection.push(previousSelectedItem);
                }
              }
            }

            onSelectionChange?.(new Set(newSelection));
          }
        : undefined
  });

  return selectionMode === 'mode.multiple'
    ? {
        ...comboBoxState,
        ...listState,
        // handle keyboard selection
        commit() {
          listState.selectionManager.toggleSelection(
            listState.selectionManager.focusedKey
          );
        }
      }
    : comboBoxState;
}

export { useComboBoxState };
