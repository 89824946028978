import { CampaignObjective } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface CampaignObjectiveDefinition {
  readonly id: CampaignObjective;
  readonly label: string;
  readonly description: string;
}

const { getById: campaignObjectiveDef, getAll: campaignObjectiveAllDefs } =
  createDefinition<CampaignObjective, CampaignObjectiveDefinition>({
    definitions: {
      DriveSales: {
        label: t('campaignmodel.OBJECTIVE_DRIVE_SALES'),
        description: t('campaignmodel.OBJECTIVE_DRIVE_SALES_DESCRIPTION')
      },
      BuildLoyalty: {
        label: t('campaignmodel.OBJECTIVE_BUILD_LOYALTY'),
        description: t('campaignmodel.OBJECTIVE_BUILD_LOYALTY_DESCRIPTION')
      },
      AcquireNewCustomers: {
        label: t('campaignmodel.OBJECTIVE_ACQUIRE_NEW_CUSTOMERS'),
        description: t(
          'campaignmodel.OBJECTIVE_ACQUIRE_NEW_CUSTOMERS_DESCRIPTION'
        )
      }
    },
    getUnknownDefinition(id: string) {
      return {
        id: id as CampaignObjective,
        label: id,
        description: id
      };
    }
  });

export { campaignObjectiveAllDefs, campaignObjectiveDef };
export type { CampaignObjectiveDefinition };
