import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/24/solid';

import { Modal, PrimaryButton } from '@library/components';
import { AddCustomersModalContent } from './AddCustomersModalContent';

const AddCustomersModal = () => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal
      isOpen={isModalOpen}
      trigger={
        <PrimaryButton size='size.md' onClick={() => setIsModalOpen(true)}>
          <PlusIcon className='mr-2 h-4 w-4' />
          {t('label.addCustomers')}
        </PrimaryButton>
      }
      header={
        <div className='p-4'>
          <h3 className='mb-2 text-2xl'>{t('label.addCustomers')}</h3>
          <p className='text-sm font-normal'>
            {t('label.chooseMethodForAddingCustomers')}
          </p>
        </div>
      }
      maxWidth='maxWidth.large'
      onClose={() => {
        setIsModalOpen(false);
      }}
      content={<AddCustomersModalContent setIsModalOpen={setIsModalOpen} />}
    />
  );
};

export { AddCustomersModal };
