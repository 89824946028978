import { classnames } from '@library/utils';
import {
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from '@heroicons/react/24/solid';
import * as RadixPortal from '@radix-ui/react-portal';
import * as RadixToast from '@radix-ui/react-toast';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../buttonlike';
import { SolidExclamationOctagonIcon } from '../icons';
import { reducer } from './reducer';
import { ToastData, ToastVariant } from './types';

function renderIcon(variant: ToastVariant) {
  const iconClasses = 'h-12 w-12';

  switch (variant) {
    case 'variant.error': {
      return <SolidExclamationOctagonIcon className={iconClasses} />;
    }
    case 'variant.info': {
      return <InformationCircleIcon className={iconClasses} />;
    }
    case 'variant.success': {
      return <CheckIcon className={iconClasses} />;
    }
    case 'variant.warning': {
      return <ExclamationTriangleIcon className={iconClasses} />;
    }
  }
}

const variantClasses = {
  'variant.error': 'bg-error',
  'variant.info': 'bg-info',
  'variant.success': 'bg-success',
  'variant.warning': 'bg-warning'
} satisfies Record<ToastVariant, string>;

function Toast({ id, variant, content }: ToastData) {
  const { t } = useTranslation();
  const { removeToast } = useToasts();

  const animationClasses =
    'data-[state=open]:animate-slideInLeft data-[state=closed]:animate-slideOutRight';

  return (
    <RadixToast.Root
      className={classnames(
        'z-[1000] flex w-fit max-w-lg overflow-hidden rounded-md border border-neutral bg-content shadow-md',
        animationClasses
      )}
      onOpenChange={(newOpenState) => {
        /**
         * The newOpenState = true case is impossible to test as far as I am
         * aware so we need to ignore that branch.
         * vitest removes comments so we need to specially tag it so that
         * istanbul can see to ignore it for coverage.
         * https://github.com/vitest-dev/vitest/issues/2021#issuecomment-1242909898
         */
        /* istanbul ignore else -- @preserve */
        if (!newOpenState) {
          // wait for exit animation to finish before removing
          setTimeout(() => {
            removeToast(id);
          }, 500);
        }
      }}
    >
      <div
        className={classnames(
          'flex items-center p-2 text-inverse',
          variantClasses[variant]
        )}
      >
        {renderIcon(variant)}
      </div>
      {/* https://github.com/tailwindlabs/tailwindcss/issues/835 for why min-w-0 is here */}
      <RadixToast.Description className='flex min-w-0 flex-1 items-center p-4 font-bold'>
        {content}
      </RadixToast.Description>
      <div className='flex items-center p-4'>
        <RadixToast.Close asChild={true}>
          <LinkButton>
            <span className='uppercase'>{t('common.close')}</span>
          </LinkButton>
        </RadixToast.Close>
      </div>
    </RadixToast.Root>
  );
}

interface ToastsContextValue {
  sendToast: (notification: Omit<ToastData, 'id'>) => void;
  removeToast: (id: string | number) => void;
  clearAll: () => void;
}

const ToastsContext = createContext<ToastsContextValue | undefined>(undefined);

function useToasts() {
  const context = useContext(ToastsContext);
  if (context === undefined) {
    throw new Error('useToasts can only be used within a ToastsProvider');
  }
  return context;
}

const initialToastsState: ToastData[] = [];

interface ToastsProviderProps {
  children?: React.ReactNode;
  defaultDuration?: number;
}

function ToastsProvider({
  children,
  defaultDuration = 4000
}: ToastsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialToastsState);

  const sendToast = useCallback((notification: Omit<ToastData, 'id'>) => {
    dispatch({ type: 'ADD', notification });
  }, []);

  const removeToast = useCallback((id: string | number) => {
    dispatch({ type: 'REMOVE', id });
  }, []);

  const clearAll = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  const value = { sendToast, removeToast, clearAll };

  return (
    <RadixToast.Provider duration={defaultDuration}>
      <ToastsContext.Provider value={value}>
        {children}
        {state.map(({ id, variant, content }) => (
          <Toast key={id} id={id} variant={variant} content={content} />
        ))}
      </ToastsContext.Provider>
      <RadixPortal.Root asChild={true}>
        <RadixToast.Viewport className='fixed right-8 top-20 z-[1000] flex flex-col items-end gap-2' />
      </RadixPortal.Root>
    </RadixToast.Provider>
  );
}

export { ToastsProvider, initialToastsState, useToasts };
