import { MerchantProductCategory } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface MerchantProductCategoryDefinition {
  readonly id: MerchantProductCategory;
  readonly label: string;
}

const {
  getById: merchantProductCategoryDef,
  getAll: merchantProductCategoryAllDefs
} = createDefinition<
  MerchantProductCategory,
  MerchantProductCategoryDefinition
>({
  definitions: {
    Membership: {
      label: t('merchantmodel.PRODUCT_CATEGORY_MEMBERSHIP')
    },
    Subscription: {
      label: t('merchantmodel.PRODUCT_CATEGORY_SUBSCRIPTION')
    },
    Fuel: {
      label: t('merchantmodel.PRODUCT_CATEGORY_FUEL')
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as MerchantProductCategory,
      label: id
    };
  }
});

export { merchantProductCategoryAllDefs, merchantProductCategoryDef };
export type { MerchantProductCategoryDefinition };
