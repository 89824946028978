import { customerAdapter } from '@adapters/customer';
import { ServiceMutation } from '../service';
// import { CreateTransactionRequest } from '@models/customer';

type CustomerTypeCreateVariables = {
  payload: any[];
};

const createOfferTransaction: ServiceMutation<
  any,
  CustomerTypeCreateVariables
> = async (params) => {
  const { payload } = params;

  const response = await customerAdapter.postOfferTransaction({
    payload
  });

  return {
    response
  };
};

export { createOfferTransaction };
