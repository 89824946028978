import { LinkButton, Modal } from '@/library/components';
import { OfferInstructionsModalContent } from './OfferInstructionsModalContent';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setCookie } from '@/utils/common';
import { useTranslation } from 'react-i18next';

interface InstructionsModalProps {
  autoTrigger?: boolean;
}

const OfferInstructionsModal = ({
  autoTrigger = false
}: InstructionsModalProps) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const cookieName = 'tomatoOfferInstructions';
  const cookie = localStorage.getItem(cookieName);

  useEffect(() => {
    if (autoTrigger && location.pathname.includes('/offers') && !cookie) {
      setIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setCookie(cookieName);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      trigger={
        !autoTrigger && (
          <LinkButton onClick={() => setIsOpen(true)}>
            {t('label.offerInstructions')}
          </LinkButton>
        )
      }
      header={
        <div>
          <h2>How to add offers</h2>
        </div>
      }
      content={<OfferInstructionsModalContent />}
    />
  );
};

export { OfferInstructionsModal };
