import React from 'react';
import { i18Instance } from '@/i18n';
import { I18nextProvider } from 'react-i18next';
import { ToastsProvider } from '@library/components';
import { ApiServiceProvider } from './api-service';
import { AuthProvider } from './auth';
import { InstitutionProvider } from './institution';

interface ContextProvidersProps {
  children: React.ReactNode;
}

const ContextProviders = ({ children }: ContextProvidersProps) => (
  <I18nextProvider i18n={i18Instance}>
    <ToastsProvider>
      <ApiServiceProvider>
        <InstitutionProvider>
          <AuthProvider>{children}</AuthProvider>
        </InstitutionProvider>
      </ApiServiceProvider>
    </ToastsProvider>
  </I18nextProvider>
);

export { ContextProviders };
