import { z } from 'zod';

import { textFieldSchema } from '@library/components/forms/fields/text/schema';
import { numberFieldSchema } from '@library/components/inputs/number/schema';

export type EnrollmentStatusLabelEnum = 'INACTIVE' | 'ACTIVE';
export type EnrollmentStatusEnum = 'Opted out' | 'Opted in';

interface EnrollmentStatusEnumDefinition {
  readonly id: EnrollmentStatusLabelEnum;
}

const EnrollmentStatusType: EnrollmentStatusEnumDefinition[] = [
  {
    id: 'INACTIVE'
  },
  {
    id: 'ACTIVE'
  }
];

const ENROLLMENT_STATUS = ['INACTIVE', 'ACTIVE'] as const;
const enrollmentStatusEnum = z.enum(ENROLLMENT_STATUS);

const createCustomerSchema = z.object({
  sourceCustomerId: textFieldSchema({ optional: false, max: 50 }),
  sourceAccountId: textFieldSchema({ optional: false, max: 50 }),
  sourceOrganizationId: textFieldSchema({ optional: false, max: 50 }),
  sourcePortfolioId: textFieldSchema({ optional: false, max: 50 }),
  enrollmentOptInStatus: z.object({ id: enrollmentStatusEnum }).required(),
  lifetimeEarnedAmount: numberFieldSchema.required()
});

type CreateCustomerFormSchemaType = z.infer<typeof createCustomerSchema>;

export { createCustomerSchema, EnrollmentStatusType };
export type { CreateCustomerFormSchemaType, EnrollmentStatusEnumDefinition };
