import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { Institution } from '@models/institution';

const get: AdapterFunction<Institution[]> = ({ signal }) => {
  const url = '/v2/institutions';

  apiTransport.setBaseUrl(process.env.PM_API_URL);

  const response = apiTransport.get<Institution[]>({
    url,
    config: {
      signal
    }
  });

  apiTransport.setBaseUrl(process.env.API_URL);

  return response;
};

export { get };
