// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
const SvgError403 = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width={438}
    height={550}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <ellipse
      opacity={0.03}
      cx={219}
      cy={254.172}
      rx={219}
      ry={219.172}
      fill='#5CD6FF'
    />
    <ellipse
      opacity={0.1}
      cx={218.529}
      cy={248.379}
      rx={204.529}
      ry={204.69}
      fill='#5CD6FF'
    />
    <g opacity={0.65}>
      <path
        opacity={0.42}
        d='m263.468 290.202-.03.051v.06c0 1.346-1.14 2.696-3.378 3.969-2.22 1.264-5.448 2.41-9.455 3.375-8.011 1.929-19.09 3.124-31.335 3.124-12.304 0-23.436-1.195-31.486-3.124-4.027-.965-7.27-2.111-9.502-3.375-2.248-1.274-3.393-2.623-3.393-3.969v-.06l-.031-.051L26.382 37.487h385.236l-148.15 252.715Z'
        fill='url(#error-403_svg__a)'
        stroke='url(#error-403_svg__b)'
        strokeWidth={0.438}
      />
      <path
        opacity={0.65}
        d='m248.902 287.872-.021.057v.06c0 1.333-.762 2.648-2.242 3.887-1.479 1.238-3.637 2.367-6.334 3.322-5.39 1.908-12.858 3.095-21.122 3.095-8.305 0-15.809-1.187-21.225-3.095-2.71-.955-4.879-2.085-6.365-3.323-1.488-1.239-2.253-2.554-2.253-3.886v-.06l-.021-.057L93.074 36.328h251.853l-96.025 251.544Z'
        fill='url(#error-403_svg__c)'
        stroke='url(#error-403_svg__d)'
        strokeWidth={0.657}
      />
      <path
        d='m233.345 284.429-.007.037v.038c0 2.736-1.543 5.253-4.119 7.104-2.575 1.849-6.155 3.007-10.129 3.007-3.995 0-7.593-1.158-10.181-3.008-2.589-1.851-4.139-4.368-4.139-7.103v-.038l-.007-.037L162.19 38.973h113.62L233.345 284.43Z'
        fill='url(#error-403_svg__e)'
        stroke='url(#error-403_svg__f)'
        strokeWidth={0.876}
      />
    </g>
    <path
      d='M144.178 328.67c0-41.792 33.705-75.67 75.282-75.67s75.282 33.878 75.282 75.67v41.29H144.178v-41.29Z'
      fill='#fff'
    />
    <path
      d='M191.909 365.39v.875H248.196v-61.848c0-15.565-12.599-28.186-28.143-28.186-15.545 0-28.144 12.621-28.144 28.186v60.973Z'
      fill='#64B5F6'
    />
    <path
      d='M191.909 365.39v.875H248.196v-61.848c0-15.565-12.599-28.186-28.143-28.186-15.545 0-28.144 12.621-28.144 28.186v60.973Z'
      fill='url(#error-403_svg__g)'
    />
    <path
      d='M191.909 365.39v.875H248.196v-61.848c0-15.565-12.599-28.186-28.143-28.186-15.545 0-28.144 12.621-28.144 28.186v60.973Z'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <ellipse cx={220.053} cy={304.417} rx={20.926} ry={20.959} fill='#484848' />
    <ellipse cx={220.053} cy={306.958} rx={17.121} ry={17.148} fill='#fff' />
    <ellipse cx={220.053} cy={306.958} rx={17.121} ry={17.148} fill='#fff' />
    <ellipse cx={220.053} cy={297.193} rx={5.707} ry={5.716} fill='#484848' />
    <path
      d='M224.492 294.652a1.904 1.904 0 0 0-1.903-1.905c-1.05 0-1.902.853-1.902 1.905s.852 1.905 1.902 1.905a1.904 1.904 0 0 0 1.903-1.905ZM220.687 297.192a.635.635 0 1 0-1.27 0 .635.635 0 0 0 1.27 0Z'
      fill='#fff'
    />
    <path
      d='M143.302 369.96v.876h152.315V328.67c0-42.271-34.092-76.546-76.157-76.546s-76.158 34.275-76.158 76.546v41.29Z'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      opacity={0.21}
      d='M146.292 322.414c0-38.294 32.179-69.337 71.874-69.337 39.695 0 71.875 31.043 71.875 69.337v37.835H146.292v-37.835Z'
      fill='url(#error-403_svg__h)'
    />
    <path
      d='M219.46 436.737c48.005 0 91.492-4.323 123.001-11.324 15.748-3.499 28.551-7.677 37.439-12.346 4.443-2.334 7.95-4.813 10.357-7.426 2.411-2.618 3.76-5.421 3.76-8.371 0-2.935-1.337-5.929-3.723-8.888-2.388-2.963-5.878-5.953-10.317-8.903-8.878-5.901-21.677-11.722-37.434-16.86-31.525-10.28-75.037-17.877-123.083-17.877-48.046 0-91.558 7.597-123.083 17.877-15.757 5.138-28.556 10.959-37.434 16.86-4.439 2.95-7.929 5.94-10.317 8.903-2.386 2.959-3.723 5.953-3.723 8.888 0 2.95 1.349 5.753 3.76 8.371 2.407 2.613 5.914 5.092 10.357 7.426 8.888 4.669 21.691 8.847 37.439 12.346 31.509 7.001 74.996 11.324 123.001 11.324Z'
      fill='#D7F1FA'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M392.703 397.27c0 2.522-1.148 5.022-3.412 7.481-2.267 2.462-5.632 4.857-10.002 7.153-8.739 4.591-21.414 8.738-37.113 12.227-31.39 6.974-74.777 11.292-122.716 11.292-47.939 0-91.326-4.318-122.716-11.292-15.7-3.489-28.374-7.636-37.113-12.227-4.37-2.296-7.735-4.691-10.002-7.153-2.264-2.459-3.412-4.959-3.412-7.481 0-2.521 1.148-5.021 3.412-7.481 2.267-2.461 5.632-4.857 10.002-7.152 8.74-4.591 21.414-8.739 37.113-12.227 31.39-6.975 74.777-11.293 122.716-11.293 47.939 0 91.326 4.318 122.716 11.293 15.699 3.488 28.374 7.636 37.113 12.227 4.37 2.295 7.735 4.691 10.002 7.152 2.264 2.46 3.412 4.96 3.412 7.481Z'
      fill='#0075C9'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='m153.494 440.34-.015-.046c-.053-.159-.262-.577-.652-1.277-.371-.668-.862-1.515-1.426-2.487l-.006-.012c-1.412-2.434-3.259-5.622-4.679-8.505-.71-1.443-1.326-2.835-1.727-4.033-.2-.6-.353-1.17-.435-1.687-.079-.505-.101-1.023.015-1.487l8.925 19.534Zm0 0 .021.044m-.021-.044.021.044m0 0c1.031 2.238 3.596 4.092 7.164 5.641 3.611 1.567 8.432 2.899 14.25 3.981 11.641 2.166 27.408 3.355 45.771 3.355s34.13-1.189 45.771-3.355c5.818-1.082 10.639-2.414 14.25-3.981 3.568-1.549 6.133-3.403 7.164-5.641l.02-.044m-134.39.044 134.39-.044m0 0 .016-.046m-.016.046.016-.046m0 0c.052-.159.262-.577.651-1.277m-.651 1.277.651-1.277m0 0c.372-.668.863-1.515 1.426-2.487m-1.426 2.487 1.426-2.487m0 0 .007-.012m-.007.012.007-.012m0 0c1.411-2.435 3.259-5.622 4.678-8.505m-4.678 8.505 4.678-8.505m0 0c.711-1.443 1.326-2.835 1.727-4.033m-1.727 4.033 1.727-4.033m0 0c.201-.6.354-1.17.435-1.687m-.435 1.687.435-1.687m0 0c.08-.505.102-1.023-.014-1.487m.014 1.487-.014-1.487m0 0c-.29-1.164-1.312-2.154-2.62-3.006m2.62 3.006-2.62-3.006m0 0c-1.346-.876-3.174-1.72-5.381-2.526m5.381 2.526-5.381-2.526m0 0c-4.419-1.614-10.494-3.125-17.598-4.423m17.598 4.423-17.598-4.423m0 0c-14.218-2.598-32.678-4.363-50.532-4.363m50.532 4.363-50.532-4.363m0 0c-17.854 0-36.315 1.765-50.532 4.363m50.532-4.363-50.532 4.363m0 0c-7.105 1.298-13.179 2.809-17.598 4.423m17.598-4.423-17.598 4.423m0 0c-2.207.806-4.036 1.65-5.382 2.526m5.382-2.526-5.382 2.526m0 0c-1.307.852-2.329 1.842-2.619 3.006l2.619-3.006Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M220.7 452.923c18.351 0 34.977-1.397 47.025-3.659 6.021-1.131 10.92-2.482 14.325-3.994 1.701-.755 3.053-1.561 3.987-2.418.933-.856 1.491-1.804 1.491-2.835 0-1.03-.558-1.979-1.491-2.835-.934-.857-2.286-1.663-3.987-2.418-3.405-1.512-8.304-2.863-14.325-3.993-12.048-2.263-28.674-3.66-47.025-3.66-18.352 0-34.978 1.397-47.026 3.66-6.021 1.13-10.92 2.481-14.325 3.993-1.701.755-3.053 1.561-3.987 2.418-.933.856-1.49 1.805-1.49 2.835 0 1.031.557 1.979 1.49 2.835.934.857 2.286 1.663 3.987 2.418 3.405 1.512 8.304 2.863 14.325 3.994 12.048 2.262 28.674 3.659 47.026 3.659Z'
      fill='#0078BF'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.35}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M220.645 451.297c35.684 0 64.612-5.05 64.612-11.28 0-6.23-28.928-11.28-64.612-11.28s-64.612 5.05-64.612 11.28c0 6.23 28.928 11.28 64.612 11.28Zm0 0c34.375 0 62.241-4.784 62.241-10.686s-27.866-10.687-62.241-10.687c-34.374 0-62.24 4.785-62.24 10.687 0 5.902 27.866 10.686 62.24 10.686Z'
      fill='#5CD6FF'
    />
    <path
      opacity={0.35}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M220.444 432.245c92.661 0 167.777-15.664 167.777-34.988 0-19.323-75.116-34.988-167.777-34.988-92.661 0-167.777 15.665-167.777 34.988 0 19.324 75.116 34.988 167.777 34.988Zm0 0c89.26 0 161.62-14.84 161.62-33.146 0-18.307-72.36-33.147-161.62-33.147-89.261 0-161.62 14.84-161.62 33.147 0 18.306 72.359 33.146 161.62 33.146Z'
      fill='#5CD6FF'
    />
    <path
      d='M273.173 441.205c0 .233-.14.609-.762 1.116-.61.498-1.564 1.018-2.876 1.535-2.616 1.033-6.452 1.98-11.256 2.782-9.593 1.601-22.88 2.596-37.579 2.596-14.699 0-27.986-.995-37.58-2.596-4.803-.802-8.64-1.749-11.256-2.782-1.312-.517-2.265-1.037-2.876-1.535-.621-.507-.762-.883-.762-1.116 0-.233.141-.61.762-1.117.611-.498 1.564-1.017 2.876-1.535 2.616-1.032 6.453-1.98 11.256-2.782 9.594-1.601 22.881-2.596 37.58-2.596 14.699 0 27.986.995 37.579 2.596 4.804.802 8.64 1.75 11.256 2.782 1.312.518 2.266 1.037 2.876 1.535.622.507.762.884.762 1.117Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='M80.752 401.864c2.832 0 5.418-.54 7.312-1.433 1.86-.876 3.203-2.174 3.203-3.754 0-1.581-1.343-2.878-3.203-3.755-1.894-.893-4.48-1.433-7.312-1.433-2.833 0-5.418.54-7.312 1.433-1.86.877-3.203 2.174-3.203 3.755 0 1.58 1.343 2.878 3.203 3.754 1.894.893 4.48 1.433 7.312 1.433Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M87.71 393.055v-.296l-.275-.11c-1.817-.728-4.15-1.16-6.683-1.16-2.533 0-4.866.432-6.683 1.16l-.276.11V396.677c0 3.848 3.115 6.968 6.959 6.968 3.844 0 6.958-3.12 6.958-6.968v-3.622Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M87.272 396.677c0 3.607-2.919 6.53-6.52 6.53 2.37 0 2.964-1.979 2.964-2.968v-8.103c1.322.191 2.528.507 3.556.919v3.622Z'
      fill='#fff'
    />
    <path
      d='M114.54 416.175c1.691 0 3.243-.345 4.389-.925 1.121-.568 1.976-1.431 1.976-2.512s-.855-1.945-1.976-2.512c-1.146-.58-2.698-.926-4.389-.926-1.691 0-3.244.346-4.39.926-1.121.567-1.976 1.431-1.976 2.512s.855 1.944 1.976 2.512c1.146.58 2.699.925 4.39.925Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M118.813 410.451v-.289l-.265-.114c-1.097-.471-2.496-.748-4.008-.748-1.513 0-2.912.277-4.009.748l-.265.114v2.576c0 2.489 1.884 4.562 4.274 4.562 2.389 0 4.273-2.073 4.273-4.562v-2.287Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M118.375 412.738c0 2.278-1.717 4.124-3.835 4.124 1.394 0 1.743-1.249 1.743-1.874v-5.118a8.69 8.69 0 0 1 2.092.581v2.287Z'
      fill='#fff'
    />
    <path
      d='M326.751 416.175c1.691 0 3.244-.345 4.389-.925 1.122-.568 1.977-1.431 1.977-2.512s-.855-1.945-1.977-2.512c-1.145-.58-2.698-.926-4.389-.926-1.691 0-3.243.346-4.389.926-1.121.567-1.976 1.431-1.976 2.512s.855 1.944 1.976 2.512c1.146.58 2.698.925 4.389.925Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M331.025 410.451v-.289l-.265-.114c-1.097-.471-2.496-.748-4.009-.748-1.512 0-2.912.277-4.008.748l-.265.114v2.576c0 2.489 1.884 4.562 4.273 4.562 2.39 0 4.274-2.073 4.274-4.562v-2.287Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M330.587 412.738c0 2.278-1.717 4.124-3.836 4.124 1.395 0 1.744-1.249 1.744-1.874v-5.118c.777.121 1.487.32 2.092.581v2.287Z'
      fill='#fff'
    />
    <path
      d='M358.168 401.864c2.833 0 5.418-.54 7.312-1.433 1.86-.876 3.203-2.174 3.203-3.754 0-1.581-1.343-2.878-3.203-3.755-1.894-.893-4.479-1.433-7.312-1.433-2.832 0-5.418.54-7.312 1.433-1.86.877-3.203 2.174-3.203 3.755 0 1.58 1.343 2.878 3.203 3.754 1.894.893 4.48 1.433 7.312 1.433Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M365.126 393.055v-.296l-.275-.11c-1.817-.728-4.15-1.16-6.683-1.16-2.533 0-4.866.432-6.683 1.16l-.275.11V396.677c0 3.848 3.114 6.968 6.958 6.968 3.844 0 6.958-3.12 6.958-6.968v-3.622Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M364.689 396.677c0 3.607-2.92 6.53-6.521 6.53 2.371 0 2.964-1.979 2.964-2.968v-8.103c1.322.191 2.528.507 3.557.919v3.622Z'
      fill='#fff'
    />
    <path
      d='M298.298 388.928c3.65 0 6.975-.68 9.405-1.798 2.391-1.1 4.074-2.709 4.074-4.64 0-1.931-1.683-3.54-4.074-4.64-2.43-1.118-5.755-1.797-9.405-1.797-3.649 0-6.975.679-9.404 1.797-2.392 1.1-4.074 2.709-4.074 4.64 0 1.931 1.682 3.54 4.074 4.64 2.429 1.118 5.755 1.798 9.404 1.798Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M307.174 377.916v-.299l-.278-.109c-2.334-.912-5.335-1.455-8.598-1.455-3.263 0-6.264.543-8.597 1.455l-.279.109v4.873c0 4.807 3.984 8.687 8.876 8.687 4.893 0 8.876-3.88 8.876-8.687v-4.574Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M306.737 382.49c0 4.556-3.778 8.25-8.439 8.25 3.069 0 3.836-2.5 3.836-3.75v-10.236c1.711.242 3.272.642 4.603 1.162v4.574Z'
      fill='#fff'
    />
    <path
      d='M142.993 388.928c3.649 0 6.975-.68 9.404-1.798 2.392-1.1 4.074-2.709 4.074-4.64 0-1.931-1.682-3.54-4.074-4.64-2.429-1.118-5.755-1.797-9.404-1.797-3.65 0-6.975.679-9.405 1.797-2.391 1.1-4.074 2.709-4.074 4.64 0 1.931 1.683 3.54 4.074 4.64 2.43 1.118 5.755 1.798 9.405 1.798Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M151.869 377.916v-.299l-.279-.109c-2.333-.912-5.334-1.455-8.597-1.455s-6.264.543-8.598 1.455l-.278.109v4.873c0 4.807 3.983 8.687 8.876 8.687 4.892 0 8.876-3.88 8.876-8.687v-4.574Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M151.431 382.49c0 4.556-3.778 8.25-8.438 8.25 3.068 0 3.835-2.5 3.835-3.75v-10.236c1.711.242 3.272.642 4.603 1.162v4.574Z'
      fill='#fff'
    />
    <path
      d='M221.238 383.803c4.304 0 8.221-.792 11.079-2.089 2.819-1.28 4.771-3.138 4.771-5.349 0-2.21-1.952-4.068-4.771-5.348-2.858-1.298-6.775-2.089-11.079-2.089-4.303 0-8.221.791-11.079 2.089-2.818 1.28-4.771 3.138-4.771 5.348 0 2.211 1.953 4.069 4.771 5.349 2.858 1.297 6.776 2.089 11.079 2.089Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      d='M231.649 371.029v-.301l-.281-.108c-2.746-1.059-6.282-1.692-10.13-1.692-3.847 0-7.383.633-10.13 1.692l-.28.108V376.365c0 5.572 4.676 10.063 10.41 10.063 5.735 0 10.411-4.491 10.411-10.063v-5.336Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={0.876}
    />
    <path
      opacity={0.42}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M231.211 376.365c0 5.316-4.465 9.625-9.973 9.625 3.627 0 4.533-2.917 4.533-4.375v-11.941c2.022.282 3.867.748 5.44 1.355v5.336Z'
      fill='#fff'
    />
    <g opacity={0.65} fill='#fff' stroke='#484848'>
      <path
        d='M251.035 473.241c0-1.206-.756-2.429-2.3-3.609-1.538-1.174-3.79-2.25-6.613-3.162-5.64-1.821-13.459-2.955-22.116-2.955s-16.476 1.134-22.116 2.955c-2.823.912-5.076 1.988-6.613 3.162-1.544 1.18-2.301 2.403-2.301 3.609 0 1.205.757 2.429 2.301 3.608 1.537 1.174 3.79 2.25 6.613 3.162 5.64 1.822 13.459 2.956 22.116 2.956s16.476-1.134 22.116-2.956c2.823-.912 5.075-1.988 6.613-3.162 1.544-1.179 2.3-2.403 2.3-3.608Z'
        strokeWidth={0.876}
      />
      <path
        opacity={0.5}
        d='M284.011 490c0 2.073-1.66 4.138-4.896 6.083-3.217 1.933-7.892 3.685-13.699 5.161-11.609 2.95-27.664 4.779-45.41 4.779-17.746 0-33.801-1.829-45.41-4.779-5.807-1.476-10.483-3.228-13.699-5.161-3.237-1.945-4.896-4.01-4.896-6.083 0-2.072 1.659-4.138 4.896-6.083 3.216-1.932 7.892-3.684 13.699-5.16 11.609-2.951 27.664-4.779 45.41-4.779 17.746 0 33.801 1.828 45.41 4.779 5.807 1.476 10.482 3.228 13.699 5.16 3.236 1.945 4.896 4.011 4.896 6.083Z'
        strokeWidth={0.657}
      />
      <path
        opacity={0.2}
        d='M314.889 511.02c0 3.044-2.56 6.011-7.363 8.755-4.787 2.735-11.728 5.205-20.324 7.282-17.189 4.153-40.947 6.724-67.196 6.724-26.25 0-50.007-2.571-67.196-6.724-8.596-2.077-15.537-4.547-20.324-7.282-4.803-2.744-7.363-5.711-7.363-8.755 0-3.044 2.56-6.01 7.363-8.754 4.787-2.735 11.728-5.205 20.324-7.282 17.189-4.153 40.946-6.724 67.196-6.724 26.249 0 50.007 2.571 67.196 6.724 8.596 2.077 15.537 4.547 20.324 7.282 4.803 2.744 7.363 5.71 7.363 8.754Z'
        strokeWidth={0.438}
      />
    </g>
    <path
      d='m255.313 1.509-.219-.22h-.311l-72.471-.039h-.311l-.22.22-51.272 51.217-.22.22v.31l-.039 72.471v.311l.219.22 51.218 51.272.219.22h.311l72.471.039h.311l.22-.219 51.272-51.218.22-.219v-.311l.039-72.471v-.31l-.219-.22-51.218-51.273Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={1.5}
    />
    <path
      d='m252.747 7.78-.256-.256h-.363l-67.201-.036h-.362l-.257.256-47.544 47.492-.256.257v.363l-.037 67.2v.363l.257.257 47.492 47.543.256.257h.363l67.201.036h.362l.257-.256 47.544-47.492.256-.257v-.363l.037-67.2v-.363l-.257-.257-47.492-47.543Z'
      fill='#64B5F6'
      stroke='#484848'
      strokeWidth={1.752}
    />
    <path
      d='m222.724 104.192-.484.291v14.331a3.371 3.371 0 0 1-3.374 3.368 3.37 3.37 0 0 1-3.374-3.368v-14.423l-.463-.295a7.288 7.288 0 0 1-3.386-6.158c0-4.03 3.272-7.299 7.31-7.299 4.039 0 7.31 3.27 7.31 7.299a7.294 7.294 0 0 1-3.539 6.254Zm-22.627-26.856V63.522c0-10.3 8.363-18.654 18.681-18.654 10.319 0 18.682 8.353 18.682 18.654v13.754c-6.178-1.06-12.892-1.914-18.507-1.914-5.721 0-12.583.886-18.856 1.974Zm49.51 2.367V63.434c0-16.96-13.856-30.7-30.829-30.7-16.972 0-30.828 13.74-30.828 30.7V79.78c-.807.184-1.554.357-2.23.517-2.936.695-4.943 3.32-4.943 6.307v38.851c0 2.924 1.926 5.512 4.784 6.26 7.125 1.865 22.347 5.481 33.392 5.481 11.046 0 26.267-3.616 33.392-5.481 2.859-.748 4.785-3.336 4.785-6.26v-38.85c0-2.987-2.007-5.613-4.943-6.307-.773-.183-1.639-.384-2.58-.597Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={2}
    />
    <path
      d='m157.966 234.413 2.793-10.422 7.629 7.63-10.422 2.792ZM177.496 232.924l4.26-3.268.7 5.324-4.96-2.056ZM249.442 250.655l3.759-6.016 3.331 6.264-7.09-.248Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.5}
    />
    <path
      d='m264.801 211.081 17.158 2.052-10.356 13.834-6.802-15.886Z'
      fill='#fff'
      stroke='#484848'
    />
    <path
      d='m182.493 203.971 12.457 4.272-9.929 8.652-2.528-12.924Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.75}
    />
    <path
      d='m276.409 240.469 5.013 4.152-6.103 2.265 1.09-6.417Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.5}
    />
    <path
      d='m152.88 264.207-.294 4.023-3.337-2.266 3.631-1.757ZM293.88 285.207l-.294 4.023-3.337-2.266 3.631-1.757Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={0.25}
    />
    <path
      d='m218.344 162.229 30.729 25.454-37.409 13.885 6.68-39.339Z'
      fill='#fff'
      stroke='#484848'
      strokeWidth={1.5}
    />
    <defs>
      <linearGradient
        id='error-403_svg__a'
        x1={219}
        y1={301}
        x2={219}
        y2={-159.263}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__b'
        x1={219}
        y1={301}
        x2={219}
        y2={37.268}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#484848' />
        <stop offset={1} stopColor='#484848' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__c'
        x1={219}
        y1={298.621}
        x2={219}
        y2={-159.263}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__d'
        x1={219}
        y1={298.621}
        x2={219}
        y2={36}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#484848' />
        <stop offset={1} stopColor='#484848' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__e'
        x1={219}
        y1={295.053}
        x2={219}
        y2={-159.264}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__f'
        x1={219}
        y1={295.053}
        x2={219}
        y2={38.536}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#484848' />
        <stop offset={1} stopColor='#484848' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__g'
        x1={220.053}
        y1={240.158}
        x2={220.053}
        y2={341.367}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='error-403_svg__h'
        x1={218.166}
        y1={281.366}
        x2={218.166}
        y2={350.457}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgError403);
export default ForwardRef;
