import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { OffersResponse } from '@models/ads';

interface GetByIdParams {
  sourceCustomerId: string;
}

const getOffersById: AdapterFunction<OffersResponse, GetByIdParams> = (
  { sourceCustomerId },
  { signal }
) => {
  const url = `/v1/customers/ads`;

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: sourceCustomerId
  });

  const response = apiTransport.post<OffersResponse>({
    url,
    body: {
      channel: 'OLB',
      supplyContext: {
        channel: 1,
        sectionIds: null
      },
      abc: null,
      demandModifier: {
        maxAdLimit: -1,
        visibilityStates: ['VISIBLE', 'PARKED']
      }
    },
    config: {
      signal
    }
  });

  apiTransport.setHeader({ key: 'X-Cdlx-Customer-Id', value: '' });

  return response;
};

export type { GetByIdParams };
export { getOffersById };
