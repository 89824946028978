import { classnames } from '@library/utils';
import React, { AnchorHTMLAttributes } from 'react';
import { ButtonlikeSize, ButtonlikeVariant, ButtonlikeWidth } from '../types';
import { useButtonClasses } from '../use-button-classes';
import { BaseAnchor } from './base';

interface SecondaryAnchorProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
  disabled?: boolean;
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  href: string;
  width?: ButtonlikeWidth;
}

const SecondaryAnchor = React.forwardRef<
  HTMLAnchorElement,
  SecondaryAnchorProps
>(function SecondaryAnchorWithRef(
  {
    size = 'size.md',
    variant = 'variant.base',
    disabled = false,
    width = 'width.unset',
    ...delegated
  },
  ref
) {
  return (
    <BaseAnchor
      {...delegated}
      disabled={disabled}
      className={classnames(
        useButtonClasses({ size, usage: 'usage.secondary', variant, width })
      )}
      ref={ref}
    />
  );
});

export { SecondaryAnchor };
export type { SecondaryAnchorProps };
