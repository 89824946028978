import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@heroicons/react/24/outline';

import {
  Dropdown,
  DropdownItem,
  IconButton,
  Tooltip
} from '@library/components';
import { DatePicker } from '@library/components/date-picker';
import { NumberInput } from '@library/components/inputs';

import { useInstitution } from '@/context-providers/institution';

import { Transaction, useOffer } from '@/context-providers/offer';

interface RedemptionFormProps {
  order: number;
  transaction: Transaction;
}

const transactionTypes = [
  {
    id: 'CLEAR',
    label: 'CLEAR'
  },
  {
    id: 'AUTHORIZED',
    label: 'AUTHORIZED'
  },
  {
    id: 'RETURN',
    label: 'RETURN'
  },
  {
    id: 'CHARGEBACK',
    label: 'CHARGEBACK'
  }
];

const RedemptionForm = ({ order, transaction }: RedemptionFormProps) => {
  const [t] = useTranslation();

  const transactionDetail = useMemo(() => transaction, [transaction]);

  const { currency } = useInstitution();

  const {
    transactionStrings,
    isLoadingTransactionsStrings,
    advertisement,
    handleRemovePendingTransaction,
    handleUpdateTransaction
  } = useOffer();

  const isTransactionStringsEmpty = useMemo(
    () => transactionStrings.length === 0,
    [transactionStrings]
  );

  const handleRemoveTransactionClick = () => {
    handleRemovePendingTransaction(transaction.localId);
  };

  const handleNumberInputChange = (value) => {
    const newTransaction = { ...transaction, transactionAmount: value };

    handleUpdateTransaction({ newTransaction, targetId: transaction.localId });
  };

  const handleTransactionStringChange = (item) => {
    const newTransaction = { ...transaction, transactionString: item };

    handleUpdateTransaction({ newTransaction, targetId: transaction.localId });
  };

  const handleTransactionTypeChange = (item) => {
    const newTransaction = { ...transaction, transactionType: item };

    handleUpdateTransaction({ newTransaction, targetId: transaction.localId });
  };

  const handleAuthorizationDateChange = (date) => {
    const newTransaction = { ...transaction, authorizationDate: date };

    handleUpdateTransaction({ newTransaction, targetId: transaction.localId });
  };

  const handlePostedDateChange = (date) => {
    const newTransaction = { ...transaction, postedDate: date };

    handleUpdateTransaction({ newTransaction, targetId: transaction.localId });
  };

  return isLoadingTransactionsStrings ? (
    <div className='w-full py-8'>
      <div className='pulsate mb-2 h-28 w-full animate-pulsate' />
    </div>
  ) : (
    <div className='border-t border-t-gray-lightGray py-8'>
      <div className='mb-4 flex items-center'>
        <h3 className='mr-2 text-lg font-bold'>
          {`Transaction #${order + 1}`}
        </h3>
        <Tooltip
          content={t('label.removeTransaction')}
          placement='placement.top'
        >
          <IconButton
            aria-label={`${t('label.removeTransaction')}`}
            size='size.md'
            variant='variant.primary'
            onClick={handleRemoveTransactionClick}
          >
            <TrashIcon className='w-4 text-gray-darkGray' />
          </IconButton>
        </Tooltip>
      </div>
      <div className='grid grid-cols-4 gap-4'>
        <div>
          <p>{t('label.transactionType')}</p>
          <Dropdown
            selectedItem={transactionDetail.transactionType}
            onSelectionChange={handleTransactionTypeChange}
            itemKey='id'
            items={transactionTypes}
            size='size.md'
            aria-label={`${t('label.transactionType')}`}
            className='dropdown-class text-sm'
            forceIsOpen={false}
          >
            {(item) => <DropdownItem>{item.label}</DropdownItem>}
          </Dropdown>
        </div>

        <div>
          <p>{t('label.authorizationDate')}</p>
          <DatePicker
            id='authorization-date'
            value={transactionDetail.authorizationDate}
            onChange={handleAuthorizationDateChange}
            aria-label={t('label.authorizationDate')}
            size='size.md'
          />
        </div>
        <div>
          <p>{t('label.postedDate')}</p>
          <DatePicker
            id='posted-date'
            value={transactionDetail.postedDate}
            onChange={handlePostedDateChange}
            aria-label={t('label.postedDate')}
            size='size.md'
          />
        </div>
        <div className='relative pb-4'>
          <p>{t('label.amount')}</p>
          <NumberInput
            className='flex-1'
            onChange={handleNumberInputChange}
            size='size.md'
            value={transactionDetail.transactionAmount}
            currency={currency.code}
          />
          <p className='absolute bottom-0 left-0 text-xs text-gray-disabledText'>
            {`${t('label.minSpend')}: ${currency.mark}${
              advertisement.reward.purchaseRequirement.minSpendAmount || 0.2
            }`}
          </p>
        </div>
      </div>
      <div className='mb-3  pt-3'>
        <div>
          <p>{t('label.redeemingTransaction')}</p>
          <div>
            <Dropdown
              selectedItem={transactionDetail.transactionString}
              onSelectionChange={handleTransactionStringChange}
              itemKey='id'
              items={transactionStrings}
              size='size.md'
              aria-label={`${t('label.redeemingTransaction')}`}
              className='dropdown-class text-sm'
              forceIsOpen={false}
              disabled={isTransactionStringsEmpty}
            >
              {(item) => <DropdownItem>{item.label}</DropdownItem>}
            </Dropdown>
            {isTransactionStringsEmpty && (
              <p className='text-sm text-error'>
                {t('label.noTransactionStrings')}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { RedemptionForm };
