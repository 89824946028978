import { ServiceData, ServiceMutation } from '../service';

import { offerAdapter } from '@adapters/offer';

import { UpdateCampaignRequest } from '@models/campaign';

type PortfolioTypeUpdateVariables = {
  payload: UpdateCampaignRequest;
};

interface UpdateData extends ServiceData {
  offer: { data: Partial<UpdateCampaignRequest> };
}

const update: ServiceMutation<
  UpdateData,
  PortfolioTypeUpdateVariables
> = async (params) => {
  const { payload } = params;
  const { campaignId } = payload;

  const response = await offerAdapter.put({
    id: campaignId,
    body: payload
  });

  return {
    offer: response
  };
};

export type { UpdateData };
export { update };
