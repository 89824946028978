import { useState } from 'react';

import { Modal } from '@library/components';

import { PrimaryButton, SecondaryButton } from '@library/components';
import { t } from 'i18next';

interface ConfirmationModalProps {
  trigger: React.ReactNode | string;
  title: string;
  modalBody: string | React.ReactNode;
  confirmBtnText: string;
  handleConfirmation: () => void;
}

const ConfirmationModal = ({
  trigger,
  title,
  modalBody,
  confirmBtnText,
  handleConfirmation
}: ConfirmationModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmationBtnClick = () => {
    handleConfirmation();
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      trigger={
        <div
          role='button'
          tabIndex={0}
          onClick={() => setIsModalOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsModalOpen(true);
            }
          }}
        >
          {trigger}
        </div>
      }
      header={
        <div className='p-4'>
          <h3 className='mb-2 text-2xl'>{title}</h3>
        </div>
      }
      maxWidth='maxWidth.large'
      onClose={() => {
        setIsModalOpen(false);
      }}
      content={
        <>
          <div>{modalBody}</div>
          <div className='mt-10 flex items-center justify-between'>
            <SecondaryButton size='size.md' onClick={closeModal}>
              {t('library.cancel')}
            </SecondaryButton>
            <div className='flex items-center'>
              <PrimaryButton onClick={confirmationBtnClick}>
                {confirmBtnText}
              </PrimaryButton>
            </div>
          </div>
        </>
      }
    />
  );
};

export { ConfirmationModal };
