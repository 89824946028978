import { Link } from 'react-router-dom';
import { RocketLogoIcon } from '../icons';
import { NavItems } from './nav-items';

interface NavItem {
  text: string;
  url: string;
  end?: boolean;
  disabled?: boolean;
}

interface NavItemParent {
  text: string;
  items: NavItem[];
  disabled?: boolean;
}

interface AppHeaderProps {
  logoUrl: string | undefined;
  logoText: string;
  navItems: (NavItem | NavItemParent)[];
  rightSlot?: React.ReactNode;
  children?: React.ReactNode;
}

const AppHeader = ({
  logoUrl,
  navItems,
  logoText,
  children,
  rightSlot
}: AppHeaderProps) => (
  <nav className='fixed inset-0 z-50 h-16 overflow-x-auto bg-white shadow-md'>
    <div className='flex h-16 min-w-body items-center px-10'>
      <div className='mr-20 flex items-center'>
        <Link to='/' className='flex items-center'>
          <RocketLogoIcon className='mr-4 h-7 w-7' />
          {logoUrl && logoUrl.length > 0 && (
            <img
              src={logoUrl}
              alt='Publisher Logo'
              className='h-9 object-contain'
            />
          )}
          {logoText && logoText.length > 0 && (
            <h3 className='ml-6 text-lg font-bold text-gray-darkGray'>
              {logoText}
            </h3>
          )}
        </Link>
      </div>
      <NavItems items={navItems} />
      <div className='flex flex-1 flex-row-reverse'>
        {children}
        {rightSlot}
      </div>
    </div>
  </nav>
);

export { AppHeader };
export type { NavItem, NavItemParent };
