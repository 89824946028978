import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { EditCustomerOfferRow } from './EditCustomerOfferRow';
import { TextInput } from '@library/components';

import { useCustomer } from '@/context-providers/customer';

import { Offer } from '@models/campaign';

interface EditCustomerOffersListProps {
  offers: Offer[];
  isLoading: boolean;
  customerId: string;
  isError: boolean;
}

const OfferList = ({
  offers,
  customerId,
  isError
}: Partial<EditCustomerOffersListProps>) => {
  const [t] = useTranslation();

  const { customer } = useCustomer();

  const { sourceAccountId, last4, iin } = customer;

  const isOffersEmpty = useMemo(
    () => offers && offers.length === 0,
    [isError, offers]
  );

  const errorMessage = useMemo(() => {
    if (isError) {
      return t('label.couldNotLoadOffers');
    } else if (isOffersEmpty) {
      return t('label.noCustomersAssigned');
    }
    return '';
  }, [isError, offers]);

  if (errorMessage.length > 0) {
    return <h2 className='py-4 text-lg font-bold'>{errorMessage}</h2>;
  }

  return (
    <ul className='mt-2 max-h-105 overflow-auto'>
      {offers &&
        offers.map((offer) => {
          const editOfferProps = {
            ...offer,
            customerId,
            sourceAccountId,
            iin,
            last4
          };

          return (
            <li
              className='mt-6 border-b-2 border-b-gray-rules'
              key={offer.adId}
            >
              <EditCustomerOfferRow {...editOfferProps} />
            </li>
          );
        })}
    </ul>
  );
};

const EditCustomerOffersList = ({
  offers,
  isLoading,
  isError,
  customerId
}: EditCustomerOffersListProps) => {
  const [t] = useTranslation();

  const [filterText, setFilterText] = useState('');

  const { isRefetchingOffers } = useCustomer();

  const filteredOffers = useMemo(
    // () => offers.filter((offer) => offer.redemptionState !== 'FULLY_REDEEMED'),
    () =>
      offers.filter((offer) =>
        offer.adId
          .toString()
          .toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase())
      ),
    [offers, filterText]
  );

  return (
    <div className='p-2'>
      <div className='flex items-end justify-between'>
        <div>
          <h3 className='text-xl font-bold'>
            {t('label.offer', { count: 2 })}
          </h3>
          <p>{t('label.configureOfferState')}</p>
        </div>
        <div>
          <TextInput
            value={filterText}
            className='flex-1'
            onChange={setFilterText}
            decorator={<MagnifyingGlassIcon />}
            size='size.md'
            placeholder='Search by Ad ID'
          />
        </div>
      </div>

      {isLoading || isRefetchingOffers ? (
        <div className='w-full pt-4'>
          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-8 h-4 w-4/5 animate-pulsate' />

          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-4/5 animate-pulsate' />
        </div>
      ) : (
        <OfferList
          offers={filteredOffers}
          isError={isError}
          customerId={customerId}
        />
      )}
    </div>
  );
};

export { EditCustomerOffersList };
