import { apiTransport } from '@services/rest-api';
import { useEffect } from 'react';
// import { useFlags } from './use-flags';

const useApiTransport = () => {
  // const { validateApiResponseSchema } = useFlags();

  useEffect(() => {
    apiTransport.setOptions({
      validateApiResponseSchema: false
    });
  }, []);
};

export { useApiTransport };
