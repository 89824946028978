import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import {
  FieldContainer,
  FieldError,
  FieldLabel,
  useFieldError,
  useFieldProps
} from '../../helpers';
import { BaseField } from '../../helpers/types';
import { DropdownProps, Dropdown } from './dropdown';

type DropdownFieldProps<
  TFormSchemaType extends FieldValues,
  TItemType extends object
> = Omit<
  DropdownProps<TItemType>,
  'id' | 'selectedItem' | 'onSelectionChange'
> &
  BaseField<TFormSchemaType> & {
    bottomAddOn?: React.ReactNode;
  };

function DropdownField<
  TFormSchemaType extends FieldValues,
  TItemType extends object
>({
  bottomAddOn,
  deps,
  disabled = false,
  label,
  name,
  optional = false,
  secondaryLabel,
  tooltipContent,
  warningSchema,
  ...delegated
}: DropdownFieldProps<TFormSchemaType, TItemType>) {
  const { error } = useFieldError({ name });
  const hasError = !!error;

  const { control } = useFormContext();

  const {
    containerProps,
    controllerProps,
    inputProps,
    labelProps,
    errorProps,
    triggerDeps
  } = useFieldProps<TFormSchemaType>({
    deps,
    label,
    name,
    optional,
    secondaryLabel,
    tooltipContent,
    warningSchema
  });

  return (
    <FieldContainer {...containerProps}>
      <FieldLabel {...labelProps} />
      <Controller
        control={control}
        {...controllerProps}
        render={({ field: { onChange, onBlur, value } }) => (
          <Dropdown
            {...inputProps}
            {...delegated}
            aria-label={label}
            aria-describedby={hasError ? errorProps.id : undefined}
            aria-invalid={hasError}
            aria-required={!optional}
            name={name}
            selectedItem={value}
            onSelectionChange={(newValue) => {
              onChange(newValue);
              triggerDeps();
            }}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
      />
      {hasError ? (
        <div className='flex items-start justify-between gap-2'>
          <FieldError<TFormSchemaType> {...errorProps} collapsible={true} />
          {bottomAddOn}
        </div>
      ) : null}
    </FieldContainer>
  );
}

export { DropdownField };
