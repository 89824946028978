import { classnames } from '@library/utils';
import { Image } from '../image';

interface BrandImageProps {
  src?: string;
  alt?: string;
}

function BrandImage({ src, alt }: BrandImageProps) {
  /**
   * min-height to make the ad preview reflow as little as possible while
   * waiting for the brand image to load. Tailwind won't load classes unless
   * they appear as unbroken strings so we have to use the exact values here.
   *
   * 376px is 380px (w-adPreview) minus 4px for the left and right border (border-2).
   * 2880/1308 corresponds to the the old dimensions of the brand image. The reason
   * we use this instead of CREATIVE_HERO_SIZE is because we don't know what the
   * dimensions of the image we're loading in are, so we need to choose the smallest
   * possible min-height to prevent vertical stretching. (Eventually, maybe a
   * quarter or so after CREATIVE_HERO_SIZE becomes the official brand image size,
   * we can just use CREATIVE_HERO_SIZE because the ads using the old dimensions
   * will be from long-completed campaigns where it doesn't really matter if the
   * preview looks a bit stretched.)
   *
   * (For reference, based on the above, we can look into cleaning this up around
   * March 2024.)
   */

  return src ? (
    <Image
      className={classnames(
        'min-h-[calc(376px/(2880/1308))] w-full rounded-t-[28px]'
      )}
      src={src}
      alt={alt}
    />
  ) : null;
}

export { BrandImage };
