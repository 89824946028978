import {
  parseDateTime,
  getLocalTimeZone,
  today,
  parseDate,
  CalendarDate
} from '@internationalized/date';
import { testIsoString } from './test-iso-string';

const QUARTERS = [
  {
    start: {
      month: 1,
      day: 1
    },
    end: {
      month: 3,
      day: 31
    }
  },
  {
    start: {
      month: 4,
      day: 1
    },
    end: {
      month: 6,
      day: 30
    }
  },
  {
    start: {
      month: 7,
      day: 1
    },
    end: {
      month: 9,
      day: 30
    }
  },
  {
    start: {
      month: 10,
      day: 1
    },
    end: {
      month: 12,
      day: 31
    }
  }
];

interface IgnoreTimeZoneOptions extends Intl.DateTimeFormatOptions {
  ignoreTimeZone: boolean;
  timeZone?: never;
}

interface TimeZoneOptions extends Intl.DateTimeFormatOptions {
  ignoreTimeZone?: never;
}

interface DateFormatterProps {
  value: Date | string;
  locale?: string;
  options: TimeZoneOptions | IgnoreTimeZoneOptions;
}

/*
 * If the value is an ISO date time string that already contains a time
 * zone, return true. Time zones are represented by either "Z" (UTC) or
 * +/-, like 2015-12-23T00:00:00-06:00, so we have to check to see if a
 * "-" is found *after* the first colon, because it'll otherwise return
 * true since hyphens are used in the regular date part of the string.
 */
function doesIsoDateHaveTimezoneOffset(value: string) {
  const colonIndex = value.indexOf(':');
  return ['-', '+', 'Z'].some((offsetSep) =>
    value.includes(offsetSep, colonIndex)
  );
}

function getTimeZonedValue(value: string) {
  // If the value is an ISO date string without time, return it
  if (!value.includes('T')) {
    return value;
  }

  if (doesIsoDateHaveTimezoneOffset(value)) {
    /*
     * If the value is an ISO date time string that already contains a time
     * zone, return that.
     */
    return value;
  }

  // If it's an ISO date time string without a time zone, slap a Z onto it
  return `${value}Z`;
}

const dateFormatter = ({
  value,
  locale = 'en-US',
  options
}: DateFormatterProps) => {
  /*
   * An ISO date string without time or time zone is set to 0:00:00 UTC,
   * but a date time string without time zone is set to the given time
   * of the user's time zone. For example, new Date('2020-01-01') is
   * Tue Dec 31 2019 16:00:00 GMT-0800 (Pacific Standard Time), but new
   * Date('2020-01-01T00:00:00') is Wed Jan 01 2020 00:00:00 GMT-0800
   * (Pacific Standard Time). In other words, the former sets the time
   * based on UTC while the latter sets the time based on the user's
   * time zone. This is obviously not good or consistent, so we need to
   * see if the passed-in date has an attached time zone and if not, to
   * set it to UTC.
   */
  const date =
    typeof value === 'string' ? new Date(getTimeZonedValue(value)) : value;

  const { ignoreTimeZone, ...dateOptions } = options;

  const effectiveOptions: Intl.DateTimeFormatOptions = {
    /*
     * We have several instances of date strings without any sort of
     * context - for example, "2022-03-25" to represent the date from
     * a date picker. The date constructor will take such a date and
     * set it to 0:00:00 UTC, but by default format it based on the
     * user's time zone, meaning that it might display as March 24,
     * 2022 (because the time will be set to 5pm PDT). To avoid this,
     * the "ignoreTimeZone" option can be used to signify that it
     * should be formatted using the same time zone as the constructor.
     */
    timeZone: ignoreTimeZone ? 'Etc/UTC' : undefined,
    ...dateOptions
  };

  return new Intl.DateTimeFormat(locale, effectiveOptions).format(date);
};

/*
 * Given a date time ISO string, returns just the date portion. This is
 * needed in situations such as the date picker and anywhere parseDate
 * is needed, because parseDate will throw an error if a time is found.
 */
function dateTimeToDate(dateTimeStr: string) {
  testIsoString(dateTimeStr);

  return dateTimeStr.split('T')[0];
}

/*
 * A wrapper function because parseDateTime does not want the time zone in there.
 */
function getParsedDateTime(dateStr: string) {
  return parseDateTime(new Date(dateStr).toISOString().split('Z')[0]);
}

function getToday() {
  return today(getLocalTimeZone());
}

function getPastMonthRange(offsetMonths: number) {
  if (offsetMonths === 0) {
    return {
      start: getToday().set({ day: 1 }),
      end: getToday()
    };
  }

  const adjustedDate = getToday().subtract({ months: offsetMonths });

  return {
    start: adjustedDate.set({ day: 1 }),
    // Day will be constrained accordingly, so you won't end up with, say, February 31
    end: adjustedDate.set({ day: 31 })
  };
}

function getCurrentQuarterIndex(day: CalendarDate) {
  let currentQuarter = 0;

  for (const [index, quarter] of QUARTERS.entries()) {
    const quarterStart = new CalendarDate(
      day.year,
      quarter.start.month,
      quarter.start.day
    );
    const quarterEnd = new CalendarDate(
      day.year,
      quarter.end.month,
      quarter.end.day
    );

    if (day.compare(quarterStart) >= 0 && day.compare(quarterEnd) <= 0) {
      currentQuarter = index;
    }
  }

  return currentQuarter;
}

function getPastQuarterRange(offsetQuarters: number) {
  const todayDate = getToday();

  const quarterIndex = getCurrentQuarterIndex(todayDate);
  const quarter = QUARTERS[quarterIndex];

  if (offsetQuarters === 0) {
    return {
      start: new CalendarDate(
        todayDate.year,
        quarter.start.month,
        quarter.start.day
      ),
      end: todayDate
    };
  }

  /*
   * Offset = 1, quarterIndex = 1: Math.ceil(0) = 0 -> same year
   * Offset = 2, quarterIndex = 1: Math.ceil(0.25) = 1 -> last year
   * Offset = 3, quarterIndex = 1: Math.ceil(0.5) = 1 -> last year
   * Offset = 4, quarterIndex = 1: Math.ceil(0.75) = 1 -> last year
   * Offset = 5, quarterIndex = 1: Math.ceil(1) = 1 -> last year
   * Offset = 6, quarterIndex = 1: Math.ceil(1.25) = 2 -> year before last year
   */
  const yearsToGoBack = Math.ceil(
    (offsetQuarters - quarterIndex) / QUARTERS.length
  );

  /*
   * Offset = 1, quarterIndex = 1: (4 - (0 % 4)) % 4 = 0
   * Offset = 2, quarterIndex = 1: (4 - (1 % 4)) % 4 = 3
   * Offset = 3, quarterIndex = 1: (4 - (2 % 4)) % 4 = 2
   * Offset = 4, quarterIndex = 1: (4 - (3 % 4)) % 4 = 1
   * Offset = 5, quarterIndex = 1: (4 - (4 % 4)) % 4 = 0
   * Offset = 6, quarterIndex = 1: (4 - (5 % 4)) % 4 = 3
   */
  const newQuarterIndex =
    (QUARTERS.length - ((offsetQuarters - quarterIndex) % QUARTERS.length)) %
    QUARTERS.length;

  const newQuarter = QUARTERS[newQuarterIndex];

  return {
    start: new CalendarDate(
      todayDate.year - yearsToGoBack,
      newQuarter.start.month,
      newQuarter.start.day
    ),
    end: new CalendarDate(
      todayDate.year - yearsToGoBack,
      newQuarter.end.month,
      newQuarter.end.day
    )
  };
}

function getPastYearRange(offsetYears: number) {
  if (offsetYears === 0) {
    return {
      start: getToday().set({ month: 1, day: 1 }),
      end: getToday()
    };
  }

  const adjustedDate = getToday().subtract({ years: offsetYears });

  return {
    start: adjustedDate.set({ month: 1, day: 1 }),
    end: adjustedDate.set({ month: 12, day: 31 })
  };
}

function diffInDays(
  firstDateStr: string,
  secondDateStr: string,
  inclusive?: boolean
) {
  const diff = parseDate(dateTimeToDate(secondDateStr)).compare(
    parseDate(dateTimeToDate(firstDateStr))
  );

  const inclusiveAdjustment = diff >= 0 ? 1 : -1;

  return diff + (inclusive ? inclusiveAdjustment : 0);
}

function isDateGreater(target, reference) {
  const dateObj1 = new Date(target);
  const dateObj2 = new Date(reference);

  return dateObj1 > dateObj2;
}

function isDateLower(target, reference) {
  const dateObj1 = new Date(target);
  const dateObj2 = new Date(reference);

  return dateObj1 < dateObj2;
}

export {
  dateFormatter,
  dateTimeToDate,
  getParsedDateTime,
  getToday,
  getPastYearRange,
  diffInDays,
  getPastMonthRange,
  getPastQuarterRange,
  isDateGreater,
  isDateLower
};
export type { DateFormatterProps };
