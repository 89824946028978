import { classnames } from '@library/utils';
import {
  CalendarDateTime,
  createCalendar,
  DateValue
} from '@internationalized/date';
import {
  AriaDateFieldProps,
  useDateField,
  useDateSegment
} from '@react-aria/datepicker';
import { useLocale } from '@react-aria/i18n';
import {
  DateFieldState,
  DateSegment,
  useDateFieldState
} from '@react-stately/datepicker';
import { useRef } from 'react';

function DateInputSegment({
  segment,
  dateInputState
}: {
  segment: DateSegment;
  dateInputState: DateFieldState;
}) {
  const ref = useRef(null);

  const { segmentProps } = useDateSegment(segment, dateInputState, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth: Number.isInteger(segment.maxValue)
          ? `${String(segment.maxValue).length}ch`
          : undefined
      }}
      className={classnames(
        'group box-content rounded-sm px-0.5 tabular-nums outline-none focus:bg-active focus:text-inverse',
        // A segment can be a number or the divider; dividers aren't editable
        segment.isEditable ? '' : 'text-disabled'
      )}
    >
      <span
        aria-hidden='true'
        className={classnames(
          // Reserve space for the placeholder to prevent shifting while editing
          'block w-full italic text-placeholder group-focus:text-inverse',
          segment.isPlaceholder ? '' : 'hidden'
        )}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}

function DateInput(
  props: AriaDateFieldProps<CalendarDateTime> & {
    onChange?: (value: DateValue) => void;
  }
) {
  const { locale } = useLocale();

  const dateInputState = useDateFieldState({
    ...props,
    locale,
    createCalendar
  });

  const ref = useRef(null);

  const { fieldProps } = useDateField(props, dateInputState, ref);

  return (
    <div {...fieldProps} ref={ref} className='flex'>
      {dateInputState.segments.map((segment, index) => (
        <DateInputSegment
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          segment={segment}
          dateInputState={dateInputState}
        />
      ))}
    </div>
  );
}

export { DateInput };
