import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomer } from '@/context-providers/customer';

import { Offer } from '@models/campaign';

interface RedeemedOffersListProps {
  offers: Offer[];
  isLoading: boolean;
  isError: boolean;
}

const OfferList = ({ offers, isError }: Partial<RedeemedOffersListProps>) => {
  const [t] = useTranslation();

  const isOffersEmpty = useMemo(
    () => offers && offers.length === 0,
    [isError, offers]
  );

  const errorMessage = useMemo(() => {
    if (isError) {
      return t('label.couldNotLoadOffers');
    } else if (isOffersEmpty) {
      return t('label.noCustomersAssigned');
    }
    return '';
  }, [isError, offers]);

  if (errorMessage.length > 0) {
    return <h2 className='py-4 text-lg font-bold'>{errorMessage}</h2>;
  }

  return (
    <ul className='mt-2 max-h-105 overflow-auto'>
      {offers &&
        offers.map((offer) => (
          <li
            className='mt-6 border-b-4 border-b-gray-rules p-4'
            key={offer.adId}
          >
            <div className='mb-2'>{`${offer.adId} - ${offer.merchantName} - ${offer.redemptionState}`}</div>
          </li>
        ))}
    </ul>
  );
};

const RedeemedOffersList = ({
  offers,
  isLoading,
  isError
}: RedeemedOffersListProps) => {
  const { isRefetchingOffers } = useCustomer();

  const filteredOffers = useMemo(
    () => offers.filter((offer) => offer.redemptionState === 'FULLY_REDEEMED'),
    [offers]
  );

  return (
    <div className='p-2'>
      <h3 className='text-xl font-bold'>Redeemed Offers</h3>

      {isLoading || isRefetchingOffers ? (
        <div className='w-full pt-4'>
          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-8 h-4 w-4/5 animate-pulsate' />

          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-4/5 animate-pulsate' />
        </div>
      ) : (
        <OfferList offers={filteredOffers} isError={isError} />
      )}
    </div>
  );
};

export { RedeemedOffersList };
