import { Dispatch, SetStateAction, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, TextInput, useToasts } from '@library/components';

interface MainLoginFlowProps {
  setLoginFlow: Dispatch<SetStateAction<'MFA' | 'NEW_PASSWORD' | 'MAIN'>>;
  setCredentials: Dispatch<SetStateAction<{ email: string; password: string }>>;
  isLoading: boolean;
}

const MainLoginFlow = ({
  setLoginFlow,
  setCredentials,
  isLoading
}: MainLoginFlowProps) => {
  const [t] = useTranslation();
  const { sendToast } = useToasts();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [enableCustomFlow, setEnableCustomFlow] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const validateEmail = () => {
    const {
      DOMAIN,
      RESPONSE_TYPE,
      USER_POOL_WEB_CLIENT_ID,
      SCOPES,
      REDIRECT_SIGN_IN,
      CDLX_ID_PROVIDER
    } = process.env.COGNITO;

    const scopes = SCOPES.join('+');
    const redirectUri = decodeURIComponent(REDIRECT_SIGN_IN);

    const cognitoLoginUrl = `https://${DOMAIN}/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${USER_POOL_WEB_CLIENT_ID}&scope=${scopes}&redirect_uri=${redirectUri}`;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErrorMessage(`${t('label.invalidEmail')}`);
      return;
    }
    setErrorMessage('');

    const domain = email.split('@')[1];

    switch (domain) {
      case 'cardlytics.com': {
        setIsSigningIn(true);
        const idProvider = `&identity_provider=${CDLX_ID_PROVIDER}`;
        window.location.replace(`${cognitoLoginUrl}${idProvider}`);
        break;
      }
      // case 'wellsfargo.com': {
      //   // TODO: Set ID Provider querystring and redirect
      //   break;
      // }
      default: {
        setEnableCustomFlow(true);
        break;
      }
    }
  };

  const onCustomLoginClick = async () => {
    setIsSigningIn(true);

    if (email.length === 0 || password.length === 0) {
      setErrorMessage(`${t('label.inputValidCredentials')}`);
      setIsSigningIn(false);
      return;
    }

    setErrorMessage('');

    try {
      const loginResp = await Auth.signIn(email, password);

      const { challengeName } = loginResp;

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        sendToast({
          variant: 'variant.info',
          content: `${t('label.createNewPassword')}`
        });
        setLoginFlow('NEW_PASSWORD');
        setCredentials({ email, password });
      }

      // TODO: WHEN ENABLING MFA
      // if (challengeName === 'NEEDS_VERIFICATION_CODE') {
      //   setLoginFlow('MFA');
      //   setCredentials({ email, password });
      // }
    } catch (error) {
      sendToast({
        variant: 'variant.error',
        content: `${error}`
      });
    }
    setIsSigningIn(false);
  };

  const onEmailKeyUp = (event) => {
    if (event.key === 'Enter') {
      validateEmail();
    }
  };

  const onPasswordKeyUp = (event) => {
    if (event.key === 'Enter') {
      onCustomLoginClick();
    }
  };

  return (
    <div>
      <div>
        <TextInput
          className='flex-1'
          value={email}
          onChange={setEmail}
          size='size.md'
          placeholder={`${t('label.email')}`}
          clearable={false}
          onKeyUp={onEmailKeyUp}
        />
      </div>
      {enableCustomFlow && (
        <div className='mt-2'>
          <TextInput
            className='flex-1'
            value={password}
            onChange={setPassword}
            size='size.md'
            placeholder={`${t('label.password')}`}
            type='password'
            clearable={false}
            onKeyUp={onPasswordKeyUp}
          />
        </div>
      )}
      {errorMessage ? (
        <p className='m-0 mt-1 text-sm text-error'>{errorMessage}</p>
      ) : null}
      <div className='mt-4'>
        {enableCustomFlow ? (
          <PrimaryButton
            onClick={onCustomLoginClick}
            disabled={isSigningIn || isLoading}
          >
            {isLoading || isSigningIn
              ? t('library.loadingEllipsis')
              : t('library.login')}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={validateEmail}
            disabled={isSigningIn || isLoading}
          >
            {isLoading || isSigningIn
              ? t('library.loadingEllipsis')
              : t('label.continue')}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export { MainLoginFlow };
