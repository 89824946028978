// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgBuildLoyalty(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      viewBox='0 0 124 106'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <rect
        x={55.162}
        y={31.537}
        width={49.022}
        height={28.521}
        rx={3.251}
        stroke='#0078BF'
        strokeWidth={2.609}
      />
      <path
        d='m75.033 47.96 4.609 4.61 5.177-4.919a7.798 7.798 0 0 0 2.416-5.643c0-2.125-1.722-3.865-3.846-3.865a3.846 3.846 0 0 0-3.004 1.443l-.364.455-.718-.719a4.026 4.026 0 0 0-2.848-1.18h-.496a3.53 3.53 0 0 0-3.53 3.531c0 2.358.936 4.62 2.604 6.287Z'
        stroke='#0CB9F8'
        strokeWidth={2.136}
      />
      <path
        d='M111.269 38.312c1.288-.428 4.052-.43 4.805 2.99m-4.031-9.307a6.834 6.834 0 0 1 8.196.61m-18.004-8.843c.37-1.548.444-5.341-2.224-8.138m-2.293 10.615c-.428-1.289-2.682-4.69-6.718-3.202'
        stroke='#0CB9F8'
        strokeWidth={2.134}
      />
      <path
        d='M19.823 92.114V54.53c-.253-1.772-.151-7.821 3.797-11.77 1.385-1.384 2.564-2.45 5.233-4.935m13.749 54.29V77.558c0-5.59 4.532-10.121 10.122-10.121H65.57a3.227 3.227 0 0 0 0-6.454H55.51c-5.315 0-13.445.683-16.786-8.732-2.448-6.897 1.926-13.672 6.58-17.997 2.937-2.729 6.996-3.643 11.005-3.643h13.36a2.924 2.924 0 0 0 .158-5.844l-22.67-1.225m0 0a6.341 6.341 0 0 0-4.624 1.646c-3.79 3.464-9.546 8.787-13.68 12.636m18.305-14.282 1.139-2.422 15.58-5.92a3.205 3.205 0 0 0-2.269-5.994L50.195 13.51m-9.112 3.435-12.23 20.88m12.23-20.88 9.112-3.435m-9.112 3.435 6.454-12.907a3.478 3.478 0 1 1 6.145 3.258l-3.487 6.214'
        stroke='#0078BF'
        strokeWidth={2.609}
      />
      <path
        d='m108.804 26.147 3.07-4.363m0 0-.775-4.46 4.177-1.302m-3.402 5.762 4.178-1.301-.776-4.46m0 0 2.303-3.273'
        stroke='#0CB9F8'
        strokeWidth={2.134}
      />
      <path
        d='M.534 92.542C3.629 86.908 15.087 85.5 20.429 85.5V105c-5.527-.542-23.764-5.417-19.895-12.458ZM61.68 97.958C58.586 103.592 47.128 105 41.786 105V85.5c5.526.542 23.763 5.417 19.894 12.458Z'
        fill='#0078BF'
      />
      <path
        d='M19.5 103.607c7.429 1.393 18.571.929 25.071 0'
        stroke='#0078BF'
        strokeWidth={2.609}
      />
      <path
        fill='#0078BF'
        d='M19.314 91.071h1.857v13h-1.857zM41.321 91.071h1.857v13h-1.857z'
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgBuildLoyalty);
export default ForwardRef;
