import React from 'react';
import { ButtonlikeIconVariant, ButtonlikeSize } from '../types';
import { useIconButtonClasses } from '../use-icon-button-classes';
import { BaseButton } from './base';

interface IconButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  size?: ButtonlikeSize;
  variant?: ButtonlikeIconVariant;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButtonWithRef(
    { size = 'size.md', variant = 'variant.base', ...delegated },
    ref
  ) {
    return (
      <BaseButton
        {...delegated}
        className={useIconButtonClasses({ size, variant })}
        ref={ref}
      />
    );
  }
);

export type { IconButtonProps };
export { IconButton };
