import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { classnames } from '@library/utils';
import { Table } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePageSizeOptions } from '.';
import { IconButton, TertiaryButton } from '../buttonlike';
import { NumberInput } from '../inputs';
import { ComboBox, ComboBoxItem } from '../selectors';
import { UNKNOWN_TOTAL_VALUE } from './helpers';
import { TablePaginatorButtons } from './paginator-buttons';

interface ChangePageProps<TableData> {
  table: Table<TableData>;
  pageIndex: number;
}

/*
 * For client-side tables, total is managed internally; for example, if there
 * is a filter applied, the table knows to update the total rows automatically,
 * while the component calling the table has no need to know what that number
 * is. For server-side, the table has no idea what the actual total is and
 * relies on the calling component passing it in.
 */
function getTotalRows<TableData>(table: Table<TableData>) {
  const serverTotal = table.options.meta?.server?.totalRows;

  if (serverTotal !== undefined) {
    return serverTotal;
  }

  return table.getPrePaginationRowModel().rows.length;
}

function changePage<TableData>({
  table,
  pageIndex
}: ChangePageProps<TableData>) {
  if (table.getState().pagination.pageIndex === pageIndex) {
    return;
  }

  const pageCount = table.getPageCount();

  if (pageIndex < 0) {
    pageIndex = 0;
  } else if (pageIndex > pageCount - 1 && pageCount !== UNKNOWN_TOTAL_VALUE) {
    pageIndex = pageCount - 1;
  }

  table.setPageIndex(pageIndex);

  if (!table.options.meta?.persistSelectedItemsAcrossPages) {
    table.toggleAllRowsSelected(false);
  }
}

interface ChangePageSizeProps<TableData> {
  table: Table<TableData>;
  pageSize: number;
}

function changePageSize<TableData>({
  table,
  pageSize
}: ChangePageSizeProps<TableData>) {
  changePage({ table, pageIndex: 0 });

  table.setPageSize(pageSize);

  if (!table.options.meta?.persistSelectedItemsAcrossPages) {
    // Even if the page index doesn't change, reset row selection
    table.toggleAllRowsSelected(false);
  }
}

function getPageStart<TableData>(table: Table<TableData>) {
  return getTotalRows(table) === 0
    ? 0
    : table.getState().pagination.pageIndex *
        table.getState().pagination.pageSize +
        1;
}

function getPageEnd<TableData>(table: Table<TableData>) {
  const pageSize = table.getState().pagination.pageSize;

  if (table.getPageCount() === UNKNOWN_TOTAL_VALUE) {
    return table.getState().pagination.pageIndex * pageSize + pageSize;
  }

  return Math.min(
    table.getState().pagination.pageIndex * pageSize + pageSize,
    getTotalRows(table)
  );
}

const outerBorderClasses = 'border-b border-x';

interface TablePaginatorProps<TableData> {
  table: Table<TableData>;
  pageSizeOptions: TablePageSizeOptions;
  borderless?: boolean;
}

function TablePaginator<TableData>({
  table,
  pageSizeOptions,
  borderless
}: TablePaginatorProps<TableData>) {
  const { t } = useTranslation('global');

  const [pageInput, setPageInput] = useState<number | null>(null);

  const pageSizeComboBoxOptions = pageSizeOptions.map((size) => ({
    key: size,
    label: t('library.numberDefault', { value: size })
  }));

  const [selectedPageSize, setSelectedPageSize] = useState(
    pageSizeComboBoxOptions.find(
      ({ key }) => key === table.getState().pagination.pageSize
    ) || null
  );

  /*
   * baseId is required but technically possibly non-existent for TypeScript reasons,
   * so we cast it "just in case" it's undefined (even though it will never be).
   */
  const baseId = table.options.meta?.baseId as string;

  return (
    <div
      data-testid={`table-paginator-${baseId}`}
      className={classnames(
        'relative -mt-px flex min-w-224 justify-between border-t border-neutral px-4 py-2 text-sm',
        borderless ? null : outerBorderClasses
      )}
    >
      <div className='flex items-center'>
        <div className='whitespace-nowrap'>{t('library.itemsPerPage')}</div>
        <div className='mx-3 w-24'>
          {/* @ts-ignore */}
          <ComboBox
            aria-label={`${t('library.itemsPerPage')}`}
            defaultItems={pageSizeComboBoxOptions}
            itemKey='key'
            size='size.md'
            selectionMode='mode.single'
            selectedItem={selectedPageSize}
            onSelectionChange={(selection) => {
              if (selection) {
                setSelectedPageSize(selection);
                changePageSize({
                  table,
                  pageSize: Number(selection.key)
                });
              }
            }}
          >
            {(item) => (
              <ComboBoxItem textValue={item.label}>
                <div className='text-sm'>{item.label}</div>
              </ComboBoxItem>
            )}
          </ComboBox>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='mr-4 whitespace-nowrap'>
          {table.getPageCount() === UNKNOWN_TOTAL_VALUE
            ? t('library.showingXToYItems', {
                x: getPageStart(table),
                y: getPageEnd(table)
              })
            : t('library.showingXToYCountItems', {
                x: getPageStart(table),
                y: getPageEnd(table),
                count: getTotalRows(table)
              })}
        </div>
        {table.getPageCount() === UNKNOWN_TOTAL_VALUE ? null : (
          <TertiaryButton
            variant='variant.alt'
            onClick={() => {
              changePage({
                table,
                pageIndex: 0
              });
            }}
            disabled={!table.getCanPreviousPage()}
          >
            {t('library.first')}
          </TertiaryButton>
        )}
        <IconButton
          onClick={() => {
            changePage({
              table,
              pageIndex: table.getState().pagination.pageIndex - 1
            });
          }}
          disabled={!table.getCanPreviousPage()}
          title={`${t('library.previous')}`}
          size='size.sm'
        >
          <ChevronLeftIcon />
        </IconButton>
        <TablePaginatorButtons
          pageIndex={table.getState().pagination.pageIndex}
          totalPages={table.getPageCount()}
          onButtonClick={(newIndex) => {
            changePage({
              table,
              pageIndex: newIndex
            });
          }}
        />
        <IconButton
          onClick={() => {
            changePage({
              table,
              pageIndex: table.getState().pagination.pageIndex + 1
            });
          }}
          disabled={!table.getCanNextPage()}
          title={`${t('library.next')}`}
          size='size.sm'
        >
          <ChevronRightIcon />
        </IconButton>
        {table.getPageCount() === UNKNOWN_TOTAL_VALUE ? null : (
          <TertiaryButton
            variant='variant.alt'
            onClick={() => {
              changePage({
                table,
                pageIndex: table.getPageCount() - 1
              });
            }}
            disabled={!table.getCanNextPage()}
          >
            {t('library.last')}
          </TertiaryButton>
        )}
        <div className='ml-4 flex items-center'>
          <label className='whitespace-nowrap' htmlFor={`go-to-page-${baseId}`}>
            {t('library.goToPage')}
          </label>
          <div className='ml-2 w-16'>
            <NumberInput
              clearable={false}
              id={`go-to-page-${baseId}`}
              value={pageInput}
              size='size.md'
              onChange={setPageInput}
              onKeyUp={(e) => {
                if (['Enter', 'NumpadEnter'].includes(e.code)) {
                  changePage({
                    table,
                    pageIndex: pageInput === null ? 0 : pageInput - 1
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { TablePaginator };
