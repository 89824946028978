import { cleanString, constants, pick } from '@library/utils';
import { z } from 'zod';

const CREATIVE_AD_COPY_TOKENS = [
  'ExpirationDate',
  'MerchantName',
  'Amount',
  'RewardCap',
  'MinSpend',
  'RedemptionEndDate'
] as const;
const creativeAdCopyTokenSchema = z.enum(CREATIVE_AD_COPY_TOKENS);
type CreativeAdCopyToken = (typeof CREATIVE_AD_COPY_TOKENS)[number];

const adCopyTokens = {
  rewardCap: 'RewardCap',
  amount: 'Amount',
  minSpend: 'MinSpend',
  merchantName: 'MerchantName',
  redemptionEndDate: 'RedemptionEndDate',
  expirationDate: 'ExpirationDate'
} satisfies Record<string, CreativeAdCopyToken>;

/*
 * Note the omission of PostMessage, which is technically a valid slot for a creative
 * and is basically the headline, reward details, and terms and conditions combined into
 * one long string. However, PostMessage only appears if the creativeVersion is equal to
 * 1. In the UI, though, we only care about creativeVersion = 3, and it doesn't matter if
 * creatives on older versions render incorrectly because they are very old.
 */
const CREATIVE_AD_COPY_SLOTS = [
  'ShortPreMessage',
  'PreMessage',
  'Headline',
  'MarketingCopy',
  'RewardDetails',
  'TermsAndConditions',
  'ThankYouMessage'
] as const;
const creativeAdCopySlotSchema = z.enum(CREATIVE_AD_COPY_SLOTS);
type CreativeAdCopySlot = (typeof CREATIVE_AD_COPY_SLOTS)[number];

// https://github.com/cardlytics/ad-assets-3/blob/main/Cardlytics.AdAssets/Cardlytics.AdAssets.Api/Mapping/CreativeBeaconIncomingBeaconTypeResolver.cs#L38
const CREATIVE_BEACON_SLOTS = [
  'ClickBeacon',
  'ImpressionBeacon',
  'ThirdPartyBeacon'
] as const;
const creativeBeaconSlotSchema = z.enum(CREATIVE_BEACON_SLOTS);
type CreativeBeaconSlot = (typeof CREATIVE_BEACON_SLOTS)[number];

const CREATIVE_COUNTRIES = ['US', 'GB'] as const;
const creativeCountrySchema = z.enum(CREATIVE_COUNTRIES);
type CreativeCountry = (typeof CREATIVE_COUNTRIES)[number];

// https://github.com/cardlytics/bsp-bank-approval-services/blob/970dbe44ad6fde50ff67776351754059ad71511f/Services/BankHub.Approval.RuleProcessor/Cardlytics.BankHub.Approval.RuleProcessor.Model/AdAssets/SlotImage.cs
const CREATIVE_IMAGE_SLOTS = ['Logo', 'HeroImage', 'AnnotatedLogo'] as const;
const creativeImageSlotSchema = z.enum(CREATIVE_IMAGE_SLOTS);
type CreativeImageSlot = (typeof CREATIVE_IMAGE_SLOTS)[number];

const CREATIVE_LINK_TOKENS = [
  'Link1',
  'Link2',
  'Link3',
  'Link4',
  'Link5'
] as const;
const creativeLinkTokenSchema = z.enum(CREATIVE_LINK_TOKENS);
type CreativeLinkToken = (typeof CREATIVE_LINK_TOKENS)[number];

const linkTokens = {
  link1: 'Link1',
  link2: 'Link2',
  link3: 'Link3',
  link4: 'Link4',
  link5: 'Link5'
} satisfies Record<string, CreativeLinkToken>;

/*
 * Note that CallToActionLink does not exist for creatives with creativeVersion = 1 or 2.
 * Instead, the CTA is saved onto Link1. However, as with the ad copy slots comment above,
 * we do not care about old creative versions.
 */
const CREATIVE_LINK_SLOTS = [
  'CallToActionLink',
  ...CREATIVE_LINK_TOKENS
] as const;
const creativeLinkSlotSchema = z.enum(CREATIVE_LINK_SLOTS);
type CreativeLinkSlot = (typeof CREATIVE_LINK_SLOTS)[number];

/*
 * You will see this type being used for the reward's rewardTemplate field. This is
 * intentional, both because 1) they are equivalent and 2) there already exists an
 * unrelated type called RewardTemplate, so instead of having two RewardTemplate types
 * (one for the other thing and one for these values), we'll just have the one usage
 * and use CreativeRewardType wherever the reward's rewardTemplate has to be typed.
 */
const CREATIVE_REWARD_TYPES = [
  'PercentBack',
  'SpendXGetY',
  'FlatAmountBack'
] as const;
const creativeRewardTypeSchema = z.enum(CREATIVE_REWARD_TYPES);
type CreativeRewardType = (typeof CREATIVE_REWARD_TYPES)[number];

/*
 * In pixels. Note that we used to support 2880x1308 and before that, 1200x632,
 * but those are no longer relevant.
 */
const CREATIVE_HERO_SIZE = {
  width: 1200,
  height: 627
};

/*
 * The limit is 2 MB, which can translate to a different number of bytes
 * depending on if you use base 2 or base 10. We are using base 2 elsewhere,
 * so we are using it here as well. (This used to be 400 kB.)
 */
const CREATIVE_HERO_MAX_BYTES = 2 * constants.BYTES_PER_MB;

const CREATIVE_LOGO_SIZE = {
  width: 627,
  height: 627
};

const CREATIVE_ANNOTATED_LOGO_SIZE = {
  width: 128,
  height: 128
};

/*
 * The limit is 2 MB, which can translate to a different number of bytes
 * depending on if you use base 2 or base 10. We are using base 2 elsewhere,
 * so we are using it here as well.
 *
 * There was no old limit.
 */
const CREATIVE_LOGO_MAX_BYTES = 2 * constants.BYTES_PER_MB;

const creativeAdCopySchema = z.object({
  adCopyId: z.number(),
  id: z.number(),
  slot: creativeAdCopySlotSchema,
  text: z.string()
});
type CreativeAdCopy = z.infer<typeof creativeAdCopySchema>;

const creativeBeaconSchema = z.object({
  beaconId: z.number(),
  id: z.number(),
  name: z.string(),
  slot: creativeBeaconSlotSchema,
  url: z.string()
});
type CreativeBeacon = z.infer<typeof creativeBeaconSchema>;

const creativeImageVersionSchema = z.object({
  externalUrl: z.string(),
  fileSize: z.number(),
  height: z.number(),
  imageId: z.number(),
  mimeType: z.string(),
  width: z.number()
});

const creativeImageSchema = z.object({
  externalUrl: z.string(),
  id: z.number(),
  imageId: z.number(),
  slot: creativeImageSlotSchema,
  sourceImage: creativeImageVersionSchema,
  versions: z.array(creativeImageVersionSchema)
});
type CreativeImage = z.infer<typeof creativeImageSchema>;

const creativeLinkSchema = z.object({
  id: z.number(),
  linkId: z.number(),
  shortenedUrl: z.string(),
  slot: creativeLinkSlotSchema,
  text: z.string(),
  url: z.string()
});
type CreativeLink = z.infer<typeof creativeLinkSchema>;

/*
 * We need a simple version of the creative link schema for the ad preview, which
 * must operate both in the context of having a whole existing creative passed in
 * as well as the create case for a form. For the former, URL is not guaranteed to
 * exist (for some reason - I have found that sometimes, when doing a POST with a
 * link, the returned result does not have URL, but when doing a subsequent GET on
 * the newly-created entity, URL is there again). For the latter, IDs and shortened
 * URL will not exist. However, between shortenedUrl and url, at least one will
 * always be there.
 */
const creativeLinkSimpleSchema = creativeLinkSchema
  .partial()
  .required({
    slot: true,
    text: true
  })
  .refine(
    ({ shortenedUrl, url }) => shortenedUrl !== undefined || url !== undefined,
    { message: 'shortenedUrl or url must be present' }
  );
type CreativeLinkSimple = z.infer<typeof creativeLinkSimpleSchema>;

const apiCreativeSchema = z.object({
  accountId: z.string(),
  adCopy: z.array(creativeAdCopySchema),
  allowMultipleRewards: z.boolean(),
  beacons: z.array(creativeBeaconSchema),
  country: creativeCountrySchema,
  creativeVersion: z.literal(1).or(z.literal(2)).or(z.literal(3)),
  id: z.number(),
  images: z.array(creativeImageSchema),
  links: z.array(creativeLinkSchema),
  merchantName: z.string(),
  rewardType: creativeRewardTypeSchema
});
type ApiCreative = z.infer<typeof apiCreativeSchema>;

type ApiCreativeImageVariables = Pick<CreativeImage, 'imageId' | 'slot'>;

type ApiCreativeAdCopyVariables = Pick<CreativeAdCopy, 'slot'> &
  Partial<Pick<CreativeAdCopy, 'adCopyId' | 'text'>>;

type ApiCreativeLinkVariables = Pick<CreativeLink, 'slot'> &
  Partial<Pick<CreativeLink, 'linkId' | 'text' | 'url'>>;

type ApiCreativeVariables = Pick<
  AdapterCreative,
  | 'accountId'
  | 'merchantName'
  | 'country'
  | 'rewardType'
  | 'allowMultipleRewards'
> & {
  adCopy: ApiCreativeAdCopyVariables[];
  images: ApiCreativeImageVariables[];
  links: ApiCreativeLinkVariables[];
};

/*
 * The primary object is a helper object that consists of the fields that
 * should be used within the UI. The external URL should be the URL of the
 * image that matches the crop dimensions of the image manager for the
 * given image and is pulled from the versions array (if available), while
 * the image ID should be the image ID of the source image - the image
 * that was initially uploaded to the BE for processing. (It's generally
 * assumed that the source image will be the same as the image that
 * matches the crop dimensions, but it is technically possible, if not
 * likely, that they are not.)
 *
 * bankApprovalImageId is basically just the imageId from the base image
 * object, because no downstream systems have made the needed changes yet
 * to read from sourceImage and versions. It is unclear what they will
 * read from in the future.
 */
const adapterCreativeImagePrimarySchema = z.object({
  primary: z.object({
    bankApprovalImageId: z.number(),
    externalUrl: z.string(),
    imageId: z.number()
  })
});

const adapterCreativeImageSchema = creativeImageSchema
  .omit({ imageId: true })
  .and(adapterCreativeImagePrimarySchema)
  .optional();
type AdapterCreativeImage = z.infer<typeof adapterCreativeImageSchema>;

/*
 * The adapter version is similar to the API version, but with flattened
 * ad copy, images, and links to facilitate access. (Beacons could be
 * flattened as well, but we don't use them, so there's no point.)
 */
const adapterCreativeSchema = apiCreativeSchema
  .omit({
    images: true,
    adCopy: true,
    links: true
  })
  .and(
    z.object({
      annotatedLogoImage: adapterCreativeImageSchema,
      callToActionLink: creativeLinkSchema.optional(),
      headline: creativeAdCopySchema.optional(),
      heroImage: adapterCreativeImageSchema,
      link1: creativeLinkSchema.optional(),
      link2: creativeLinkSchema.optional(),
      link3: creativeLinkSchema.optional(),
      link4: creativeLinkSchema.optional(),
      link5: creativeLinkSchema.optional(),
      logoImage: adapterCreativeImageSchema,
      marketingCopy: creativeAdCopySchema.optional(),
      preMessage: creativeAdCopySchema.optional(),
      rewardCopy: creativeAdCopySchema.optional(),
      shortPreMessage: creativeAdCopySchema.optional(),
      termsAndConditions: creativeAdCopySchema.optional(),
      thankYouMessage: creativeAdCopySchema.optional()
    })
  );
type AdapterCreative = z.infer<typeof adapterCreativeSchema>;

function isCreativeLink(
  link: { text: string; url: string } | CreativeLink
): link is CreativeLink {
  return 'linkId' in link;
}

type AdapterCreativeVariables = Pick<
  AdapterCreative,
  | 'accountId'
  | 'merchantName'
  | 'country'
  | 'rewardType'
  | 'allowMultipleRewards'
> & {
  annotatedLogoId?: number;
  callToActionLink?: { text: string; url: string } | CreativeLink;
  headline: string | CreativeAdCopy;
  heroId?: number;
  link1?: { text: string; url: string } | CreativeLink;
  link2?: { text: string; url: string } | CreativeLink;
  link3?: { text: string; url: string } | CreativeLink;
  link4?: { text: string; url: string } | CreativeLink;
  link5?: { text: string; url: string } | CreativeLink;
  logoId?: number;
  marketingCopy?: string | CreativeAdCopy;
  preMessage: string | CreativeAdCopy;
  rewardCopy: string | CreativeAdCopy;
  shortPreMessage: string | CreativeAdCopy;
  termsAndConditions: string | CreativeAdCopy;
  thankYouMessage: string | CreativeAdCopy;
};

type AdapterCreativePostVariables = {
  variables: AdapterCreativeVariables;
};

// Same as AdapterCreativeVariables, but without CreativeLink/CreativeAdCopy
type UiFormCreativeVariables = Pick<
  AdapterCreativeVariables,
  | 'accountId'
  | 'allowMultipleRewards'
  | 'annotatedLogoId'
  | 'country'
  | 'heroId'
  | 'logoId'
  | 'merchantName'
  | 'rewardType'
> & {
  callToActionLink?: { text: string; url: string };
  headline: string;
  link1?: { text: string; url: string };
  link2?: { text: string; url: string };
  link3?: { text: string; url: string };
  link4?: { text: string; url: string };
  link5?: { text: string; url: string };
  marketingCopy: string;
  preMessage: string;
  rewardCopy: string;
  shortPreMessage: string;
  termsAndConditions: string;
  thankYouMessage: string;
};

/*
 * We have [entity]ToApi() functions for a lot of our entities, but this specific
 * function is not meant for passing stuff all the way through to the API; it's
 * just for sanitizing the creative before we even pass it to the service layer.
 */
function sanitizeCreative(
  creative: UiFormCreativeVariables
): UiFormCreativeVariables {
  const { marketingCopy, rewardCopy, termsAndConditions } = creative;

  return {
    ...creative,
    marketingCopy: cleanString(marketingCopy),
    rewardCopy: cleanString(rewardCopy),
    termsAndConditions: cleanString(termsAndConditions)
  };
}

const CREATIVE_IMAGE_SLOT_DIMENSIONS_MAP = {
  AnnotatedLogo: CREATIVE_ANNOTATED_LOGO_SIZE,
  HeroImage: CREATIVE_HERO_SIZE,
  Logo: CREATIVE_LOGO_SIZE
} satisfies Record<CreativeImageSlot, { height: number; width: number }>;

function getAdapterImage(
  imagesMap: Map<CreativeImageSlot, CreativeImage>,
  imageSlot: CreativeImageSlot
): AdapterCreativeImage {
  const image = imagesMap.get(imageSlot);

  if (!image) {
    return;
  }

  const original = image.versions.find(
    ({ height, width }) =>
      height === CREATIVE_IMAGE_SLOT_DIMENSIONS_MAP[imageSlot].height &&
      width === CREATIVE_IMAGE_SLOT_DIMENSIONS_MAP[imageSlot].width
  );

  return {
    ...image,
    primary: {
      bankApprovalImageId: image.imageId,
      externalUrl: original?.externalUrl || image.externalUrl,
      imageId: image.sourceImage.imageId
    }
  };
}

/*
 * There's a lot of arrays in the creative object, which makes accessing
 * certain things hard, so we flatten it for UI use and convert it back
 * when we have to modify data.
 */
function creativeApiToAdapter(apiData: ApiCreative): AdapterCreative {
  const { adCopy, images, links, ...creative } = apiData;

  const imagesMap = new Map<CreativeImageSlot, CreativeImage>();
  for (const image of images) {
    imagesMap.set(image.slot, image);
  }

  const adCopyMap = new Map<CreativeAdCopySlot, CreativeAdCopy>();
  for (const copy of adCopy) {
    adCopyMap.set(copy.slot, copy);
  }

  const linksMap = new Map<CreativeLinkSlot, CreativeLink>();
  for (const link of links) {
    linksMap.set(link.slot, link);
  }

  return {
    ...creative,
    shortPreMessage: adCopyMap.get('ShortPreMessage'),
    preMessage: adCopyMap.get('PreMessage'),
    headline: adCopyMap.get('Headline'),
    marketingCopy: adCopyMap.get('MarketingCopy'),
    rewardCopy: adCopyMap.get('RewardDetails'),
    termsAndConditions: adCopyMap.get('TermsAndConditions'),
    thankYouMessage: adCopyMap.get('ThankYouMessage'),
    logoImage: getAdapterImage(imagesMap, 'Logo'),
    annotatedLogoImage: getAdapterImage(imagesMap, 'AnnotatedLogo'),
    heroImage: getAdapterImage(imagesMap, 'HeroImage'),
    link1: linksMap.get(linkTokens.link1),
    link2: linksMap.get(linkTokens.link2),
    link3: linksMap.get(linkTokens.link3),
    link4: linksMap.get(linkTokens.link4),
    link5: linksMap.get(linkTokens.link5),
    callToActionLink: linksMap.get('CallToActionLink')
  };
}

function creativeAdapterToApi(
  adapterData: AdapterCreativeVariables
): ApiCreativeVariables {
  const {
    logoId,
    annotatedLogoId,
    heroId,
    marketingCopy,
    rewardCopy,
    termsAndConditions,
    shortPreMessage,
    preMessage,
    headline,
    thankYouMessage,
    link1,
    link2,
    link3,
    link4,
    link5,
    callToActionLink,
    ...creative
  } = adapterData;

  const images: ApiCreativeImageVariables[] = [];

  if (logoId) {
    images.push({
      imageId: logoId,
      slot: 'Logo'
    });
  }

  if (annotatedLogoId) {
    images.push({
      imageId: annotatedLogoId,
      slot: 'AnnotatedLogo'
    });
  }

  if (heroId) {
    images.push({
      imageId: heroId,
      slot: 'HeroImage'
    });
  }

  // the type will be a string if the value was changed, the original object otherwise
  const adCopy: ApiCreativeAdCopyVariables[] = [];

  if (typeof rewardCopy === 'string') {
    adCopy.push({
      slot: 'RewardDetails',
      text: rewardCopy
    });
  } else {
    adCopy.push(pick(rewardCopy, ['slot', 'adCopyId']));
  }

  /*
   * For the create case, marketing copy can be an empty string if the user
   * doesn't fill it in. For the update case, marketing copy can be undefined
   * if it wasn't filled in before and is still not filled in now. This is
   * due to the fact that if an ad copy field did not change, we send the
   * original ad copy object and not the text area value, and so it's
   * undefined instead of a blank string. In both cases (blank string and
   * undefined), we do not want to create a marketing copy object, nor do we
   * want to pick it from the existing creative (since it doesn't exist).
   */
  if (marketingCopy) {
    if (typeof marketingCopy === 'string') {
      adCopy.push({
        slot: 'MarketingCopy',
        text: marketingCopy
      });
    } else {
      adCopy.push(pick(marketingCopy, ['slot', 'adCopyId']));
    }
  }

  if (typeof termsAndConditions === 'string') {
    adCopy.push({
      slot: 'TermsAndConditions',
      text: termsAndConditions
    });
  } else {
    adCopy.push(pick(termsAndConditions, ['slot', 'adCopyId']));
  }

  if (typeof shortPreMessage === 'string') {
    adCopy.push({
      slot: 'ShortPreMessage',
      text: shortPreMessage
    });
  } else {
    adCopy.push(pick(shortPreMessage, ['slot', 'adCopyId']));
  }

  if (typeof preMessage === 'string') {
    adCopy.push({
      slot: 'PreMessage',
      text: preMessage
    });
  } else {
    adCopy.push(pick(preMessage, ['slot', 'adCopyId']));
  }

  if (typeof headline === 'string') {
    adCopy.push({
      slot: 'Headline',
      text: headline
    });
  } else {
    adCopy.push(pick(headline, ['slot', 'adCopyId']));
  }

  if (typeof thankYouMessage === 'string') {
    adCopy.push({
      slot: 'ThankYouMessage',
      text: thankYouMessage
    });
  } else {
    adCopy.push(pick(thankYouMessage, ['slot', 'adCopyId']));
  }

  const links: ApiCreativeLinkVariables[] = [];

  if (link1 && isCreativeLink(link1)) {
    links.push(pick(link1, ['slot', 'linkId']));
  } else if (link1) {
    links.push({ slot: linkTokens.link1, text: link1.text, url: link1.url });
  }

  if (link2 && isCreativeLink(link2)) {
    links.push(pick(link2, ['slot', 'linkId']));
  } else if (link2) {
    links.push({ slot: linkTokens.link2, text: link2.text, url: link2.url });
  }

  if (link3 && isCreativeLink(link3)) {
    links.push(pick(link3, ['slot', 'linkId']));
  } else if (link3) {
    links.push({ slot: linkTokens.link3, text: link3.text, url: link3.url });
  }

  if (link4 && isCreativeLink(link4)) {
    links.push(pick(link4, ['slot', 'linkId']));
  } else if (link4) {
    links.push({ slot: linkTokens.link4, text: link4.text, url: link4.url });
  }

  if (link5 && isCreativeLink(link5)) {
    links.push(pick(link5, ['slot', 'linkId']));
  } else if (link5) {
    links.push({ slot: linkTokens.link5, text: link5.text, url: link5.url });
  }

  if (callToActionLink && isCreativeLink(callToActionLink)) {
    links.push(pick(callToActionLink, ['slot', 'linkId']));
  } else if (callToActionLink) {
    links.push({
      slot: 'CallToActionLink',
      text: callToActionLink.text,
      url: callToActionLink.url
    });
  }

  return {
    ...creative,
    adCopy,
    images,
    links
  };
}

export * from './definitions';
export {
  CREATIVE_AD_COPY_SLOTS,
  CREATIVE_AD_COPY_TOKENS,
  CREATIVE_ANNOTATED_LOGO_SIZE,
  CREATIVE_BEACON_SLOTS,
  CREATIVE_COUNTRIES,
  CREATIVE_HERO_MAX_BYTES,
  CREATIVE_HERO_SIZE,
  CREATIVE_IMAGE_SLOTS,
  CREATIVE_LINK_SLOTS,
  CREATIVE_LOGO_MAX_BYTES,
  CREATIVE_LOGO_SIZE,
  CREATIVE_REWARD_TYPES,
  adCopyTokens,
  adapterCreativeSchema,
  apiCreativeSchema,
  creativeAdCopySchema,
  creativeAdCopySlotSchema,
  creativeAdCopyTokenSchema,
  creativeAdapterToApi,
  creativeApiToAdapter,
  creativeBeaconSchema,
  creativeBeaconSlotSchema,
  creativeCountrySchema,
  creativeImageSchema,
  creativeImageSlotSchema,
  creativeLinkSchema,
  creativeLinkSimpleSchema,
  creativeLinkSlotSchema,
  creativeLinkTokenSchema,
  creativeRewardTypeSchema,
  isCreativeLink,
  linkTokens,
  sanitizeCreative
};
export type {
  AdapterCreative,
  AdapterCreativePostVariables,
  AdapterCreativeVariables,
  ApiCreative,
  ApiCreativeAdCopyVariables,
  ApiCreativeImageVariables,
  ApiCreativeLinkVariables,
  ApiCreativeVariables,
  CreativeAdCopy,
  CreativeAdCopySlot,
  CreativeAdCopyToken,
  CreativeBeacon,
  CreativeBeaconSlot,
  CreativeCountry,
  CreativeImage,
  CreativeImageSlot,
  CreativeLink,
  CreativeLinkSimple,
  CreativeLinkSlot,
  CreativeLinkToken,
  CreativeRewardType,
  UiFormCreativeVariables
};
