import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

import { Customer } from '@models/customer';

interface PutParams {
  payload: Partial<Customer>;
}

const put: AdapterMutationFunction<Partial<Customer>, PutParams> = async (
  params
) => {
  const { payload } = params;

  const url = `/v1/customers/profiles`;

  return apiTransport.put<Partial<Customer>>({
    url,
    body: payload
  });
};

export type { PutParams };
export { put };
