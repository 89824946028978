import { MerchantStoreLocationSubType } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface MerchantStoreLocationSubTypeDefinition {
  readonly id: MerchantStoreLocationSubType;
  readonly label: string;
}

const {
  getById: merchantStoreLocationSubTypeDef,
  getAll: merchantStoreLocationSubTypeAllDefs
} = createDefinition<
  MerchantStoreLocationSubType,
  MerchantStoreLocationSubTypeDefinition
>({
  definitions: {
    PayAtPump: {
      label: t('merchantmodel.STORE_LOCATION_SUB_TYPE_PAY_AT_PUMP')
    },
    Convenience: {
      label: t('merchantmodel.STORE_LOCATION_SUB_TYPE_CONVENIENCE')
    },
    Grocery: {
      label: t('merchantmodel.STORE_LOCATION_SUB_TYPE_GROCERY')
    },
    Restaurant: {
      label: t('merchantmodel.STORE_LOCATION_SUB_TYPE_RESTAURANT')
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as MerchantStoreLocationSubType,
      label: id
    };
  }
});

export { merchantStoreLocationSubTypeAllDefs, merchantStoreLocationSubTypeDef };
export type { MerchantStoreLocationSubTypeDefinition };
