import { useTranslation } from 'react-i18next';
// import { PrimaryButton } from '../buttonlike';
import backgroundImgUrl from './login-background.png';
import logoDarkImgUrl from './logo-block-dark.svg';
import logoImgUrl from './logo-block.svg';
// import { TextInput } from '@library/components/inputs';
import { ReactNode } from 'react';

interface LoginPageProps {
  appName: string;
  buildNumber?: string;
  children: ReactNode;
}

function LoginPage({ appName, buildNumber, children }: LoginPageProps) {
  const { t } = useTranslation();

  return (
    <div className='flex h-full grow flex-col content-center items-center justify-center'>
      <div className='h-[540px] w-[880px] overflow-hidden shadow-lg transition-shadow hover:shadow-xl md:w-full md:shadow-none md:hover:shadow-none'>
        <div className='h-full min-w-[360px] md:p-12'>
          <div className='flex h-full flex-row bg-bottom md:flex-col'>
            <div className='relative flex h-full w-2/4 items-center justify-center overflow-hidden md:w-full'>
              <img
                src={backgroundImgUrl}
                className='absolute block h-full min-h-full min-w-full md:hidden'
                alt='Cardlytics background'
              />
              <div className='relative'>
                <img
                  src={logoImgUrl}
                  height='148'
                  className='block md:hidden'
                  alt='Cardlytics Logo'
                />
                <img
                  src={logoDarkImgUrl}
                  height='186'
                  className='hidden md:block'
                  alt='Cardlytics Logo'
                />
              </div>
            </div>
            <div className='flex h-full w-2/4 flex-col justify-center bg-white p-12 md:w-full'>
              <div className='text-base font-bold leading-5'>
                {t('library.welcomeTo')}
              </div>
              <div className='mb-8'>
                <span className='text-3xl font-bold'>{appName}</span>
              </div>
              <div>{children}</div>
            </div>
          </div>
          <span className='absolute bottom-1 right-1 text-xs'>
            {buildNumber}
          </span>
        </div>
      </div>
    </div>
  );
}

export { LoginPage };
export type { LoginPageProps };
