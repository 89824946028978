import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { InputSize, TextInput, TextInputType } from '../../../../inputs';
import {
  useFieldProps,
  FieldContainer,
  FieldLabel,
  FieldError,
  FieldInputCount
} from '../../helpers';
import { BaseField } from '../../helpers/types';

interface TextFieldProps<TFormSchemaType extends FieldValues>
  extends BaseField<TFormSchemaType> {
  decorator?: React.ReactNode;
  max?: number;
  placeholder?: string;
  size?: InputSize;
  type?: TextInputType;
  clearable?: boolean;
  counter?: boolean;
  postContent?: string;
}

function TextField<TFormSchemaType extends FieldValues>({
  decorator,
  deps,
  disabled = false,
  label,
  max = Number.POSITIVE_INFINITY,
  name,
  optional = false,
  placeholder,
  secondaryLabel,
  size,
  tooltipContent,
  type,
  warningSchema,
  clearable,
  counter = true,
  postContent
}: TextFieldProps<TFormSchemaType>) {
  const {
    formState: { errors }
  } = useFormContext();

  const error = errors[name]?.message as string | undefined;

  const {
    containerProps,
    controllerProps,
    inputProps,
    labelProps,
    errorProps,
    triggerDeps
  } = useFieldProps<TFormSchemaType>({
    deps,
    label,
    name,
    optional,
    secondaryLabel,
    tooltipContent,
    warningSchema
  });

  return (
    <FieldContainer {...containerProps}>
      <FieldLabel {...labelProps} />
      <Controller
        {...controllerProps}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            {...inputProps}
            clearable={clearable}
            disabled={disabled}
            decorator={decorator}
            placeholder={placeholder}
            aria-describedby={error ? errorProps.id : undefined}
            aria-invalid={error !== undefined}
            aria-required={!optional}
            size={size}
            type={type}
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
              triggerDeps();
            }}
            onBlur={onBlur}
            postContent={postContent}
          />
        )}
      />
      <div className='flex justify-between'>
        <div className='mr-2'>
          <FieldError<TFormSchemaType> {...errorProps} />
        </div>
        {counter && (
          <div className='ml-auto'>
            <FieldInputCount<TFormSchemaType> name={name} max={max} />
          </div>
        )}
      </div>
    </FieldContainer>
  );
}

export type { TextFieldProps };
export { TextField };
