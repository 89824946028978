import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoContainer } from '@components/InfoContainer/InfoContainer';
import { PropertyInfo } from '@components/PropertyInfo/PropertyInfo';

import { GetCampaignByIdResponse, RewardModelType } from '@models/campaign';
import { useInstitution } from '@/context-providers/institution';

interface OfferDetailsProps {
  offer: GetCampaignByIdResponse;
  isLoading: boolean;
}

const OfferDetails = ({ offer, isLoading }: OfferDetailsProps) => {
  const [t] = useTranslation();

  const { currency } = useInstitution();

  const advertisement = useMemo(
    () => (offer && offer.ads && offer.ads.length > 0 ? offer.ads[0] : null),
    [offer]
  );

  const offerValue = useMemo(
    () =>
      advertisement?.rewardAmount === 0
        ? `${advertisement.rewardPercent * 100}%`
        : `${currency.mark}${advertisement?.rewardAmount}`,
    [advertisement]
  );

  const minSpend = useMemo(
    () => `${currency.mark}${advertisement?.minSpend}`,
    [advertisement]
  );

  const rewardCap = useMemo(
    () => `${currency.mark}${advertisement?.rewardCap}`,
    [advertisement]
  );

  const rewardTypeText = useMemo(() => {
    const rewardTranslatedProp = advertisement?.rewardType
      ? RewardModelType[advertisement?.rewardType]
      : '';

    return t(`rewardModel.${rewardTranslatedProp}`);
  }, [advertisement?.rewardType]);

  return (
    <InfoContainer title={`${t('label.offerDetails')}`}>
      {isLoading ? (
        <div className='w-full'>
          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-8 h-4 w-4/5 animate-pulsate' />

          <div className='pulsate mb-2 h-4 w-full animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-3/5 animate-pulsate' />
          <div className='pulsate mb-2 h-4 w-4/5 animate-pulsate' />
        </div>
      ) : (
        <div>
          <div className='mb-6 flex items-start justify-start'>
            <div className='mr-2 w-1/3'>
              <PropertyInfo
                text={t('label.adId')}
                value={advertisement?.adId}
              />
            </div>
            <div className='w-2/3'>
              <PropertyInfo
                text={t('label.merchantName')}
                value={advertisement?.creative.merchantName}
              />
            </div>
          </div>
          <div className='mb-6 flex items-start justify-start'>
            {advertisement?.storeLocationTypes &&
              advertisement.storeLocationTypes.length > 0 && (
                <div className='mr-2 w-1/3'>
                  <PropertyInfo
                    text={t('label.channels')}
                    value={advertisement?.storeLocationTypes.join(', ')}
                  />
                </div>
              )}
            {Boolean(advertisement?.rewardType) && (
              <div className='w-2/3'>
                <PropertyInfo
                  text={t('label.rewardType')}
                  value={rewardTypeText}
                />
              </div>
            )}
            <div />
          </div>
          <div className='flex items-start justify-start'>
            <div className='mr-2 w-1/3'>
              <PropertyInfo text={t('label.offerValue')} value={offerValue} />
            </div>
            {Boolean(advertisement?.minSpend) && (
              <div className='mr-2 w-1/3'>
                <PropertyInfo text={t('label.minSpend')} value={minSpend} />
              </div>
            )}
            {Boolean(advertisement?.rewardCap) && (
              <PropertyInfo text={t('label.maxReward')} value={rewardCap} />
            )}
          </div>
        </div>
      )}
    </InfoContainer>
  );
};

export { OfferDetails };
