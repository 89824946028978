import React from 'react';
import { ButtonlikeSize } from '../types';
import { useLinkClasses } from '../use-link-classes';
import { NavLink, NavLinkProps } from './nav-link';

interface BasicLinkProps
  extends Omit<NavLinkProps, 'className' | 'size' | 'usage' | 'variant'> {
  size?: ButtonlikeSize;
}

const BasicLink = React.forwardRef<HTMLAnchorElement, BasicLinkProps>(
  function BasicLinkWithRef({ size = 'size.md', ...delegated }, ref) {
    return (
      <NavLink {...delegated} className={useLinkClasses({ size })} ref={ref} />
    );
  }
);

export type { BasicLinkProps };
export { BasicLink };
