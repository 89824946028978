import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

import { ErrorDisplay } from '@library/components/error-display';

import { UserRedirect } from '@components/UserRedirect/UserRedirect';
import { Chrome } from '@components/Chrome/Chrome';
import { RequireAuth } from '@components/RequireAuth/RequireAuth';
import { IdleTimer } from '@components/IdleTimer/IdleTimer';

import { Login } from '@views/Login/Login';
import { TabView } from '@views/TabView/TabView';
import { AdminTabView } from '@views/AdminTabView/AdminTabView';
import { CustomersListView } from '@views/CustomersListView/CustomersListView';
import { OffersListView } from '@views/OffersListView/OffersListView';
import { InstitutionList } from '@views/InstitutionList/InstitutionList';

const routes = createRoutesFromElements(
  <>
    <Route path='/' element={<UserRedirect />} />
    <Route
      path=''
      element={
        <>
          <RequireAuth>
            <IdleTimer>
              <Chrome />
            </IdleTimer>
          </RequireAuth>
        </>
      }
    >
      <Route path='' element={<AdminTabView />}>
        <Route path='publishers/*' element={<InstitutionList />} />
        <Route path='configuration/*' element={<OffersListView />} />
      </Route>
      <Route path='' element={<TabView />}>
        <Route path='customers/*' element={<CustomersListView />} />
        <Route path='offers/*' element={<OffersListView />} />
      </Route>
    </Route>
    <Route
      path='/login'
      element={
        <>
          <Login />
        </>
      }
    />
    <Route path='*' element={<ErrorDisplay status={404} />} />
  </>
);

const router = createBrowserRouter(routes);
const Router = () => <RouterProvider router={router} />;

export { Router };
