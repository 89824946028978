import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { TextInput, SingleCheckbox } from '@library/components';

interface InstitutionGridHeaderProps {
  onFilterText: (text: string) => void;
  onFilterActive: (boolean) => void;
  hideInactiveValue: boolean;
}

const InstitutionGridHeader = ({
  onFilterText,
  onFilterActive,
  hideInactiveValue
}: InstitutionGridHeaderProps) => {
  const [t] = useTranslation();

  const [value, setValue] = useState('');

  const textInputHandler = (valueInput: string) => {
    setValue(valueInput);
    onFilterText(valueInput);
  };

  return (
    <div className='flex justify-between'>
      <div className='flex w-4/5 items-center '>
        <div className='basis-2/4 '>
          <TextInput
            className='flex-1'
            value={value}
            onChange={textInputHandler}
            decorator={<MagnifyingGlassIcon />}
            size='size.md'
            placeholder={`${t('institution.searchPublisherInput')}`}
          />
        </div>
        <div className='ml-4'>
          <SingleCheckbox
            id='setup-needed'
            checked={hideInactiveValue}
            onSelectedItemChange={onFilterActive}
          >
            <span className='text-sm text-gray-darkGray'>
              {t('common.hideInactive')}
            </span>
          </SingleCheckbox>
        </div>
      </div>
    </div>
  );
};
export { InstitutionGridHeader };
