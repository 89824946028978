import {
  OnChangeFn,
  Row,
  RowSelectionState,
  Table
} from '@tanstack/react-table';
import { SingleCheckbox } from '../checkbox';

type TablePageSizeOptions = number[];

interface UnselectableTableProps {
  enableSelectedItems?: never;
  persistSelectedItemsAcrossPages?: never;
  selectedItems?: never;
  selectedItemsKey?: never;
  onSelectedItemsChange?: never;
}

interface SelectableTableProps<TableData> {
  enableSelectedItems: boolean;
  persistSelectedItemsAcrossPages?: boolean;
  selectedItems: TableData[];
  selectedItemsKey: keyof TableData;
  onSelectedItemsChange: (value: TableData[]) => void;
}

const UNKNOWN_TOTAL_VALUE = -1;

const CHECKBOX_COLUMN_ID = 'select';

const commonCellClasses = 'px-4 py-5';

const bodyCellClasses = 'border-b border-l last:border-r border-neutral';

const borderlessBodyCellClasses = 'first:border-l-0 last:border-r-0';

function getCheckboxColumn<TableData>({ id }: { id: string }) {
  return {
    id: CHECKBOX_COLUMN_ID,
    header({ table }: { table: Table<TableData> }) {
      const allSelected = table.getIsAllPageRowsSelected();
      const someSelected = table.getIsSomePageRowsSelected();
      const onAllChange = () => {
        /*
         * The below is to maintain parity with our nested checkboxes, which
         * selects all items if no items are selected OR if some, but not all,
         * items are selected.
         */
        if (allSelected) {
          table.toggleAllPageRowsSelected(false);
        } else {
          table.toggleAllPageRowsSelected(true);
        }
      };

      return (
        <SingleCheckbox
          id={`table-checkbox-${id}-all`}
          disabled={table.options.meta?.loading}
          checked={allSelected}
          indeterminate={someSelected}
          onSelectedItemChange={onAllChange}
        />
      );
    },
    cell({ row, table }: { row: Row<TableData>; table: Table<TableData> }) {
      const rowSelected = row.getIsSelected();
      const someRowSelected = row.getIsSomeSelected();
      const onRowChange = row.getToggleSelectedHandler();

      return (
        <SingleCheckbox
          id={`table-checkbox-${id}-row-${row.index}`}
          disabled={table.options.meta?.loading}
          checked={rowSelected}
          indeterminate={someRowSelected}
          onSelectedItemChange={onRowChange}
        />
      );
    }
  };
}

function getRowSelectionFromSelectedItems<TableData>({
  selectedItems,
  selectedItemsKey,
  data
}: {
  selectedItems: TableData[];
  selectedItemsKey: keyof TableData;
  data: TableData[];
}) {
  const selectedItemsMap = new Map<TableData[keyof TableData], TableData>();
  for (const item of selectedItems) {
    selectedItemsMap.set(item[selectedItemsKey], item);
  }

  const rowSelection: RowSelectionState = {};

  for (const [i, datum] of data.entries()) {
    if (selectedItemsMap.has(datum[selectedItemsKey])) {
      rowSelection[i] = true;
    }
  }

  return rowSelection;
}

function onRowSelectionChange<TableData>({
  onSelectedItemsChange,
  rowSelection,
  data
}: {
  onSelectedItemsChange: (value: TableData[]) => void;
  rowSelection: RowSelectionState;
  data: TableData[];
}): OnChangeFn<RowSelectionState> {
  return (updater) => {
    /*
     * Apparently updater is not always a function, but I don't know
     * when that would be the case.
     */
    // istanbul ignore else -- @preserve
    if (typeof updater === 'function') {
      const newRowSelectionState = updater(rowSelection);

      const newSelectedItems: TableData[] = [];

      for (const selectedIndex in newRowSelectionState) {
        newSelectedItems.push(data[+selectedIndex]);
      }

      onSelectedItemsChange(newSelectedItems);
    }
  };
}

export {
  CHECKBOX_COLUMN_ID,
  UNKNOWN_TOTAL_VALUE,
  commonCellClasses,
  bodyCellClasses,
  getCheckboxColumn,
  getRowSelectionFromSelectedItems,
  onRowSelectionChange,
  borderlessBodyCellClasses
};
export type {
  TablePageSizeOptions,
  UnselectableTableProps,
  SelectableTableProps
};
