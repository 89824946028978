import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

// import { CreateTransactionRequest } from '@models/customer';

interface PostParams {
  payload: any[];
}

const postOfferTransaction: AdapterMutationFunction<any, PostParams> = async (
  params
) => {
  const { payload } = params;

  const url = `/v1/customers/transactions`;

  return apiTransport.post<any>({
    url,
    body: payload as Record<any, any>[]
  });
};

export type { PostParams };
export { postOfferTransaction };
