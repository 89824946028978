import React from 'react';

interface BaseAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean;
  href: string;
}

const BaseAnchor = React.forwardRef<HTMLAnchorElement, BaseAnchorProps>(
  function BaseAnchor({ className, disabled, onClick, ...delegated }, ref) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <a
        {...delegated}
        aria-disabled={disabled}
        ref={ref}
        rel='noreferrer noopener'
        target='_blank'
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
            return;
          }

          onClick && onClick(e);
        }}
        className={className}
      />
    );
  }
);

export { BaseAnchor };
