import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';

import { Offer } from '@models/campaign';
import { FilterRequestParam } from '@models/table';
import { TablePaginationState } from '@library/components';

import { getFilterString, getPaginationString } from '@utils/rest-api';

interface GetParams {
  path: string;
  filter: FilterRequestParam[];
  pagination: TablePaginationState;
}

const get: AdapterFunction<Offer[], GetParams> = (
  { path, filter, pagination },
  { signal }
) => {
  const parsedFilter =
    getFilterString(filter).length > 0 ? `&${getFilterString(filter)}` : '';

  const parsedPagination =
    getPaginationString(pagination).length > 0
      ? `?${getPaginationString(pagination)}`
      : '';

  const url = `/v1/campaigns${path}${parsedPagination}${parsedFilter}`;

  const response = apiTransport.get<Offer[]>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export type { GetParams };
export { get };
