import invariant from 'tiny-invariant';
import { institutionAdapter } from '@/adapters/institution';
import { ServiceData, ServiceQuery } from '../service';

import { Institution } from '@models/institution';

interface GetByIdData extends ServiceData {
  institution: Institution;
}

interface GetByIdParams {
  institutionId: string;
}

const getById: ServiceQuery<GetByIdData, GetByIdParams> = async (context) => {
  const { queryKey, signal } = context;
  const [{ institutionId }] = queryKey;

  invariant(institutionId, 'institutionId is required');

  const { data } = await institutionAdapter.getById(
    { institutionId },
    { signal }
  );

  return { institution: data };
};

export type { GetByIdData, GetByIdParams };
export { getById };
