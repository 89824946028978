import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
import { GetCampaignByIdResponse } from '@models/campaign';

interface GetByIdParams {
  campaignId: number;
}

const getById: AdapterFunction<GetCampaignByIdResponse, GetByIdParams> = (
  { campaignId },
  { signal }
) => {
  const url = `/v1/campaigns/${campaignId}`;

  const response = apiTransport.get<GetCampaignByIdResponse>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export type { GetByIdParams };
export { getById };
