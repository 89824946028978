import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { classnames } from '@library/utils';
import {
  Column,
  HeaderContext,
  SortDirection,
  Table,
  flexRender
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

function sortColumn<TableData>({
  event,
  table,
  column
}: {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  table: Table<TableData>;
  column: Column<TableData>;
}) {
  column.getToggleSortingHandler()?.(event);

  if (!table.options.meta?.persistSelectedItemsAcrossPages) {
    table.toggleAllRowsSelected(false);
  }
}

interface SortArrowProps {
  sortDir: SortDirection | false;
  nextSortDir: SortDirection | false;
}

function SortArrow({ sortDir, nextSortDir }: SortArrowProps) {
  const effectiveSortDir = sortDir || nextSortDir;
  const isCurrentSort = !!sortDir;

  const arrowClasses = 'h-3 w-3 ml-1';

  const upcomingSortClasses = isCurrentSort
    ? ''
    : 'opacity-0 group-hover:opacity-100';

  if (effectiveSortDir === 'asc') {
    return (
      <ChevronUpIcon
        className={classnames(arrowClasses, upcomingSortClasses)}
      />
    );
  }

  return (
    <ChevronDownIcon
      className={classnames(arrowClasses, upcomingSortClasses)}
    />
  );
}

interface ColumnHeaderNameProps<TableData> {
  table: Table<TableData>;
  column: Column<TableData>;
  context: HeaderContext<TableData, unknown>;
}

function ColumnHeaderName<TableData>({
  table,
  column,
  context
}: ColumnHeaderNameProps<TableData>) {
  const { t } = useTranslation('global');

  if (!column.getCanSort()) {
    return (
      <span className='whitespace-nowrap'>
        {flexRender(column.columnDef.header, context)}
      </span>
    );
  }

  const ariaLabel: string =
    typeof column.columnDef.header === 'function'
      ? ''
      : t('library.sortByColumnX', {
          columnName: column.columnDef.header
        });

  return (
    <button
      type='button'
      aria-label={ariaLabel}
      className='group flex items-center whitespace-nowrap hover:text-link-hover'
      onClick={(e) => {
        sortColumn({ event: e, table, column });
      }}
    >
      {flexRender(column.columnDef.header, context)}
      <SortArrow
        sortDir={column.getIsSorted()}
        nextSortDir={column.getNextSortingOrder()}
      />
    </button>
  );
}

export { ColumnHeaderName };
