import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import { IconButton, Modal, Tooltip, useToasts } from '@library/components';
import { EditOfferModalContent } from './EditOfferModalContent';

import { useApiQuery } from '@hooks/use-api-query';

import { GetCampaignByIdResponse } from '@models/campaign';

import { offerService } from '@services/offer';

const EditOfferModal = ({ campaignId }: { campaignId: number }) => {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { sendToast } = useToasts();

  const { data, isLoading, isError } = useApiQuery(
    offerService.getById({
      params: { campaignId },
      enabled: isModalOpen && Boolean(campaignId)
    })
  );

  const offer = useMemo(
    () => data?.offer || ({} as GetCampaignByIdResponse),
    [data]
  );

  useEffect(() => {
    if (isModalOpen && offer.ads && offer.ads.length > 1) {
      sendToast({
        variant: 'variant.error',
        content: `${t('label.offerWithSeveralAds')}`
      });
      setIsModalOpen(false);
    }
  }, [offer, isModalOpen]);

  useEffect(() => {
    if (isError) {
      sendToast({
        variant: 'variant.error',
        content: `${t('label.couldNotLoadOffers')}`
      });
      setIsModalOpen(false);
    }
  }, [isError]);

  return (
    <Modal
      isOpen={isModalOpen}
      trigger={
        <div>
          <Tooltip content={t('label.editOffer')}>
            <IconButton
              size='size.md'
              variant='variant.primary'
              aria-label={`${t('label.editOffer')}`}
              onClick={() => setIsModalOpen(true)}
            >
              <PencilSquareIcon className=' text-blue-action' />
            </IconButton>
          </Tooltip>
        </div>
      }
      header={
        <div className='p-4'>
          <h3 className='mb-2 text-2xl'>{t('label.configureOffer')}</h3>
        </div>
      }
      maxWidth='maxWidth.large'
      onClose={() => {
        setIsModalOpen(false);
      }}
      content={
        <EditOfferModalContent
          offer={offer}
          isLoading={isLoading}
          isError={isError}
          setIsModalOpen={setIsModalOpen}
        />
      }
    />
  );
};

export { EditOfferModal };
