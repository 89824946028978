import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

import { CreateCustomer } from '@models/customer';

interface PostParams {
  payload: any;
}

const post: AdapterMutationFunction<CreateCustomer[], PostParams> = async (
  params
) => {
  const { payload } = params;

  const url = `/v1/customers`;

  return apiTransport.post<CreateCustomer[]>({
    url,
    body: payload
  });
};

export type { PostParams };
export { post };
