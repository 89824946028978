import React from 'react';
import { ButtonlikeSize, ButtonlikeVariant, ButtonlikeWidth } from '../types';
import { useButtonClasses } from '../use-button-classes';
import { NavLink, NavLinkProps } from './nav-link';

interface SecondaryLinkProps
  extends Omit<NavLinkProps, 'className' | 'size' | 'usage' | 'variant'> {
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  width?: ButtonlikeWidth;
}

const SecondaryLink = React.forwardRef<HTMLAnchorElement, SecondaryLinkProps>(
  function SecondaryLinkWithRef(
    {
      size = 'size.md',
      variant = 'variant.base',
      width = 'width.unset',
      ...delegated
    },
    ref
  ) {
    return (
      <NavLink
        {...delegated}
        className={useButtonClasses({
          size,
          usage: 'usage.secondary',
          variant,
          width
        })}
        ref={ref}
      />
    );
  }
);

export type { SecondaryLinkProps };
export { SecondaryLink };
