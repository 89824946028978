import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useToasts } from '@library/components';

import { useApiMutation } from '@hooks/use-api-mutation';
import { useApiQuery } from '@hooks/use-api-query';

import { Ad } from '@models/ads';

import { customerService } from '@services/customer';

import { useCustomer } from './customer';
import { useInstitution } from './institution';

import { isDateGreater, isDateLower } from '@library/utils/date';

export interface DropdownOption {
  id: string;
  label: string;
}

interface OfferProviderProps {
  children: React.ReactNode;
}

type OfferContextType = {
  advertisement: Ad;
  selectedOfferState: DropdownOption;
  isSavingOfferState: boolean;
  offerStateOptions: DropdownOption[];
  isRedeemFieldsVisible: boolean;
  isTransactionStringsEmpty: boolean;
  pendingTransactions: Transaction[];
  transactionStrings: DropdownOption[];
  isRedeemingOffer: boolean;
  parkedOffer: boolean;
  isOfferHidden: boolean;
  isLoadingTransactionsStrings: boolean;
  transactionValidationMessages: string[];
  removeAllPendingTransactions: () => void;
  handleUpdateTransaction: ({
    targetId,
    newTransaction
  }: {
    targetId: string;
    newTransaction: Transaction;
  }) => void;
  setIsRedeemFieldsVisible: Dispatch<SetStateAction<boolean>>;
  handleRemovePendingTransaction: (transactionId: string) => void;
  handleRedeemOffer: () => void;
  saveOfferState: (item: DropdownOption) => void;
  setIsSavingOfferState: Dispatch<SetStateAction<boolean>>;
  setSelectedOfferState: Dispatch<SetStateAction<DropdownOption>>;
  setAdvertisement: Dispatch<SetStateAction<Ad>>;
  handlePostRedemptions: () => void;
  handleParkOffer: (value: boolean) => void;
};

export interface Transaction {
  localId: string;
  transactionType: DropdownOption;
  activationDate: string;
  authorizationDate: string;
  postedDate: string;
  transactionString: DropdownOption;
  transactionAmount: number;
}

const STATE_OPTIONS = {
  NEW: {
    id: 'NEW',
    label: 'New'
  },
  SERVED: {
    id: 'SERVED',
    label: 'Served'
  },
  ACTIVATED: {
    id: 'ACTIVATED',
    label: 'Activated'
  }
};

const getTodaysDate = () => {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = today.getFullYear();

  return `${year}-${month}-${day}`;
};

const getDefaultTransactions = (
  transactionStrings: DropdownOption[]
): Transaction => ({
  localId: uuidv4(),
  transactionType: { id: 'CLEAR', label: 'CLEAR' },
  activationDate: getTodaysDate(),
  authorizationDate: getTodaysDate(),
  postedDate: getTodaysDate(),
  transactionString:
    transactionStrings.length > 0
      ? transactionStrings[0]
      : ({} as DropdownOption),
  transactionAmount: 1000
});

const updateDefaultTransactions = (
  pendingTransactions: Transaction[],
  transactionStrings: DropdownOption[]
) =>
  pendingTransactions.map((transaction) => ({
    ...transaction,
    transactionString: transactionStrings[0]
  }));

const OfferContext = createContext<OfferContextType>({} as OfferContextType);

const useOffer = () => {
  const context = useContext(OfferContext);

  if (context === undefined) {
    throw new Error('useOffer can only be used within an OfferContext');
  }
  return context;
};

const OfferProvider = ({ children }: OfferProviderProps) => {
  const [transactionValidationMessages, setTransactionValidationMessages] =
    useState([] as string[]);
  const [advertisement, setAdvertisement] = useState<Ad>({} as Ad);
  const [isSavingOfferState, setIsSavingOfferState] = useState(false);

  const [selectedOfferState, setSelectedOfferState] = useState<DropdownOption>({
    id: '',
    label: ''
  });
  const [parkedOffer, setParkedOffer] = useState(false);
  const [isRedeemFieldsVisible, setIsRedeemFieldsVisible] = useState(false);
  const [isRedeemingOffer, setIsRedeemingOffer] = useState(false);
  const [pendingTransactions, setPendingTransactions] = useState<Transaction[]>(
    []
  );

  const [t] = useTranslation();

  const { sendToast } = useToasts();

  const { customer, refetchOffers } = useCustomer();
  const { currency, institutionInfo } = useInstitution();

  const country = useMemo(() => institutionInfo.country, [institutionInfo]);

  ///////////// /ReactQuery
  const createMutation = useApiMutation(customerService.postClientEvent());
  const createTransactionMutation = useApiMutation(
    customerService.createOfferTransaction()
  );

  const {
    data: offerTransactionsResponse,
    isLoading: isLoadingTransactionsStrings
  } = useApiQuery(
    customerService.getOfferTransactions({
      params: { adId: `${advertisement.id}`, country },
      enabled: isRedeemFieldsVisible
    })
  );

  const transactionStrings = useMemo(
    () =>
      offerTransactionsResponse
        ? offerTransactionsResponse.data.transactions.map((transaction) => ({
            id: uuidv4(),
            label: transaction.transactionString
          }))
        : ([] as DropdownOption[]),
    [offerTransactionsResponse]
  );

  const paymentNetworkMCC = useMemo(
    () =>
      offerTransactionsResponse
        ? offerTransactionsResponse.data.paymentNetworkMCC
        : null,
    [offerTransactionsResponse]
  );

  useEffect(() => {
    // set default transaction string after fetched
    if (transactionStrings.length > 0) {
      setPendingTransactions(
        updateDefaultTransactions(pendingTransactions, transactionStrings)
      );
    }
  }, [transactionStrings]);

  const isTransactionStringsEmpty = useMemo(
    () => !isLoadingTransactionsStrings && transactionStrings.length === 0,
    [transactionStrings]
  );

  ///////////// Default Values
  useEffect(() => {
    if (advertisement.visibilityState) {
      setParkedOffer(advertisement.visibilityState === 'PARKED');
    }
  }, [advertisement.visibilityState]);

  useEffect(() => {
    if (advertisement.activationState) {
      setSelectedOfferState(STATE_OPTIONS[advertisement.activationState]);
    }
  }, [advertisement.activationState]);

  const currentOfferState = useMemo(
    () => advertisement.activationState,
    [advertisement.activationState]
  );

  const isOfferHidden = useMemo(
    () => advertisement.visibilityState === 'PARKED',
    [advertisement.visibilityState]
  );

  const offerStateOptions = useMemo(() => {
    let options = [] as DropdownOption[];
    switch (currentOfferState) {
      case 'NEW': {
        options = [
          {
            id: 'NEW',
            label: 'New'
          },
          {
            id: 'SERVED',
            label: 'Serve'
          }
        ];
        break;
      }

      case 'SERVED': {
        options = [
          {
            id: 'SERVED',
            label: 'Serve'
          },
          {
            id: 'ACTIVATED',
            label: 'Activate'
          }
        ];
        break;
      }

      case 'ACTIVATED': {
        options = [
          {
            id: 'ACTIVATED',
            label: 'Activate'
          }
        ];
        break;
      }

      default: {
        options = [
          {
            id: 'REDEEMED',
            label: 'Redeemed'
          }
        ];
        break;
      }
    }
    return options;
  }, [advertisement.activationState, selectedOfferState]);

  ///////////// Handlers
  const handleRedeemOffer = () => {
    const isMultiRedemption = advertisement.reward.isMultiRedemption;

    if (isMultiRedemption || pendingTransactions.length === 0) {
      const newPendingTransaction = getDefaultTransactions(transactionStrings);
      setPendingTransactions([...pendingTransactions, newPendingTransaction]);
    }

    setIsRedeemFieldsVisible(true);
  };
  const handleRemovePendingTransaction = (transactionId) => {
    if (pendingTransactions.length === 1) {
      setIsRedeemFieldsVisible(false);
    }

    setPendingTransactions(
      pendingTransactions.filter(
        (transaction) => transaction.localId !== transactionId
      )
    );
  };

  const removeAllPendingTransactions = () => {
    setPendingTransactions([]);
  };

  const handleUpdateTransaction = ({ newTransaction, targetId }) => {
    setPendingTransactions(
      pendingTransactions.map((transaction) =>
        transaction.localId === targetId ? newTransaction : transaction
      )
    );
  };

  const handleParkOffer = (value) => {
    setParkedOffer(value);
    setIsSavingOfferState(true);

    const { adServeToken, id } = advertisement;

    const clientEvent = value ? 'ParkOffer' : 'UnParkOffer';
    const visibilityState = value ? 'PARKED' : 'VISIBLE';

    const payload = {
      ads: {
        adId: id,
        adServeToken,
        visibilityState
      },
      clientEventType: 'AdInteraction',
      clientEvent
    };

    requestUpdateOffer(payload);
  };

  ///////////// Requests
  const saveOfferState = (item: DropdownOption) => {
    setIsSavingOfferState(true);

    const isActivation = item.id === 'ACTIVATED';

    const { adServeToken, id } = advertisement;

    const payload = {
      ads: {
        adId: id,
        activationState: item.id,
        adServeToken
      },
      clientEventType: isActivation ? 'AdInteraction' : 'AdImpression',
      clientEvent: isActivation ? 'ActivateOffer' : 'AdViewableImpression'
    };

    requestUpdateOffer(payload);
  };

  const requestUpdateOffer = (payload) => {
    createMutation.mutate(
      {
        payload,
        sourceCustomerId: customer.sourceCustomerId
      },
      {
        onSuccess() {
          // invalidate cache to request org list again after redirection
          refetchOffers();
          setIsSavingOfferState(false);
        },
        onError(error) {
          setIsSavingOfferState(false);

          sendToast({
            variant: 'variant.error',
            content:
              error?.message ||
              `Could not update the offer state now. Try again later`
          });
        }
      }
    );
  };

  const areTransactionsValid = (payload) => {
    const { minSpendAmount } = advertisement.reward.purchaseRequirement;

    const authorizationDateErrorMessage = t('common.authorizationDateError');
    const postedDateErrorMessage = t('common.postedDateError');
    const minSpendErrorMessage = t('common.minSpendError');

    const messages: string[] = [];

    // Validate Min Spend
    payload.forEach((transaction) => {
      if (
        transaction.postedAmount < minSpendAmount &&
        !messages.includes(minSpendErrorMessage)
      ) {
        messages.push(minSpendErrorMessage);
      }
    });

    payload.forEach((transaction) => {
      const { endDate, activationDate } = advertisement;
      const { transactionTimestamp, transactionPostingTimestamp } = transaction;

      if (
        isDateLower(transactionTimestamp, activationDate) &&
        !messages.includes(authorizationDateErrorMessage)
      ) {
        messages.push(authorizationDateErrorMessage);
      }

      if (
        isDateGreater(transactionPostingTimestamp, endDate) &&
        !messages.includes(postedDateErrorMessage)
      ) {
        messages.push(postedDateErrorMessage);
      }
    });

    setTransactionValidationMessages(messages);
    return messages.length === 0;
  };

  const handlePostRedemptions = () => {
    setTransactionValidationMessages([]);
    setIsRedeemingOffer(true);

    const { sourceAccountId, iin, last4, sourceCustomerId } = customer;

    const payload = pendingTransactions.map((transaction) => ({
      adId: advertisement.id,
      sourceAccountId,
      postedAmount: `${transaction.transactionAmount * 100}`, // API will divide by 100 to get the decimals
      transactionType: transaction.transactionType.label,
      sourceCustomerId,
      paymentNetworkMCC: `${paymentNetworkMCC}`,
      currencyCode: currency.code,
      transactionPostingTimestamp: new Date(
        transaction.postedDate
      ).toISOString(),
      transactionTimestamp: new Date(
        transaction.authorizationDate
      ).toISOString(),
      authorizationAmount: `${transaction.transactionAmount * 100}`, // API will divide by 100 to get the decimals
      transactionString: transaction.transactionString.label,
      iin,
      last4
    }));

    if (areTransactionsValid(payload)) {
      createTransactionMutation.mutate(
        {
          payload
        },
        {
          onSuccess() {
            sendToast({
              variant: 'variant.success',
              content: `Offer redemption started successfully. This could take up to 7 hours`
            });

            setIsRedeemingOffer(false);
          },
          onError(error) {
            setIsRedeemingOffer(false);

            sendToast({
              variant: 'variant.error',
              content:
                error?.message || `Could not redeem offer now. Try again later`
            });
          }
        }
      );
    }

    setIsRedeemingOffer(false); // TODO: move to proper place
  };

  return (
    <OfferContext.Provider
      value={{
        advertisement,
        selectedOfferState,
        parkedOffer,
        isSavingOfferState,
        offerStateOptions,
        isRedeemFieldsVisible,
        isTransactionStringsEmpty,
        pendingTransactions,
        transactionStrings,
        isRedeemingOffer,
        isOfferHidden,
        isLoadingTransactionsStrings,
        transactionValidationMessages,
        removeAllPendingTransactions,
        handleParkOffer,
        setIsRedeemFieldsVisible,
        handleUpdateTransaction,
        handleRemovePendingTransaction,
        handleRedeemOffer,
        saveOfferState,
        setIsSavingOfferState,
        setAdvertisement,
        setSelectedOfferState,
        handlePostRedemptions
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export { OfferProvider, useOffer };
