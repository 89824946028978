import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

import { UpdateCampaignRequest } from '@models/campaign';

interface PutParams {
  body: any;
  sourceCustomerId: string;
}

const postClientEvent: AdapterMutationFunction<
  Partial<UpdateCampaignRequest>,
  PutParams
> = async (params) => {
  const { body, sourceCustomerId } = params;

  const url = `/v1/customers/clientevents`;

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: sourceCustomerId
  });

  const response = apiTransport.post<Partial<UpdateCampaignRequest>>({
    url,
    body
  });

  apiTransport.setHeader({
    key: 'X-Cdlx-Customer-Id',
    value: ''
  });

  return response;
};

export type { PutParams };
export { postClientEvent };
