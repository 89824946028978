import invariant from 'tiny-invariant';
import { ServiceData, ServiceQuery } from '../service';

import { customerAdapter } from '@adapters/customer';

interface GetByIdData extends ServiceData {
  data: any;
}

interface GetByIdParams {
  adId: string;
  country: string;
}

const getOfferTransactions: ServiceQuery<GetByIdData, GetByIdParams> = async (
  context
) => {
  const { queryKey, signal } = context;
  const [{ adId, country }] = queryKey;

  invariant(adId, 'adId is required');
  invariant(country, 'country is required');

  const { data } = await customerAdapter.getOfferTransactions(
    { adId, country },
    { signal }
  );

  return {
    data: {
      transactions: data.transactions,
      paymentNetworkMCC: data.merchantCategoryId
    }
  };
};

export type { GetByIdData, GetByIdParams };
export { getOfferTransactions };
