import { useId } from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { ZodSchema } from 'zod';

interface UseFieldPropsArgs<TFormSchemaType extends FieldValues> {
  collapsibleError?: boolean;
  deps?: string | string[];
  label: string;
  name: FieldPath<TFormSchemaType>;
  optional?: boolean;
  secondaryLabel?: string;
  tooltipContent?: React.ReactNode;
  warningSchema?: ZodSchema;
}

function useFieldProps<TFormSchemaType extends FieldValues>({
  collapsibleError,
  deps,
  label,
  name,
  optional,
  secondaryLabel,
  tooltipContent,
  warningSchema
}: UseFieldPropsArgs<TFormSchemaType>) {
  // make a call to useId to ensure that there are no duplicated field ids
  // in the case that we have multiple forms on a page
  const id = `${name}-field-${useId()}`;
  const errorId = `${id}-error`;

  const { trigger } = useFormContext();

  return {
    containerProps: {
      id
    },
    controllerProps: {
      name
      // shouldUnregister is required for fields that might disappear from the form,
      // but it does not play nicely with React.StrictMode, Controller, React 18, and useWatch
      // shouldUnregister: true
    },
    inputProps: {
      id
    },
    labelProps: {
      label,
      htmlFor: id,
      optional,
      secondaryLabel,
      tooltipContent
    },
    errorProps: {
      collapsible: collapsibleError,
      id: errorId,
      name,
      warningSchema
    },
    triggerDeps() {
      deps && void trigger(deps);
    }
  };
}

export { useFieldProps };
