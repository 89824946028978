import { AccountBookingCountry } from '../../account';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface CallToActionDefinition {
  readonly id: string;
  readonly countries?: AccountBookingCountry[];
  readonly default?: true;
}

const {
  getById: creativeCallToActionDef,
  getAll: creativeCallToActionAllDefs
} = createDefinition<string, CallToActionDefinition>({
  definitions: {
    [t('creativemodel.NO_CALL_TO_ACTION')]: {
      default: true
    },
    // The 'id' saved for call-to-action links is the full text shown to a user, not an internal Enum string
    // Should we add additional supported languages or calls to action, this will need to be expanded to include
    // all possible combinations of call to action and country.
    'Book Now': {
      countries: ['US', 'GB']
    },
    'Find Locations': {
      countries: ['US', 'GB']
    },
    'Get Started': {
      countries: ['US', 'GB']
    },
    'Learn More': {
      countries: ['US', 'GB']
    },
    'Order Now': {
      countries: ['US', 'GB']
    },
    'See Menu': {
      countries: ['US', 'GB']
    },
    'Shop Now': {
      countries: ['US', 'GB']
    },
    'Visit Site': {
      countries: ['US', 'GB']
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id,
      countries: []
    };
  }
});

// The default call to action definition is the definition that does not
// specify any countries
function getDefaultCallToActionDef() {
  return creativeCallToActionAllDefs().find(
    (definition) => definition.default
  ) as CallToActionDefinition;
}

export {
  creativeCallToActionAllDefs,
  creativeCallToActionDef,
  getDefaultCallToActionDef
};
export type { CallToActionDefinition };
