import { apiTransport } from '@services/rest-api';
import { AdapterDeleteFunction } from '../types';

interface DeleteParams {
  campaignId: string;
}

const del: AdapterDeleteFunction<DeleteParams> = async function ({
  campaignId
}) {
  const url = `/v1/campaigns/${campaignId}`;

  await apiTransport.delete({
    url
  });
};

export { del };
export type { DeleteParams };
