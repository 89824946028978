import { ServiceOptions, wrapQueryFunction } from '../service';

import { getById } from './get-by-id';
import { get } from './get';

const options: ServiceOptions = {
  name: 'INSTITUTION',
  cacheTime: 'cache.none'
};

const institutionService = {
  invalidationKey: [{ name: options.name }],
  getById: wrapQueryFunction(options, getById),
  get: wrapQueryFunction(options, get)
};

export { institutionService };
