import { apiTransport } from '@services/rest-api';
import { AdapterFunction } from '../types';
// import { TransactionStringsResponse } from '@models/customer';

interface GetByIdParams {
  adId: string;
  country: string;
}

const getOfferTransactions: AdapterFunction<any, GetByIdParams> = (
  { adId, country },
  { signal }
) => {
  const url = `/v1/transactions/transactionstrings?adId=${adId}&country=${country}`;

  const response = apiTransport.get<any>({
    url,
    config: {
      signal
    }
  });

  return response;
};

export type { GetByIdParams };
export { getOfferTransactions };
