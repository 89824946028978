import { redeemingMerchantAnnotationDef } from '@library/models/redeeming-merchant-annotation';
import { rewardAnnotationDef } from '@library/models/reward';
import { Headline } from './ad-copy';
import { useAdPreviewValues } from './ad-preview';

function AnnotationBadge({ text }: { text: string }) {
  return (
    <span className='rounded bg-neutral p-1 text-xs font-bold'>{text}</span>
  );
}

function Annotations() {
  const {
    productCategoryAnnotations,
    purchaseChannelAnnotations,
    rewardAnnotation,
    locale
  } = useAdPreviewValues();

  const redeemingMerchantAnnotations = [
    ...purchaseChannelAnnotations,
    ...productCategoryAnnotations
  ];

  const rewardAnnotationValue =
    rewardAnnotationDef(rewardAnnotation).annotationValue[locale];

  return (
    <>
      {redeemingMerchantAnnotations.length > 0 ? (
        <div>
          <AnnotationBadge
            text={redeemingMerchantAnnotations
              .map(
                (annotation) =>
                  redeemingMerchantAnnotationDef(annotation).label[locale]
              )
              .join(', ')}
          />
        </div>
      ) : null}
      {rewardAnnotationValue ? (
        <div>
          <AnnotationBadge text={rewardAnnotationValue} />
        </div>
      ) : null}
    </>
  );
}

function RewardInfo() {
  const { rewardCapMessage, expiresMessage } = useAdPreviewValues();

  return (
    <>
      <Headline />
      <div>{expiresMessage}</div>
      <div>{rewardCapMessage}</div>
    </>
  );
}

export { Annotations, RewardInfo };
