import { z } from 'zod';
import { translate as t } from '@/i18n';

interface NumberFieldSchemaOptions {
  currency?: string;
  max?: number;
  min?: number;
}

function getBaseSchema({
  currency,
  max = Number.POSITIVE_INFINITY,
  min = Number.NEGATIVE_INFINITY
}: NumberFieldSchemaOptions) {
  const schema = z
    .number()
    .min(min, {
      message: t('library.fieldValueMinError', {
        value: currency
          ? t('library.currencyDefault', { value: min, currency })
          : t('library.numberDefault', { value: min })
      })
    })
    .max(max, {
      message: t('library.fieldValueMaxError', {
        value: currency
          ? t('library.currencyDefault', { value: max, currency })
          : t('library.numberDefault', { value: max })
      })
    })
    .nullable();

  return schema;
}

function required(options: NumberFieldSchemaOptions = {}) {
  const schema = getBaseSchema(options);

  return schema.refine(
    (val): val is number => val !== null,
    t('library.fieldRequiredError')
  );
}

function optional(options: NumberFieldSchemaOptions = {}) {
  return getBaseSchema(options);
}

const numberFieldSchema = {
  required,
  optional
};

export { numberFieldSchema };
