import { apiTransport } from '@services/rest-api';
import { AdapterMutationFunction } from '../types';

import { CreateOfferRequest } from '@models/campaign';

interface PostParams {
  payload: any;
}

const post: AdapterMutationFunction<CreateOfferRequest, PostParams> = async (
  params
) => {
  const { payload } = params;

  const url = `/v1/campaigns`;

  return apiTransport.post<CreateOfferRequest>({
    url,
    body: payload
  });
};

export type { PostParams };
export { post };
