import { offerAdapter } from '@adapters/offer';
import { CreateOfferRequest } from '@models/campaign';
import { ServiceData, ServiceMutation } from '../service';
// import { offerService } from '.';

type OfferTypeCreateVariables = {
  payload: CreateOfferRequest;
};

interface CreateData extends ServiceData {
  // offers: any[]; // TODO: replace any for whatever the api returns
  // errors: any[];
  offer: { data: any };
}

const create: ServiceMutation<CreateData, OfferTypeCreateVariables> = async (
  params
) => {
  const { payload } = params;

  // const promises = offersToDuplicate.map((offer) =>
  //   offerAdapter.post({
  //     payload: offer
  //   })
  // );

  const responses = await offerAdapter.post({
    payload
  });

  // const responses = (await Promise.allSettled(promises)) as any;
  // const offers = responses.filter(
  //   (response) => response.status === 'fulfilled'
  // );

  // const errors = responses.filter(
  //   (response) => response.status !== 'fulfilled'
  // );

  return {
    offer: responses
  };
};

export type { CreateData, OfferTypeCreateVariables };
export { create };
