import { CampaignScheduleType } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface CampaignScheduleTypeDefinition {
  readonly id: CampaignScheduleType;
  readonly label: string;
}

const {
  getById: campaignScheduleTypeDef,
  getAll: campaignScheduleTypeAllDefs
} = createDefinition<CampaignScheduleType, CampaignScheduleTypeDefinition>({
  definitions: {
    Daily: {
      label: t('campaignmodel.SCHEDULE_DAILY')
    },
    Lifetime: {
      label: t('campaignmodel.SCHEDULE_LIFETIME')
    }
  },
  getUnknownDefinition(id: string) {
    return {
      id: id as CampaignScheduleType,
      label: id
    };
  }
});

export { campaignScheduleTypeAllDefs, campaignScheduleTypeDef };
export type { CampaignScheduleTypeDefinition };
