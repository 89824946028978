// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgLogo(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.8 0H0V14.4h4.8V9.6h4.8V4.8h4.8V0H4.8Z'
        fill='#46C8F2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.6 4.8h4.8v4.8H9.6V4.8Zm0 9.6v4.8h9.6V9.6h-4.8v4.8H9.6Zm0 0V9.6H4.8v4.8h4.8Z'
        fill='#1B89CA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.6 14.4H4.8V24H19.2v-4.8H9.6v-4.8Z'
        fill='#1DB0E6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2 4.8h-4.8v4.8h4.8v9.6H24V4.8h-4.8Z'
        fill='#46C8F2'
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
