import React, { AnchorHTMLAttributes } from 'react';
import { ButtonlikeSize, ButtonlikeVariant } from '../types';
import { useLinkClasses } from '../use-link-classes';
import { BaseAnchor } from './base';

interface BasicAnchorProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
  disabled?: boolean;
  variant?: ButtonlikeVariant;
  size?: ButtonlikeSize;
  href: string;
}

const BasicAnchor = React.forwardRef<HTMLAnchorElement, BasicAnchorProps>(
  function BasicAnchorWithRef(
    { size = 'size.md', disabled = false, ...delegated },
    ref
  ) {
    return (
      <BaseAnchor
        {...delegated}
        disabled={disabled}
        className={useLinkClasses({ size })}
        ref={ref}
      />
    );
  }
);

export { BasicAnchor };
export type { BasicAnchorProps };
