import { useTranslation } from 'react-i18next';

import { PurchaseChannel } from '@models/ads';
import { useMemo } from 'react';

interface OfferHeadlineProps {
  logo: string;
  merchantName: string;
  rewardDetails: string;
  online?: boolean;
  inStore?: boolean;
  startDate?: string;
  endDate?: string;
  purchaseChannels?: PurchaseChannel[];
  offerId: number | string;
  createOfferResponse?: any;
}

const OfferHeadline = ({
  logo,
  merchantName,
  rewardDetails,
  startDate,
  endDate,
  purchaseChannels,
  offerId,
  createOfferResponse
}: OfferHeadlineProps) => {
  const [t] = useTranslation();

  const isSuccess = useMemo(() => {
    if (createOfferResponse && createOfferResponse.length === 0) {
      return false;
    }
    return (
      createOfferResponse &&
      createOfferResponse.some(
        (offer) => offer.campaignId === offerId && offer.state === 200
      )
    );
  }, [createOfferResponse]);

  const isError = useMemo(() => {
    if (createOfferResponse && createOfferResponse.length === 0) {
      return false;
    }
    return (
      createOfferResponse &&
      createOfferResponse.some(
        (offer) => offer.campaignId === offerId && offer.state === 400
      )
    );
  }, [createOfferResponse]);

  const errorMessage = useMemo(() => {
    if (createOfferResponse && createOfferResponse.length === 0) {
      return '';
    }
    return (
      createOfferResponse &&
      createOfferResponse.find(
        (offer) => offer.campaignId === offerId && offer.state === 400
      )?.message
    );
  }, [createOfferResponse]);

  if (startDate && endDate) {
    return (
      <div className='mr-12 flex w-96 shrink flex-row items-start'>
        <div className='w-[100px] p-2'>
          <img className='w-full' src={logo} alt={`${merchantName} Logo`} />
        </div>

        <div className='flex w-[310px] flex-col flex-wrap pl-6'>
          <h3 className='text-xs text-gray-disabledText'>
            <span className='mr-1 font-bold'>Ad ID:</span>
            {offerId}
          </h3>
          <h2 className='text-lg font-bold'>{merchantName}</h2>
          <p className='flex-wrap break-all text-sm'>{rewardDetails}</p>
          <p className='item-center mt-2 flex flex-wrap justify-start text-sm font-bold'>
            <span>
              {purchaseChannels &&
                purchaseChannels
                  .map((annotation) => t(`rewardAnnotation.${annotation}`))
                  .join(', ')}
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='mr-12 flex w-96 shrink flex-row items-start'>
      {/* TODO: Fix Logo size */}
      <img
        width='100'
        height='100'
        className='p-2'
        src={logo}
        alt={`${merchantName} Logo`}
      />
      <div className='flex w-[310px] flex-col flex-wrap pl-6'>
        <h3 className='text-xs text-gray-disabledText'>
          <span className='mr-1 font-bold'>Ad ID:</span>
          {offerId}
        </h3>
        <h2 className='text-lg font-bold'>{merchantName}</h2>
        <p className='flex-wrap break-all text-sm'>{rewardDetails}</p>
        {isError && (
          <p className='mt-2 text-sm font-bold text-error'>{errorMessage}</p>
        )}
        {isSuccess && (
          <p className='mt-2 text-sm font-bold text-green-success'>
            {t('label.offerCreated')}
          </p>
        )}
      </div>
    </div>
  );
};

export { OfferHeadline };
