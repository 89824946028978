import { classnames } from '@library/utils';
import { forwardRef, useRef, useState } from 'react';
import ReactCurrencyInputField from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { ClearButton } from '../clear-button';
import { BaseInputProps } from '../types';
import { sizeClasses } from '../utils';

function getStringFromValue(value: number | null | undefined): string {
  return value === null || value === undefined ? '' : `${value}`;
}

interface NumberInputProps extends BaseInputProps<number | null> {
  clearable?: boolean;
  decorator?: React.ReactNode;
  currency?: string;
  suffix?: string;
  decimals?: number;
  postContent?: string;
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      className,
      clearable = true,
      decorator,
      currency,
      disabled,
      decimals = currency ? 2 : 0,
      id,
      size = 'size.lg',
      value,
      onBlur,
      onChange,
      postContent,
      ...delegated
    },
    ref
  ) {
    const { i18n } = useTranslation();

    const [stringValue, setStringValue] = useState<string>(
      getStringFromValue(value)
    );
    const lastFloatValue = useRef(value);

    /**
     * If the last seen float value is equal to the external prop value,
     * use the string (this means all the values are in sync and no one has
     * changed anything elsewhere). This is because if the string has something
     * like "1.", we want to preserve the period as they're typing. However,
     * if the external prop value differs, we should ditch the current string
     * state and use the external value.
     *
     * If the external prop value is undefined, then the input is meant to be
     * uncontrolled so we can preserve the current string state.
     */
    const effectiveValue =
      lastFloatValue.current === value || value === undefined
        ? stringValue
        : getStringFromValue(value);

    return (
      <div
        data-before={postContent}
        className={classnames(
          className,
          'group relative inline-block',
          postContent &&
            `after:absolute after:left-16 after:top-4 after:text-gray-rules after:content-[attr(data-before)]`
        )}
      >
        <ReactCurrencyInputField
          {...delegated}
          className={classnames(
            className,
            sizeClasses[size],
            'peer input-field placeholder-shown:overflow-ellipsis',
            clearable && 'pr-10',
            !!decorator && 'pl-10'
          )}
          allowDecimals={decimals !== 0}
          id={id}
          decimalsLimit={decimals}
          decimalScale={decimals}
          disableAbbreviations={true}
          disabled={disabled}
          intlConfig={{ locale: i18n.language, currency }}
          value={effectiveValue}
          onBlur={onBlur}
          onValueChange={(_value, _fieldName, values) => {
            setStringValue(values?.value || '');

            const floatValue = values?.float;
            // It is unclear how to make the float value undefined, but type-wise
            // it is possible so we safeguard against it and are unable to test it
            /* istanbul ignore next -- @preserve */
            const safeFloatValue = floatValue === undefined ? null : floatValue;

            lastFloatValue.current = safeFloatValue;
            onChange?.(safeFloatValue);
          }}
          ref={ref}
        />
        {decorator ? (
          <span className='input-decorator'>{decorator}</span>
        ) : null}
        {clearable ? (
          <ClearButton
            id={id}
            onClick={() => {
              onChange && onChange(null);
              onBlur && onBlur();
            }}
            disabled={disabled}
          />
        ) : null}
      </div>
    );
  }
);

export type { NumberInputProps };
export { NumberInput };
