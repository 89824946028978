import { getById } from './get-by-id';
import { post } from './post';
import { put } from './put';
import { del } from './delete';
import { get } from './get';

const offerAdapter = {
  get,
  getById,
  post,
  put,
  del
};

export { offerAdapter };
