interface BodyContainerProps {
  children: React.ReactNode;
}

const BodyContainer = ({ children }: BodyContainerProps) => (
  <div className='m-auto w-body'>{children}</div>
);

export type { BodyContainerProps };
export { BodyContainer };
