import { customerAdapter } from '@adapters/customer';
import { CreateCustomer } from '@models/customer';
import { ServiceData, ServiceMutation } from '../service';

type CustomerTypeCreateVariables = {
  payload: CreateCustomer[];
};

interface CreateData extends ServiceData {
  customers: { data: any }; // TODO: replace any for whatever the api returns
}

const create: ServiceMutation<CreateData, CustomerTypeCreateVariables> = async (
  params
) => {
  const { payload } = params;

  const responses = await customerAdapter.post({
    payload
  });

  return {
    customers: responses
  };
};

export type { CreateData };
export { create };
