// This file was generated by "svgr"! DO NOT EDIT THIS FILE DIRECTLY!
// Any changes to this file, or new similar files should be performed
// by modifying the corresponding svg file in the "raw" folder above
// and running: pnpm --filter library svgr
import { Ref, SVGProps, forwardRef } from 'react';
function SvgSortByAlpha(
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 0 24 24'
      width='24px'
      fill='none'
      {...props}
      ref={ref}
    >
      <path
        d='M0 0h24v24H0V0zm0 0h24v24H0V0zm.75.75h22.5v22.5H.75z'
        fill='none'
      />
      <path d='M14.94 4.66h-4.72l2.36-2.36zm-4.69 14.71h4.66l-2.33 2.33zM6.1 6.27L1.6 17.73h1.84l.92-2.45h5.11l.92 2.45h1.84L7.74 6.27H6.1zm-1.13 7.37l1.94-5.18 1.94 5.18H4.97zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z' />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgSortByAlpha);
export default ForwardRef;
