import { z } from 'zod';

const OPPORTUNITY_CURRENCY_ISO_CODES = ['GBP', 'USD'] as const;
const opportunityCurrencyIsoCodeSchema = z.enum(OPPORTUNITY_CURRENCY_ISO_CODES);
type OpportunityCurrencyIsoCode = z.infer<
  typeof opportunityCurrencyIsoCodeSchema
>;

const OPPORTUNITY_PRICING_MODELS = [
  'CPE',
  'CPM',
  'CPR',
  'CPS',
  'CPSH',
  'CPST',
  'CPT'
] as const;
const opportunityPricingModelSchema = z.enum(OPPORTUNITY_PRICING_MODELS);
type OpportunityPricingModel = z.infer<typeof opportunityPricingModelSchema>;

const OPPORTUNITY_STAGE_NAMES = [
  'ClosedLost',
  'ClosedWon',
  'ContractNegotiation',
  'MeetingPresentation',
  'Proposal',
  'Qualification',
  'QualifiedOut',
  'SubmittedProposal',
  'SubmittoClose'
] as const;
const opportunityStageNameSchema = z.enum(OPPORTUNITY_STAGE_NAMES);
type OpportunityStageName = z.infer<typeof opportunityStageNameSchema>;

const OPPORTUNITY_PRODUCT_FI_FUNDING_TYPES = [
  'BankFunded',
  'Counseled',
  'ManagedOffers',
  'ManagedOffersPlus',
  'Unknown'
] as const;
const opportunityProductFiFundingTypeSchema = z.enum(
  OPPORTUNITY_PRODUCT_FI_FUNDING_TYPES
);
type OpportunityProductFiFundingType = z.infer<
  typeof opportunityProductFiFundingTypeSchema
>;

const OPPORTUNITY_HAS_A_SPECIFIC_BUDGET_TYPES = [
  'Yes',
  'No',
  'Unknown'
] as const;
const opportunityHasASpecificBudgetTypeSchema = z.enum(
  OPPORTUNITY_HAS_A_SPECIFIC_BUDGET_TYPES
);
type OpportunityHasASpecificBudgetType = z.infer<
  typeof opportunityHasASpecificBudgetTypeSchema
>;

const OPPORTUNITY_OVERAGE_ALLOWANCE_TYPES = [
  'DollarAmount',
  'Percentage',
  'Unknown'
] as const;
const opportunityOverageAllowanceTypeSchema = z.enum(
  OPPORTUNITY_OVERAGE_ALLOWANCE_TYPES
);
type OpportunityOverageAllowanceType = z.infer<
  typeof opportunityOverageAllowanceTypeSchema
>;

const OPPORTUNITY_PRICING_MODELS_FOR_DYNAMIC_CLO: Set<OpportunityPricingModel> =
  new Set(['CPE']);

const OPPORTUNITY_PRICING_MODELS_FOR_FIXED_CLO: Set<OpportunityPricingModel> =
  new Set(['CPM', 'CPR', 'CPS', 'CPSH', 'CPST', 'CPT']);

const apiOpportunityBrandSchema = z.object({
  BrandId: z.number(),
  Name: z.string()
});

const apiOpportunityProductSchema = z.object({
  Active: z.boolean(),
  FIFundingType: opportunityProductFiFundingTypeSchema.nullable(),
  IsBillable: z.boolean(),
  IsInternalTest: z.boolean(),
  OpportunityLineItemId: z.string(),
  ProductCode: z.string().nullable(),
  ProductName: z.string(),
  TotalPrice: z.number()
});

/*
 * Note the following nullable fields: ContractedEndDate, ContractedStartDate,
 * IONumber, and PricingModel. All of these are technically required, but
 * unfortunately there is a non-trivial amount of malformed data in UAT that
 * we have to deal with. We do so by filtering them out on the adapter layer
 * so that the ones returned by the adapter all have the below already set.
 */
const apiOpportunitySchema = z.object({
  AccountId: z.string(),
  BillingAccountId: z.string().nullable(),
  BillingId: z.string().nullable(),
  BookingAmount: z.number(),
  Brands: z.array(apiOpportunityBrandSchema),
  ChildBillingAccountId: z.string().nullable(),
  ContractedEndDate: z.string().nullable(),
  ContractedStartDate: z.string().nullable(),
  CurrencyIsoCode: opportunityCurrencyIsoCodeSchema,
  DiscountedAmount: z.number(),
  HasASpecificBudget: opportunityHasASpecificBudgetTypeSchema.nullable(),
  IONumber: z.string().nullable(),
  Name: z.string(),
  OpportunityId: z.string(),
  OverageAllowance: z.number(),
  OverageAllowanceType: opportunityOverageAllowanceTypeSchema.nullable(),
  PONumber: z.string().nullable(),
  PricingModel: opportunityPricingModelSchema.nullable(),
  Products: z.array(apiOpportunityProductSchema),
  StageName: opportunityStageNameSchema,
  TotalClosedWonBooking: z.number(),
  TotalDeliveryAmount: z.number(),
  TotalInvoiceCap: z.number()
});

type ApiOpportunity = z.infer<typeof apiOpportunitySchema>;

const adapterOpportunityBrandSchema = z.object({
  brandId: z.number(),
  name: z.string()
});

type AdapterOpportunityBrand = z.infer<typeof adapterOpportunityBrandSchema>;

/*
 * We aren't using zodToCamelCase here because the typing changes don't play
 * well with form schemas.
 */
const adapterOpportunitySchema = z.object({
  accountId: z.string(),
  billingAccountId: z.string().nullable(),
  billingId: z.string().nullable(),
  bookingAmount: z.number(),
  brands: z.array(adapterOpportunityBrandSchema),
  childBillingAccountId: z.string().nullable(),
  contractedEndDate: z.string(),
  contractedStartDate: z.string(),
  currencyIsoCode: opportunityCurrencyIsoCodeSchema,
  discountedAmount: z.number(),
  hasASpecificBudget: opportunityHasASpecificBudgetTypeSchema.nullable(),
  ioNumber: z.string(),
  name: z.string(),
  opportunityId: z.string(),
  overageAllowance: z.number(),
  overageAllowanceType: opportunityOverageAllowanceTypeSchema.nullable(),
  poNumber: z.string().nullable(),
  pricingModel: opportunityPricingModelSchema,
  products: z.array(
    z.object({
      active: z.boolean(),
      fiFundingType: opportunityProductFiFundingTypeSchema.nullable(),
      isBillable: z.boolean(),
      isInternalTest: z.boolean(),
      opportunityLineItemId: z.string(),
      productCode: z.string().nullable(),
      productName: z.string(),
      totalPrice: z.number()
    })
  ),
  stageName: opportunityStageNameSchema,
  totalClosedWonBooking: z.number(),
  totalDeliveryAmount: z.number(),
  totalInvoiceCap: z.number()
});

type AdapterOpportunity = z.infer<typeof adapterOpportunitySchema>;

function opportunityApiToAdapter(apiData: ApiOpportunity): AdapterOpportunity {
  const {
    AccountId,
    BillingAccountId,
    BillingId,
    BookingAmount,
    Brands,
    ChildBillingAccountId,
    ContractedEndDate,
    ContractedStartDate,
    CurrencyIsoCode,
    DiscountedAmount,
    HasASpecificBudget,
    IONumber,
    Name,
    OpportunityId,
    OverageAllowance,
    OverageAllowanceType,
    PONumber,
    PricingModel,
    Products,
    StageName,
    TotalClosedWonBooking,
    TotalDeliveryAmount,
    TotalInvoiceCap
  } = apiData;

  return {
    accountId: AccountId,
    billingAccountId: BillingAccountId,
    billingId: BillingId,
    bookingAmount: BookingAmount,
    brands: Brands.map((brand) => ({
      brandId: brand.BrandId,
      name: brand.Name
    })),
    childBillingAccountId: ChildBillingAccountId,
    // See comment for apiOpportunitySchema - by this point, this should always exist in practice
    contractedEndDate: (ContractedEndDate || '').split('T')[0],
    // See comment for apiOpportunitySchema
    contractedStartDate: (ContractedStartDate || '').split('T')[0],
    currencyIsoCode: CurrencyIsoCode,
    discountedAmount: DiscountedAmount,
    hasASpecificBudget: HasASpecificBudget,
    // See comment for apiOpportunitySchema
    ioNumber: IONumber || '',
    name: Name,
    opportunityId: OpportunityId,
    overageAllowance: OverageAllowance,
    overageAllowanceType: OverageAllowanceType,
    poNumber: PONumber,
    // See comment for apiOpportunitySchema
    pricingModel: PricingModel || 'CPE',
    products: Products.map(
      ({
        Active,
        FIFundingType,
        IsBillable,
        IsInternalTest,
        OpportunityLineItemId,
        ProductCode,
        ProductName,
        TotalPrice
      }) => ({
        active: Active,
        fiFundingType: FIFundingType,
        isBillable: IsBillable,
        isInternalTest: IsInternalTest,
        opportunityLineItemId: OpportunityLineItemId,
        productCode: ProductCode,
        productName: ProductName,
        totalPrice: TotalPrice
      })
    ),
    stageName: StageName,
    totalClosedWonBooking: TotalClosedWonBooking,
    totalDeliveryAmount: TotalDeliveryAmount,
    totalInvoiceCap: TotalInvoiceCap
  };
}

function getOpportunitiesToDetermineCampaignTypeSupport({
  isInternal,
  opportunities
}: {
  isInternal: boolean;
  opportunities: AdapterOpportunity[];
}) {
  return opportunities.filter(({ stageName }) => {
    // internal users will use any opportunity to check for campaign type support
    if (isInternal) {
      return true;
    }

    // external user will use only ClosedWon opportunities to check for campaign type support
    return stageName === 'ClosedWon';
  });
}

export * from './definitions';
export {
  OPPORTUNITY_CURRENCY_ISO_CODES,
  OPPORTUNITY_HAS_A_SPECIFIC_BUDGET_TYPES,
  OPPORTUNITY_OVERAGE_ALLOWANCE_TYPES,
  OPPORTUNITY_PRICING_MODELS,
  OPPORTUNITY_PRICING_MODELS_FOR_DYNAMIC_CLO,
  OPPORTUNITY_PRICING_MODELS_FOR_FIXED_CLO,
  OPPORTUNITY_PRODUCT_FI_FUNDING_TYPES,
  OPPORTUNITY_STAGE_NAMES,
  adapterOpportunityBrandSchema,
  adapterOpportunitySchema,
  apiOpportunitySchema,
  getOpportunitiesToDetermineCampaignTypeSupport,
  opportunityApiToAdapter,
  opportunityCurrencyIsoCodeSchema,
  opportunityHasASpecificBudgetTypeSchema,
  opportunityOverageAllowanceTypeSchema,
  opportunityPricingModelSchema,
  opportunityProductFiFundingTypeSchema,
  opportunityStageNameSchema
};
export type {
  AdapterOpportunity,
  AdapterOpportunityBrand,
  ApiOpportunity,
  OpportunityCurrencyIsoCode,
  OpportunityHasASpecificBudgetType,
  OpportunityOverageAllowanceType,
  OpportunityPricingModel,
  OpportunityProductFiFundingType,
  OpportunityStageName
};
