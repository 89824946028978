import { PublisherLocaleCode } from '@library/utils';
import { AdapterRewardAnnotation, ApiRewardAnnotation } from '..';
import { t } from 'i18next';
import { createDefinition } from '../../utils';

interface RewardAnnotationDefinition {
  readonly id: AdapterRewardAnnotation;
  readonly label: Record<PublisherLocaleCode, string>;
  readonly annotationValue: Record<PublisherLocaleCode, string>;
}

const TEXT = {
  FlashDeal: {
    'en-US': 'Flash Deal',
    'en-GB': 'Flash Deal'
  },
  ReserveExcl: {
    'en-US': 'Reserve Exclusive',
    'en-GB': 'Reserve Exclusive'
  }
} satisfies Record<ApiRewardAnnotation, Record<PublisherLocaleCode, string>>;

const { getById: rewardAnnotationDef, getAll: rewardAnnotationAllDefs } =
  createDefinition<AdapterRewardAnnotation, RewardAnnotationDefinition>({
    definitions: {
      None: {
        label: {
          'en-US': t('rewardmodel.ANNOTATION_NONE'),
          'en-GB': t('rewardmodel.ANNOTATION_NONE')
        },
        annotationValue: {
          'en-US': '',
          'en-GB': ''
        }
      },
      FlashDeal: {
        label: TEXT.FlashDeal,
        annotationValue: TEXT.FlashDeal
      },
      ReserveExcl: {
        label: TEXT.ReserveExcl,
        annotationValue: TEXT.ReserveExcl
      }
    },
    getUnknownDefinition(id: string) {
      return {
        id: id as AdapterRewardAnnotation,
        apiId: id as ApiRewardAnnotation,
        label: {
          'en-US': id,
          'en-GB': id
        },
        annotationValue: {
          'en-US': id,
          'en-GB': id
        }
      };
    }
  });

export { rewardAnnotationAllDefs, rewardAnnotationDef };
export type { RewardAnnotationDefinition };
