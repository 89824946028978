import { PrimaryAnchor, PrimaryButton } from '../buttonlike';
import { useAdPreviewValues } from './ad-preview';

function AdCopy({ value, className }: { value: string; className?: string }) {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: value }} />
  );
}

function Headline() {
  const { headline } = useAdPreviewValues();

  return <AdCopy className='text-xl font-bold' value={headline} />;
}

function RewardDetails() {
  const { rewardDetails, marketingCopy } = useAdPreviewValues();

  return (
    <div>
      <AdCopy value={rewardDetails} />
      {marketingCopy ? (
        <>
          <br />
          <AdCopy value={marketingCopy} />
        </>
      ) : null}
    </div>
  );
}

function CallToAction() {
  const { callToAction, callToActionUrl } = useAdPreviewValues();

  if (callToAction && callToActionUrl) {
    return (
      <PrimaryAnchor href={callToActionUrl}>
        <div className='w-full text-center'>{callToAction}</div>
      </PrimaryAnchor>
    );
  }

  // use button to emulate style but it doesn't link out since it has nowhere to go
  if (callToAction) {
    return (
      <PrimaryButton>
        <div className='w-full text-center'>{callToAction}</div>
      </PrimaryButton>
    );
  }

  return null;
}

function TermsAndConditions() {
  const { termsAndConditions } = useAdPreviewValues();

  return <AdCopy className='text-xs' value={termsAndConditions} />;
}

export { CallToAction, Headline, RewardDetails, TermsAndConditions };
