import { classnames } from '@library/utils';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFieldError } from './use-field-error';

interface InputCountProps<TFormSchema extends FieldValues> {
  name: FieldPath<TFormSchema>;
  max: number;
}

function FieldInputCount<TFormSchema extends FieldValues>({
  name,
  max
}: InputCountProps<TFormSchema>) {
  const { t } = useTranslation();

  const value = useWatch<TFormSchema>({ name });

  const { error } = useFieldError({ name });
  const hasError = !!error;

  const classes = classnames('text-sm', hasError && 'text-error');

  if (max !== Number.POSITIVE_INFINITY) {
    return (
      <span className={classes}>
        {t('library.numberDefault', {
          value: value && (value.length as number)
        })}{' '}
        / {t('library.numberDefault', { value: max })}
      </span>
    );
  }

  return (
    <span className={classes}>
      {t('library.numberDefault', { value: value.length as number })}
    </span>
  );
}

export { FieldInputCount };
