import { XMarkIcon } from '@heroicons/react/24/outline';
import { classnames } from '@library/utils';

interface ClearButtonProps {
  id?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ClearButton = ({ disabled, onClick }: ClearButtonProps) => (
  <button
    type='button'
    className='absolute bottom-0 right-4 top-0 m-auto h-4 w-4'
    disabled={disabled}
    onClick={onClick}
  >
    <XMarkIcon
      className={classnames(
        'stroke-2',
        disabled
          ? 'cursor-default stroke-disabled'
          : 'cursor-pointer stroke-activeInput'
      )}
    />
  </button>
);

export { ClearButton };
