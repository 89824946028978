import { Controller, FieldValues } from 'react-hook-form';
import { InputSize, NumberInput } from '../../../inputs';
import {
  FieldContainer,
  FieldError,
  FieldLabel,
  useFieldError,
  useFieldProps
} from '../helpers';
import { BaseField } from '../helpers/types';

interface NumberFieldProps<TFormSchemaType extends FieldValues>
  extends BaseField<TFormSchemaType> {
  currency?: string;
  decimals?: number;
  placeholder?: string;
  size?: InputSize;
  postContent?: string;
}

function NumberField<TFormSchemaType extends FieldValues>({
  currency,
  decimals,
  deps,
  disabled = false,
  label,
  name,
  optional = false,
  placeholder,
  secondaryLabel,
  size,
  tooltipContent,
  warningSchema,
  postContent
}: NumberFieldProps<TFormSchemaType>) {
  const { error } = useFieldError({ name });
  const hasError = !!error;

  const {
    containerProps,
    controllerProps,
    inputProps,
    labelProps,
    errorProps,
    triggerDeps
  } = useFieldProps<TFormSchemaType>({
    deps,
    label,
    name,
    optional,
    secondaryLabel,
    tooltipContent,
    warningSchema
  });

  return (
    <FieldContainer {...containerProps}>
      <FieldLabel {...labelProps} />
      <Controller
        {...controllerProps}
        render={({ field: { onChange, onBlur, value } }) => (
          <NumberInput
            {...inputProps}
            currency={currency}
            decimals={decimals}
            disabled={disabled}
            placeholder={placeholder}
            aria-describedby={hasError ? errorProps.id : undefined}
            aria-invalid={hasError}
            aria-required={!optional}
            size={size}
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
              triggerDeps();
            }}
            onBlur={onBlur}
            postContent={postContent}
          />
        )}
      />
      <FieldError<TFormSchemaType> {...errorProps} />
    </FieldContainer>
  );
}

export type { NumberFieldProps };
export { NumberField };
