import { Customer } from '@models/customer';
import { ServiceData, ServiceMutation } from '../service';
import { customerAdapter } from '@adapters/customer';

type PortfolioTypeUpdateVariables = {
  payload: Partial<Customer>;
};

interface UpdateData extends ServiceData {
  customers: { data: any }; // TODO: replace any for whatever the api returns
}

const update: ServiceMutation<
  UpdateData,
  PortfolioTypeUpdateVariables
> = async (params) => {
  const { payload } = params;

  const responses = await customerAdapter.put({
    payload
  });

  return {
    customers: responses
  };
};

export type { UpdateData };
export { update };
