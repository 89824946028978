interface ContentProps {
  children: React.ReactNode;
}

const Content = ({ children }: ContentProps) => (
  <div className='overflow-hidden rounded-md border border-neutral bg-content'>
    {children}
  </div>
);

export type { ContentProps };
export { Content };
